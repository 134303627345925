<div class="dialog" [class.hide]="showDilog2">
    <div class="action_btns">
        <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 256 256" xml:space="preserve">

            <defs>
            </defs>
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                <path class="dynamicColorSvg" d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                <path d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                <path d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            </g>
            </svg>
    </div>
    <div >
        <div class="product-details" *ngIf="!show_edit_loader">
            <div class="product-image mt-3 mb-3 flex-nowrap ">
                <div class="pro-image" (click)="trigger_upload_image($event)">
                    <div *ngIf="!image_uploading">
                        <img src="../../assets/images/Rectangle 316.png" alt=""
                            *ngIf="imageurl1x == ''">
                        <video src="{{imageurl1x}}" *ngIf="imageurl1x != '' && isVideo==true"
                            style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;"
                            autoplay muted loop></video>
                        <img src="{{imageurl1x}}" alt=""
                            *ngIf="imageurl1x != '' &&( !isVideo || isVideo=='youtube'||isVideo=='facebook')"
                            style="border-radius:10px;min-width:61px;min-height: 61px;height: 61px;background: #ececec;">
                    </div>
                    <div *ngIf="image_uploading">
                        <img src="assets/imgs/loading2.gif">
                    </div>
                    <input type="file" name="upload_image_update" accept="video/* image/*"
                        (change)="upload2($event,'1')" class="update_image_upload"
                        style="opacity:0;position:absolute;">
                </div>
                <div class="pro-image-details">
                    <h5>Product Image</h5>
                    <span style="font-size: 9px;cursor:pointer;" (click)="openIconModal('edit')">Select
                        via Unsplash</span><br>
                    <span style="font-size: 16px;"><b>Upload Image / Video</b></span><br><span>
                        PNG/JPG/JPEG/GIF/WEBM</span> <br>
                    <span>Max. 2 MB</span>
                </div>
            </div>
            <div class="product-form">
                <!-- <div class="imgDialog"
                    (click)="openImgDialog(current_selected_product_data_full[0]?.product_description,current_select_product_id)">
                    <svg xmlns="http://www.w3.org/2000/svg" class="image-suggetion-icon" width="40"
                        height="40" viewBox=" 0 0 24 24" style="background-color: white;">
                        <path
                            d="M14 9l-2.519 4-2.481-1.96-5 6.96h16l-6-9zm8-5v16h-20v-16h20zm2-2h-24v20h24v-20zm-20 6c0-1.104.896-2 2-2s2 .896 2 2c0 1.105-.896 2-2 2s-2-.895-2-2z" />
                    </svg> <span style="margin-left: 10px;"><b>Select Image</b></span>
                </div> -->
                <form [formGroup]="product_update_form"
                    (ngSubmit)="product_update(product_update_form.value);">
                    <div class="form-group">
                        <input type="text" class="form-control" id="ProductDescription"
                            placeholder="Product Name" formControlName="product_description" />
                    </div>
                    <div class="form-group">
                        <input type="text" class="form-control" id="Pack" placeholder="Pack"
                            formControlName="pack" />
                    </div>
                    <div class="form-group" *ngIf="!this.show_digital_coupons">
                        <input class="form-control" placeholder="Select Label Format"
                            (click)="open_format($event)" formControlName="price_label_format" readonly
                            style="cursor:pointer;">

                        <!--  <input type="text" class="form-control" id="PriceLabel"
                            placeholder="Price label format" formControlName="price_label_format" /> -->
                    </div>


                    <div *ngIf="!show_digital_coupons">
                        <div class="row" style="cursor: pointer;border: 1px solid #DDDDDD;"
                            style="position:absolute;margin-right:15px;z-index:1;background:#fff;"
                            *ngIf="show_format_panel && this.price_label_type =='cleaver'">
                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(1)">
                                <img src="assets/imgs/1format.png">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(2)">
                                <img src="assets/imgs/2format.png">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(3)">
                                <img src="assets/imgs/3formate.png">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(4)">
                                <img src="assets/imgs/4format.png">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(5)">
                                <img src="assets/imgs/5format.png">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(6)">
                                <img src="assets/imgs/6format.png">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(7)">
                                <img src="assets/imgs/7format.png">
                            </div>
                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(8)">
                                <img src="assets/imgs/8format.png">
                            </div>
                        </div>
                        <div class="row" style="cursor: pointer;border: 1px solid #DDDDDD;"
                        style="position:absolute;margin-right:15px;z-index:1;background:#fff;"
                        *ngIf="show_format_panel && this.price_label_type =='circular'">
                        <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(1)">
                                <img src="assets/imgs/label1.svg">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(2)">
                                <img src="assets/imgs/label2.svg">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(3)">
                                <img src="assets/imgs/label3.svg">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(4)">
                                <img src="assets/imgs/label4.svg">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(5)">
                                <img src="assets/imgs/label5.svg">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(6)">
                                <img src="assets/imgs/label6.svg">
                            </div>

                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(7)">
                                <img src="assets/imgs/label7.svg">
                            </div>
                            <div class="col-6"
                                style="border: 0.5px solid #DDDDDD;    text-align: center;padding: 10px;cursor:pointer;"
                                (click)="selected_price_label(8)">
                                <img src="assets/imgs/label8.svg">
                            </div>
                        </div>
                    </div>

                    <div *ngIf="current_price_label_format !='' && !this.show_digital_coupons">
                        <div class="row">
                            <div class="col-4" *ngIf="price_label_svg !=''" style="margin-bottom: 5px;">
                                <div class="pricelabel"  *ngIf="price_label_svg !=''">                            
                                    <span [innerHTML]="price_label_svg_html"
                                    *ngIf="price_label_svg !=''"></span>
                                </div>
                            </div>
           <div class="col-8" *ngIf="current_price_label_format =='format1'">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format1')" />
                                </div>
                                <div class="form-group" style="display: none;">
                                    <input type="text" class="form-control" id="Pack21" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format1')" />
                                </div>
                                <div class="form-group" style="display: none;">
                                    <input type="text" class="form-control" id="Pack43" placeholder=""
                                        formControlName="price_label_value3"
                                        (keyup)="price_format_value_change($event,'value3','format1')" />
                                </div>
                            </div>
                            <div class="col-8" *ngIf="current_price_label_format =='format2'">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format2')" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format2')" />
                                </div>
                                <div class="form-group" style="display:none;">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value3"
                                        (keyup)="price_format_value_change($event,'value3','format2')" />
                                </div>
                            </div>
                            <div class="col-8" *ngIf="current_price_label_format =='format3'">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format3')" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format3')" />
                                </div>
                                <div class="form-group" style="display: none;">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value3"
                                        (keyup)="price_format_value_change($event,'value3','format3')" />
                                </div>
                                <div>
                                    /LB
                                </div>
                            </div>
                            <div class="col-8" *ngIf="current_price_label_format =='format4'">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format4')" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format4')" />
                                </div>
                                <div class="form-group" style="display:none;">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value3"
                                        (keyup)="price_format_value_change($event,'value3','format4')" />
                                </div>
                                <div class="">
                                    <p>/EA</p>
                                </div>
                            </div>
                            <div class="col-8" *ngIf="current_price_label_format =='format5'">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack1" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format5')" />
                                </div>
                                <div class="form-group">
                                    <p>FOR $</p>
                                    <input type="text" class="form-control" id="Pack2" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format5')" />
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack3" placeholder=""
                                        formControlName="price_label_value3"
                                        (keyup)="price_format_value_change($event,'value3','format5')" />
                                </div>
                            </div>
                            <div class="col-8" *ngIf="current_price_label_format =='format6'">
                                <div class="form-group">
                                    <p style="margin-bottom:0;">BUY</p>
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format6')" />
                                </div>
                                <div class="form-group">
                                    <p style="margin-bottom:0;">GET</p>
                                    <input type="text" class="form-control" id="Pack2" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format6')" />
                                </div>
                                <div class="form-group">
                                    <p style="margin-bottom:0;">For</p>
                                    <input type="text" class="form-control" id="Pack3" placeholder=""
                                        formControlName="price_label_value3" placeholder="Free"
                                        value="Free"
                                        (keyup)="price_format_value_change($event,'value3','format6')" />
                                </div>
                            </div>
                            <div class="col-8" *ngIf="current_price_label_format =='format7'">
                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value1"
                                        (keyup)="price_format_value_change($event,'value1','format7')" />
                                </div>

                                <div class="form-group">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value2"
                                        (keyup)="price_format_value_change($event,'value2','format7')" />
                                </div>

                                <div class="form-group" style="display:none;">
                                    <input type="text" class="form-control" id="Pack" placeholder=""
                                        formControlName="price_label_value3"
                                        (keyup)="price_format_value_change($event,'value3','format7')" />
                                </div>

                                <div class="">
                                    <p>OFF</p>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="form-group">

                        <div class="pro-checkboxes">

                              <div class="form-group" >
                                <div class="bundle-input" style="display: flex;">												
                                    <input class="form-control"type="text" id="bundleProduct" formControlName="bundleProduct" #bundleProduct (keydown.enter)="onEnter($event)" placeholder="Add product to bundle" />&nbsp;
                                    <button type="button"  style=" width: 52px;height: 32px;text-align: center;display: flex;align-items: center;justify-content: center" class="btn btn-primary" (click)="updateToBundledList()">Add</button>
                                </div>
                              </div>
                          
                              <div *ngIf="bundledList?.length > 0" class="bundled-list" >
                                <h6 style="color: black;">Products in the Bundle:</h6>
                                <ul>
                                    <li *ngFor="let product of bundledList; let i = index" style="color: black;">
                                    <img src="assets/imgs/icons8-delete.svg" alt="Remove" (click)="removeFromBundledList(product)" class="remove-icon" />
                                    <input 
                                    type="text" 
                                    value="{{product}}" 
                                    #inputElement 
                                    class="form-control bundlelistwidth" 
                                    style="display: inline; width: auto; margin-right: 8px;" 
                                  />
                                  <button  type="button"  class="btn btn-primary"   (click)="onProductChange(i, inputElement.value)"  style="margin-left: 8px;">update</button>
                                    <!-- <button type="button" (click)="removeFromBundledList(product)">Remove</button> -->
                                  </li>
                                </ul>
                              </div>
                        </div>
                    </div>
                    <div class="submit-btn text-center">
                        <button type="submit" class="pro-detail-submit-btn btn btn-primary"
                            [disabled]="buttonDisabled">Update</button>
                    </div>
                </form>
            </div>
        </div>
</div>
<div class="editloader" *ngIf="show_edit_loader">
</div>
</div>
<div class="dilog" *ngIf="showDilog2">
    <div class="action_btns" style="right: 0;">
        <svg (click)="closeModalBgRemove()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 256 256" xml:space="preserve">

            <defs>
            </defs>
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                <path class="dynamicColorSvg" d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                <path d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                <path d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            </g>
            </svg>
    </div>
    <div class="editloader" *ngIf="show_edit_loader">
    </div>
    <h4 class="text-center img-text">Image Preview</h4>
    <div class="image-preview-container">
        <!-- Original Image -->
        <div class="image-container"
             [ngClass]="{'selected': selectedType === 'original'}" 
             (click)="selectImage('original')" *ngIf="imageSrc">
          <h5 class="text-center">Original Image</h5>
          <img class="image_process" [src]="imageSrc" *ngIf="imageSrc" alt="Original" height="150">
        </div>

        <!-- Processed Image (Only if available) -->
        <div *ngIf="showProcessedImage" class="image-container" [ngClass]="{'selected': selectedType === 'processed'}"
            (click)="selectImage('processed')">
            <h5 *ngIf="processedImage" class="text-center">Processed Image</h5>
            <div class="loadingButtons tabloader" *ngIf="tabLoader" style="align-self: center; justify-self: center;"></div>
            <img class="image_process" *ngIf="processedImage" [src]="processedImage" alt="Processed" height="150">
        </div>
    </div>

    <div class="button-group mt-3">
        <button (click)="submitDialog2()" class="btn-primary fs-1 fs-lg-2 fs-md-1 fs-sm-1 p-lg-2 p-md-1 p-sm-1 p-1 border-0">Submit</button>
        <button (click)="removeBackground()" class="btn-danger fs-1 fs-lg-2 fs-md-1 fs-sm-1 p-lg-2 p-md-1 p-sm-1 p-1 border-0 " *ngIf="!this.showProcessedImage">Remove Background </button>
    </div>
</div>
