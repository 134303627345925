<ng-template #mymodal let-modal >
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add Store Owner</h4>
		<button  #closebtn type="button" class="close close_add_store_modal close_store_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<form [formGroup]="storeaddForm" (ngSubmit)="store_add(storeaddForm.value);" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">
			<mat-form-field class="custom-form-field" style="width: 100%;">
				<input matInput placeholder="Store Name" formControlName="add_store_name" autocomplete="off">
				<mat-error *ngFor="let validation of validationMessages.add_store_name">
				<mat-error class="error-message" *ngIf="storeaddForm.get('add_store_name').hasError(validation.type) && (storeaddForm.get('add_store_name').dirty || storeaddForm.get('add_store_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Store Owner Name" formControlName="add_so_name" autocomplete="off">
				<mat-error *ngFor="let validation of validationMessages.add_so_name">
				<mat-error class="error-message" *ngIf="storeaddForm.get('add_so_name').hasError(validation.type) && (storeaddForm.get('add_so_name').dirty || storeaddForm.get('add_so_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<div class="" style="margin-bottom: 1rem;">
				<label style="    font-size: 12px;color: #949494;margin-bottom: 0;">Select Group</label>
				<ng-select  bindLabel="Select Group"   class="selectnoborder selectwithadd" formControlName="add_group_id"  [clearable]="true" (change)="add_select_group_change($event)"   placeholder="Select Group (if applicable)" notFoundText="No group found"   #addgroupid >
					<ng-option value="0">Add new Group</ng-option>
				   <ng-option *ngFor="let type of groupaddlist;let j = index" [value]="type.uid">{{type.group_name}}</ng-option>
				</ng-select>
			</div>
			<div class="" style="margin-bottom: 1rem;">
				<label style="    font-size: 12px;color: #949494;margin-bottom: 0;">Select Warehouse</label>
				<ng-select  bindLabel="Select Warehouse"  class="selectnoborder selectwithadd" formControlName="add_warehouse_id" [clearable]="true" (change)="add_select_warehouse_change($event)" placeholder="Select Warehouse(if applicable)" notFoundText="No warehouse found"   #addwarehouseid >
					<ng-option value="0">Add new Warehouse</ng-option>
				   <ng-option *ngFor="let type of warehouselist;let j = index" [value]="type.uid">{{type.name}}</ng-option>
				</ng-select>
			</div>
			<div class="" style="margin-bottom: 1rem;">
				<label style="    font-size: 12px;color: #949494;margin-bottom: 0;">Select Status</label>
				<ng-select  bindLabel="Select Status"  class="selectnoborder" formControlName="add_status"  [searchable]="false" placeholder="Select Status" notFoundText="No Status"  [clearable]="false"   #addwarehouseid >
				   <ng-option *ngFor="let type of statuslist;let j = index" [value]="type.name">{{type.name}}</ng-option>
				</ng-select>
			</div>
			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Store ID" formControlName="add_store_id">
			</mat-form-field>
			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="add_email" autocomplete="off">
				<mat-error *ngFor="let validation of validationMessages.add_email">
				<mat-error class="error-message" *ngIf="storeaddForm.get('add_email').hasError(validation.type) && (storeaddForm.get('add_email').dirty || storeaddForm.get('add_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field" style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="add_phone" autocomplete="off">
			</mat-form-field>
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-success">Add</button>
	</div>
	</form>
</ng-template>





<ng-template #storeupdateModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title4" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Store Details</h4>
		<button  #closebtn type="button" class="close close_store_update_modal" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>
	<form [formGroup]="store_update_form" (ngSubmit)="store_update(store_update_form.value);" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">
			<mat-form-field class="custom-form-field" style="width: 100%;">
				<input matInput placeholder="Store Name" formControlName="update_store_name" autocomplete="off">
				<mat-error *ngFor="let validation of validationMessages.update_store_name">
				<mat-error class="error-message" *ngIf="store_update_form.get('update_store_name').hasError(validation.type) && (store_update_form.get('update_store_name').dirty || store_update_form.get('update_store_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Store Owner Name" formControlName="update_so_name" autocomplete="off">
				<mat-error *ngFor="let validation of validationMessages.update_so_name">
				<mat-error class="error-message" *ngIf="store_update_form.get('update_so_name').hasError(validation.type) && (store_update_form.get('update_so_name').dirty || store_update_form.get('update_so_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<div class="" style="margin-bottom: 1rem;">
				<label style="    font-size: 12px;color: #949494;margin-bottom: 0;">Select Group</label>
				<ng-select  bindLabel="Select Group"   class="selectnoborder selectwithadd" formControlName="update_group_id"  [clearable]="true" (change)="add_select_group_change($event)"   placeholder="Select Group (if applicable)" notFoundText="No group found"   #addgroupid >
					<ng-option value="0">Add new Group</ng-option>
				   <ng-option *ngFor="let type of groupaddlist;let j = index" [value]="type.uid">{{type.group_name}}</ng-option>
				</ng-select>
			</div>
			<div class="" style="margin-bottom: 1rem;">
				<label style="    font-size: 12px;color: #949494;margin-bottom: 0;">Select Warehouse</label>
				<ng-select  bindLabel="Select Warehouse"  class="selectnoborder selectwithadd" formControlName="update_warehouse_id" [clearable]="true" (change)="add_select_warehouse_change($event)" placeholder="Select Warehouse(if applicable)" notFoundText="No warehouse found"   #addwarehouseid >
					<ng-option value="0">Add new Warehouse</ng-option>
				   <ng-option *ngFor="let type of warehouselist;let j = index" [value]="type.uid">{{type.name}}</ng-option>
				</ng-select>
			</div>
			<div class="" style="margin-bottom: 1rem;">
				<label style="    font-size: 12px;color: #949494;margin-bottom: 0;">Select Status</label>
				<ng-select  bindLabel="Select Status"  class="selectnoborder" formControlName="update_status"  [searchable]="false" placeholder="Select Status" notFoundText="No Status"  [clearable]="false"   #addwarehouseid >
				   <ng-option *ngFor="let type of statuslist;let j = index" [value]="type.name">{{type.name}}</ng-option>
				</ng-select>
			</div>
			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Store ID" formControlName="update_store_id">
			</mat-form-field>
			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="update_email" autocomplete="off">
				<mat-error *ngFor="let validation of validationMessages.update_email">
				<mat-error class="error-message" *ngIf="store_update_form.get('update_email').hasError(validation.type) && (store_update_form.get('update_email').dirty || store_update_form.get('update_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field" style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="update_phone" autocomplete="off">
			</mat-form-field>
			<input hidden formControlName="update_uid" autocomplete="off">
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-success">Update</button>
	</div>
	</form>
</ng-template>




<ng-template #groupModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title2" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add new Group</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" >
			<span aria-hidden="true">×</span>
		</button>
	</div>

	<form [formGroup]="group_add_form" (ngSubmit)="group_add(group_add_form.value);modal.dismiss('Cross click');" style="width: 100%;padding: 0 1rem;">

	<div class="modal-body">
			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Group Name" formControlName="group_name">
				<!-- <mat-error *ngFor="let validation of validationMessages.fullname">
				<mat-error class="error-message" *ngIf="storeaddForm.get('fullname').hasError(validation.type) && (storeaddForm.get('fullname').dirty || storeaddForm.get('fullname').touched)">{{validation.message}}</mat-error>
				</mat-error> -->
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Group Owner Name" formControlName="go_name">
				<mat-error *ngFor="let validation of validationMessages.go_name">
				<mat-error class="error-message" *ngIf="group_add_form.get('go_name').hasError(validation.type) && (group_add_form.get('go_name').dirty || group_add_form.get('go_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Email" formControlName="go_email">
				<mat-error *ngFor="let validation of validationMessages.go_email">
				<mat-error class="error-message" *ngIf="group_add_form.get('go_email').hasError(validation.type) && (group_add_form.get('go_email').dirty || group_add_form.get('go_email').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input type="number" matInput placeholder="Phone No." formControlName="go_phone">
			</mat-form-field>
			
		
		
	</div>
	<div class="modal-footer">
		<button type="submit" class="btn btn-primary">Add Group</button>
	</div>
	</form>
</ng-template>


<ng-template #warehouseModal let-modal>
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title3" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Add new Warehouse</h4>
		<button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
			<span aria-hidden="true">×</span>
		</button>
	</div>


	<div class="modal-body">
		<form [formGroup]="warehouse_add_form" (ngSubmit)="warehouse_add(warehouse_add_form.value);modal.dismiss('Cross click');" style="width: 100%;padding: 0 1rem;">

			<mat-form-field class="custom-form-field"  style="width: 100%;">
				<input matInput placeholder="Warehouse Name" formControlName="warehouse_name">
				<mat-error *ngFor="let validation of validationMessages.warehouse_name">
				<mat-error class="error-message" *ngIf="warehouse_add_form.get('warehouse_name').hasError(validation.type) && (warehouse_add_form.get('warehouse_name').dirty || warehouse_add_form.get('warehouse_name').touched)">{{validation.message}}</mat-error>
				</mat-error>
			</mat-form-field>
			
				<button type="submit" class="btn btn-primary float-right">Add Warehouse</button>
			
		</form>

		<div class="row" style="margin-top: 3rem;padding: 0 2rem;">
			<label>Warehouses List</label>
			<ul class="list-group" style="width: 100%;height: 225px;overflow: auto;">
			  <li class="list-group-item" *ngFor="let item of warehouselist">{{item.name}} <span (click)="delete_warehouse(item.uid)"><img src="assets/imgs/icon-trash.svg" style="    height: 13px;float: right;margin-top: 5px;"></span></li>
			  <li class="list-group-item" *ngIf="warehouselist && warehouselist.length == 0" style="background: #f7f7f7;">No Warehouses added</li>
			</ul>
		</div>




	</div>
	
	
</ng-template>

<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
			<form [formGroup]="filter_store_form" (ngSubmit)="filter_store(filter_store_form.value)"  style="width:100%;">
				<div class="leads-visualization visualizations-section">
					<div class="row no-gutters">
						<div class=" col-3">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111">Stores</h4>
							</div>
						</div>
						
						<div class="col-12">
							<div class="row">
								
								<div class="col-4" style="padding-left: 20px;">
									<div class="form-group">
										<ng-select formControlName="warehouse_id" [clearable]="false" placeholder="Select Warehouse" notFoundText="No warehouse found" class="select_input_noborder"   #warehouseid >
											<ng-option [value]="0">All Warehouses</ng-option>
										   <ng-option *ngFor="let type of warehouselist;let j = index" [value]="type.warehouse_name">{{type.warehouse_name}}</ng-option>
										</ng-select>
									</div>
								</div>
								
								<div class="col-2"  style="padding-left: 0;">
					    				<input class="form-control" formControlName="store_id"  type="text" placeholder="Search by Keyword" >
					  			</div>
					  			
					  			<div class="col" style="padding: 0;">
									<button type="submit" class="btn btn-primary" style="background: #71B01D !important;border:none;">Apply</button>
								
									<button type="button" class="btn btn-outline-primary" style="margin-left: 10px; background: #fff !important;
    border:1px solid #76B100 !important;color:#71B01D !important;" (click)="clear_filter()">Clear Filter</button>
								
									<button type="button" class="btn btn-success" (click)="addNewStore()" style="    float: right;margin-right: 10px;"><i class="fas fa-plus" style="margin-right:4px;"></i>Add New Store</button>
								</div>
							</div>
						</div>

						

						
					</div>
				</div>
			</form>

			<div class="row no-gutters">
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="stores_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="company_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Company Name </th>
							<td mat-cell *matCellDef="let element"> {{element.company_name}} </td>
						</ng-container>
						
						<ng-container matColumnDef="so_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> StoreOwner Name </th>
							<td mat-cell *matCellDef="let element"> {{element.store_owner_name}}
							</td>
						</ng-container>
						<!-- <ng-container matColumnDef="group">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Group </th>
							<td mat-cell *matCellDef="let element">
								{{element.group_name}}

							</td>
						</ng-container> -->
						<ng-container matColumnDef="added_date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Added Date </th>
							<td mat-cell *matCellDef="let element"> {{element.created_at.seconds * 1000 | date:'MM/dd/yyyy'}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="store_id">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Store ID </th>
							<td mat-cell *matCellDef="let element"> {{element.store_id}} </td>
						</ng-container> -->
						<ng-container matColumnDef="branch_count">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Total Branches </th>
							<td mat-cell *matCellDef="let element"> {{element.branch_count}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="status">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Status </th>
							<td mat-cell *matCellDef="let element"> {{element.status}} </td>
						</ng-container> -->

						<ng-container matColumnDef="login">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Login </th>
							<td mat-cell *matCellDef="let element"> 
								<button style="background: transparent;border: none !important;outline: none;"><div class="custom-control custom-switch">
						              <input type="checkbox" class="custom-control-input" id="customSwitch{{element.uid}}" [checked]="element.can_login == '1'" (click)="change_login_status(element.uid,element.user_id,element.can_login)">
						              <label class="custom-control-label" for="customSwitch{{element.uid}}" ></label>
						            </div>
						        </button>
						        
							</td>
						</ng-container>
				
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
								<button class="btn btn-sm  btnicon" style="border:1px solid #71B01D;margin-right: 5px;"  (click)="edit_store(element.uid)"><img src="assets/imgs/icon-pencil.svg" ></button>
							<button class="btn btn-sm btn-outline-success" (click)="view_branches(element.uid)">View Branches</button>
							<!-- <button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
							</td>
						</ng-container>  
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div>

	</div>
</div>
