import { Injectable } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import Shepherd from "shepherd.js";

@Injectable({
  providedIn: "root",
})
export class AppTourService {
  private tour: Shepherd.Tour;
  private tourSeenFlag = 'tourSeen';
  currentUrl: string;
  stepsID: any;
  constructor(private router: Router) {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        },
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.tour.isActive) {
        if (event.url !== this.currentUrl) {
          this.tour.cancel();
        }
        this.currentUrl = event.url;
      }


    });
  }

  private setTourSeenFlag() {
    localStorage.setItem(this.tourSeenFlag, 'true');
  }

  private hasTourBeenSeen() {
    return localStorage.getItem(this.tourSeenFlag) === 'true';
  }




  startTour() {
    if (!this.hasTourBeenSeen()) {
      this.tour.addSteps([
        {
          id: 'show-tour-action',
          title: '<h3>Welcome To Our Digital Circular</h3>',
          text: '<p>Here you will find our sale items, coupons, and recipes. It’s all about the plus button. Use it to add everything to your shopping list. </p>',
          attachTo: {
            element: '',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
            },
          },
          buttons: [
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'START TOUR',
              action: this.tour.next
            }

          ]
        },
        {
          id: 'step-1',
          title: '<h3>The Browse Page</h3>',
          text: '<p>This is where you find and add all our sale items to your shopping list.</p>',
          attachTo: {
            element: '#Browse',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#Browse');
              if (!element) {
                if (previousId === 'show-tour-action') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-2',
          title: '<h3>The Coupons Page</h3>',
          text: '<p>This is where you find and add coupons to your shopping list.</p>',
          attachTo: {
            element: '#Coupons',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          popperOptions: {
            modifiers: [
              {
                name: 'arrow',
                options: {
                  element: '.shepherd-arrow',
                },
              },
              {
                name: 'offset',
                options: {
                  offset: [0, 16], // You may need to adjust this offset based on your design
                },
              },
            ],
          },
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#Coupons');
              if (!element) {
                if (previousId === 'step-1') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-3',
          title: '<h3>The Recipes Page</h3>',
          text: '<p>This is where you find recipes and add recipe ingredients to your shopping list. </p>',
          attachTo: {
            element: '#Recipes',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#Recipes');
              if (!element) {
                if (previousId === 'step-2') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-4',
          title: '<h3>The Categories Bar</h3>',
          text: '<p>Press a category to just see the sale items in that category.</p>',
          attachTo: {
            element: '.food-category-menu',
            on: 'bottom-start'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.food-category-menu');
              if (!element) {
                if (previousId === 'step-3') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-5',
          title: '<h3>The Plus Button</h3>',
          text: '<p>It’s all about the Plus Button. Use this button to add sale items, coupons, and recipe ingredients to your shopping list.</p>',
          attachTo: {
            element: '.deleteicon_click',
            on: 'bottom-start'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.deleteicon_click');
              if (!element) {
                if (previousId === 'step-4') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },
        {
          id: 'step-6',
          title: '<h3>Sale Items</h3>',
          text: '<p>Click any sale item to open a popup with more details about the item.</p>',
          attachTo: {
            element: '.grid-stack-item-content',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.grid-stack-item-content');
              if (!element) {
                if (previousId === 'step-5') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },

        {
          id: 'step-7',
          title: '<h3>The Shopping List</h3>',
          text: '<p>This is your shopping list! It is the list view of everything you added to your shopping list. On mobile, you can swipe left and right to open and close.</p>',
          attachTo: {
            element: '.shopping-list-headline',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.shopping-list-headline');
              if (!element) {
                if (previousId === 'step-6') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },

        {
          id: 'step-8',
          title: '<h3>Circular Good Through Dates</h3>',
          text: '<p>Here you will find the current circular from and to dates. </p>',
          attachTo: {
            element: '#price-goods',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.price-goods');
              if (!element) {
                if (previousId === 'step-7') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },

        {
          id: 'step-9',
          title: '<h3>Social Media Icons</h3>',
          text: '<p>Here you will find links to our social media pages. </p>',
          attachTo: {
            element: '.follow-headline',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.follow-headline') as any;
              if (!element || (element && element.offsetParent === null)) {
                if (previousId === 'step-8') {
                  this.tour.next();
                } else {
                  this.tour.back();
                }
              }
            }
          },

          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },

        {
          id: 'step-10',
          title: '<h3>Main Menu</h3>',
          text: '<p>Click here to find links to our website pages and other store information.</p>',
          attachTo: {
            element: '#SiteMenuDropdown',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#SiteMenuDropdown');
              if (!element) {
                if (previousId === 'step-9') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },

        {
          id: 'step-11',
          title: '<h3>The Help Icon</h3>',
          text: '<p>Press this icon to open this tutorial anytime.  </p>',
          attachTo: {
            element: '.Help',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.Help');
              if (!element) {
                if (previousId === 'step-10') {
                  this.tour.complete();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'FINISH',
              action: () => {
                this.tour.complete()
              }
            }
          ]
        },
      ]);


      this.tour.start();

      this.setTourSeenFlag();

    }
  }

  cancelTour() {
    this.tour.cancel();
  }

  private getButtons(labels: string[]): Shepherd.Step.StepOptionsButton[] {
    return labels.map((label) => ({
      action: this.tour[label.toLowerCase()],
      classes: label.toLowerCase() + "-button",
      text: label,
    }));
  }
}
