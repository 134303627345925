<!-- Login Section  -->
<section class="login-form-section">
    <div class="container">
            <div class="login-box">
              <div class="login-area">  
              <div class="login-box-top-area">
                    <span class="title-img"><img src="../assets/images/DIGITAL-MENUS-PRO1.png" alt="login-headline"></span>
                </div>
                <div class="login-form">
                  <form>
                    <div class="form-group row">
                      <label for="Email" class="col-sm-2 col-form-label sr-only">Email</label>
                      <div class="col-sm-12">
                        <input type="email" class="form-control" id="email" #userName   placeholder="Enter your Email">
                      </div>
                    </div>
                    <div class="form-group row">
                      <label for="Password" class="col-sm-2 col-form-label sr-only">Password</label>
                      <div class="col-sm-12">
                        <input type="password" class="form-control" placeholder="Enter your password" id="password" #userPassword (keyup.enter)="SignIn(userName.value, userPassword.value);" >
                      </div>
                    </div>
                   
                    <div class="form-group row password-row">
                      
                      <div class="col-sm-6">
                        <div class="form-check d-flex">
                          <input class="form-check-input" type="checkbox" id="gridCheck1">
                          <label class="form-check-label show-pwd" for="gridCheck1">
                           Show Password
                          </label>
                        </div>
                      </div>
                        
                      <div class="col-sm-6">
                        <div class="form-check text-right">
                           <a href="forgot-password" data-toggle="modal" data-target="#forgotPasswordPopup"class="forgot-password">Forgot Password?</a>
                        </div>
                      </div>
                    </div>
                    <div class="form-group row">
                      <div class="col-sm-12 text-center">
                        <button  class="btn btn-primary login-submit-btn" (click)="SignIn(userName.value, userPassword.value);" *ngIf="!loading"> Sign In</button>
                          <button class="sign-in-btn btn-light " style="background:#71B01D;border:none;padding:0;text-align:center;width: 36px;height: 36px;border-radius: 32px;" *ngIf="loading"><img src="./assets/imgs/loading.gif" style="height: 22px;"></button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
        </div>
    </div>
  </section>
<!-- Login Section  -->


<!-- forgot password Modal -->

<div class="modal fade" id="forgotPasswordPopup" tabindex="-1" role="dialog" aria-labelledby="forgotPasswordPopup"
aria-hidden="true">
<div class="modal-dialog modal-dialog-centered" role="document">
  <div class="modal-content ">
    <div class="modal-header col-md-12 d-flex ">
      <div class="admin-account-details-headline d-flex align-items-center col-md-6 p-0 ">
        <span class="close pl-0 pr-4 ml-0" data-dismiss="modal" aria-label="Close"><img
            src="../../assets/images/Arrow.png" alt=""></span>
        <a class="mb-0 cursor-pointer" routerLink="['/forgot-password']">Forgot Password</a>
      </div>

    </div>
    <div class="modal-body">
        <div class="forgot-pwd-img text-center">
            <img src="../../assets/images/forgot-pwd.svg" alt="">
        </div>
        <div class="forgot-pwd-text mt-5">
          <p class="text-center">Don’t worry! In order to set up a new Password,  <br>
            we will send you a link on your registered Email id.</p>
            <input type="email" id="RegEmailId" placeholder="Enter your Register Email Id" class="form-control gray-form"/>
        </div>
      <div class="change-password-msg mt-4 mb-4">
       
        <span class="d-flex justify-content-center"><button type="button" class="btn btn-primary">Send Password Reset Link</button></span>
      </div>
    </div>

  </div>
</div>
</div>







