<div class="dialog">
    <div class="action_btns">
        <svg (click)="closeModal()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="40" height="40" viewBox="0 0 256 256" xml:space="preserve">

            <defs>
            </defs>
            <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                <path class="dynamicColorSvg" d="M 45 90 C 20.187 90 0 69.813 0 45 C 0 20.187 20.187 0 45 0 c 24.813 0 45 20.187 45 45 C 90 69.813 69.813 90 45 90 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(236,0,0); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                <path d="M 28.902 66.098 c -1.28 0 -2.559 -0.488 -3.536 -1.465 c -1.953 -1.952 -1.953 -5.118 0 -7.07 l 32.196 -32.196 c 1.951 -1.952 5.119 -1.952 7.07 0 c 1.953 1.953 1.953 5.119 0 7.071 L 32.438 64.633 C 31.461 65.609 30.182 66.098 28.902 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                <path d="M 61.098 66.098 c -1.279 0 -2.56 -0.488 -3.535 -1.465 L 25.367 32.438 c -1.953 -1.953 -1.953 -5.119 0 -7.071 c 1.953 -1.952 5.118 -1.952 7.071 0 l 32.195 32.196 c 1.953 1.952 1.953 5.118 0 7.07 C 63.657 65.609 62.377 66.098 61.098 66.098 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
            </g>
            </svg>
        <div *ngIf="!data.YoutubeURL">
            <img src="{{image_path}}" (click)="addItem()" *ngIf="!this.data.product.hide_addtolist_label">
            <div class="count-products box_layout_count d-flex flex-column align-items-center justify-content-center"
                *ngIf="qnt_dropdown" style="visibility: visible;">
                <i class="fas fa-plus" (click)="increaseQNT()"></i>
                <span class="layout_product_qnt product_qnt"
                    style="border: 2px solid black;margin: 5px 0;padding: 2px 8px;background-color: #ffffff;">{{product_count}}</span>
                <i class="fas fa-minus" (click)="decreaseQNT()"></i>
            </div>
        </div>
    </div>
    <div class="image_label">
        <div class="label" id="price_label"></div>
        <div class="Digital_price_main1" id="digital_preview"></div>
        <div class="use_up_to_wrapper1" id="transaction_limit_preview"></div>
        <div class="price_link_data1" id="price_link_preview"></div>
        <div *ngIf="data.YoutubeURL">
            <iframe id="youtube_id" width="512" height="350" title="YouTube video player" frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen>
            </iframe>
        </div>
        <img style="width: 100%;" *ngIf="getHostname(data.product.product_image) == 'images.unsplash.com'" src="{{data.product.product_image}}">
        <img *ngIf="!data.YoutubeURL && !data.product.isVideo && getHostname(data.product.product_image) != 'images.unsplash.com'" src="{{data.product.product_image}}&size=408&thumbnail=true&palette=RGB">
        <video playsinline src="{{data.product.product_image}}" *ngIf="data.product.isVideo" autoplay loop></video>
    </div>
    <div class="product_info">
        <div class="info_text">
            <h3>{{data.product.product_description}}</h3>
            <p>{{data.product.pack}}</p>
            <!-- <p *ngIf="!this.data.override_noad_layout">Offer valid from {{ (data.actualStartDate ? data.actualStartDate : data.StartDate) | date:"MM/dd/yyyy"}} to {{ (data.actualEndDate ? data.actualEndDate : data.EndDate) | date:"MM/dd/yyyy"}}</p> -->
        </div>
        <div class="share_icon">
            <a class="icon-wrapper"><span>share</span>
                <mat-icon role="img" svgicon="share-circle"
                    class="mat-icon" aria-hidden="true" data-mat-icon-type="svg"
                    data-mat-icon-name="share-circle"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.8 103.7"
                        fill="#76b101" fit="" height="100%" width="100%" preserveAspectRatio="xMidYMid meet" focusable="false">
                        <title>share</title>
                        <path class="dynamicColorSvg"
                            d="M55,3a51.85,51.85,0,1,0,51.9,51.8A51.9,51.9,0,0,0,55,3Zm0,98.7A47.07,47.07,0,0,1,16.5,81.6C33.2,63.3,57.1,64.2,60.4,64.4V81.7L89.3,52.8l-28.9-29V39.9C54,40.6,25.1,45.4,15,79.4a46.94,46.94,0,1,1,40,22.3Z"
                            transform="translate(-3.1 -3)"></path>
                    </svg></mat-icon>
            </a>
        </div>
    </div>
</div>
<div class="loader" *ngIf="show_loader">
    <mat-spinner mode="indeterminate" style=" position: fixed; top: 50%; margin: 0 auto; right: 0; left: 0; transform: translate(-50%);"></mat-spinner>
</div>