import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss',
  ]
})
export class DashboardComponent implements OnInit {


user:any;
ghost_mode:boolean = false;
get_current_store_id:any;
store_data:any;
  	constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
  	}

  	ngOnInit(): void {

	  	let chkghost = localStorage.getItem('ghost_mode');
  		if(chkghost)
  		{
  			this.ghost_mode = true;
  		} else {
  			this.ghost_mode = false;
  		}
  		this.get_current_store_id = localStorage.getItem('current_store_id');
  		if(this.get_current_store_id)
  		{
  			console.log('get data from localStorage');
  			this.user = JSON.parse(this.get_current_store_id);
  		} else{
  			console.log('get data from login user');
  			this.user = JSON.parse(localStorage.getItem('userrole'));
  		}
  		console.log('IN STORE DASHBORD',this.user.store_uid);
  		this.afs.collection('/stores',ref=>ref.where('uid','==',this.user.store_uid)).snapshotChanges().subscribe(data => {
			this.store_data = data.map(e => {
				return{
					uid: e.payload.doc.id,
					store_name: e.payload.doc.data()['store_name'],
					email: e.payload.doc.data()['email'],
					group_id: e.payload.doc.data()['group_id'],
					group_name: e.payload.doc.data()['group_name'],
					phone: e.payload.doc.data()['phone'],
					store_id: e.payload.doc.data()['store_id'],
					store_owner_name: e.payload.doc.data()['store_owner_name'],
					warehouse_id: e.payload.doc.data()['warehouse_id'],
					warehouse_name: e.payload.doc.data()['warehouse_name'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					status:e.payload.doc.data()['status'],
					user_id:e.payload.doc.data()['user_id']
				};
			});	
			console.log("IN STORE DASHBOARD ----",this.store_data);
		});
  	}

}