import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import {   MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';


@Component({
  selector: 'app-add-new-store',
  templateUrl: './add-new-store.component.html',
  styleUrls: ['./add-new-store.component.scss']
})
export class AddNewStoreComponent implements OnInit {

storeaddForm: FormGroup;
closeResult:any;
modalRef:any;
create_warehouse_form:FormGroup;
warehouselist:any = [];
required_label_warehouse:boolean = false;
selected_warehouse:any;
user:any;
users:any;

@ViewChild('warehouseAddModal') warehouseAddModal;

  constructor( @Inject(PLATFORM_ID) private platformId: object,
  private route: ActivatedRoute,
  private router:Router,
  private formBuilder: FormBuilder,public authService: AuthService,
  public afs: AngularFirestore,
  public afAuth: AngularFireAuth,
  private modalService: NgbModal,
  private storage: AngularFireStorage,private _snackBar: MatSnackBar) {

	this.storeaddForm = new FormGroup({
			company_name: new FormControl(null, Validators.compose([Validators.required])),
			store_owner_name: new FormControl(null, Validators.compose([Validators.required])),
			email       : new FormControl(null),
			phone       : new FormControl(null),
			warehouse   : new FormControl(0),
			store_id    : new FormControl( null),
			branch_name : new FormControl(null),
			zip_code    : new FormControl(null),
			address     : new FormControl(null),
			menu1_name  : new FormControl(null),
			menu1_link  : new FormControl(null),
			menu2_name  : new FormControl(null),
			menu2_link  : new FormControl(null),
			menu3_name  : new FormControl(null),
			menu3_link  : new FormControl(null),
			menu4_name  : new FormControl(null),
			menu4_link  : new FormControl(null),
			menu5_name  : new FormControl(null),
			menu5_link  : new FormControl(null),
			menu6_name  : new FormControl(null),
			menu6_link  : new FormControl(null),
			menu7_name  : new FormControl(null),
			menu7_link  : new FormControl(null),
			menu8_name	: new FormControl(null),
			menu8_link 	: new FormControl(null),
			menu9_name 	: new FormControl(null),
			menu9_link 	: new FormControl(null),
			access_layout: new FormControl(""),
			access_recipe: new FormControl(""),
			access_coupon: new FormControl(""),
	  });

		this.create_warehouse_form = new FormGroup({
			warehouse_name: new FormControl(null, Validators.compose([Validators.required])),
    	});


  }

  ngOnInit(): void {


			console.log('get data from login user');
			this.user = JSON.parse(localStorage.getItem('userrole'));
			console.log("USER GOT IISS---",this.user);

			//this.user = JSON.parse(localStorage.getItem('userrole'));
			this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
			this.users = data.map(e => {
			return{
			uid: e.payload.doc.id,
			photoURL: e.payload.doc.data()['photoURL']?e.payload.doc.data()['photoURL']:'./assets/imgs/user-placeholder.png',
			displayName: e.payload.doc.data()['displayName'],
			role: e.payload.doc.data()['role']
			};
			});
			console.log(this.users);
			});

  	this.afs.collection('/warehouses',ref=>ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.warehouselist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				warehouse_name: e.payload.doc.data()['warehouse_name']?e.payload.doc.data()['warehouse_name']:"",
			};
		});	
	});

  }

  goToLogin() {
	window.location.href = '/login';
  }
  ChangePassword() {
	$('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
	$('#exampleModal').css('opacity', '1');
  }
  backToStore(){
	this.router.navigate(['/admin/store-owners']);
  }

  store_add(data)
  {
		console.log('store data got is ');
		console.log(data);
		//return false;
	let storedata = {
		email: data.email?data.email:"",
		company_name:data.company_name?data.company_name:"",
		store_owner_name: data.store_owner_name?data.store_owner_name:"",
		phone: data.phone? data.phone:"",
		warehouse: data.warehouse?data.warehouse:"",
		store_id: data.store_id?data.store_id:"",
		branch_name: data.branch_name?data.branch_name:"",
		zip_code: data.zip_code?data.zip_code:"",
		address: data.address?data.address:"",
		menu1_name: data.menu1_name?data.menu1_name:"",
		menu1_link: data.menu1_link?data.menu1_link:"",
		menu2_name: data.menu2_name?data.menu2_name:"",
		menu2_link: data.menu2_link?data.menu2_link:"",
		menu3_name: data.menu3_name?data.menu3_name:"",
		menu3_link: data.menu3_link?data.menu3_link:"",
		menu4_name: data.menu4_name?data.menu4_name:"",
		menu4_link: data.menu4_link?data.menu4_link:"",
		menu5_name: data.menu5_name?data.menu5_name:"",
		menu5_link: data.menu5_link?data.menu5_link:"",
		menu6_name: data.menu6_name?data.menu6_name:"",
		menu6_link: data.menu6_link?data.menu6_link:"",
		menu7_name: data.menu7_name?data.menu7_name:"",
		menu7_link: data.menu7_link?data.menu7_link:"",
		menu8_name: data.menu8_name?data.menu8_name:"",
		menu8_link: data.menu8_link?data.menu8_link:"",
		menu9_name: data.menu9_name?data.menu9_name:"",
		menu9_link: data.menu9_link?data.menu9_link:"",
		access_layout: data.access_layout?data.access_layout:"",
		access_recipe: data.access_recipe?data.access_recipe:"",
		access_coupon: data.access_coupon?data.access_coupon:"",
		type: 'main_store',
		branch_count: 1,
		can_login: '1',
		created_at: new Date(),
	};

	this.afAuth.createUserWithEmailAndPassword(data.email, "123456")
		.then((result) => {
			/* Call the SendVerificaitonMail() function when new user sign
			up and returns promise */
			console.log('user id',result.user.uid);
			console.log(result);
			//user data 
			let userdata = {
				email: data.email?data.email:"",
				displayName: data.store_owner_name?data.store_owner_name:"",
				uid: result.user.uid,
				role: "group",
				created_at: new Date(),
				can_login: '1',
			};

			let useradd = this.afs.doc('/users/'+ result.user.uid).set(userdata,{merge:true}).then(docRef => {
			});

			storedata['user_id'] = result.user.uid;
			//store data
			let storeadd = this.afs.collection('/stores').add(storedata).then(docRef => {
				const itemRefx= this.afs.doc('/stores/'+ docRef.id);
				itemRefx.update({ uid: docRef.id, parent_id: docRef.id,user_id : result.user.uid }); 
				const itemRefx2= this.afs.doc('/users/' + result.user.uid);
				itemRefx2.update({ store_uid: docRef.id}); 
			});

			//sending password reset mail to store owner
			this.ForgotPassword(data.email);

			this.router.navigate(['/admin/store-owners']);

	}, (err) => {
		console.log(err,"Error");
		this._snackBar.open(err.message, '', {
			duration: 2000,
		});
	});

  }

  addWarehouse()
  {
  	console.log('open warehouse modal here');
  	this.open(this.warehouseAddModal,'modal-basic-title2');

  }
  warehouseChange()
  {
  	console.log("SELECTED WAREHOUSE ",this.selected_warehouse);
  	let selected_value = this.selected_warehouse;
  	//console.log('warehouse value changedd',this.storeaddForm.value.warehouse.value);
  	//console.log(value);
  	if(selected_value == "add")
  	{
  		//open the warehouse add modal
  		this.open(this.warehouseAddModal,'modal-basic-title2');
  	}
  }

  submit_warehouse(data)
  {
  	console.log(data);
	let thedata={
		warehouse_name: data.warehouse_name,
		created_at: new Date(),
	};

  	let radio_add = this.afs.collection('/warehouses').add(thedata).then(docRef => {
		const itemRefx= this.afs.doc('/warehouses/'+docRef.id);
		itemRefx.update({ uid: docRef.id}); 
	});

	this.create_warehouse_form.reset();
	let element: HTMLElement = document.getElementsByClassName('close_add_warehouse_modal')[0] as HTMLElement;
	element.click();

	this._snackBar.open("Warehouse created successfully", '', {
		duration: 1000,
	});

  }

  	open(content,title) {
		this.modalRef = this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
		//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	ForgotPassword(passwordResetEmail) {
		return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
		console.log('password reset email sent to '+ passwordResetEmail);
		// window.alert('Password reset email sent, check your inbox.');
		}).catch((error) => {
		//window.alert(error)
		})
	}
	delete_warehouse(uid)
	{
		console.log(uid);
	    let del_ware = this.afs.doc('/warehouses/'+uid).delete();
	    this._snackBar.open('Warehouse deleted successfully', '', {
	      duration: 1000,
	    });
 
	}


}
