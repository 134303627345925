<div class="landing_modal">
  <h3 mat-dialog-title style="text-align: center">INSTALL THE DIGITAL CIRCULAR APP</h3>
<div mat-dialog-content>
  Access your digital Circular Shopping List, Coupons, and Recipes anytime.
</div>
<div class="io-dialog-content-title-1">
    TAP <img style="width: 25px;" src="../assets/icons/ios-share-icon.png" alt=""> BELOW
</div>
<div mat-dialog-content class="io-dialog-content-title-2 card-title-responsive">
  THEN CHOOSE <div>Add to homescreen <img style="width: 25px;" src="../assets/icons/add-to-home.png" alt=""></div>

</div>
<div mat-dialog-content class="io-dialog-content"> 
  <!-- give the class -->
  Then look for the icon on your home screen.
</div>
<!-- <div mat-dialog-actions style="justify-content: space-around;">
  <button mat-button mat-dialog-close>Close</button>
</div> -->
<div class="arrow-set">
<img src="../assets/icons/down-arrow.png" alt="">
</div>
</div>