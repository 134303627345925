import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: 'app-incident-reports',
  templateUrl: './incident-reports.component.html',
  styleUrls: ['./incident-reports.component.scss']
})
export class IncidentReportsComponent implements OnInit {



tableColumns  :  string[] = ['id', 'incident_name', 'reported_by','reported_on','status','actions'];
groups_list:any;
closeResult:any;
view_details:any; 
incident_list:any;
user:any;
@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('viewIncidentModal') viewIncidentModal;


  	constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
	

   }

  	ngOnInit() {
  		this.user = JSON.parse(localStorage.getItem('userrole'));
		this.afs.collection('/incident-reports',ref=>ref.where('store_uid','==',this.user.store_uid).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.incident_list = data.map(e => {
				return{
					uid: e.payload.doc.id,
					incident_name: e.payload.doc.data()['incident_name']?e.payload.doc.data()['incident_name']:"",
					description: e.payload.doc.data()['description']?e.payload.doc.data()['description']:"",
					image1_url: e.payload.doc.data()['image1_url']?e.payload.doc.data()['image1_url']:"",
					image2_url: e.payload.doc.data()['image2_url']?e.payload.doc.data()['image2_url']:"",
					created_at:e.payload.doc.data()['created_at']?e.payload.doc.data()['created_at']:0,
					reported_by:e.payload.doc.data()['reported_by']?e.payload.doc.data()['reported_by']:'',
					status:e.payload.doc.data()['status']?e.payload.doc.data()['status']:'pending',
				};
			});	

			this.incident_list = new MatTableDataSource(this.incident_list);
			this.incident_list.sort = this.leadsSort;
			this.incident_list.paginator = this.leadsPaginator;
		});
 
  	}


	open(content,title) {
		this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
	//	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	view_incident_details(data)
	{
		this.view_details = data;
		this.open(this.viewIncidentModal,'modal-basic-title');
	}

	search_incident = (value: string) => {
		this.incident_list.filter = value.trim().toLocaleLowerCase();
	}

  	mark_as_complete(uid)
  	{
  		let incident_update = this.afs.doc('/incident-reports/'+uid).set({status:'completed'},{merge:true}).then(docRef => {
		});

		let element: HTMLElement = document.getElementsByClassName('close_incident_detail_modal')[0] as HTMLElement;
		element.click();

		this._snackBar.open("Reported Marked as complete successfully!", '', {
	    	duration: 1500,
	    });
  	}

} 