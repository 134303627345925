import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CleaverPriceFormatService {

  constructor() { }
  price_format(format,labelSize,price_label_value1,price_label_value2,price_label_value3,current_price_label_format){
    let price_label_svg
    if (format == 'format1') {
		price_label_svg = 
		'<div id="format1" class="circular-card-deal-wrapperknife  ' + labelSize + '">' +
		  '<div class="top-line" style="position:relative; width:100%; height:100%;">' +
			'<img src="../assets/imgs/knipe.png" alt="Cleaver" ' +
				 'style="width:100%; height:100%; object-fit: cover;" />' +
			// Price container absolutely centered on the image:
			'<div class="price-container" ' +
				 'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;">' +
				'<div class="top-line"> ' +
		          '<span class="dollar-sign" style="color:red; font-weight:bold;  margin-right:1px;">$</span>' +
				 '<span id="dollar" class="dollar-amount" style="color:red;  font-weight:bold;">' + price_label_value1 + '</span>' +
			'</div>' +
			'</div>' +
		  '</div>' +
		'</div>';
	   
			}
			if (format == 'format2') {
				if (price_label_value1) {
					price_label_svg = '<div id="format2" class="circular-card-deal-wrapperknife ' + labelSize + '" "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;"><div class="top-line"><span class="dollar-sign" style="color:red;  font-weight:bold;">$</span><span id="dollar" style="color:red;  font-weight:bold;" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper" style="font-weight: bold; color: red;"><span id="cent" class="cents-amount" style="color:red;  font-weight:bold;">' + ' ' + price_label_value2 + '</span></span></div></div></div></div></div>';
				} else {
					price_label_svg = '<div id="format2" class="circular-card-deal-wrapperknife ' + labelSize + '" "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;"><div class="top-line"><span id="cent" style="color:red;  font-weight:bold;" class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="cent-sign" style="color:red; font-weight:bold;">¢</span></span></span></div></div></div></div></div>'
				}
		}
		if (format == 'format3') {
			if (price_label_value1) {
				price_label_svg = '<div id="format3" class="circular-card-deal-wrapperknife ' + labelSize + '" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><div class="price-unit-wrapper" ><span id="cent" class="cents-amount" >' + ' ' + price_label_value2 + '</span><span class="price-unit">/LB</span></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format3" class="circular-card-deal-wrapperknife ' + labelSize + '" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><div class="price-unit-wrapper" ><span class="cent-sign" >¢</span><span class="price-unit">/LB</span></div></div></div></div></div></div>'
			}
		}
		if (format == 'format4') {
			if (price_label_value1) {
				price_label_svg = '<div id="format4" class="circular-card-deal-wrapperknife ' + labelSize + '" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><div class="price-unit-wrapper" ><span id="cent" class="cents-amount" >' + ' ' + price_label_value2 + '</span><span class="price-unit">/EA</span></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format4" class="circular-card-deal-wrapperknife ' + labelSize + '" " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><div class="price-unit-wrapper" ><span class="cent-sign">¢</span><span class="price-unit">/EA</span></div></div></div></div></div></div>'
			}
		}
		if (format == 'format5') {
			if (price_label_value2) {
				price_label_svg = '<div id="format5" class="circular-card-deal-wrapperknife ' + labelSize + '"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold;flex-direction:column;"><div class="num-to-cart pricing-num-for "><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for" >for</span></div> <div class="circular-card-price"><div class="top-line"><span class="dollar-sign" >$</span><span id="cent" class="cents-amount" style="">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></span></div></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format5" class="circular-card-deal-wrapperknife ' + labelSize + '"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold;flex-direction:column;"><div class="num-to-cart pricing-num-for "><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for" >for</span></div><div class="circular-card-price"><div class="top-line"> <span id="third" class="cents-amount" style="">' + ' ' + price_label_value3 + '</span><span class="price-unit-wrapper"><span class="cent-sign">¢</span></span></span></div></div></div></div></div></div>'
			}
		}
		if (format == 'format6') {
			price_label_value3 = price_label_value3 == '' ? 'Free' : price_label_value3
			if (price_label_value3 == 'Free') {
				price_label_svg = '<div id="format6" class="circular-card-deal-wrapperknife ' + labelSize + '"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:64%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold;line-height:0.5;text-transform: uppercase;"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format6" class="circular-card-deal-wrapperknife ' + labelSize + ' withNumber"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:64%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold;line-height:0.5;text-transform: uppercase;"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div>';
			}
		}
		if (current_price_label_format == 'format7') {
			if (price_label_value1) {
				price_label_svg = '<div id="format7" class="circular-card-deal-wrapperknife  withNumber ' + labelSize + '"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:65%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><span><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div>';
			} else {
				price_label_svg = '<div id="format7" class="circular-card-deal-wrapperknife  withNumber ' + labelSize + '"  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:65%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><span><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + price_label_value2 + '</span><span class="cent-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div>'
			}
		}
		if (current_price_label_format == 'format8') {
			price_label_svg = '<div id="format8" class="circular-card-deal-wrapperknife ' + labelSize + ' withNumber""style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div style="position:relative;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Market</span></div><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Price</span></div></div></div></div></div>';

		}
    return price_label_svg
  }
  selected_price_format(current_price_label_format,price_label_value1,price_label_value2,price_label_value3){
	let price_label_svg
	if (current_price_label_format == 'format1') {
		price_label_svg = 
		'<div id="format1" class="circular-card-deal-wrapperknife  ">' +
		  '<div class="top-line" style="position:relative; width:100%; height:100%;">' +
			'<img src="../assets/imgs/knipe.png" alt="Cleaver" ' +
				 'style="width:100%; height:100%; object-fit: cover;" />' +
			// Price container absolutely centered on the image:
			'<div class="price-container" ' +
				 'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;">' +
				 '<div class="top-line"> ' +
				 '<span style="color:red; font-weight:bold; font-size:16px; margin-right:1px;">$</span>' +
				 '<span id="dollar" class="dollar-amount" style="color:red; font-size:16px; font-weight:bold;">' + price_label_value1 + '</span>' +
			'</div>' +
			'</div>' +
		  '</div>' +
		'</div>';		}
	if (current_price_label_format == 'format2') {
		if (price_label_value1) {
			price_label_svg = '<div id="format2" class="circular-card-deal-wrapperknife " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;"><div class="top-line"><span class="dollar-sign" style="color:red;  font-weight:bold; ">$</span><span id="dollar" style="color:red;  font-weight:bold;" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="price-unit-wrapper" style=" font-weight: bold; color: red;"><span id="cent" class="cents-amount" style="color:red;  font-weight:bold;">' + ' ' + price_label_value2 + '</span></span></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format2" class="circular-card-deal-wrapperknife " "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;"><div class="top-line"><span id="cent" style="color:red;  font-weight:bold; " class="dollar-amount">' + ' ' + price_label_value2 + '</span><span class="price-unit-wrapper"><span class="cent-sign" style="color:red; font-weight:bold;">¢</span></span></span></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format3') {
		if (price_label_value1) {
			price_label_svg = '<div id="format3" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><div class="price-unit-wrapper"><span id="cent" class="cents-amount" >' + ' ' + price_label_value2 + '</span><span class="price-unit">/LB</span></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format3" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><div class="price-unit-wrapper"><span class="cent-sign">¢</span><span class="price-unit">/LB</span></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format4') {
		if (price_label_value1) {
			price_label_svg = '<div id="format4" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><div class="price-unit-wrapper"><span id="cent" class="cents-amount" >' + ' ' + price_label_value2 + '</span><span class="price-unit">/EA</span></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format4" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + price_label_value2 + '</span><div class="price-unit-wrapper"><span class="cent-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format5') {
		if (price_label_value2) {
			price_label_svg = '<div id="format5" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold;flex-direction:column;"><div class="num-to-cart pricing-num-for "><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for" >for</span></div> <div class="circular-card-price"><div class="top-line"><span class="dollar-sign" >$</span><span id="cent" class="cents-amount" style="">' + ' ' + price_label_value2 + '</span><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format5" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:unset;color:red;font-weight:bold;flex-direction:column;"><div class="num-to-cart pricing-num-for "><span id="dollar" class="dollar-amount">' + ' ' + price_label_value1 + '</span><span class="pricing-for" >for</span></div><div class="circular-card-price"><div class="top-line"> <span id="third" class="cents-amount" style="">' + ' ' + price_label_value3 + '</span><span><span class="cent-sign">¢</span></span></span></div></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format6') {
		if (price_label_value3 == 'Free') {
			price_label_svg = '<div id="format6" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:64%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold;line-height:0.5;text-transform: uppercase;"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format6" class="circular-card-deal-wrapperknife "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:64%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold;line-height:0.5;text-transform: uppercase;"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span class="dollar-amount">' + price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + price_label_value3 + '</span></div></div></div></div>';
		}
	}
	if (current_price_label_format == 'format7') {
		if (price_label_value1) {
			price_label_svg = '<div id="format7" class="circular-card-deal-wrapperknife  withNumber "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:65%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><span><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + price_label_value1 + '</span><span id="cent" class="cents-amount">' + ' ' + price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div>';
		} else {
			price_label_svg = '<div id="format7" class="circular-card-deal-wrapperknife  withNumber "  "style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div class="top-line" style="position:relative; width:100%; height:100%;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:65%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><span><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + price_label_value2 + '</span><span class="cent-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div>'
		}
	}
	if (current_price_label_format == 'format8') {
		price_label_svg = '<div id="format8" class="circular-card-deal-wrapperknife  withNumber""style="position:relative;  left:-8px; bottom:2px; width:100px; height:100px;"><div class="circular-card-price"><div style="position:relative;"><img src="../assets/imgs/knipe.png" alt="Cleaver" ' + 'style="width:100%; height:100%; object-fit: cover;" /><div class="price-container" ' +'style="position:absolute; top:61%; left:43%; transform: translate(-50%, -50%); display: flex; align-items: center; justify-content: center;flex-direction:column;color:red;font-weight:bold"><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Market</span></div><div class="num-to-cart pricing-num-for1"><span class="pricing-buy-Market">Price</span></div></div></div></div></div>';
	}
	return price_label_svg
  } 
    
}
