import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,FormArray,Validators,FormGroupDirective } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { SideMenusService } from '../../../core/side-menus/side-menus.service';

  
@Component({
  selector: 'app-log-template-view',
  templateUrl: './log-template-view.component.html',
  styleUrls: ['./log-template-view.component.scss']
})
export class LogTemplateViewComponent implements OnInit {

 
i= 1;

validationMessages = { 
    add_store_name: [
      { type: 'required', message: 'Please enter Store Name' }
    ],
    add_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    add_so_name: [
      { type: 'required', message: 'Please enter Store Owner Name' },
    ],
    group_name: [
      { type: 'required', message: 'Please enter Group Name' },
    ],
    go_name: [
      { type: 'required', message: 'Please enter Group Owner Name' },
    ],
    go_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    warehouse_name: [
      { type: 'required', message: 'Warehouse name is required' }
    ]
  };


create_temp_form:FormGroup;
create_radio_form:FormGroup;
create_check_form:FormGroup;
radios:any;
checks:any;
temp_value:any = [];
modalRef:any;
closeResult:any;
i2 = 1;
i3 = 1;
user:any; 
id:any;
current_rowid:any; 
required_label_radio:boolean = false;
required_label_check:boolean = false;
required_label_temp_name:boolean = false;
current_section:any = 'creator';
form_values:any;
form_values_res:any;
template:any;
my_template_quest:any;
@ViewChild('createForm') createForm: FormGroupDirective; 
@ViewChild('radioAddModal') radioAddModal;
@ViewChild('checkAddModal') checkAddModal;

 constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private sideMenusService: SideMenusService,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
		this.create_temp_form = new FormGroup({
			temp_name: new FormControl(null, Validators.compose([Validators.required])),
		 	questions: new FormArray([])
    	});

    	this.create_radio_form = new FormGroup({
			temp_name: new FormControl(null, Validators.compose([Validators.required])),
			values: new FormArray([])
    	});
    	this.create_check_form = new FormGroup({
			temp_name: new FormControl(null, Validators.compose([Validators.required])),
			values: new FormArray([]) 
    	});
   }

	get f() { return this.create_temp_form.controls; }
	get f2() { return this.create_radio_form.controls; }
	get f3() { return this.create_check_form.controls; }
	get t() { return this.f.questions as FormArray; }
	get t2() { return this.f2.values as FormArray; }
	get t3() { return this.f3.values as FormArray; }
	get ticketFormGroups() { return this.t.controls as FormGroup[]; }
	get ticketFormGroups2() { return this.t2.controls as FormGroup[]; }
	get ticketFormGroups3() { return this.t3.controls as FormGroup[]; }
 
  	ngOnInit(): void {
  		this.id = this.route.snapshot.params['id'];

  		this.user = JSON.parse(localStorage.getItem('userrole'));

  		this.afs.collection('/templates',ref=>ref.where('uid','==',this.id)).snapshotChanges().subscribe(data => {
			this.template = data.map(e => {
				return{
					uid: e.payload.doc.id,
					template_name: e.payload.doc.data()['template_name']?e.payload.doc.data()['template_name']:"",
					created_at: e.payload.doc.data()['created_at']?e.payload.doc.data()['created_at']:"",
					created_by: e.payload.doc.data()['created_by']?e.payload.doc.data()['created_by']:"",
					created_by_uid: e.payload.doc.data()['created_by_uid']?e.payload.doc.data()['created_by_uid']:"",
					assigned_to: e.payload.doc.data()['assigned_to']?e.payload.doc.data()['assigned_to']:[],
				};
			});

			this.create_temp_form.patchValue(
		        { 
		           temp_name: this.template[0]?.template_name
		        }
	        );

		});

		

  		this.afs.collection('/response-types',ref=>ref.where('type','==','radio').orderBy('created_at','asc')).snapshotChanges().subscribe(data => {
			this.radios = data.map(e => {
				return{
					uid: e.payload.doc.id, 
					type: e.payload.doc.data()['type'],
					name: e.payload.doc.data()['name'],
					value: e.payload.doc.data()['value']
				};
			});	
		});

		this.afs.collection('/response-types',ref=>ref.where('type','==','checkbox').orderBy('created_at','asc')).snapshotChanges().subscribe(data => {
			this.checks = data.map(e => {
				return{
					uid: e.payload.doc.id,
					type: e.payload.doc.data()['type'],
					name: e.payload.doc.data()['name'],
					value: e.payload.doc.data()['value']
				};
			});	
		});
  		//this.appendDiv();
  		this.appendDiv2();
  		this.appendDiv3();


  		let temp_ques = this.afs.collection('/templates/'+this.id+'/questions/').snapshotChanges().subscribe(data => {
			this.my_template_quest = data.map(e => {
				return{
					uid: e.payload.doc.id,
					question: e.payload.doc.data()['question']?e.payload.doc.data()['question']:"",
					response: e.payload.doc.data()['response']?e.payload.doc.data()['response']:"",
					sort_order: e.payload.doc.data()['sort_order']?e.payload.doc.data()['sort_order']:"",
					type: e.payload.doc.data()['type']?e.payload.doc.data()['type']:"",
					type_label: e.payload.doc.data()['type_label']?e.payload.doc.data()['type_label']:"",
				};
			});	
			const numberOfTickets = this.i || 1;
			this.my_template_quest.forEach( (value, key) => {
				this.t.push(this.formBuilder.group({
            	  	question:[value.question],
	                response: [value.response],
	                type:[value.type],
	                type_label:[value.type_label],
	                sort_order:[value.sort_order]
            	}));
	       	 	this.temp_value[key] = value.type_label;
	       	 	this.i++;
	       	});
			
			temp_ques.unsubscribe();
		});
  	}
  	open(content,title) {
		this.modalRef = this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
	//	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

  	toggleMainMenu(): void 
  	{
		this.sideMenusService.toggleMainMenuSubject.next('toggle');
  	}

  	preview_temp() 
  	{ 
      this.form_values = (this.create_temp_form.value);
  		console.log('PREVIEW TEMPLATE HERE',this.form_values);

  	}
    openradioAddModal(rowid)
    {
      this.current_rowid = rowid;
      this.open(this.radioAddModal,'modal-basic-title');
    }

    opencheckAddModal(rowid)
    {
      this.current_rowid = rowid;
      this.open(this.checkAddModal,'modal-basic-title2');
    }

    openLogs()
    { 
      this.router.navigate(['/admin/saved-templates']);
    }


  
 	  submit_temp(value)
  	{
  		//questions
  		//temp_name
  		//this.user.uid;
  		//this.user.displayName;
  		console.log("THE TEMPLATE VALUES ARE ---->>>>> ",value);
  		
  		if(value.temp_name == '' || value.temp_name == null)
  		{
  			this.required_label_temp_name = true;
  			this._snackBar.open("Please enter the Log Template name", '', {
					duration: 2000,
			});
  			return false;	 
  		}
  		this.required_label_temp_name = false;
  		 
  		let valuedata = value.questions;
		valuedata = valuedata.filter(item => item.question !== '' && item.question !==null && item.type !== '' && item.type !== null );
		console.log(valuedata);
		
		if(valuedata.length > 0)
		{
			console.log(valuedata);
			this._snackBar.open("Log Template Updated Successfully", '', {
					duration: 2000,
			});
			let tempdata = {
				updated_at: new Date(),
				//created_uid: this.user.uid,
				//created_by: this.user.displayName,
				template_name: value.temp_name,
        //assigned_to:[this.user.uid]
			};
		
			let aax = this.afs.doc('/templates/'+this.id).set(tempdata,{merge:true}).then(docRefx => {
		    });

			//let aa3 = this.afs.collection('/templates/'+this.id+'/questions').delete();

	    	let aa2 = this.afs.collection('/templates/'+this.id+'/questions').add(value).then(docRef2 => {
				const itemRef2 = this.afs.doc('/templates/'+this.id+'/questions/'+docRef2.id);
				itemRef2.update({ uid: docRef2.id});
			});

			this.router.navigate(['admin/saved-templates']);



		} else {
			this._snackBar.open("Please enter the question/response", '', {
					duration: 2000,
			});
		}

  	}

  	submit_radio(value)
  	{
  		console.log('radio',value);
  		let thedata={
  			name: value.temp_name,
  			type: 'radio',
  			created_at: new Date(),
  			value: JSON.stringify(value.values)
  		}

  		if(value.temp_name == '' || value.temp_name == null)
  		{
  			this.required_label_radio = true;
  			this._snackBar.open("Please enter the label name", '', {
					duration: 2000,
			});
  			return false;	
  		}

  		this.required_label_radio = false;
  		
  		let valuedata = value.values;
		valuedata = valuedata.filter(item => item.value !== '' && item.value !==null );
		console.log(valuedata);
    console.log("LENGTH ---->> ",valuedata.length);
    if(valuedata.length < 2)
    {
      this._snackBar.open("Min. 2 Values are required.", '', {
          duration: 1500,
        });
      return false;
    }
		
		if(valuedata.length > 0)
		{
			let radio_add = this.afs.collection('/response-types').add(thedata).then(docRef => {
				const itemRefx= this.afs.doc('/response-types/'+docRef.id);
				itemRefx.update({ uid: docRef.id}); 
			});
	  		this.create_radio_form.reset();
	  		let element: HTMLElement = document.getElementsByClassName('close_add_radio_modal')[0] as HTMLElement;
				element.click();
				this._snackBar.open("Radio values created successfully", '', {
					duration: 1000,
				});

        this.t.at(this.current_rowid).patchValue(
          { 
            type_label: value.temp_name,
            type: 'radio',
             response : (value.values),
          }
        );
        this.temp_value[this.current_rowid] = value.temp_name;

			} else {
				this._snackBar.open("Please enter atleast 1 radio value", '', {
					duration: 1500,
				});
			}
  		
  	}


  	submit_check(value)
  	{
  		console.log('checkbox',value);
  		let thedata={
  			name: value.temp_name,
  			type: 'checkbox',
  			created_at: new Date(),
  			value: JSON.stringify(value.values)
  		}
  		if(value.temp_name == '' || value.temp_name == null)
  		{
  			this.required_label_check = true;
  			this._snackBar.open("Please enter the label name", '', {
					duration: 2000,
			});
  			return false;	
  		}

  		this.required_label_check = false;
  		
  		let valuedata = value.values;
		valuedata = valuedata.filter(item => item.value !== '' && item.value !==null );
		console.log(valuedata);

     if(valuedata.length < 2)
    {
      this._snackBar.open("Min. 2 Values are required.", '', {
          duration: 1500,
        });
      return false;
    }
		
		if(valuedata.length > 0)
		{
	  		let radio_add = this.afs.collection('/response-types').add(thedata).then(docRef => {
					const itemRefx= this.afs.doc('/response-types/'+docRef.id);
					itemRefx.update({ uid: docRef.id}); 
				});
	  		this.create_check_form.reset();
	  		let element: HTMLElement = document.getElementsByClassName('close_add_check_modal')[0] as HTMLElement;
				element.click();
				this._snackBar.open("Checkbox values created successfully", '', {
					duration: 1000,
				});

        this.t.at(this.current_rowid).patchValue(
          { 
            type_label: value.temp_name,
            type: 'checkbox',
            response : (value.values),
          }
        );
        this.temp_value[this.current_rowid] = value.temp_name;

		}else {
				this._snackBar.open("Please enter atleast 1 checkbox value", '', {
					duration: 1500,
				});
			}
  	}

  	appendDiv()
  	{
  		console.log('the t leng',this.t.length);
  		
  	 	const numberOfTickets = this.i || 1;
        for (let i = this.t.length; i < numberOfTickets; i++) {
            this.t.push(this.formBuilder.group({
            	question:[null],
                response: [null],
                type:[null],
                type_label:[null],
                sort_order:[i]
            }));
        }
        
		this.i++;
 	}


  delete_radio(uid)
  {
    let del_radio = this.afs.doc('/response-types/'+uid).delete();
    this._snackBar.open('Radio deleted successfully', '', {
      duration: 1000,
    });
  }

  delete_check(uid)
  {
    let del_check = this.afs.doc('/response-types/'+uid).delete();
    this._snackBar.open('Checkbox deleted successfully', '', {
      duration: 1000,
    });
  }

 	appendDiv2()
  	{
  		console.log('the t leng',this.t2.length);
  		if(this.t2.length > 4)
  		{
  			this._snackBar.open("Can't add more than 5 values", '', {
				duration: 3000,
			});
			return false;
  		}
  	 	const numberOfTickets = this.i2 || 1;
        for (let i = this.t2.length; i < numberOfTickets; i++) {
            this.t2.push(this.formBuilder.group({
            	value:[null],
            }));
        }
        
		this.i2++;
 	}

 	appendDiv3()
  	{
  		console.log('the t leng-----',this.t3.length);
  		if(this.t3.length > 9)
  		{
  			this._snackBar.open("Can't add more than 10 values", '', {
				duration: 3000,
			});
			return false;
  		}
  	 	const numberOfTickets = this.i3 || 1;
        for (let i = this.t3.length; i < numberOfTickets; i++) {
            this.t3.push(this.formBuilder.group({
            	value:[null],
            }));
        }
        
		this.i3++;
 	}

 	setRadio(rowid,value)
 	{
 		console.log(rowid);
 		console.log(value);
 		this.t.at(rowid).patchValue(
          { 
          	type_label: value.name,
          	type: 'radio',
           	response : JSON.parse(value.value),
          }
        );
        this.temp_value[rowid] = value.name;

 	}
 	setCheck(rowid,value)
 	{
 		console.log(rowid);
 		console.log(value);
 		this.t.at(rowid).patchValue(
          { 
          	type_label: value.name,
          	type: 'checkbox',
           	response : JSON.parse(value.value),
          }
        );
        this.temp_value[rowid] = value.name;
 	}
 	setType(rowid,value)
 	{
 		console.log(rowid);
 		console.log(value);
 		this.t.at(rowid).patchValue(
          { 
          	type_label: value,
          	type: value,
           	response : value,
          }
        );
        this.temp_value[rowid] = value;

 	}

 	removeRow(id){
        this.t.removeAt(id);
        this.i--;	
        this.temp_value[id] = 0;
        this.temp_value.splice(id, 1);
  	}
  	removeRow2(id){
        this.t2.removeAt(id);
        this.i2--;	
  	}
  	removeRow3(id){
        this.t3.removeAt(id);
        this.i3--;	
  	}



}
