import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, OnInit, Output, PLATFORM_ID, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { EditStoreProductComponent } from '../edit-store-product.component';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { GridStack } from 'gridstack';
import { finalize, Subscription } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/shared/services/auth.service';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { MatSnackBar } from '@angular/material/snack-bar';
import { environment } from 'src/environments/environment';
import { ComponentService } from 'src/app/components/component.service';
import { ProductUpdateService } from 'src/app/shared/services/product-update.service';
import { PriceFormatService } from 'src/app/shared/services/price-format.service';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import * as $ from 'jquery';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { CleaverPriceFormatService } from 'src/app/shared/services/cleaver-price-format.service';
import imageCompression from 'browser-image-compression';
@Component({
  selector: 'app-product-dialog5-component',
  templateUrl: './product-dialog5-component.component.html',
  styleUrls: ['./product-dialog5-component.component.scss']
})
export class ProductDialog5ComponentComponent implements OnInit {
  cartItem = []
  cart_ids = []
  all_product_count = []
  qnt_dropdown: any = false
  isLoading :boolean=false
  product_count: any
  showProcessedImage: boolean = false;
  selectedFile:any;
  processedImage:any;
  showDilog2: boolean = false;
  selectedType: 'original' | 'processed' = 'original'; // default selection
  originalFile: File; // 👈 Track the original uploaded file
  image_path = ''
  current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
  current_list_data: any = {}
  counter_img = ''
  show_loader = false
  view: string;
  show_edit_loader =true;
  @Output() cart_created = new EventEmitter
  past_item: any;
  layout_form: FormGroup;
	product_update_form: FormGroup;
	searchBox: FormGroup;
	product_add_form: FormGroup;
	search_icon_form: FormGroup;

	@ViewChild('iconSearchModal') iconSearchModal;
	@ViewChild('uploadExistModal') uploadExistModal;
	@ViewChild('clearLayoutModal') clearLayoutModal;
	@ViewChild('RestoreLayoutModal') RestoreLayoutModal;
	@ViewChild('myDialog') myDialog;
	@ViewChild('fileInput') fileInput: ElementRef;

	page_type: any;
	page_type1: any = 'url';
	page_type2: any = 'url';
	page_type3: any = 'url';
	page_type4: any = 'url';
	page_type5: any = 'url';
	layout_type: any = ''; // 2 values - grid or page
	layout_json: any;
	imageSrc: any;
	imageurl: any;
	ref: any;
	task: any;
	imageurl1: any;
	imageurl2: any;
	imageurl3: any;
	imageurl4: any;
	imageurl5: any;
	last_save_timestamp: any = 0;
	last_publish_timestamp: any = 0;
	get_current_store_id: any;
	ghost_mode = false;
	current_select_product_id: any;
	current_select_product_index: any;
	current_select_product_data: any;
	current_selected_product_data_full:any;
		show_format_panel = false;
	current_price_label_format: any;
	price_label_value1: any = '';
	price_label_value2: any = '';
	price_label_value3: any = '';
	price_label_svg: any = '';
	categories_list: any;
	current_timestamp: any = '';
	current_template_id: any = '';
	do_not_refresh_iframe = false;
	saved_items_list: any = [];
	all_products_list: any = [];
	layout_last_data: any = [];
	csv_data_new: any = []
	loading = false;
	publishLoading=false;
	image_uploading = false;
	closeResult: any;
	icons: any;
	show_icon_results = false;
	current_layout_data: any;
	user: any;
	user_data: any;
	current_store_uid: any;
	current_unsplash_type: any;
	imageurl1x: any = '';
	imageurl2x: any = '';
	show_add_product = false;
	show_edit_form = false;
	master_list: any;
	unmatchedProducts: any = [];
	matchedProducts: any = [];
	csvProductDescriptions: any = [];
	importcsvdata: any = [];
	duplicates_temp: any;
	saved_items_list_temp: any;
	store_data: any;
	final_product_array = [];
	oldProducts = []
	show_discard = false
	publish_btn_class = "disable-class"
	is_save_active = false
	preview_url: any
	preview_obj: any
	PreviewBtnClass: boolean = false;
	is_preview_clicked = false
	add_form_img = ''
	isGIF: boolean
	isVideo: any = false
	master_item_product: any[] = [];
	selected_product_name: any
	selected_product_uid: any
	selected_master_product_uid: any
	CXH_image: any[] = [];
	submitted: boolean = false;
	minDate = new Date(new Date().getTime()).toISOString().split('T')[0]
	maxDate = new Date().toISOString().split('T')[0]
	searchLoading: boolean = false;
	popup_loading: boolean = false;
	number: any;
	unsplash_page: number = 1;
	unsplash_per_page: number = 9;
	CXH_skip: number = 0;
	CXH_take: number = 9;
	totalImage: number = 0;
	storeInMaster: boolean = false;
	add_editorContent: any = '';
	selected_master_product: any;
	matchingItems: any;
	matchImage: boolean = true;
	private subscriptions: Subscription[] = [];
	layout_created: boolean = false;
	dynamicElements: any[] = [];
	dynamicElements1: any;
	offerLabel: any;
	showbuttons: boolean = true
	showLabelSide: boolean = false;
	selectedLabelSide: string = 'topleft';
	selectedItem: any;
	buttonDisabled = false;
	selected_master_product1: boolean = false;
	labelSize: string;
	current_user: any;
	grid: GridStack[];
	config: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: '5rem',
		minHeight: '5rem',
		placeholder: 'Enter text here...',
		translate: 'no',
		toolbarHiddenButtons: [
			['fontSize'],
			['insertImage'],
			['insertVideo'],
			['fontName'],
			['textColor'],
			['backgroundColor'],
			['unlink'],
			['insertHorizontalRule'],
			['removeFormat'],
			['toggleEditorMode'],
			['subscript'],
			['superscript'],
			['strikeThrough'],
			['underline'],
			['heading'],
			['link']
		]
	}
	remainingTime: string = '';
	timeLeft: boolean;
	show_sale_price_format_panel: boolean;
	show_digital_coupon_format_panel: boolean;
	show_final_price_format_panel: boolean;
	show_digital_coupons: boolean;
	current_sale_price_label_format: any;
	current_digital_coupon_price_label_format: any;
	sale_price_label_value1: any ='';
	sale_price_label_value2: any ='';
	sale_price_label_value3: any ='';
	sale_price_label_svg: any='';
	sale_content_price_label_svg:any='';
	current_final_price_label_format: string;
	final_price_label_value1: any ='';
	final_price_label_value2: any ='';
	final_price_label_value3: any ='';
	final_price_label_svg: any='';
	final_content_price_label_svg:any='';
	digital_coupon_price_label_value1: any ='';
	digital_coupon_price_label_value2: any ='';
	digital_coupon_price_label_value3: any ='';
	digital_coupon_price_label_svg: any='';
	digital_content_coupon_price_label_svg: any='';
	digital_coupon:boolean ;
	note: any ='';
	usage_limit: any='';
	link: any='';
	buttonActiveDeactiveCalled: boolean=false;
	tabLoader: boolean;
	tab_btn_class: string;
	deleteProduct: boolean;
	csvData: any;
	endDateRemain: any;
	domain_name:string='';
	loopVideoDisplay: boolean;
	openImageDialogByGrid: boolean=false;
	current_select_product_json_index: any;
	current_select_product_json_data: any;
	current_select_product_json_saved_index: any;
	current_select_product_json_saved_data: any;
	matchedProduct: any;
	accessToken: any;
	tokens: any[];
	price_label_svg_html: any='';
	price_label_type:any;
	formPreviewImage: string | ArrayBuffer;
	processing: boolean;
	selectedPreviewImage: any;
	originalImageSrc: any;
	processedFile: Blob;
	processedFileImageSrc: Blob;
	// isBundle: any;
	// bundledList: any[];
	constructor(@Inject(PLATFORM_ID,)
	private platformId: object,
		public sanitizer: DomSanitizer,
		private router: Router,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private afStorage: AngularFireStorage,
		private http: HttpClient,
		public dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private cdr: ChangeDetectorRef,
		private fns: AngularFireFunctions,
		private cleaverPriceFormatService: CleaverPriceFormatService,
    public dialogRef: MatDialogRef<EditStoreProductComponent>,
    public componentService : ComponentService,
    private productUpdateService: ProductUpdateService,
    private priceFormatService:PriceFormatService,
    @Inject(MAT_DIALOG_DATA) public data: any) {

	

		this.current_timestamp = new Date().getTime();
		this.product_update_form = new FormGroup({
			uid: new FormControl(''),
			w: new FormControl(''),
			h: new FormControl(''),
			x: new FormControl(''),
			y: new FormControl(''),
			master_uid: new FormControl(''),
			product_image: new FormControl(''),
			upc_code: new FormControl(''),
			product_description: new FormControl(''),
			pack: new FormControl(''),
			product_url: new FormControl(''),
			category: new FormControl(''),
			offerLabel: new FormControl(''),
			labelSide: new FormControl('topleft'),
			price_label_format: new FormControl(''),
			new_window: new FormControl(''),
			isBanner: new FormControl(''),
			bannerText: new FormControl(''),
			autoplay: new FormControl(''),
			loopvideo: new FormControl(''),
			storeInMaster: new FormControl(''),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			price_label_value1: new FormControl('0'),
			price_label_value2: new FormControl('0'),
			price_label_value3: new FormControl('0'),
			price_label_svg: new FormControl(''),
			isVideo: new FormControl(false),
			digital_coupon:new FormControl(false),
			digital_coupon_price_label_format: new FormControl(''),
			sale_price_label_format:new FormControl(''),
			final_price_label_format: new FormControl(''),
			note : new FormControl(''),
			link : new FormControl(''),
			usage_limit : new FormControl(''),
			sale_price_label_value1: new FormControl('0'),
			sale_price_label_value2: new FormControl('0'),
			sale_price_label_value3: new FormControl('0'),
			sale_content_price_label_svg: new FormControl(''),
			sale_price_label_svg: new FormControl(''),
			final_price_label_value1: new FormControl('0'),
			final_price_label_value2: new FormControl('0'),
			final_price_label_value3: new FormControl('0'),
			final_price_label_svg: new FormControl(''),
			digital_coupon_price_label_svg: new FormControl(''),
			final_content_price_label_svg: new FormControl(''),
			digital_coupon_price_label_value1: new FormControl('0'),
			digital_coupon_price_label_value2: new FormControl('0'),
			digital_coupon_price_label_value3: new FormControl('0'),
			digital_content_coupon_price_label_svg: new FormControl(''),
			isBundleProduct: new FormControl(false),
			// bundleProduct: new FormControl(''),
		});

		this.searchBox = new FormGroup({
			query: new FormControl(null, Validators.required),
			radioBox: new FormControl('cxh', Validators.required)
		})

		this.layout_form = new FormGroup({
			uid: new FormControl(''),
			layout_type: new FormControl('grid'),  // 2 values - grid or page
			layout_json: new FormControl([]),
			include_header_checkbox: new FormControl(0),
			start_date: new FormControl(''),
			end_date: new FormControl(''),
			actual_start_date: new FormControl(''),
			actual_end_date: new FormControl(''),
			header_text: new FormControl(''),
			terms: new FormControl(''),
			is_circular: new FormControl(true),
			layout_json_saved: new FormControl([]),
			layout_json_temp: new FormControl([]),
			page1_type: new FormControl('url'),
			page1_url: new FormControl(''),
			page2_type: new FormControl('url'),
			page2_url: new FormControl(''),
			page3_type: new FormControl('url'),
			page3_url: new FormControl(''),
			page4_type: new FormControl('url'),
			page4_url: new FormControl(''),
			page5_type: new FormControl('url'),
			page5_url: new FormControl(''),
			is_publish: new FormControl(0),
		});

		this.product_add_form = new FormGroup({
			uid: new FormControl(''),
			product_image: new FormControl(''),
			upc_code: new FormControl(''),
			product_description: new FormControl(''),
			pack: new FormControl(''),
			product_url: new FormControl(''),
			category: new FormControl(''),
			offerLabel: new FormControl(''),
			labelSide: new FormControl('topright'),
			price_label_format: new FormControl(''),
			new_window: new FormControl(''),
			isBanner: new FormControl(''),
			isBundleProduct: new FormControl(false),
			// bundleProduct: new FormControl(''),
			bannerText: new FormControl(''),
			autoplay: new FormControl(''),
			loopvideo: new FormControl(''),
			storeInMaster: new FormControl(''),
			hide_price_label: new FormControl(false),
			hide_addtolist_label: new FormControl(false),
			hide_productname_label: new FormControl(false),
			price_label_value1: new FormControl('0'),
			price_label_value2: new FormControl('0'),
			price_label_value3: new FormControl('0'),
			price_label_svg: new FormControl(''),
			isVideo: new FormControl(false),
			digital_coupon:new FormControl(false),
			digital_coupon_price_label_format: new FormControl(''),
			sale_price_label_format:new FormControl(''),
			final_price_label_format: new FormControl(''),
			note : new FormControl(''),
			link : new FormControl(''),
			usage_limit : new FormControl(''),
			sale_price_label_value1: new FormControl('0'),
			sale_price_label_value2: new FormControl('0'),
			sale_price_label_value3: new FormControl('0'),
			sale_price_label_svg: new FormControl(''),			
			sale_content_price_label_svg: new FormControl(''),
			final_price_label_value1: new FormControl('0'),
			final_price_label_value2: new FormControl('0'),
			final_price_label_value3: new FormControl('0'),
			final_price_label_svg: new FormControl(''),
			final_content_price_label_svg: new FormControl(''),
			digital_coupon_price_label_value1: new FormControl('0'),
			digital_coupon_price_label_value2: new FormControl('0'),
			digital_coupon_price_label_value3: new FormControl('0'),
			digital_coupon_price_label_svg: new FormControl(''),
			digital_content_coupon_price_label_svg: new FormControl(''),
		});
		this.search_icon_form = new FormGroup({
			query: new FormControl(null, Validators.compose([Validators.required])),
		});
	}


  async ngOnInit() {
	await this.getLayoutData();

    // await this.afs.collection('layouts').doc(this.current_template_id).update({ selected_product_id: this.data.id, selected_product_width: w, selected_product_height: h })
    await this.updateFormData(this.data.id);
	if(this.data?.price_label == 'cleaver'){
		this.price_label_type = 'cleaver'
	}else{
		this.price_label_type = 'circular'
	}
  }

	closeModal() {
		this.dialogRef.close()
	}
	closeModalBgRemove() {
		this.showDilog2=false;
		this.processedImage =''
		this.showProcessedImage=false;
		this.selectedType='original'
		// this.imageurl1x=this.product_update_form.value.product_image
		this.image_uploading = false;
		const fileInput = document.querySelector('.update_image_upload') as HTMLInputElement;
		if (fileInput) {
		  fileInput.value = ''; // Reset input
		}
	}
	trigger_upload_image(event: MouseEvent) {
		event.stopPropagation(); // Prevent parent click handlers
		this.triggerFileInput();
	}
	selectImage(type: 'original' | 'processed') {
		if (type === 'processed' && !this.showProcessedImage) {
			return;
		}
		this.selectedType = type;
		if (type === 'original') {
			this.processedFile = this.processedFileImageSrc
		} else if (type === 'processed' && this.processedImage) {
			fetch(this.processedImage)
				.then(res => res.blob())
				.then(blob => {
					this.processedFile = new Blob([blob], { type: 'image/png' })

					this.product_update_form.patchValue({
						product_image: this.processedFile,
					})
				})
				.catch(console.error);
		}
	}
	triggerFileInput() {
		const fileInput = document.querySelector(".update_image_upload") as HTMLInputElement;
		if (fileInput) {
		  fileInput.click();
		}
	  }
	async  submitDialog2() {
		this.show_edit_loader=true;
		const randomId = 'digital-menus-' + new Date().getTime() + Math.random().toString(36).substring(2);
		this.ref = this.afStorage.ref('uploads/' + randomId);
		this.task = this.ref.put(this.processedFile);
		await this.task.snapshotChanges().pipe(
			finalize(() => {
				this.subscriptions[this.subscriptions.length] = this.ref.getDownloadURL().subscribe(url => {
					this.subscriptions[this.subscriptions.length] = this.ref.getMetadata().subscribe(res => {
						if (res.contentType.split('/')[1] == 'gif') {
							this.isGIF = true
							this.isVideo = false
						} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
							this.isVideo = true
							this.isGIF = false
							this.product_update_form.patchValue({
								isVideo: true
							})
							this.product_add_form.patchValue({
								isVideo: true
							})
							if (this.isVideo || this.isGIF) {
								this.storeInMaster = true;
							} else {
								this.product_add_form.controls['storeInMaster'].setValue(false);
								this.storeInMaster = false;
							}
						} else {
							if (this.isVideo == 'youtube' ) {
								this.product_update_form.patchValue({
									isVideo: false,
									product_url: ''
								})
								this.isVideo = false
								this.storeInMaster = false;
							}else if (this.isVideo=='facebook') {
								this.product_update_form.patchValue({
									isVideo: "facebook",
									
								})
								this.isVideo = "facebook"
								this.storeInMaster = true;
							} else {
								this.isGIF = false
								this.isVideo = false
								this.storeInMaster = false;
							}
						}
					});
					if (this.isVideo) {
						this.storeInMaster = true;
					} else {
						this.product_update_form?.controls['storeInMaster']?.setValue(false);
						this.storeInMaster = false;
					}
						this.imageurl1x = url;
						this.product_update_form.patchValue({
							product_image: url,
						});
					this.image_uploading = false;
					this.showDilog2 = false;
					this.show_edit_loader=false;
					this.showProcessedImage=false;
					this.selectedType='original'
					this.processedImage ='';
				});
			})
		).subscribe();
		this.cdr.detectChanges();
	  }
	async upload2(event, ind) {
		this.matchImage = false
		this.image_uploading = true;
		this.selectedType='original'
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			if (file.type.startsWith('image/')) { 
				const reader = new FileReader();
				reader.onload = e => this.imageSrc = reader.result;
				reader.readAsDataURL(file);
				this.showDilog2 = true;
				this.processedFile = file;
				this.processedFileImageSrc = file;
			} else {
				this.showDilog2 = false; // Don't show dialog for non-image files
				const reader = new FileReader();
				reader.onload = e => this.imageSrc = reader.result;
				reader.readAsDataURL(file);
				const randomId = 'digital-menus-' + new Date().getTime() + Math.random().toString(36).substring(2);
				this.ref = this.afStorage.ref('uploads/' + randomId);
				this.task = this.ref.put(event.target.files[0]);
				 this.task.snapshotChanges().pipe(
					finalize(() => {
						this.subscriptions[this.subscriptions.length] = this.ref.getDownloadURL().subscribe(url => {
							this.subscriptions[this.subscriptions.length] = this.ref.getMetadata().subscribe(res => {
								if (res.contentType.split('/')[1] == 'gif') {
									this.isGIF = true
									this.isVideo = false
								} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
									this.isVideo = true
									this.isGIF = false
									this.product_update_form.patchValue({
										isVideo: true
									})
									this.product_add_form.patchValue({
										isVideo: true
									})
									if (this.isVideo || this.isGIF) {
										this.storeInMaster = true;
									} else {
										this.product_add_form.controls['storeInMaster'].setValue(false);
										this.storeInMaster = false;
									}
								} else {
									if (this.isVideo == 'youtube' ) {
										this.product_update_form.patchValue({
											isVideo: false,
											product_url: ''
										})
										this.isVideo = false
										this.storeInMaster = false;
									}else if (this.isVideo=='facebook') {
										this.product_update_form.patchValue({
											isVideo: "facebook",
											
										})
										this.isVideo = "facebook"
										this.storeInMaster = true;
									} else {
										this.isGIF = false
										this.isVideo = false
										this.storeInMaster = false;
									}
								}
							});
							if (this.isVideo) {
								this.storeInMaster = true;
							} else {
								this.product_update_form?.controls['storeInMaster']?.setValue(false);
								this.storeInMaster = false;
							}
								this.imageurl1x = url;
								this.product_update_form.patchValue({
									product_image: url,
								});
							this.image_uploading = false;
						});
					})
				).subscribe();		
			}

			
		}
	}
	removeBackground() {
		this.tabLoader=true
		this.showProcessedImage = true;
			fetch(this.imageSrc)
			.then(res => res.blob())
			.then(async blob => {
				const file = new File([blob], "product-image.png", { type: "image/png" });
				if (file.size > 50 * 1024 * 1024) {
					const compressedFile = await this.compressImage(file);
					await this.processImageFile(compressedFile);
				  } else {
				await this.processImageFile(file);
				  }
			})
			.catch(error => {
				console.error("❌ Failed to fetch image:", error)
			    this.tabLoader=false
			}
			);
	}
	async compressImage(file: File): Promise<File> {
		// Configure compression options:
		const options = {
		  maxSizeMB: 50,              // Target maximum file size (in MB)
		  maxWidthOrHeight: 6000,     // Maximum width or height (pixels)
		  useWebWorker: true,
		  initialQuality: 0.95,
		  maxIteration: 1           // Enable web worker for faster compression
		};
	
		try {
		  const compressedFile = await imageCompression(file, options);
		  console.log("Original file size:", (file.size / 1024 / 1024).toFixed(2), "MB");
		  console.log("Compressed file size:", (compressedFile.size / 1024 / 1024).toFixed(2), "MB");
		  return compressedFile;
		} catch (error) {
		  console.error("Error compressing image:", error);
		  // If compression fails, fallback to the original file
		  return file;
		}
	  }
	
	processImageFile(file: File) {
		const formData = new FormData();
		formData.append("image_file", file, file.name);
	
		fetch("https://sdk.photoroom.com/v1/segment", {
			method: "POST",
			headers: {
				"x-api-key": "sandbox_ae742e2f975d503b0e06bc8ff03a5d7c53d58f05"
			},
			body: formData
		})
		.then(response => response.blob())
		.then(removedBgBlob => {			
			// ✅ Ensure the original image is still available
			if (!this.imageSrc) {
				this.imageSrc = URL.createObjectURL(file);
			}
	
			this.processedImage = URL.createObjectURL(removedBgBlob);
			this.selectedType = 'processed';
			this.tabLoader=false
			fetch(this.processedImage)
				.then(res => res.blob())
				.then(blob => {
					this.processedFile = new Blob([blob], { type: 'image/png' })
					this.product_update_form.patchValue({
						product_image: this.processedFile,
					})
				})
				.catch(console.error);
		})
		.catch(error => console.error("❌ Background removal failed:", error));
	}
  convertFacebookWatchLink(link) {
		const regex = /^https:\/\/www\.facebook\.com\/watch\/\?v=(\d+)$/i;
		const match = link.match(regex);
		if (match) {
			const videoId = match[1];
			const canonicalUrl = `https://www.facebook.com/facebook/videos/${videoId}/`;
			return canonicalUrl;
		}else{
			return link
		}

	}
	async currentData() {
		const layoutRef = this.afs.doc('/layouts/' + this.data?.layout_id);
		const doc: any = await layoutRef.get().toPromise();
		var currentData = await doc.data();
		return currentData;
	}
	async getLayoutData() {
		const current_date = new Date();
		try {
			const layoutSnapshot = await this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.data?.store_uid).orderBy('start_date', 'desc')).get().toPromise();

			this.current_layout_data = layoutSnapshot.docs.map((doc: any) => {
				const startDate = doc.data().start_date.toDate();
				const endDate = doc.data().end_date.toDate();
				const uid =doc.data().uid
				if (startDate <= current_date && endDate > current_date) {
					// this.endDateRemain = doc.data().end_date.toDate()
					return doc.data();
				}
				return null; // or any appropriate placeholder for excluded data
			}).filter(data => data !== null);
			this.endDateRemain = this.current_layout_data[0].end_date.toDate()
         console.log("Current Circular Id :- ",this.current_layout_data[0]?.uid,)
		} catch (error) {
			// Handle errors here
			console.error("Error fetching layout data:", error);
		}
	}
  async product_update(data) {
		this.buttonDisabled = true;
		data.isBundleProduct=false;
		if (this.show_edit_form == false) {
			return false;
		}
		if (this.isGIF || !this.isVideo) {
			data.isVideo = false;
		}
		if (!data.isBanner) {
			data.bannerText = '';
		}
		if (data.upc_code == "" || data.upc_code == null) {
			this._snackBar.open("Error:UPC Code is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		if (data.offerLabel != '' && (data.labelSide == "" || data.upc_code == null)) {
			this._snackBar.open("Error:Offer Label Position is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		}
		// if(this.bundledList){
		// 	data.bundleProduct = this.bundledList
		// }
		if(data.digital_coupon){
			data.price_label_value1='';
			data.price_label_value2='';
			data.price_label_value3='';
			if(data.sale_price_label_value1=='' && data.sale_price_label_value2=='' && data.sale_price_label_value3==''){
				this._snackBar.open('Error:Sale Price is Required', '', {
					duration: 1500,
				});
				this.buttonDisabled = false;
				return false;
			}
			if(data.final_price_label_value1=='' && data.final_price_label_value2=='' && data.final_price_label_value3==''){
				this._snackBar.open('Error:Final Price is Required', '', {
					duration: 1500,
				});
				this.buttonDisabled = false;
				return false;
			}
			if(data.digital_coupon_price_label_value1=='' && data.digital_coupon_price_label_value2=='' && data.digital_coupon_price_label_value3==''){
				this._snackBar.open('Error:Digital Coupon is Required', '', {
					duration: 1500,
				});
				this.buttonDisabled = false;
				return false;
			};
			if(data.usage_limit=='' || data.usage_limit==null){		
				this._snackBar.open('Error:Frequency Field is Required', '', {
					  duration: 1500,
				  });
				//   this.buttonDisabled = false;
				  return false;
			  }
		}
		if (data.product_description == "" || data.product_description == null) {
			this._snackBar.open("Error:Product description is Required", '', {
				duration: 1500,
			});
			this.buttonDisabled = false;
			return false;
		} else {
			data.product_description = data.product_description.trim()
		}
		data.product_name_slug = data?.product_description?.replace(`'`, '');
		data.tag = data?.product_description.split(" ")
		let product_url = ''
		if (data.product_url) {
			var domain = new URL(data?.product_url)
			if (domain?.hostname == "www.youtube-nocookie.com" || domain?.hostname == "www.youtube.com") {
				data.isVideo = 'youtube'
				var ytid = data.product_url.match(/\/embed\/([\w\-]{11,})/)[0];
				ytid = ytid.replace('/embed/', '');
				data.product_image = `https://i1.ytimg.com/vi/${ytid}/sddefault.jpg?thumbnail=true`;
				const modifiedUrl = `https://www.youtube-nocookie.com/embed/${ytid}`;
				product_url = modifiedUrl + `?playlist=${ytid}&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
				data.product_url = product_url;
				this.domain_name='youtube'
			}		
		    if (domain?.hostname == "www.facebook.com") {
				data.isVideo = "facebook"
		    	data.product_url=await this.convertFacebookWatchLink(data?.product_url)
		    	const baseEmbedURL = "https://www.facebook.com/plugins/video.php?href=";
		    	const encodedURL = encodeURIComponent(data?.product_url);
		    	// const facebookEmbedURL = `${baseEmbedURL}${encodedURL}&show_text=false&appId&loop=${data.loopvideo ? 1 : 0}&autoplay=${data.autoplay ? 1 : 0}&mute=${data.autoplay ? 1 : 0}&rel=0`;
		    	data.product_url=data?.product_url
				this.domain_name='facebook'
		    }
		}	
		const product_data = await this.afs.doc('store-items/' + data.uid).set(data, { merge: true })
		if (data.isVideo == 'youtube' || data.isVideo=='facebook'|| data.isVideo == true) {
			if (data.storeInMaster) {
				if (data.master_uid == '' || data.master_uid == null) {
					data.created_at = new Date();
					const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
						const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
						data.master_uid = docRef.id
						itemRefx2.update({ uid: docRef.id });
					})
				} else if (data.master_uid) {
					const itemRefx2 = await this.afs.doc('/master-items/' + data.master_uid).get().toPromise();
					const master_old_data: any = itemRefx2.data();
					const master_data = { ...data, secondery_product_image: master_old_data?.secondery_product_image || [] }
					delete master_data.uid
					if (!this.matchImage) {
						if (data.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726' && this.current_selected_product_data_full[0]?.product_image != data.product_image) {
							master_data.secondery_product_image.push({ 'id': Date.now(), 'url': data?.product_image })
						}
					}
					if (this.user_data?.role != 'admin' && this.current_selected_product_data_full[0].product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						delete master_data.product_image
					}
					const master_product_data = await this.afs.doc('master-items/' + data.master_uid).set(master_data, { merge: true }).then(docRef => {
					});
				}
			} else {
				if (data.master_uid != '' && data.master_uid != null) {
					await this.afs.doc('/master-items/' + data.master_uid).delete().then(() => {
						data.master_uid = '';
						// Perform additional actions if needed
					}).catch((error) => {
						console.error('Error deleting product: ', error);
					});
				}
			}
		} else {
			const querySnapshot: any = await this.afs.collection('/master-items', ref => ref.where('product_description', '==', data.product_description)).get().toPromise();
			this.master_list = querySnapshot.docs.map((doc: any) => ({
				upc_code: doc.data()['upc_code'],
				product_description: doc.data()['product_description'] ? doc.data()['product_description'] : '',
				product_name_slug: doc.data()['product_name_slug'] ? doc.data()['product_name_slug'] : '',
				pack: doc.data()['pack'] ? doc.data()['pack'] : '',
				price_label_format: doc.data()['price_label_format'] ? doc.data()['price_label_format'] : '',
				price_label_value1: doc.data()['price_label_value1'] ? doc.data()['price_label_value1'] : '',
				price_label_value2: doc.data()['price_label_value2'] ? doc.data()['price_label_value2'] : '',
				price_label_value3: doc.data()['price_label_value3'] ? doc.data()['price_label_value3'] : '',
				price_label_highlight: doc.data()['price_label_highlight'] ? doc.data()['price_label_highlight'] : '',
				product_url: doc.data()['product_url'] ? doc.data()['product_url'] : '',
				hide_price_label: doc.data()['hide_price_label'] ? doc.data()['hide_price_label'] : false,
				hide_addtolist_label: doc.data()['hide_addtolist_label'] ? doc.data()['hide_addtolist_label'] : false,
				hide_productname_label: doc.data()['hide_productname_label'] ? doc.data()['hide_productname_label'] : false,
				cost: doc.data()['cost'] ? doc.data()['cost'] : '',
				srp: doc.data()['srp'] ? doc.data()['srp'] : '',
				category: doc.data()['category'] ? doc.data()['category'] : '',
				isBundleProduct: doc.data()['isBundleProduct'] ? doc.data()['isBundleProduct'] : false,
				// bundleProduct: doc.data()['bundleProduct'] ? doc.data()['bundleProduct'] : '',
				uid: doc.data()['uid'],
				product_image: doc.data()['product_image'] ? doc.data()['product_image'] : "https://via.placeholder.com/30/cecece/cecece",
				price_label_svg: doc.data()['price_label_svg'] ? doc.data()['price_label_svg'] : '',
				is_saved: doc.data()['is_saved'] ? doc.data()['is_saved'] : false,
				tag: doc.data()['tag'] ? doc.data()['tag'] : [],
				secondery_product_image: doc.data()['secondery_product_image'] ? doc.data()['secondery_product_image'] : [],
				upload_file_id: doc.data()['upload_file_id'] ? doc.data()['upload_file_id'] : '',
			    final_price_label_format: doc.data()['final_price_label_format'] ? doc.data()['final_price_label_format'] : '',
			    final_price_label_value1: doc.data()['final_price_label_value1'] ? doc.data()['final_price_label_value1'] : '',
			    final_price_label_value2: doc.data()['final_price_label_value2'] ? doc.data()['final_price_label_value2'] : '',
			    final_price_label_value3: doc.data()['final_price_label_value3'] ? doc.data()['final_price_label_value3'] : '',
			    sale_price_label_format: doc.data()['sale_price_label_format'] ? doc.data()['sale_price_label_format'] : '',
			    sale_price_label_value1: doc.data()['sale_price_label_value1'] ? doc.data()['sale_price_label_value1'] : '',
			    sale_price_label_value2: doc.data()['sale_price_label_value2'] ? doc.data()['sale_price_label_value2'] : '',
			    sale_price_label_value3: doc.data()['sale_price_label_value3'] ? doc.data()['sale_price_label_value3'] : '',
			    digital_coupon_price_label_format: doc.data()['digital_coupon_price_label_format'] ? doc.data()['digital_coupon_price_label_format'] : '',
			    digital_coupon_price_label_value1: doc.data()['digital_coupon_price_label_value1'] ? doc.data()['digital_coupon_price_label_value1'] : '',
			    digital_coupon_price_label_value2: doc.data()['digital_coupon_price_label_value2'] ? doc.data()['digital_coupon_price_label_value2'] : '',
			    digital_coupon_price_label_value3: doc.data()['digital_coupon_price_label_value3'] ? doc.data()['digital_coupon_price_label_value3'] : '',
			    note: doc.data()['note'] ? doc.data()['note'] : '',
			    usage_limit: doc.data()['usage_limit'] ? doc.data()['usage_limit'] : '',
			    link: doc.data()['link'] ? doc.data()['link'] : '',
			    digital_coupon: doc.data()['digital_coupon'] ? doc.data()['digital_coupon'] : false,
				digital_coupon_price_label_svg: doc.data()['digital_coupon_price_label_svg'] ? doc.data()['digital_coupon_price_label_svg'] : '',
			    sale_price_label_svg: doc.data()['sale_price_label_svg'] ? doc.data()['sale_price_label_svg'] : '',
			    sale_content_price_label_svg: doc.data()['sale_content_price_label_svg'] ? doc.data()['sale_content_price_label_svg'] : '',
			    final_price_label_svg: doc.data()['final_price_label_svg'] ? doc.data()['final_price_label_svg'] : '',
			    final_content_price_label_svg: doc.data()['final_content_price_label_svg'] ? doc.data()['final_content_price_label_svg'] : '',
			    digital_content_coupon_price_label_svg: doc.data()['digital_content_coupon_price_label_svg'] ? doc.data()['digital_content_coupon_price_label_svg'] : '',
			}));

			let master_result = querySnapshot.docs[0]?.data();
			if (master_result) {
				if (!this.matchImage) {
					this.matchImage = true
					if (data?.product_image != 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
						if (master_result.product_image == 'https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726') {
							master_result.product_image = data?.product_image
						}
						master_result?.secondery_product_image.push({ 'id': Date.now(), 'url': data?.product_image })
					}
					master_result.upc_code = data.upc_code
					const master_colData: any = await this.afs.doc('/master-items/' + master_result.uid).set(master_result, { merge: true })
				}
			} else {
				if (data.product_image == "https://firebasestorage.googleapis.com/v0/b/digital-menus-pro-6b7bd.appspot.com/o/uploads%2FPhoto%20Coming%20Soon.jpg?alt=media&token=9951c800-09c8-4642-b73f-3caee5455726") {
					data.secondery_product_image = []
				} else {
					data.secondery_product_image = [{ 'id': Date.now(), 'url': data?.product_image }]
				}
				const master_product_data = await this.afs.collection('master-items').add(data).then(docRef => {
					const itemRefx2 = this.afs.doc('/master-items/' + docRef.id);
					data.master_uid = docRef.id
					itemRefx2.update({ uid: docRef.id });
					delete data?.secondery_product_image
				})
			}
		}
		this._snackBar.open('Product details updated successfully!', '', {
			duration: 1500,
		});
		this.buttonDisabled = false;

		let gifClass = ''
		if (this.isGIF == true) {
			gifClass = ' gifImg '
		}
		const width = this.matchedProduct.w
		const height = this.matchedProduct.h
		let className = this.getFontsize(width > height ? height : width)
		let content_data =await this.productUpdateService.generateContentData(data,gifClass,className,this.store_data)
		// if(data.digital_coupon){
		// 	this.data.w=2;
		// 	this.data.h=2
		// }
		let product_data_card 
		if(data.digital_coupon){
			product_data_card = {
				id: data.uid,
				upc_code: data?.upc_code,
				w: this.matchedProduct?.w ? this.matchedProduct?.w : 1,
				h: this.matchedProduct?.h ? this.matchedProduct?.h : 1,
				x: this.matchedProduct?.x ?this.matchedProduct?.x:0,
				y: this.matchedProduct?.y ?this.matchedProduct?.y:0,
				category: data.category ? data.category : '',
				content: content_data,
				youtubeURL: data.product_url ? data.product_url : '',
				domain_name:this.domain_name?this.domain_name:'',
				openNewTab: data.new_window ? data.new_window : '',
				isVideo: data.isVideo ? data.isVideo : '',
				isBanner: data.isBanner ? data.isBanner : '',
				bannerText: data.bannerText ? data.bannerText : '',
				product_image: data.product_image ? data.product_image : '',
				product_description: data.product_description ? data.product_description : '',
				autoplay: data.autoplay ? data.autoplay : false,
				loopvideo: data.loopvideo ? data.loopvideo : false,
				price_label_svg: data.price_label_svg ? data.price_label_svg : '',
				sale_price_label_value1: data.sale_price_label_value1 ? data.sale_price_label_value1 : '',
				sale_price_label_value2: data.sale_price_label_value2 ? data.sale_price_label_value2 : '',
				sale_price_label_value3: data.sale_price_label_value3 ? data.sale_price_label_value3 : '',
				sale_price_label_format: data.sale_price_label_format ? data.sale_price_label_format : '',
				sale_price_label_svg: data.sale_price_label_svg ? data.sale_price_label_svg : '',
				sale_content_price_label_svg: data.sale_content_price_label_svg ? data.sale_content_price_label_svg : '',
				final_content_price_label_svg: data.final_content_price_label_svg ? data.final_content_price_label_svg : '',
				digital_content_coupon_price_label_svg: data.digital_content_coupon_price_label_svg ? data.digital_content_coupon_price_label_svg : '',
				digital_coupon: data.digital_coupon ? data.digital_coupon : false,
				note: data.note ? data.note : '',
				usage_limit: data.usage_limit ? data.usage_limit : '',
				link: data.link ? data.link : '',
				pack:data.pack ? data.pack :'',
				isBundleProduct:data.isBundleProduct?data.isBundleProduct:false
			};
		}else{
		 product_data_card = {
			id: data.uid,
			upc_code: data?.upc_code,
			w: this.matchedProduct?.w ? this.matchedProduct?.w : 1,
			h: this.matchedProduct?.h ? this.matchedProduct?.h : 1,
			x: this.matchedProduct?.x ?this.matchedProduct?.x:0,
			y: this.matchedProduct?.y ?this.matchedProduct?.y:0,
			category: data.category ? data.category : '',
			content: content_data,
			youtubeURL: data.product_url ? data.product_url : '',
			domain_name:this.domain_name?this.domain_name:'',
			// isBundle: data.isBundle ? data.isBundle : false,
			// bundleProducts: data.bundleProduct ? data.bundleProduct : '',
			openNewTab: data.new_window ? data.new_window : '',
			isVideo: data.isVideo ? data.isVideo : '',
			isBanner: data.isBanner ? data.isBanner : '',
			bannerText: data.bannerText ? data.bannerText : '',
			product_image: data.product_image ? data.product_image : '',
			product_description: data.product_description ? data.product_description : '',
			autoplay: data.autoplay ? data.autoplay : false,
			loopvideo: data.loopvideo ? data.loopvideo : false,
			price_label_value1: data.price_label_value1 ? data.price_label_value1 : '',
			price_label_value2: data.price_label_value2 ? data.price_label_value2 : '',
			price_label_value3: data.price_label_value3 ? data.price_label_value3 : '',
			price_label_format: data.price_label_format ? data.price_label_format : '',
			price_label_svg: data.price_label_svg ? data.price_label_svg : '',
			digital_coupon: data.digital_coupon ? data.digital_coupon : false,
			pack:data.pack ? data.pack :'',
			isBundleProduct:data.isBundleProduct?data.isBundleProduct:false
			};
		}
		const dataSnapshot: any = await this.afs.collection('/layouts', ref => ref.where('uid', '==', this.data?.layout_id)).get().toPromise();
		let priceChanged = false;
		let priceChanges: any = { ...product_data_card, priceChanges: {} };
			if (!dataSnapshot.empty) {
				const docData = dataSnapshot.docs[0].data();
				const checkJson = docData.layout_json
				if(checkJson.length>0){
				const matchProduct=	checkJson.forEach(product=>{
					if(product.id==product_data_card.id){
						["price_label_value1", "price_label_value2", "price_label_value3"].forEach(priceKey => {
							if (product_data_card[priceKey] !== product[priceKey]) {
								priceChanged = true;
								priceChanges.priceChanges[priceKey] = {
									old: product[priceKey],
									new: product_data_card[priceKey]
								};
							}
						});
						if (priceChanged) {
							product_data_card.updated = true;
							product_data_card.updatedAt = Date.now()
						} else {
							const updatedTime = product_data_card.updatedAt || 0; // Default to 0 if not available
							const currentTime = Date.now();
							const timeDifference = currentTime - updatedTime;
							// Check if 24 hours have passed (24 * 60 * 60 * 1000 ms
							if (product_data_card.updated) {
								if (timeDifference >= 24 * 60 * 60 * 1000) {
									product_data_card.updated = false;
									product_data_card.updatedAt = Date.now()
								} else {
									product_data_card.updated = product_data_card.updated ? product_data_card.updated : false;
									product_data_card.updatedAt = product_data_card.updatedAt ? product_data_card.updatedAt : Date.now()
								}
							} else {
								product_data_card.updated = false;
								product_data_card.updatedAt = Date.now()
							}
						}
				}
			})
			}
		}
		const index = this.current_select_product_index.toString().includes(' ') ? this.current_select_product_index.split(' ') : this.current_select_product_index
		const json_index = this.current_select_product_json_index.toString().includes(' ') ? this.current_select_product_json_index.split(' ') : this.current_select_product_json_index
		const json_saved__index = this.current_select_product_json_saved_index.toString().includes(' ') ? this.current_select_product_json_saved_index.split(' ') : this.current_select_product_json_saved_index
			this.current_layout_data[0].layout_json_temp[index] = product_data_card;
			this.current_layout_data[0].layout_json[index] = product_data_card;
			this.current_layout_data[0].layout_json_saved[index] = product_data_card;
		var currentData: any = await this.currentData();
		const layout_json =await this.afs.doc('/layouts/' + this.data?.layout_id).set({ layout_json: this.current_layout_data[0].layout_json }, { merge: true })
		this.show_edit_form = false;		
		this.closeModal();
		if (Array.isArray (currentData?.layout_json_temp)) {
			const layout_data_updatex =await this.afs.doc('/layouts/' + this.data?.layout_id).set({ layout_json_temp: this.current_layout_data[0].layout_json_temp }, { merge: true })
		}else if (typeof (currentData.layout_json_temp) == "string") {
			await this.componentService.setTempLayout(this.current_layout_data[0].layout_json_temp, currentData.layout_json_temp)
		}else{
			console.error("layout json temp null or undefined")
		}
		if (Array.isArray (currentData?.layout_json_saved)) {
			const layout_json_saved =await this.afs.doc('/layouts/' + this.data?.layout_id).set({ layout_json_saved: this.current_layout_data[0].layout_json_saved }, { merge: true })
		}else if (typeof (currentData.layout_json_saved) == "string") {
			await this.componentService.setSavedLayout(this.current_layout_data[0].layout_json_saved, currentData.layout_json_saved);	
		}else{
			console.error("layout json saved null or undefined")
		}
		await this.fns.httpsCallable('getAccessToken')({}).toPromise()
			.then(response => {
				this.accessToken = response.access_token
			})
			.catch(error => {
				console.error('Error fetching access token:', error); // Handle the error
			});
			 // storeName should be a string
			 const querySnapshot = await this.afs.collection('userTokens', ref => 
				ref.where('isLoggedIn', '==', 'yes')
				   .where('userRole', '==', 'store-manager')
				   .where('link', '==', 'https://store.digitalmenuspro.com')
			).get().toPromise();
			
			this.tokens = [];
			querySnapshot?.forEach(doc => {
				if (doc.exists) {
					const tokenData: any = doc.data(); // Get the document data
					const isMatchingStore = tokenData?.store?.some((s: any) => 
						s.company_name === this.data.storeName
					);
			
					if (isMatchingStore) {
						if (tokenData.token) {
							this.tokens.push(tokenData.token); // Add the token to the array
						}
					}
				}
			});
			if(this.tokens?.length>0){
				const callable1 = await this.fns.httpsCallable('subscribeToTopic')({
					tokens: this.tokens,
					topics: `PWANotificationSM${this.data?.companyNameSlug}`
				}).toPromise().then(response => {
					console.log( "response")
				});
			
			const body = {
			  "message": {
				  "topic": `PWANotificationSM${this.data?.companyNameSlug}`,
				  "notification": {
					  "body": "A circular has been updated. Please review the latest changes.",
					  "title": "DMP Notification",
				  },
				  "webpush": {
					  "fcm_options": {
						  "link": `https://store.digitalmenuspro.com/${this.data?.companyNameSlug}`
					  },
					  "headers": {
						  "Urgency": "high"
					  },
					  "notification": {
						  "body": "A circular has been updated. Please review the latest changes.",
						  "requireInteraction": "true",
						  "badge": "/badge-icon.png",
						  "silent":"false"
					  },
					  "data": {
						  "link": `https://store.digitalmenuspro.com/${this.data?.companyNameSlug}`
					  }
				  }
			  }
		  }
		  this.sendNotification(this.accessToken, body).toPromise()
		  .then(response => {
			  console.log('Notification sent');
		  })
		  .catch(error => {
			  console.error('Error sending notification:', error);
		  });
	  }
	}
	apiUrl = "https://fcm.googleapis.com/v1/projects/digital-menus-pro-6b7bd/messages:send"
	sendNotification(privateKey: string, body: any) {
		const headers = new HttpHeaders({
			// 'X-Clover-Merchant-Id': merchantId,
			Authorization: `Bearer ${privateKey}`,
			'Content-Type': 'application/json',
		});
		return this.http.post(this.apiUrl, body, { headers });
	}
  async updateFormData(id) {
		this.show_add_product = false
		await this.getLayoutData();
	    var currentData: any = await this.currentData();
	      this.current_layout_data[0].layout_json = currentData?.layout_json
		  this.current_select_product_json_index = currentData?.layout_json.findIndex(obj => obj.id == id);
		  this.current_select_product_json_data = currentData?.layout_json.find(obj => obj.id == id);
		  this.current_select_product_id = id
	                if (currentData?.layout_json_temp) {
	                	  let currentDataTemp
	                	  if (Array.isArray(currentData.layout_json_temp)) {
	                		  currentDataTemp = currentData
	                		  this.current_layout_data[0].layout_json_temp = currentData?.layout_json_temp
	                	  }else if (typeof (currentData?.layout_json_temp) == "string") {
	                		  currentDataTemp = await this.componentService.getTempLayout(currentData?.layout_json_temp)
	                		  this.current_layout_data[0].layout_json_temp = currentDataTemp?.layout_json_temp
	                	  }else{
	                		  console.error("layout json temp null or undefined")
	                	  }
	                	  this.current_select_product_index = currentDataTemp?.layout_json_temp.findIndex(obj => obj.id == id);
	                	  this.current_select_product_data = currentDataTemp?.layout_json_temp.find(obj => obj.id == id);
	                }
	                if (currentData?.layout_json_temp) {
	                		let currentDataSaved
	                	  if (Array.isArray(currentData.layout_json_saved)) {
	                		currentDataSaved = currentData
	                		this.current_layout_data[0].layout_json_saved = currentData?.layout_json_saved
	                	}else if (typeof (currentData?.layout_json_saved) == "string") {
	                		currentDataSaved = await this.componentService.getSavedLayout(currentData?.layout_json_saved)
	                		this.current_layout_data[0].layout_json_saved = currentDataSaved?.layout_json_saved
	                	}else{
	                		console.error("layout json temp null or undefined")
	                	}
	                	  this.current_select_product_json_saved_index = currentDataSaved?.layout_json_saved.findIndex(obj => obj.id == id);
	                	  this.current_select_product_json_saved_data = currentDataSaved?.layout_json_saved.find(obj => obj.id == id);
	                }
		  
	  
					if(this.data?.gridView?.digital_coupon ==true){
						this.show_digital_coupons=true;
						}else{
						this.show_digital_coupons=false;
						}
					if (this.data?.gridView?.isVideo) {
						this.storeInMaster = true;
					} else {
						this.storeInMaster = false;
					}
					this.imageurl1x = this.data?.gridView?.product_image ? this.data?.gridView?.product_image : '';
					// this.bundledList = this.data?.gridView?.bundleProduct ? this.data?.gridView?.bundleProduct : '';
					// this.isBundle = this.data?.gridView?.isBundle ? this.data?.gridView?.isBundle : false;
					// this.product_update_form.get('isBundle')?.setValue(this.isBundle);
					this.current_price_label_format = this.getFormate(this.data.gridView?.content);
					this.price_label_svg = this.getSVG(this.data.gridView?.content);
			        this.price_label_svg_html = this.sanitizer.bypassSecurityTrustHtml(this.price_label_svg)
					this.price_label_value1 = this.getPrice(this.data.gridView?.content, 'lable1') ? this.getPrice(this.data.gridView?.content, 'lable1') : '';
					this.price_label_value2 = this.getPrice(this.data.gridView?.content, 'lable2') ? this.getPrice(this.data.gridView?.content, 'lable2') : '';
					this.price_label_value3 = this.getPrice(this.data.gridView?.content, 'lable3') ? this.getPrice(this.data.gridView?.content, 'lable3') : '';
					this.add_editorContent = this.data?.gridView?.bannerText ? this.data?.gridView?.bannerText : false;
					this.isVideo = this.data?.gridView?.isVideo ? this.data?.gridView?.isVideo : '';
					this.selectedLabelSide = this.data?.gridView?.labelSide ? this.data?.gridView?.labelSide : ''
					this.sale_price_label_value1= this.data?.gridView?.sale_price_label_value1 ? this.data?.gridView?.sale_price_label_value1 : '',
					this.sale_price_label_value2 =this.data?.gridView?.sale_price_label_value2 ? this.data?.gridView?.sale_price_label_value2 : '',
					this.sale_price_label_value3 = this.data?.gridView?.sale_price_label_value3 ? this.data?.gridView?.sale_price_label_value3 : '',
					this.sale_price_label_svg = this.data?.gridView?.sale_price_label_svg ? this.data?.gridView?.sale_price_label_svg : '',
					this.sale_content_price_label_svg = this.data?.gridView?.sale_content_price_label_svg ? this.data?.gridView?.sale_content_price_label_svg : '',
					this.final_price_label_value1= this.data?.gridView?.final_price_label_value1 ? this.data?.gridView?.final_price_label_value1 : '',
					this.final_price_label_value2= this.data?.gridView?.final_price_label_value2 ? this.data?.gridView?.final_price_label_value2 : '',
					this.final_price_label_value3= this.data?.gridView?.final_price_label_value3 ? this.data?.gridView?.final_price_label_value3 : '',
					this.final_price_label_svg= this.data?.gridView?.final_price_label_svg ? this.data?.gridView?.final_price_label_svg : '',
					this.final_content_price_label_svg = this.data?.gridView?.final_content_price_label_svg ? this.data?.gridView?.final_content_price_label_svg : '',
					this.digital_coupon_price_label_value1= this.data?.gridView?.digital_coupon_price_label_value1 ? this.data?.gridView?.digital_coupon_price_label_value1 : '',
					this.digital_coupon_price_label_value2= this.data?.gridView?.digital_coupon_price_label_value2 ? this.data?.gridView?.digital_coupon_price_label_value2 : '',
					this.digital_coupon_price_label_value3= this.data?.gridView?.digital_coupon_price_label_value3 ? this.data?.gridView?.digital_coupon_price_label_value3 : '',
					this.digital_coupon_price_label_svg= this.data?.gridView?.digital_coupon_price_label_svg ? this.data?.gridView?.digital_coupon_price_label_svg : '',
					this.digital_coupon= this.data?.gridView?.digital_coupon ? this.data?.gridView?.digital_coupon : false,
					this.digital_content_coupon_price_label_svg = this.data?.gridView?.digital_content_coupon_price_label_svg ? this.data?.gridView?.digital_content_coupon_price_label_svg : '',
					this.current_sale_price_label_format = this.data?.gridView?.sale_price_label_format ?this.data?.gridView?.sale_price_label_format:'';
					this.current_final_price_label_format = this.data?.gridView?.final_price_label_format ?this.data?.gridView?.final_price_label_format :'';
					this.note =this.data?.gridView?.note ?this.data?.gridView?.note:'',
					this.link =this.data?.gridView?.link ?this.data?.gridView?.link:''
					this.usage_limit =this.data?.gridView?.usage_limit ?this.data?.gridView?.usage_limit:''
					this.current_digital_coupon_price_label_format = this.data?.gridView?.digital_coupon_price_label_format ?this.data?.gridView?.digital_coupon_price_label_format:'';
					const layout = this.current_layout_data[0];
					const layoutJson = layout?.layout_json;
					this.matchedProduct = layoutJson.find((product: any) => {
					  return product.id === this.data?.gridView?.id;
					});	
					this.product_update_form.patchValue({
						uid: this.data?.gridView?.id ? this.data?.gridView?.id : '',
						w:this.matchedProduct?.w ? this.matchedProduct?.w : '',
						h:this.matchedProduct?.h? this.matchedProduct?.h : '',
						x:this.matchedProduct?.x ? this.matchedProduct?.x : '',
						y:this.matchedProduct?.y ? this.matchedProduct?.y : '',
						master_uid: this.data?.gridView?.master_uid ? this.data?.gridView?.master_uid : '',
						product_image: this.data?.gridView?.product_image ? this.data?.gridView?.product_image : '',
						upc_code: this.data?.gridView?.upc_code ? this.data?.gridView?.upc_code : '',
						product_name_slug: this.data?.gridView?.product_name_slug ? this.data?.gridView?.product_name_slug : '',
						product_description: this.data?.gridView?.product_description ? this.data?.gridView?.product_description : '',
						pack: this.data?.gridView?.pack ? this.data?.gridView?.pack : '',
						price_label_format: this.data?.gridView?.price_label_format ? this.data?.gridView?.price_label_format : '',
						category: this.data?.gridView?.category ? this.data?.gridView?.category : '',
						product_url: this.data?.gridView?.youtubeURL ? this.data?.gridView?.youtubeURL.split('?')[0] : '',
						new_window: this.data?.gridView?.new_window ? this.data?.gridView?.new_window : false,
						autoplay: this.data?.gridView?.autoplay ? this.data?.gridView?.autoplay : false,
						loopvideo: this.data?.gridView?.loopvideo ? this.data?.gridView?.loopvideo : false,
						isBanner: this.data?.gridView?.isBanner ? this.data?.gridView?.isBanner : false,
						bannerText: this.data?.gridView?.bannerText ? this.data?.gridView?.bannerText : '',
						storeInMaster: this.data?.gridView?.storeInMaster ? this.data?.gridView?.storeInMaster : false,
						offerLabel: this.data?.gridView?.offerLabel ? this.data?.gridView?.offerLabel : '',
						labelSide: this.data?.gridView?.labelSide ? this.data?.gridView?.labelSide : '',
						hide_price_label: this.data?.gridView?.hide_price_label ? this.data?.gridView?.hide_price_label : false,
						hide_addtolist_label: this.data?.gridView?.hide_addtolist_label ? this.data?.gridView?.hide_addtolist_label : false,
						hide_productname_label: this.data?.gridView?.hide_productname_label ? this.data?.gridView?.hide_productname_label : false,
						price_label_value1: this.price_label_value1 ? this.price_label_value1 : '',
						price_label_value2: this.price_label_value2 ? this.price_label_value2 : '',
						price_label_value3: this.price_label_value3 ? this.price_label_value3 : '',
						price_label_svg: this.price_label_svg ? this.price_label_svg : '',
						isVideo: this.data?.gridView?.isVideo ? this.data?.gridView?.isVideo : '',
						sale_price_label_value1: this.data?.gridView?.sale_price_label_value1 ? this.data?.gridView?.sale_price_label_value1 : '',
						sale_price_label_value2: this.data?.gridView?.sale_price_label_value2 ? this.data?.gridView?.sale_price_label_value2 : '',
						sale_price_label_value3: this.data?.gridView?.sale_price_label_value3 ? this.data?.gridView?.sale_price_label_value3 : '',
						sale_price_label_svg: this.data?.gridView?.sale_price_label_svg ? this.data?.gridView?.sale_price_label_svg : '',
						sale_content_price_label_svg: this.data?.gridView?.sale_content_price_label_svg ? this.data?.gridView?.sale_content_price_label_svg : '',
						final_price_label_value1: this.data?.gridView?.final_price_label_value1 ? this.data?.gridView?.final_price_label_value1 : '',
						final_price_label_value2: this.data?.gridView?.final_price_label_value2 ? this.data?.gridView?.final_price_label_value2 : '',
						final_price_label_value3: this.data?.gridView?.final_price_label_value3 ? this.data?.gridView?.final_price_label_value3 : '',
						final_price_label_svg: this.data?.gridView?.final_price_label_svg ? this.data?.gridView?.final_price_label_svg : '',
						final_content_price_label_svg: this.data?.gridView?.final_content_price_label_svg ? this.data?.gridView?.final_content_price_label_svg : '',
						digital_coupon_price_label_value1: this.data?.gridView?.digital_coupon_price_label_value1 ? this.data?.gridView?.digital_coupon_price_label_value1 : '',
						digital_coupon_price_label_value2: this.data?.gridView?.digital_coupon_price_label_value2 ? this.data?.gridView?.digital_coupon_price_label_value2 : '',
						digital_coupon_price_label_value3: this.data?.gridView?.digital_coupon_price_label_value3 ? this.data?.gridView?.digital_coupon_price_label_value3 : '',
						digital_coupon_price_label_svg: this.data?.gridView?.digital_coupon_price_label_svg ? this.data?.gridView?.digital_coupon_price_label_svg : '',
						digital_coupon: this.data?.gridView?.digital_coupon ? this.data?.gridView?.digital_coupon : false,
						digital_content_coupon_price_label_svg: this.data?.gridView?.digital_content_coupon_price_label_svg ? this.data?.gridView?.digital_content_coupon_price_label_svg : '',
						note: this.data?.gridView?.note ? this.data?.gridView?.note : '',
						link: this.data?.gridView?.link ? this.data?.gridView?.link : '',
						usage_limit: this.data?.gridView?.usage_limit ? this.data?.gridView?.usage_limit : '',
						sale_price_label_format: this.data?.gridView?.sale_price_label_format ? this.data?.gridView?.sale_price_label_format : '',
						final_price_label_format: this.data?.gridView?.final_price_label_format ? this.data?.gridView?.final_price_label_format : '',
						digital_coupon_price_label_format: this.data?.gridView?.digital_coupon_price_label_format ? this.data?.gridView?.digital_coupon_price_label_format : ''
					  });
					  this.show_edit_loader=false
				
				// this.product_update_form.get('bundleProduct')?.reset(); // Clear the input field
				this.show_final_price_format_panel=false;
				this.show_sale_price_format_panel=false;
				this.show_digital_coupon_format_panel=false;
				this.show_edit_form = true
		}
	
   getSVG(data) {
	  var temp = document.createElement('div');
	  temp.innerHTML = data;
	  return temp.getElementsByClassName('pricelabel')[0]?.innerHTML
	}
  
	getFormate(data) {
	  var temp = document.createElement('div');
	  temp.innerHTML = data;
	  if(temp?.getElementsByClassName("circular-card-deal-wrapper")[0]){
	  return temp?.getElementsByClassName("circular-card-deal-wrapper")[0]?.getAttribute("id") || ''
	  }else if (temp?.getElementsByClassName("circular-card-deal-wrapperknife")[0]){
	  return temp?.getElementsByClassName("circular-card-deal-wrapperknife")[0]?.getAttribute("id") || ''	
	  }
	}
  
	getPrice(data, priceLabel) {
	  var temp = document.createElement('div');
	  temp.innerHTML = data;
	  if (temp.getElementsByClassName('pricelabel')[0]?.innerHTML) {
		var formate = this.getFormate(data)
		let price = ''
		switch (priceLabel) {
		  case 'lable1':
			price = $(temp).find('#dollar').text().trim()
			break
		  case 'lable2':
			price = $(temp).find('#cent').text().trim()
			break
		  case 'lable3':
			price = $(temp).find('#third').text().trim()
			break
		  default:
			price = '0'
			break
		}
		switch (formate) {
		  case 'format1':
			return price
		  case 'format2':
			return price
		  case 'format3':
			return price
		  case 'format4':
			return price
		  case 'format5':
			return price
		  case 'format6':
			return price
		  case 'format7':
			return price
		  default:
			return ''
		}
	  } else {
		return ''
	  }
	}
  async price_format_value_change(event, label, format) {
		const width = this.matchedProduct?.w
		const height = this.matchedProduct?.h
		const val = event.target.value;
		if (label == 'value1') {
			this.price_label_value1 = val;
			this.product_update_form.patchValue({
				price_label_value1: val
			});
			this.product_add_form.patchValue({
				price_label_value1: val
			});
		}

		if (label == 'value2') {
			this.price_label_value2 = val;
			this.product_update_form.patchValue({
				price_label_value2: val
			});
			this.product_add_form.patchValue({
				price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.price_label_value3 = val;
			this.product_update_form.patchValue({
				price_label_value3: val
			});

			this.product_add_form.patchValue({
				price_label_value3: val
			});
		}
		if (this.price_label_type == 'cleaver') {
			if (this.show_add_product) {
				const size = this.getCleaverSVGsize(1, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
				this.labelSize = size
			} else {
				const size = this.getCleaverSVGsize(width > height ? height : width, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
				this.labelSize = size
			}
			this.price_label_svg = await this.cleaverPriceFormatService.price_format(format, this.labelSize, this.price_label_value1, this.price_label_value2, this.price_label_value3, this.current_price_label_format);
			this.price_label_svg_html = this.sanitizer.bypassSecurityTrustHtml(this.price_label_svg)
		} else if (this.price_label_type == 'circular') {
			if (this.show_add_product) {
				const size = this.getSVGsize(1, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
				this.labelSize = size
			} else {
				const size = this.getSVGsize(width > height ? height : width, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
				this.labelSize = size
			}
			this.price_label_svg = await this.priceFormatService.price_format(format, this.labelSize, this.price_label_value1, this.price_label_value2, this.price_label_value3, this.current_price_label_format);
			this.price_label_svg_html = this.sanitizer.bypassSecurityTrustHtml(this.price_label_svg)
		}
		this.product_update_form.patchValue({
			price_label_svg: this.price_label_svg
		});
		this.product_add_form.patchValue({
			price_label_svg: this.price_label_svg
		});
	}
	async sale_price_format_value_change(event, label, format) {
		const width = this.matchedProduct?.w
		const height = this.matchedProduct?.h
		const val = event.target.value;
			this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		if (label == 'value1') {
			this.sale_price_label_value1 = val;
			this.product_update_form.patchValue({
				sale_price_label_value1: val
			});
			this.product_add_form.patchValue({
				sale_price_label_value1: val
			});
		}
		if (label == 'value2') {
			this.sale_price_label_value2 = val;
			this.product_update_form.patchValue({
				sale_price_label_value2: val
			});
			this.product_add_form.patchValue({
				sale_price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.sale_price_label_value3 = val;
			this.product_update_form.patchValue({
				sale_price_label_value3: val
			});
			this.product_add_form.patchValue({
				sale_price_label_value3: val
			});
		}
		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.sale_price_label_value1?.trim() + this.sale_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.sale_price_label_value1?.trim() + this.sale_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}
        let sale_price_format=await this.priceFormatService.sale_price(format,this.sale_price_label_value1,this.sale_price_label_value2,this.sale_price_label_value3);
		this.sale_price_label_svg=sale_price_format.sale_price_label_svg;
		this.sale_content_price_label_svg=sale_price_format.sale_content_price_label_svg
		this.product_update_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
		this.product_add_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
	}
	async final_price_format_value_change(event, label, format) {
		const width = this.matchedProduct?.w
		const height = this.matchedProduct?.h
		const val = event.target.value;
		if (label == 'value1') {
			this.final_price_label_value1 = val;
			this.product_update_form.patchValue({
				final_price_label_value1: val
			});
			this.product_add_form.patchValue({
				final_price_label_value1: val
			});
		}

		if (label == 'value2') {
			this.final_price_label_value2 = val;
			this.product_update_form.patchValue({
				final_price_label_value2: val
			});
			this.product_add_form.patchValue({
				final_price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.final_price_label_value3 = val;
			this.product_update_form.patchValue({
				final_price_label_value3: val
			});

			this.product_add_form.patchValue({
				final_price_label_value3: val
			});
		} 
		this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.final_price_label_value1?.trim() + this.final_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.final_price_label_value1?.trim() + this.final_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}
		
		let final_price_format=await this.priceFormatService.final_price(format,this.final_price_label_value1,this.final_price_label_value2,this.final_price_label_value3);
		this.final_price_label_svg=final_price_format.final_price_label_svg;
		this.final_content_price_label_svg=final_price_format.final_content_price_label_svg
	
		this.product_update_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg

		});
		this.product_add_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg
		});
	}
	async digital_coupon_price_format_value_change(event, label, format) {
		const width = this.matchedProduct?.w
		const height = this.matchedProduct?.h
		const val = event.target.value;
		this.current_digital_coupon_price_label_format='Digital Coupon'
		this.show_digital_coupon_format_panel = false;
		if (label == 'value1') {
			this.digital_coupon_price_label_value1 = val;
			this.product_update_form.patchValue({
				digital_coupon_price_label_value1: val
			});
			this.product_add_form.patchValue({
				digital_coupon_price_label_value1: val
			});
		}
		if (label == 'value2') {
			this.digital_coupon_price_label_value2 = val;
			this.product_update_form.patchValue({
				digital_coupon_price_label_value2: val
			});
			this.product_add_form.patchValue({
				digital_coupon_price_label_value2: val
			});
		}
		if (label == 'value3') {
			this.digital_coupon_price_label_value3 = val;
			this.product_update_form.patchValue({
				digital_coupon_price_label_value3: val
			});

			this.product_add_form.patchValue({
				digital_coupon_price_label_value3: val
			});
		}
		if (this.show_add_product) {
			const size = this.getSVGsize(1, (this.digital_coupon_price_label_value1?.trim() + this.digital_coupon_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		} else {
			const size = this.getSVGsize(width > height ? height : width, (this.digital_coupon_price_label_value1?.trim() + this.digital_coupon_price_label_value2?.trim()).replace('.', '').length)
			this.labelSize = size
		}        
		let digital_coupon_price_format = await this.priceFormatService.digital_coupon_price_format(format,this.digital_coupon_price_label_value1,this.digital_coupon_price_label_value2,this.digital_coupon_price_label_value3);
		this.digital_coupon_price_label_svg = digital_coupon_price_format.digital_coupon_price_label_svg;
		this.digital_content_coupon_price_label_svg = digital_coupon_price_format.digital_content_coupon_price_label_svg;
		this.product_update_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
		this.product_add_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
	}
  open_format(format) {
		if (this.show_format_panel == true) {
			this.show_format_panel = false;
		} else {
			this.show_format_panel = true;
		}
	}
  openIconModal(type) {
		this.current_unsplash_type = type;
		this.open(this.iconSearchModal, 'modal-basic-title5');
	}
  open(content, title) {
		this.modalService.open(content, { windowClass: title }).result.then((result) => {
			this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
			this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}
  private getDismissReason(reason: any): string {
		if (reason === ModalDismissReasons.ESC) {
			return 'by pressing ESC';
		} else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
			return 'by clicking on a backdrop';
		} else {
			return `with: ${reason}`;
		}
	}
	open_sale_price_format(format){
		this.current_sale_price_label_format = 'Sale Price format1' ;
		this.show_format_panel = false;

		if (this.show_sale_price_format_panel == true) {
			this.show_sale_price_format_panel = false;
		} else {
			this.show_sale_price_format_panel = true;
		}		
		this.product_update_form.patchValue({
			sale_price_label_format: 'Sale Price format1' ,
		});
		this.product_add_form.patchValue({
			sale_price_label_format: 'Sale Price format1' ,
		});
	
		if (this.current_sale_price_label_format == 'Sale Price format1') {
			if (this.sale_price_label_value1) {
				this.sale_content_price_label_svg='<div class="sell_title_wrapper"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2" id="sale_price_doller">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + this.sale_price_label_value2 + '</div></div></div></div>'
				this.sale_price_label_svg = '<div class="sell_title_wrapper"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_1">$</div><div class="price_format_data_2">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3">' + this.sale_price_label_value2 + '</div></div></div></div>';
			} else {
				if(this.sale_price_label_value2){
					this.sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + this.sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.sale_price_label_svg = '<div class="sell_title_wrapper sell_wrapper_format_1"><div class="price_format_wrapper"><div class="price_format_1"><div class="price_format_data_2">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3">' + this.sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					this.sale_content_price_label_svg='<div class="sell_title_wrapper sell_wrapper_format_1"><div class="sell_title">Sale<br> Price</div><div class="price_format_wrapper" id="sale_price_format1"><div class="price_format_1"><div class="price_format_data_2" id="sale_price_doller">' + this.sale_price_label_value1 + '</div><div class="price_format_data_3" id="sale_price_cent">' + this.sale_price_label_value2 + '</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.sale_price_label_svg = '<div id="sale_price_format1" class="circular-card-deal-wrapper DigitalExtraSmall"></div>';
				}
			}
		}
	}
	
	open_digital_coupon_format(format){
		this.current_digital_coupon_price_label_format = 'Digital Coupon';
		this.show_format_panel = false;
		this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		if (this.current_digital_coupon_price_label_format == 'Digital Coupon') {
			if (this.digital_coupon_price_label_value1) {
				this.digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + this.digital_coupon_price_label_value2 + '</div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
				this.digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_doller"> $</div><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div>' + this.digital_coupon_price_label_value2 + '</div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>';
			} else {
				if(this.digital_coupon_price_label_value2){
					this.digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + this.digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
					this.digital_coupon_price_label_svg = '<div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + this.digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
				}else{
					this.digital_content_coupon_price_label_svg ='<div class="digital_coupon_title">Digital <br>Coupon</div><div class="digital_coupon_data"><span class="digital_coupon_dess">-</span><div class="digital_coupon_price_value">' + this.digital_coupon_price_label_value1 + '</div><div class="off2_offer"><div class="offer_text_value">' + this.digital_coupon_price_label_value2 + '<div class="digital_coupon_doller">¢</div></div><div>' + ' ' + this.digital_coupon_price_label_value3 + '</div></div></div>'
					this.digital_coupon_price_label_svg = '<div id="digital_coupon" class="circular-card-deal-wrapper DigitalExtraSmall"></div>'
				}
			}
		}
	}
	
	open_final_price_format(format){
		this.current_final_price_label_format = 'Final Price Format1'
		this.show_format_panel = false;
		if (this.show_final_price_format_panel == true) {
			this.show_final_price_format_panel = false;
		} else {
			this.show_final_price_format_panel = true;
		}
		this.product_update_form.patchValue({
			final_price_label_format: 'Final Price Format1' 
		});
		this.product_add_form.patchValue({
			final_price_label_format: 'Final Price Format1' 
		});
		if (this.current_final_price_label_format == 'Final Price Format1') {
			if (this.final_price_label_value1) {
				this.final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div></div></div></div>'
				this.final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_doller"><div class="price_format_1 final_price_value_wrapper"><div class="price_format_data_1">$</div><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div></div></div></div>';
			} else {
				if(this.final_price_label_value2){
					this.final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>';
				}else{
					this.final_content_price_label_svg='<div class="final_price_title">FINAL PRICE</div><div class="with_digital_coupon_sub">with digital Coupon</div><div class="final_price_format_wrapper final_price_format1_wrapper_cent"><div class="price_format_1 final_price_value_wrapper final_sale_value"><div class="price_format_data_2">'+ this.final_price_label_value1 +'</div><div class="price_format_data_3">'+ this.final_price_label_value2 +'</div><div class="price_format_data_1">¢</div></div></div></div>'
					this.final_price_label_svg = '<div class="final_price_format_wrapper final_price_format1_wrapper_cent"></div>';
				}
			}
		}
	}
	
	async selected_price_label(id) {
		const width = this.matchedProduct?.w
		const height = this.matchedProduct?.h
		this.current_price_label_format = 'format' + id;
		this.show_format_panel = false;
		this.product_update_form.patchValue({
			price_label_format: 'format' + id,
			});
		this.product_add_form.patchValue({
			price_label_format: 'format' + id,
			});
		if (this.current_price_label_format == 'format6' && this.price_label_value3 == '') {
			this.product_update_form.patchValue({
				price_label_value3: 'Free'
			});
			this.price_label_value3 = 'Free'
			}
			if (this.price_label_type == 'cleaver') {
				if (this.show_add_product) {
					const size = this.getCleaverSVGsize(1, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
					this.labelSize = size
				} else {
					const size = this.getCleaverSVGsize(width > height ? height : width, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
					this.labelSize = size
				}
				this.price_label_svg = await this.cleaverPriceFormatService.price_format(this.current_price_label_format, this.labelSize, this.price_label_value1, this.price_label_value2, this.price_label_value3, this.current_price_label_format);
				this.price_label_svg_html = this.sanitizer.bypassSecurityTrustHtml(this.price_label_svg)
			} else if (this.price_label_type == 'circular') {
				if (this.show_add_product) {
					const size = this.getSVGsize(1, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
					this.labelSize = size
				} else {
					const size = this.getSVGsize(width > height ? height : width, (this.price_label_value1?.trim() + this.price_label_value2?.trim()).replace('.', '').length)
					this.labelSize = size
				}
				this.price_label_svg = await this.priceFormatService.price_format(this.current_price_label_format, this.labelSize, this.price_label_value1, this.price_label_value2, this.price_label_value3, this.current_price_label_format);
				this.price_label_svg_html = this.sanitizer.bypassSecurityTrustHtml(this.price_label_svg)
			}
			this.product_update_form.patchValue({
			price_label_svg: this.price_label_svg,
		});
	}
	async selected_sale_price_label(id) {
		this.current_sale_price_label_format = 'Sale Price format' + id;
		this.show_format_panel = false;
		this.show_sale_price_format_panel=false;
		this.product_update_form.patchValue({
			sale_price_label_format: 'Sale Price format' + id,
		});
		this.product_add_form.patchValue({
			sale_price_label_format: 'Sale Price format' + id,
		});
	    let sale_price_format =await this.priceFormatService.selected_sale_price_format(this.current_sale_price_label_format,this.sale_price_label_value1,this.sale_price_label_value2,this.sale_price_label_value3)
	    this.sale_price_label_svg = sale_price_format.sale_price_label_svg;
		this.sale_content_price_label_svg = sale_price_format.sale_content_price_label_svg;
		this.product_add_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
		this.product_update_form.patchValue({
			sale_price_label_svg: this.sale_price_label_svg,
			sale_content_price_label_svg: this.sale_content_price_label_svg

		});
	}

	async selected_final_price_label(id) {
		this.current_final_price_label_format = 'Final Price Format' + id;
		this.show_final_price_format_panel = false;
		this.product_update_form.patchValue({
			final_price_label_format: 'Final Price Format' + id,
		});
		this.product_add_form.patchValue({
			final_price_label_format: 'Final Price Format' + id,
		});		
        
		let final_price_format = await this.priceFormatService.selected_final_price_format(this.current_final_price_label_format,this.final_price_label_value1,this.final_price_label_value2,this.final_price_label_value3);
		this.final_price_label_svg = final_price_format.final_price_label_svg;
		this.final_content_price_label_svg = final_price_format.final_content_price_label_svg
		this.product_update_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg

		});
		this.product_add_form.patchValue({
			final_price_label_svg: this.final_price_label_svg,
			final_content_price_label_svg: this.final_content_price_label_svg
		});
	}
	async selected_digital_Coupon_price_label(id) {
		this.current_digital_coupon_price_label_format = 'Digital Coupon';
		this.show_digital_coupon_format_panel=false;
		this.product_update_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});

		this.product_add_form.patchValue({
			digital_coupon_price_label_format: 'Digital Coupon',
		});
        let digital_coupon_price_format = await this.priceFormatService.selected_digital_coupon_price(this.current_digital_coupon_price_label_format,this.digital_coupon_price_label_value1,this.digital_coupon_price_label_value2,this.digital_coupon_price_label_value3);
		this.digital_coupon_price_label_svg = digital_coupon_price_format.digital_coupon_price_label_svg;
		this.digital_content_coupon_price_label_svg = digital_coupon_price_format.digital_content_coupon_price_label_svg;
		
		this.product_update_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg

		});
		this.product_add_form.patchValue({
			digital_coupon_price_label_svg: this.digital_coupon_price_label_svg,
			digital_content_coupon_price_label_svg: this.digital_content_coupon_price_label_svg
		});
	}

	getCleaverSVGsize(width, textLength) {
		switch (parseInt(width)) {
			case 0:
			case 1:
				return this.getFontClass('extraSmall Cleaver', textLength)
			case 2:
				return this.getFontClass('baseSmall Cleaver', textLength)
			case 3:
				return this.getFontClass('base Cleaver', textLength)
			case 4:
				return this.getFontClass('medium Cleaver', textLength)
			case 5:
				return this.getFontClass('large Cleaver', textLength)
			case 6:
				return this.getFontClass('extraLarge Cleaver', textLength)
			case 7:
				return this.getFontClass('extra2large Cleaver', textLength)
			case 8:
				return this.getFontClass('extra3large Cleaver', textLength)
			case 9:
			case 10:
				return this.getFontClass('extra3large Cleaver', textLength)
			case 11:
			case 12:
				return this.getFontClass('extra3large Cleaver', textLength)
			default:
				return this.getFontClass('extraSmall Cleaver', textLength)
		}
	}
	getSVGsize(width, textLength) {
		switch (parseInt(width)) {
			case 0:
			case 1:
				return this.getFontClass('extraSmall', textLength)
			case 2:
				return this.getFontClass('baseSmall', textLength)
			case 3:
				return this.getFontClass('base', textLength)
			case 4:
				return this.getFontClass('medium', textLength)
			case 5:
				return this.getFontClass('large', textLength)
			case 6:
				return this.getFontClass('extraLarge', textLength)
			case 7:
				return this.getFontClass('extra2large', textLength)
			case 8:
				return this.getFontClass('extra3large', textLength)
			case 9:
			case 10:
				return this.getFontClass('extra3large', textLength)
			case 11:
			case 12:
				return this.getFontClass('extra3large', textLength)
			default:
				return this.getFontClass('extraSmall', textLength)
		}
	}

	getFontsize(width) {
		switch (parseInt(width)) {
			case 0:
			case 1:
				return this.getFontClassOffer('textSize1');
			case 2:
				return this.getFontClassOffer('textSize2');
			case 3:
				return this.getFontClassOffer('textSize3');
			case 4:
				return this.getFontClassOffer('textSize4');
			case 5:
				return this.getFontClassOffer('textSize5');
			case 6:
				return this.getFontClassOffer('textSize6');
			case 7:
				return this.getFontClassOffer('textSize7');
			case 8:
				return this.getFontClassOffer('textSize8');
			case 9:
			case 10:
				return this.getFontClassOffer('textSize9');
			case 11:
			case 12:
				return this.getFontClassOffer('textSize10');
			default:
				return this.getFontClassOffer('textSize11');
		}
	}




	upload(event, ind) {
		this.matchImage = false
		if (event.target.files && event.target.files[0]) {
			const file = event.target.files[0];
			const reader = new FileReader();
			reader.onload = e => this.imageSrc = reader.result;
			reader.readAsDataURL(file);
		}
		// create a random id
		const randomId = 'digital-menus-' + new Date().getTime() + Math.random().toString(36).substring(2);
		// create a reference to the storage bucket location
		this.ref = this.afStorage.ref('uploads/' + randomId);
		// the put method creates an AngularFireUploadTask
		// and kicks off the upload
		this.task = this.ref.put(event.target.files[0]);
		// this.imageurl = this.task.downloadURL();
		this.task.snapshotChanges().pipe(
			finalize(() => {
				this.subscriptions[this.subscriptions.length] = this.ref.getDownloadURL().subscribe(url => {
					this.subscriptions[this.subscriptions.length] = this.ref.getMetadata().subscribe(res => {
						if (res.contentType.split('/')[1] == 'gif') {
							this.isGIF = true
							this.isVideo = false
						} else if (res.contentType == 'video/webm' || res.contentType == 'video/mp4') {
							this.isVideo = true
							this.isGIF = false
							this.product_add_form.patchValue({
								isVideo: true
							})
						}
						else {
							this.isGIF = false
							this.isVideo = false
						}
						if (this.isVideo || this.isGIF) {
							this.storeInMaster = true;
						} else {
							this.product_add_form.controls['storeInMaster'].setValue(false);
							this.storeInMaster = false;
						}
					})
					if (ind == '1') {
						this.imageurl1 = url;
						this.layout_form.patchValue({
							page1_type: 'upload',
							page1_url: url
						});
					}
					if (ind == '2') {
						this.imageurl2 = url;
						this.imageurl2x = url;
						this.add_form_img = url
						this.layout_form.patchValue({
							page2_type: 'upload',
							page2_url: url
						});
					}
					if (ind == '3') {
						this.imageurl3 = url;
						this.layout_form.patchValue({
							page3_type: 'upload',
							page3_url: url
						});
					}
					if (ind == '4') {
						this.imageurl4 = url;
						this.layout_form.patchValue({
							page4_type: 'upload',
							page4_url: url
						});
					}
					if (ind == '5') {
						this.imageurl5 = url;
						this.layout_form.patchValue({
							page5_type: 'upload',
							page5_url: url
						});
					}
					this.image_uploading = false;

				});
			})
		).subscribe();
	}

	showStoreInMaster(data, update = false) {
		if (data != '' && data != null) {
			this.storeInMaster = true;
			var domain = new URL(data)
		    if(domain.hostname == 'www.facebook.com'){
				this.isVideo='facebook'
		    	this.loopVideoDisplay=false;
		    }else{
		    	this.loopVideoDisplay=true;
		    }
			if(domain.hostname == 'www.youtube.com'){
				this.isVideo='youtube'
			}
		} else {
			if (update) {
				this.product_update_form.controls['storeInMaster'].setValue(false);
			} else {
				this.product_add_form.controls['storeInMaster'].setValue(false);
				this.storeInMaster = false;
			}
		}
	}
	showDigitalCoupons(checked: boolean) {
		if (checked) {
			
		  this.show_digital_coupons = true;
		} else {
		  this.show_digital_coupons = false;
		}
	  }

	offerlabel(data) {
		if (data != '' && data != null) {
			this.showLabelSide = true
			this.offerLabel = data;
		} else {
			this.showLabelSide = false;
		}
	}

	showEditor_add() {
		// Check the current value of the checkbox
		const isBannerChecked = this.product_add_form.get('isBanner').value;
	}
  getImage(data, id) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		var doc = new DOMParser().parseFromString(temp?.innerHTML, "text/html")
		var imageElement = doc.getElementById(id);
		if (imageElement) {
			var src = imageElement.getAttribute('src');
			var updatedImageUrl = this.removeParameterFromUrl(src, 'size');
			updatedImageUrl = this.removeParameterFromUrl(updatedImageUrl, 'thumbnail');
			updatedImageUrl = this.removeParameterFromUrl(updatedImageUrl, 'palette');
			return updatedImageUrl;
		}
		return null;
	}

  removeParameterFromUrl(url, parameterToRemove) {
		var urlParts = url?.split('?');
		if (urlParts?.length > 1) {
			var baseUrl = urlParts[0];
			var queryParams = urlParts[1]?.split('&');
			var updatedParams = queryParams?.filter(function (param) {
				return param.indexOf(parameterToRemove + '=') !== 0;
			});
			var updatedUrl = baseUrl + '?' + updatedParams.join('&');
			return updatedUrl;
		}
		return url;
	}


  async getCSVpricelabel(data) {
    // circular price label
    if (data.price_label_format != '') {
      switch (data.price_label_format) {
        case "format1":
          return '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
        case "format2":
          if (data.price_label_value1) {
            return '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></span></div></div></div></div></div></div></div></div>';
          }
        case "format3":
          if (data.price_label_value1) {
            return '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span class=><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>'
          }
        case "format4":
          if (data.price_label_value1) {
            return '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>'
          }
        case "format5":
          if (data.price_label_value2) {
            return '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span id="third" class="cents-amount">' + ' ' + data.price_label_value3 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div></div></div></div></div></div></div></div>'
          }
        case "format6":
          if (data.price_label_value3 == '') {
            return '<div id="format6" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="third-amount"> Free</span></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format6" class="circular-card-deal-wrapper extraSmall withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></div></div></div></div></div></div></div>';
          }
        case "format7":
          if (data.price_label_value1) {
            return '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>'
          }
        default:
          return '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
      }
    } else {
      return ''
    }
  }
  getFontClass(svgSize, textLength) {
		switch (textLength) {
			case 1:
				return svgSize + ' singleDigit';
			case 2:
				return svgSize + ' doubleDigit';
			case 3:
				return svgSize + ' tripleDigit';
			case 4:
				return svgSize + ' fourDigit';
			default:
				return svgSize + ' singleDigit';
		}
	}

	getFontClassOffer(className) {
		return `${className}`;
	}
 
  make_price_message(formate, value1, value2, value3) {
    if ((value1 !== null || value1 !== '') && (value2 !== null || value2 !== '')) {
      if (formate === 'format6') {
        return `Buy ${value1} Get ${value2} ${value3}`
      }
      if (formate === 'format2' || formate === 'Final Price Format1' || formate === 'Sale Price format1' || formate === 'format3' || formate === 'format4' || formate === 'format7') {
        if (value1 == '') {
          return `$0.${value2}`
        } else if (value1 && value2) {
          return `$${value1}.${value2}`
        } else if (value1 && value2 == '') {
          return `$${value1}`
        }
      }
      if (formate === 'format5' || formate === 'Sale Price format2' || formate === 'Final Price Format2') {
        if (value2 == '') {
          return `${value1} for $0.${value3}`
        } else if (value2 && value3) {
          return `${value1} for $${value2}.${value3}`
        } else if (value2 && value3 == '') {
          return `${value1} for $${value2}`
        }
      }
    }
    if (formate === 'format1' && (value1 !== null || value1 !== '')) {
      return `$${value1}`
    }
    return ''
  }
  async addItem() {
    // this.show_loader = true
    let main_item
    if (this.data.product.id) {
      if (this.data.product.isVideo) {
        main_item = document.getElementById(this.data.product.id).parentElement
      } else {
        main_item = document.getElementById(this.data.product.id)
      }
    } else {
      if (this.data.product.isVideo) {
        main_item = document.getElementById(this.data.product.uid).parentElement
      } else {
        main_item = document.getElementById(this.data.product.uid)
      }
    }
    if (this.image_path.split('/').includes('plus.png')) {
      let msg;
      if (this.data.product.digital_coupon) {
        msg = this.make_price_message(this.data.product?.sale_price_label_format, this.data.product?.sale_price_label_value1, this.data.product?.sale_price_label_value2, this.data.product?.sale_price_label_value3)
      } else {
        msg = this.make_price_message(this.data.product?.price_label_format, this.data.product?.price_label_value1, this.data.product?.price_label_value2, this.data.product?.price_label_value3)
      };
      this.cartItem.push({ product: this.data.product, count: 1, static: false, priceMessage: msg, digital_coupon: this.data.product.digital_coupon ? this.data.product.digital_coupon : false ,isBundleProduct: this.data.product.isBundleProduct ? this.data.product.isBundleProduct : false,displayValidDate:true });
      if (this.data.product.id) {
        this.cart_ids.push(this.data.product.id)
      } else {
        this.cart_ids.push(this.data.product.uid)
      }
      this.toggleProduct('add');
      if (this.current_list_id !== '') {
        await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
        });
      } else {
        this.show_loader = true;
        const listRef = (await this.afs.collection('/list').add({ items: this.cartItem }))
        const listId = (await listRef.get()).id
        const listData: any = (await listRef.get()).data()
        const updatedList = await listRef.update({ uid: listId })
        this.current_list_id = listId
        this.current_list_data = { uid: listId, items: listData.items }
        this.asyncLocalStorage.setItem('list_id', listId).then(() => {
          return this.asyncLocalStorage.getItem('list_id');
        }).then(async data => {
          if (data) {
            await this.cart_created.emit(true);
          }
        })
      }
      this.image_path = '../../../../../assets/imgs/right.png'
      if (this.data.product.digital_coupon) {
        this.qnt_dropdown = false;
      } else {
        this.qnt_dropdown = true;

      }
      this.product_count = 1
      setTimeout(() => {
        this.show_loader = false
      }, 2000);
    } else {
      if (this.data.product.id) {
        this.cartItem = this.cartItem.filter(x =>
          x?.product?.id !== this.data.product.id)
        this.cart_ids = this.cart_ids.filter(x => x !== this.data.product.id)
      } else {
        this.cartItem = this.cartItem.filter(x =>
          x?.product?.uid !== this.data.product.uid)
        this.cart_ids = this.cart_ids.filter(x => x !== this.data.product.uid)
      }
      this.image_path = '../../../../../assets/imgs/plus.png'
      this.qnt_dropdown = false
      this.toggleProduct('delete')
      await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
      });
      this.show_loader = false
    }
  }

  asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  }

  async increaseQNT() {
    let index
    if (this.data.product.id) {
      index = this.cartItem.findIndex(c => c.product.id == this.data.product.id)
    } else {
      index = this.cartItem.findIndex(c => c.product.uid == this.data.product.uid)
    }
    let temp_itm = this.cartItem[index]
    temp_itm.count += 1
    this.product_count = temp_itm.count
    this.productCount(temp_itm.count)
    this.cartItem[index] = temp_itm
    await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
    });
  }

  async decreaseQNT() {
    let index
    if (this.data.product.id) {
      index = this.cartItem.findIndex(c => c.product.id == this.data.product.id)
    } else {
      index = this.cartItem.findIndex(c => c.product.uid == this.data.product.uid)
    }
    let temp_itm = this.cartItem[index]
    if (temp_itm.count > 1) {
      temp_itm.count -= 1
      this.cartItem[index] = temp_itm
      this.product_count = temp_itm.count
      this.productCount(temp_itm.count)
    } else if (temp_itm.count === 1) {
      this.cartItem.splice(index, 1)
      if (!temp_itm.static) {
        this.product_count = ''
        this.qnt_dropdown = false;
        if (this.data.product.id) {
          this.cart_ids.splice(this.cart_ids.indexOf(this.data.product.id), 1)
        } else {
          this.cart_ids.splice(this.cart_ids.indexOf(this.data.product.uid), 1)
        }
        this.toggleProduct('delete')
        this.image_path = '../../../../../assets/imgs/plus.png';
      }
    }

    await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => { });
    this.show_loader = false;
  }

  toggleProduct(val) {
    let main_item
    let title_bar
    let delete_icon
    let layout_product_qnt
    let layout_count_product: HTMLElement
    this.view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
    if (this.view == "listView" && window.innerWidth < 768) {
      if (this.data.product.id) {
        main_item = document.getElementById(this.data.product.id)?.parentElement?.parentElement
      } else {
        main_item = document.getElementById(this.data.product.uid)?.parentElement
      }
      delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
      layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
      layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
    } else {
      if (this.data.product.id) {
        if (this.data.product.isVideo) {
          main_item = document.getElementById(this.data.product.id).parentElement
        } else {
          main_item = document.getElementById(this.data.product.id)
        }
      } else {
        if (this.data.product.isVideo) {
          main_item = document.getElementById(this.data.product.uid).parentElement
        } else {
          main_item = document.getElementById(this.data.product.uid)
        }
      }
      delete_icon = main_item?.parentElement.getElementsByClassName('deleteicon')[0]
      title_bar = main_item?.parentElement?.childNodes[1]
      layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
      layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
    }

    if (val == 'delete') {
      title_bar?.classList?.remove("test")
      this.changeVisibility(layout_count_product)
      delete_icon.src = "../../../assets/imgs/plus.png"
    } else {
      this.changeVisibility(layout_count_product)
      layout_product_qnt.innerHTML = "1"
      title_bar?.classList?.add("test")
      delete_icon.src = '../../../assets/imgs/right.png'
    }
  }

  getHostname(url) {
    const hostname = new URL(url)
    return hostname.hostname;
  }

  productCount(val) {
    let main_item
    let layout_product_qnt;
    this.view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
    if (this.view == "listView" && window.innerWidth < 768) {
      if (this.data.product.id) {
        main_item = document.getElementById(this.data.product.id)?.parentElement?.parentElement
      } else {
        main_item = document.getElementById(this.data.product.uid)?.parentElement
      }
      layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
    } else {
      if (this.data.product.id) {
        if (this.data.product.isVideo) {
          main_item = document.getElementById(this.data.product.id).parentElement
        } else {
          main_item = document.getElementById(this.data.product.id)
        }
      } else {
        if (this.data.product.isVideo) {
          main_item = document.getElementById(this.data.product.uid).parentElement
        } else {
          main_item = document.getElementById(this.data.product.uid)
        }
      }
      layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
    }
    layout_product_qnt.innerHTML = val
  }

  changeVisibility(val) {
    const visibility = val?.style?.visibility
    if (this.data.product.digital_coupon) {
      val.style.visibility = 'hidden'
    } else {
      if (visibility == "hidden" || visibility == "") {
        val.style.visibility = 'visible'
      } else {
        val.style.visibility = 'hidden'
      }
    }
  }
}


