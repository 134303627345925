<div class="pagecontent-wrapper">

	<div class="highlighted-section" style="padding-top:0;">
		<div class="container-fluid">
				<div class="leads-visualization visualizations-section" *ngIf="group_data">
					<div class="row no-gutters">
						<div class=" col-12">
							<div class="heading-info">
								<h4 class="heading-title " style="font-size: 30px;font-weight:600;color: #111" ><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 25px;" (click)="go_back()" ></i>{{group_data && group_data[0]?.company_name}}</h4>
							</div>
						</div>
						<form [formGroup]="filter_store_form" (ngSubmit)="filter_store(filter_store_form.value)"  style="width:100%;">

						<div class="col-12" style="margin-top: 1rem;">
							<div class="row">
								
								<div class="col-4" style="padding-left: 20px;">
									<div class="form-group">
										<ng-select formControlName="warehouse_id" [clearable]="false" placeholder="Select Warehouse" notFoundText="No warehouse found" class="select_input_noborder"   #warehouseid >
											<ng-option [value]="0">All Warehouses</ng-option>
										   <ng-option *ngFor="let type of warehouselist;let j = index" [value]="type.warehouse_name">{{type.warehouse_name}}</ng-option>
										</ng-select>
									</div>
								</div>
								
								<div class="col-2"  style="padding-left: 0;">
					    				<input class="form-control" formControlName="store_id"  type="text" placeholder="Search by Keyword" >
					  			</div>
					  			
					  			<div class="col" style="padding: 0;">
									<button type="submit" class="btn btn-primary" style="background: #71B01D !important;border:none;">Apply</button>
								
									<button type="button" class="btn btn-outline-primary" style="margin-left: 10px; background: #fff !important;
    border:1px solid #76B100 !important;color:#71B01D !important;" (click)="clear_filter()">Clear Filter</button>
								
									<button type="button" class="btn btn-success" (click)="addNewBranch()" style="    float: right;margin-right: 10px;"><i class="fas fa-plus" style="margin-right:4px;"></i>Add New Branch</button>
								</div>
							</div>
						</div>
					</form>


					</div>
				</div>

			<div class="row no-gutters" style="margin-top: 1rem;">
			
				<div class="visualization-table col-12 table-responsive" style="padding-top: 10px;border-radius: 5px;margin: 0px 5px;background: #fff;box-shadow: 1px 1px 5px 1px #dbdbdb;margin-bottom: 8px;">

				 
					 <table class="leads-table table table-borderless table-sm" mat-table matSort #leadsSort="matSort" [dataSource]="stores_list">
					<ng-container matColumnDef="id">
						 <th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"># </th>
						  <td mat-cell *matCellDef="let element; let i = index;"> {{i + 1}} </td>
						</ng-container>
						<!-- <ng-container matColumnDef="uid">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> UID </th>
							<td mat-cell *matCellDef="let element" (click)="viewUser(element.uid)" style="cursor:pointer;" class="text-primary"> {{element.uid}} </td>
						</ng-container> -->
						<ng-container matColumnDef="store_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Branch Name </th>
							<td mat-cell *matCellDef="let element"> {{element.branch_name}} </td>
						</ng-container>
						
						<ng-container matColumnDef="store_owner_name">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Owner Name </th>
							<td mat-cell *matCellDef="let element"> {{element.store_owner_name}}
							</td>
						</ng-container>
						
						<ng-container matColumnDef="added_date">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Added Date </th>
							<td mat-cell *matCellDef="let element"> {{element.created_at.seconds * 1000 | date:'MM/dd/yyyy'}} </td>
						</ng-container>
						<ng-container matColumnDef="store_id">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Store #</th>
							<td mat-cell *matCellDef="let element"> {{element.store_id}} </td>
						</ng-container>
						<ng-container matColumnDef="warehouse">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;"> Warehouse </th>
							<td mat-cell *matCellDef="let element"> {{element.warehouse}} </td>
						</ng-container>
					

						<ng-container matColumnDef="login">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Login </th>
							<td mat-cell *matCellDef="let element"> 
								<button style="background: transparent;border: none !important;outline: none;"><div class="custom-control custom-switch">
						              <input type="checkbox" class="custom-control-input" id="customSwitch{{element.uid}}" [checked]="element.can_login == '1'" (click)="change_login_status(element.uid,element.user_id,element.can_login)">
						              <label class="custom-control-label" for="customSwitch{{element.uid}}" ></label>
						            </div>
						        </button>
						        
							</td>
						</ng-container>
				
					<!-- 	<ng-container matColumnDef="lastlogindate">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;"> Last login at </th>
							<td mat-cell *matCellDef="let element"> {{element.lastlogindate}} </td>
						</ng-container> -->


						<ng-container matColumnDef="actions">
							<th mat-header-cell *matHeaderCellDef mat-sort-header style="padding-bottom:15px;font-weight: 700;">Actions</th>
							<td mat-cell *matCellDef="let element">
								<button class="btn btn-sm  btnicon" style="border:1px solid #71B01D;margin-right: 5px;"  (click)="edit_branch(element.uid,element.parent_id)"><img src="assets/imgs/icon-pencil.svg" ></button>
							<button class="btn btn-sm btn-outline-success" (click)="open_store_panel(element.uid)" >Open Panel</button>
							<a href="https://digital-circular-pro-front.web.app/{{element.company_name_slug}}/{{stores_list?.branch_name_slug}}" target="_blank" style="margin-left: 7px;text-decoration: underline;color: #71B01D;cursor: pointer;" >View Site</a>
							<!-- <button style="box-shadow: none !important;
							background: #f9e4e4;color: #e60c21;border: none !important;border-radius: 4px;padding: 6px 13px;
							font-weight: 600;" (click)="blockUser(element.uid)">Block</button> -->
							</td>
						</ng-container>  
						
						
		
						 <tr mat-header-row *matHeaderRowDef="tableColumns"></tr>
						 <tr mat-row *matRowDef="let row; columns: tableColumns;"></tr>
					</table>
					<mat-paginator class="table-paginator" #leadsPaginator [pageSizeOptions]="[10, 20, 30,40,50]" [pageSize]="10"></mat-paginator>
				</div>
			</div>
		</div>

	</div>
</div>
