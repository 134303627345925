import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from "../../shared/services/auth.service";
import { Injectable, NgZone } from '@angular/core';
import { User } from "../../shared/services/user";
// compat packages are API compatible with namespaced code
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Router } from "@angular/router";
import { MatSnackBar} from '@angular/material/snack-bar';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
 
loading:boolean = false;
login_email = 0;
send_otp = 0;
userData: any; // Save logged in user data
userRole:any;
googleauthemail:any;  
loguserrole:any;
temp_user:any;
userdatatemp:any;
//loading:boolean = false;
show_email_error:boolean = false;
show_pass_error:boolean = false;
@ViewChild("mycheckbox") mycheckbox;

public currentUser: any;
	isChecked: any;
	users: any;
	user: any;
	isAdmin: boolean;
  constructor(
	public authService: AuthService,
	public afs: AngularFirestore,   // Inject Firestore service
	public afAuth: AngularFireAuth, // Inject Firebase auth service
	public router: Router,
	public ngZone: NgZone, // NgZone service to remove outside scope warning
	private _snackBar: MatSnackBar
  ) { 
	  this.afAuth.authState.subscribe(user => {
	  if (user) {
		this.userData = user;
		this.userRole = user;

		localStorage.setItem('user', JSON.stringify(this.userData));
		JSON.parse(localStorage.getItem('user'));
	  } else {
		localStorage.setItem('user', null);
		JSON.parse(localStorage.getItem('user'));
	  }
	})


  }

  ngOnInit() { } 

  signin(){
	this.loading = true;
  }


	async SignIn(email, password) {
		this.loading = true;
		if (email == "" && password == "") {
			this.show_email_error = true;
			this.show_pass_error = true;
			this.loading = false;
			return false;
		}
		if (email == "" && password != "") {
			this.show_email_error = true;
			this.show_pass_error = false;
			this.loading = false;
			return false;
		}
		if (password == "" && email != "") {
			this.show_email_error = false;
			this.show_pass_error = true;
			this.loading = false;
			return false;
		}
		return this.afAuth.signInWithEmailAndPassword(email, password)
			.then((result) => {
				this.show_pass_error = false;
				this.show_email_error = false;
				this.ngZone.run(() => {
					this.temp_user = this.afs.collection("users").ref.where("email", "==", result.user.email).onSnapshot(snap => {
						snap.forEach(async userRef => {
							this.currentUser = userRef.data();
							this.SetUserData(userRef.data());

							let temp_store_id = localStorage.getItem('current_store_id');
							let chkghost = localStorage.getItem('ghost_mode');

							if (chkghost) {
								this.user = JSON.parse(temp_store_id);
							} else {
								this.user = JSON.parse(localStorage.getItem('userrole'));
								this.isAdmin = this.user.role == "admin"
							}
							await this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
								let i = 0
								this.users = data.map(e => {
									i++
									if (e.payload.doc.data()['role'] == "user" ||e.payload.doc.data()['role'] == "store-manager") {
										return {
											uid: e.payload.doc.id,
											serial: i - 1,
											displayName: e.payload.doc.data()['displayName'],
											role: e.payload.doc.data()['role'],
											store: e.payload.doc.data()['store']?.map((x) => { return x.company_name })
										};
									} else {
										return {
											uid: e.payload.doc.id,
											serial: i - 1,
											displayName: e.payload.doc.data()['displayName'],
											role: e.payload.doc.data()['role'],
											store: e.payload.doc.data()['store']
										};
									}
								});
								const currentUrl = localStorage.getItem('currentPage');
								const currentStore = localStorage.getItem('currentStore');
								if (currentUrl) {
									if (this.users[0]?.role == 'user' || this.users[0]?.role == 'store-manager') {
										if (this.users[0]?.store) {
											if (this.users[0].store.includes(currentStore)) {
												if (currentUrl) {
													if (currentUrl.includes('/edit')) {
														window.location.href = currentUrl;
													}else {
														window.location.href =`${currentUrl}/edit`;
													}
													
												}
											} else {
												const allStores = [];
												let i = 0;
												for (const company_name of this.users[0].store) {
													this.afs
														.collection('/stores', ref =>
															ref.where('type', '==', 'main_store')
																.where('company_name', '==', company_name)
																.orderBy('created_at', 'desc')
														)
														.get()
														.subscribe(querySnapshot => {
															const stores = querySnapshot.docs.map((doc, index) => {
																i++;
																const data = doc.data();
																return {
																	uid: doc.id,
																	serial: i - 1,
																	company_name: data['company_name'],
																	company_name_slug: data['company_name_slug'],
																	email: data['email'],
																	group_id: data['group_id'],
																	group_name: data['group_name'],
																	phone: data['phone'],
																	store_id: data['store_id'],
																	store_owner_name: data['store_owner_name'],
																	warehouse_id: data['warehouse_id'],
																	warehouse_name: data['warehouse_name'],
																	created_at: data['created_at'],
																	can_login: data['can_login'] ? data['can_login'] : '1',
																	status: data['status'],
																	user_id: data['user_id'],
																	branch_count: data['branch_count'],
																	access_layout: data['access_layout'] ? data['access_layout'] : '-',
																	access_recipe: data['access_recipe'] ? data['access_recipe'] : '-',
																	access_coupon: data['access_coupon'] ? data['access_coupon'] : '-',
																};
															});

															allStores.push(...stores);
															if (this.users[0].store.length > 1) {
																this.router.navigate(["store-owner"]); //navigate to blank-user is not admin
															} else if (this.users[0].store.length == 1) {
																this.router.navigate(['/' + allStores[0].company_name_slug + '/edit']);
															}
														});
												}
											}
										}
									} else {
										if (currentUrl.includes('/edit')) {
											window.location.href = currentUrl;
										}else {
											window.location.href =`${currentUrl}/edit`;
										}
									} 
								} else {
									if (this.users[0]?.role == 'user'||this.users[0]?.role == 'store-manager') {
										const allStores = [];
										let i = 0;
										for (const company_name of this.users[0].store) {
											this.afs
												.collection('/stores', ref =>
													ref.where('type', '==', 'main_store')
														.where('company_name', '==', company_name)
														.orderBy('created_at', 'desc')
												)
												.get()
												.subscribe(querySnapshot => {
													const stores = querySnapshot.docs.map((doc, index) => {
														i++;
														const data = doc.data();
														return {
															uid: doc.id,
															serial: i - 1,
															company_name: data['company_name'],
															company_name_slug: data['company_name_slug'],
															email: data['email'],
															group_id: data['group_id'],
															group_name: data['group_name'],
															phone: data['phone'],
															store_id: data['store_id'],
															store_owner_name: data['store_owner_name'],
															warehouse_id: data['warehouse_id'],
															warehouse_name: data['warehouse_name'],
															created_at: data['created_at'],
															can_login: data['can_login'] ? data['can_login'] : '1',
															status: data['status'],
															user_id: data['user_id'],
															branch_count: data['branch_count'],
															access_layout: data['access_layout'] ? data['access_layout'] : '-',
															access_recipe: data['access_recipe'] ? data['access_recipe'] : '-',
															access_coupon: data['access_coupon'] ? data['access_coupon'] : '-',
														};
													});

													allStores.push(...stores);
													if (this.users[0].store.length > 1) {
														this.router.navigate(["store-owner"]); //navigate to blank-user is not admin
													} else if (this.users[0].store.length == 1) {
														this.router.navigate(['/' + allStores[0].company_name_slug + '/edit']);
													}
												});
										}
									} else {
										this.router.navigate(["store-owner"]); //navigate to blank-user is not admin	
									}
								}

							})
						});
					});
					//this.router.navigate(['dashboard']);


				});

			}).catch((error) => {
				this.show_pass_error = false;
				this.show_email_error = false;
				console.log(error);
				let msg = 'Error';
				if (error.code == 'auth/wrong-password') {
					msg = 'Looks like the password is incorrect';
				} else if (error.code == 'auth/user-not-found') {
					msg = 'No user found with this details';
				} else {
					msg = error.message;
				}
				this._snackBar.open("Error: " + msg, '', {
					duration: 5000,
				});
				this.loading = false;
			})
	}

  

  SetUserData(user) {
   
	const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
	const userData = {
	  uid: user.uid,
	  email: user.email,
	  displayName: user.displayName?user.displayName:'NA',
	  photoURL: user.photoURL?user.photoURL:'',
	  emailVerified: user.emailVerified?user.emailVerified:true,
	  role:user.role?user.role:'admin',
	  can_login: user.can_login?user.can_login:"1",
	  store_uid: user.store_uid?user.store_uid:"",
	  group_uid: user.group_uid?user.group_uid:"",
	  store: user.store?user.store:[]
	}

	localStorage.setItem('userrole', JSON.stringify(userData));
	this.userRole = userData;
	return userRef.set(userData, {
	  merge: true
	})
  }

  isShow(){
	this.isChecked = this.mycheckbox.nativeElement.checked
  }
  AuthLogin(provider) {
	return this.afAuth.signInWithPopup(provider)
	.then((result) => {
	  console.log('result from google after login here');
	   this.ngZone.run(() => {
		  this.router.navigate(['dashboard']);
		})
	  this.SetUserDataGoogle(result.additionalUserInfo.profile,result.user,result.additionalUserInfo.isNewUser);
	this.googleauthemail = result.additionalUserInfo.profile['email'];
	this.afs.collection("admins").ref.where("email", "==", this.googleauthemail).onSnapshot(snap =>{
		  snap.forEach(userRef => {

			this.currentUser = userRef.data();
			this.loguserrole = userRef.data()['role']; 
		   
				if(this.loguserrole == "admin") {
				  this.router.navigate(["/dashboard"]);
				}else if(this.loguserrole == "consultant"){
				  this.router.navigate(["/consultant/dashboard"]);
				}else if(this.loguserrole == "dieter"){
				  this.router.navigate(["/register-as-consultant"]);
				}
				else{
				  this.router.navigate(["/register-as-consultant"]);
				}
			

		  })
		});

	}).catch((error) => {
	  window.alert(error)
	})
  }

  GoogleAuth() {
	return this.AuthLogin(new firebase.auth.GoogleAuthProvider());
  }

   SetUserDataGoogle(user,result,isNewUser) {
	let role = "";

	this.afs.collection("users").ref.where("email", "==", user.email).onSnapshot(snap =>{
		  snap.forEach(userRef => {
			  role = userRef.data()['role'];
		  })
		});
	if(isNewUser){
	  role = "dieter";
	}
	let currenttimestamp = + new Date();
	const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${result.uid}`);
	const userData: any = {
	  uid: result.uid,
	  email: user.email,
	  displayName: user.name,
	  photoURL: user.picture?user.picture:'',
	  store: user.store,
	}

	localStorage.setItem('userrole', JSON.stringify(userData));
	this.userRole = userData;
	return userRef.set(userData, {
	  merge: true
	})
  }




}