import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import Shepherd from 'shepherd.js';

@Injectable({
  providedIn: 'root'
})
export class AppCouponTourService {

  private tour: Shepherd.Tour;
  private CouponTourSeenFlag = 'CouponTourSeen';
  currentUrl: string;
  stepsID: any;
  constructor(private router: Router) {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        },
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.tour.isActive) {
        if (event.url !== this.currentUrl) {
          this.tour.cancel();
        }
        this.currentUrl = event.url;
      }


    });

  }

  private setTourSeenFlag() {
    localStorage.setItem(this.CouponTourSeenFlag, 'true');
  }

  private hasTourBeenSeen() {
    return localStorage.getItem(this.CouponTourSeenFlag) === 'true';
  }


  addTourStep(stepsObject) {
    stepsObject.forEach((step) => {
      this.tour.addStep(
        {
          id: step.id,
          cancelIcon: {
            enabled: true,
          },
          title: '<h3>' + step.title + '</h3>',
          text: '<p>' + step.content + '</p>',
          attachTo: {
            element: '.Coupons',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          buttons: this.getButtons(["Cancel", "Next"]),
        }

      );
    });
    this.tour.start();
    stepsObject.forEach((step) => {
      this.tour.removeStep(step.id);
    })
  }

  startTour() {
    if (!this.hasTourBeenSeen()) {
      this.tour.addSteps([
        {
          id: 'show-tour-action',
          title: '<h3>Welcome to the Coupons Page</h3>',
          text: '<p>Here you will find coupons you can add to your shopping list with the plus button.<p>',
          attachTo: {
            element: '',
            classes: 'example-step-extra-class',
            on: 'bottom',
            scrollTo: true
          },
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
            },
          },
          buttons: [
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'START TOUR',
              action: this.tour.next
            }

          ]
        },



        {
          id: 'step-1',
          title: '<h3>The Plus Button</h3>',
          text: '<p>It’s all about the Plus Button. Use this button to add coupons to your shopping list.</p>',
          attachTo: {
            element: '#AddCoupon',
            on: 'bottom-start',
            scrollTo: true
          },
          classes: 'coupons-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              console.log(previousId);
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#AddCoupon');
              if (!element) {
                if (previousId === 'show-tour-action') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },

        {
          id: 'step-2',
          title: '<h3>The Help Icon</h3>',
          text: '<p>Press this icon to open this tutorial anytime.  </p>',
          attachTo: {
            element: '.Help',
            on: 'bottom'
          },
          classes: 'coupons-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.Help');
              if (!element) {
                if (previousId === 'step-1') {
                  this.tour.complete();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'FINISH',
              action: () => {
                this.tour.complete()
              }
            }
          ]
        },
      ]);



      this.tour.start();

      this.setTourSeenFlag();

    }
  }

  cancelTour() {
    this.tour.cancel();
  }

  private getButtons(labels: string[]): Shepherd.Step.StepOptionsButton[] {
    return labels.map((label) => ({
      action: this.tour[label.toLowerCase()],
      classes: label.toLowerCase() + "-button",
      text: label,
    }));
  }
}

