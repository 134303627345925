import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";



@Component({
  selector: 'app-store-owners',
  templateUrl: './store-owners.component.html',
  styleUrls: ['./store-owners.component.scss']
})
export class StoreOwnersComponent implements OnInit {
 
modalRef:NgbModalRef;
storeaddForm: FormGroup;
group_add_form:FormGroup;
filteruserform: FormGroup;
warehouse_add_form:FormGroup;
filter_store_form:FormGroup;
store_update_form:FormGroup;

completedMarketingTasks: number;
completedSalesTasks: number;
completedCustomerSupportTasks: number;
isBrowser: boolean;
statistics: any;
topSellers: any;
laggingSellers: any;
articles: any;
tasks: any;
users:any;
currentTab:any;
  
datasource = [];
filter_status: any = '';
currentrole:any = 'all';
warehouselist:any;
groupaddlist:any;
add_select_group:any;
add_select_warehouse:any;
stores_list:any;
temp_users_data:any;
temp_warehouses:any;
temp_check_dup_location:any;
store_get_data:any;
temp_user:any

warehouseaddlist:any;
leadsTableDataSource: any;
statuslist:any= [{name: "Client"},{name:"Trial"}];
// dataSource = new UserDataSource(this.userService);
tableColumns  :  string[] = ['id', 'company_name', 'actions'];


 title = 'appBootstrap';

 validationMessages = {
    add_store_name: [
      { type: 'required', message: 'Please enter Store Name' }
    ],
    add_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    add_so_name: [
      { type: 'required', message: 'Please enter Store Owner Name' },
    ],
    update_store_name: [
      { type: 'required', message: 'Please enter Store Name' }
    ],
    update_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    update_so_name: [
      { type: 'required', message: 'Please enter Store Owner Name' },
    ],
    group_name: [
      { type: 'required', message: 'Please enter Group Name' },
    ],
    go_name: [
      { type: 'required', message: 'Please enter Group Owner Name' },
    ],
    go_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    warehouse_name: [
      { type: 'required', message: 'Warehouse name is required' }
    ]
  };
  closeResult: string;
  @ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
  @ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
  @ViewChild('groupModal') groupModal;
  @ViewChild('warehouseModal') warehouseModal;
  @ViewChild('mymodal') mymodal;
  @ViewChild('closebtn', {static: true}) closebtn:ElementRef;
    @ViewChild('storeupdateModal') storeupdateModal;
	user: any;
	ghost_mode: boolean;
	isAdmin: boolean;




  constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	 public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData); 	

   }

   ngOnInit() {
	let temp_store_id = localStorage.getItem('current_store_id');
	let chkghost = localStorage.getItem('ghost_mode');
	if (chkghost) {
		this.ghost_mode = true;
	} else {
		this.ghost_mode = false;
	}
	if (chkghost) {
		this.user = JSON.parse(temp_store_id);
	} else {
		this.user = JSON.parse(localStorage.getItem('userrole'));
		this.isAdmin = this.user.role == "admin"
	}
	
	this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
		let i=0
		this.users = data.map(e => {
			i++
			if(e.payload.doc.data()['role'] == "user" || e.payload.doc.data()['role'] == "store-manager"){
				return {
					uid: e.payload.doc.id,
					serial:i-1,
					displayName: e.payload.doc.data()['displayName'],
					role: e.payload.doc.data()['role'],
					store: e.payload.doc.data()['store']?.map((x)=> {return x.uid})
				};
			} else {
				return {
					uid: e.payload.doc.id,
					serial:i-1,
					displayName: e.payload.doc.data()['displayName'],
					role: e.payload.doc.data()['role'],
					store: e.payload.doc.data()['store']
				};
			}
		});

		if (this.users[0]?.role == 'user' ||this.users[0]?.role == 'store-manager') {
			const allStores = [];
			let i = 0;
			for (const storeId of this.users[0].store) {
			  this.afs
				.collection('/stores', ref =>
				  ref.where('type', '==', 'main_store')
					 .where('uid', '==', storeId)
					 .orderBy('created_at', 'desc')
				)
				.get()
				.subscribe(querySnapshot => {
				  const stores = querySnapshot.docs.map((doc, index) => {
					i++;
					const data = doc.data();
					return {
					  uid: doc.id,
					  serial: i - 1,
					  company_name: data['company_name'],
					  company_name_slug: data['company_name_slug'],
					  email: data['email'],
					  group_id: data['group_id'],
					  group_name: data['group_name'],
					  phone: data['phone'],
					  store_id: data['store_id'],
					  store_owner_name: data['store_owner_name'],
					  warehouse_id: data['warehouse_id'],
					  warehouse_name: data['warehouse_name'],
					  created_at: data['created_at'],
					  can_login: data['can_login'] ? data['can_login'] : '1',
					  status: data['status'],
					  user_id: data['user_id'],
					  branch_count: data['branch_count'],
					  access_layout: data['access_layout'] ? data['access_layout'] : '-',
					  access_recipe: data['access_recipe'] ? data['access_recipe'] : '-',
					  access_coupon: data['access_coupon'] ? data['access_coupon'] : '-',
					};
				  });
		  
				  allStores.push(...stores);		  
				  if (this.users[0].store.length > 1) {
					if (allStores.length === this.users[0].store.length) {
					  this.stores_list = new MatTableDataSource(allStores);
					  this.stores_list.sort = this.leadsSort;
					  this.stores_list.paginator = this.leadsPaginator;
					}
				  } else {
					this.router.navigate(['/' + allStores[0].company_name_slug + '/edit']);
				  }
				});
			}
		  } else {
			this.afs
			  .collection('/stores', ref =>
				ref.where('type', '==', 'main_store').orderBy('created_at', 'desc')
			  )
			  .get()
			  .subscribe(querySnapshot => {
				let i = 0;
				this.stores_list = querySnapshot.docs.map((doc, index) => {
				  i++;
				  const data = doc.data();
				  return {
					uid: doc.id,
					serial: i - 1,
					company_name: data['company_name'],
					company_name_slug: data['company_name_slug'],
					email: data['email'],
					group_id: data['group_id'],
					group_name: data['group_name'],
					phone: data['phone'],
					store_id: data['store_id'],
					store_owner_name: data['store_owner_name'],
					warehouse_id: data['warehouse_id'],
					warehouse_name: data['warehouse_name'],
					created_at: data['created_at'],
					can_login: data['can_login'] ? data['can_login'] : '1',
					status: data['status'],
					user_id: data['user_id'],
					branch_count: data['branch_count'],
					access_layout: data['access_layout'] ? data['access_layout'] : '-',
					access_recipe: data['access_recipe'] ? data['access_recipe'] : '-',
					access_coupon: data['access_coupon'] ? data['access_coupon'] : '-',
				  };
				});
				this.stores_list = new MatTableDataSource(this.stores_list);
				this.stores_list.sort = this.leadsSort;
				this.stores_list.paginator = this.leadsPaginator;
			  });
		  }
		  
	});

	this.afs.collection('/warehouses').snapshotChanges().subscribe(data => {
		this.warehouselist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				warehouse_name: e.payload.doc.data()['warehouse_name'],
			};
		});	
	});

	this.afs.collection('/groups').snapshotChanges().subscribe(data => {
		this.groupaddlist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				group_name: e.payload.doc.data()['group_name'],
			};
		});
	});
	

  }

  	edit_store(company_name)
  	{
  		this.router.navigate(['/'+ company_name + '/edit']);
  	}
 
}  
