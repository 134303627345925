import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";


@Component({
  selector: 'app-staff',
  templateUrl: './staff.component.html',
  styleUrls: ['./staff.component.scss']
})
export class StaffComponent implements OnInit {


	validationMessages = {
	    add_store_name: [
	      { type: 'required', message: 'Please enter Store Name' }
	    ],
	    add_email: [
	      { type: 'required', message: 'Email is required' },
	      { type: 'email', message: 'Please enter a valid email' },
	    ],
	    add_so_name: [
	      { type: 'required', message: 'Please enter Store Owner Name' },
	    ],
	    group_name: [
	      { type: 'required', message: 'Please enter Group Name' },
	    ],
	    go_name: [
	      { type: 'required', message: 'Please enter Group Owner Name' },
	    ],
	    go_email: [
	      { type: 'required', message: 'Email is required' },
	      { type: 'email', message: 'Please enter a valid email' },
	    ],
	    emp_name: [
	      { type: 'required', message: 'Employee name is required' }
	    ],
	    emp_email: [
	      { type: 'required', message: 'Employee email is required' }
	    ],
	    mgr_name: [
	      { type: 'required', message: 'Manager name is required' }
	    ],
	    mgr_email: [
	      { type: 'required', message: 'Manager email is required' }
	    ]
	};


	emp_add_form:FormGroup;
	mgr_add_form:FormGroup;
	emp_update_form:FormGroup;
	mgr_update_form:FormGroup;
	tableColumns  :  string[] = ['id', 'name','added_date','email','phone','login','actions'];
	groups_list:any;
	closeResult:any;
	emp_list:any;
	user:any;
	current_role:any = 'employee';
	mgr_list:any;
	delete_uid:any;
	edit_emp:any;
	edit_mgr:any;
	get_current_store_id:any;
	ghost_mode:boolean =false;
	user_store_uid:any;


	@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
	@ViewChild('leadsSort2', {static: true}) leadsSort2: MatSort;
	@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
	@ViewChild('leadsPaginator2', {static: true}) leadsPaginator2: MatPaginator;
	@ViewChild('empModal') empModal;
	@ViewChild('mgrModal') mgrModal;
	@ViewChild('mgrDeleteModal') mgrDeleteModal;
	@ViewChild('empDeleteModal') empDeleteModal;
	@ViewChild('empUpdateModal') empUpdateModal;
	@ViewChild('mgrUpdateModal') mgrUpdateModal;


  	constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
		this.emp_add_form = new FormGroup({
			emp_name: new FormControl(null, Validators.compose([Validators.required])),
			emp_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
			emp_phone: new FormControl(),
    	});
    	this.mgr_add_form = new FormGroup({
			mgr_name: new FormControl(null, Validators.compose([Validators.required])),
			mgr_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
			mgr_phone: new FormControl(),
    	});

    	this.emp_update_form = new FormGroup({
			emp_name: new FormControl(null, Validators.compose([Validators.required])),
			emp_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
			emp_phone: new FormControl(),
			emp_uid: new FormControl(),
    	});
    	this.mgr_update_form = new FormGroup({
			mgr_name: new FormControl(null, Validators.compose([Validators.required])),
			mgr_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
			mgr_phone: new FormControl(),
			mgr_uid: new FormControl(),
    	});
		

   }

  	ngOnInit() {
  		this.get_current_store_id = localStorage.getItem('current_store_id');
  		let get_default_store = localStorage.getItem('group_default_store');
  		let chkghost = localStorage.getItem('ghost_mode');
  		if(chkghost)
  		{
  			this.ghost_mode = true;
  		} else {
  			this.ghost_mode = false;
  		}

  		if(this.get_current_store_id)
  		{
  			console.log('get data from localStorage');
  			this.user = JSON.parse(this.get_current_store_id);
  			this.user_store_uid = this.user.store_uid;
  		} else{
  			console.log('get data from login user');
  			this.user = JSON.parse(localStorage.getItem('userrole'));
  			this.user_store_uid = this.user.store_uid;
  		}

  		if(get_default_store)
  		{
  			this.user_store_uid = get_default_store;
  		}
  		
  		console.log('CURRENT LOGIN USER',this.user);
		this.afs.collection('/users',ref=>ref.where('store_uid','==',this.user_store_uid).where('role','==','employee').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.emp_list = data.map(e => {
				return{
					uid: e.payload.doc.id,
					email: e.payload.doc.data()['email'],
					displayName: e.payload.doc.data()['displayName'],
					phone: e.payload.doc.data()['phone'],
					role: e.payload.doc.data()['role'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				};
			});	

			this.emp_list = new MatTableDataSource(this.emp_list);
			this.emp_list.sort = this.leadsSort;
			this.emp_list.paginator = this.leadsPaginator;
		});

		this.afs.collection('/users',ref=>ref.where('store_uid','==',this.user_store_uid).where('role','==','manager').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.mgr_list = data.map(e => {
				return{
					uid: e.payload.doc.id,
					email: e.payload.doc.data()['email'],
					displayName: e.payload.doc.data()['displayName'],
					phone: e.payload.doc.data()['phone'],
					role: e.payload.doc.data()['role'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				};
			});	

			this.mgr_list = new MatTableDataSource(this.mgr_list);
			this.mgr_list.sort = this.leadsSort2;
			this.mgr_list.paginator = this.leadsPaginator2;
		});
 
  	}

  	switch_manager()
  	{
  		this.current_role = 'manager';
  	}

  	switch_emp()
  	{
  		this.current_role = 'employee';
  	}

  	change_login_status(uid,status)
  	{
		this._snackBar.open('Status updated successfully', '', {
			duration: 2000,
		});
		console.log('uid',uid);
		console.log('status',status);

		if(status === '1'){
			console.log('in1');
			let upd_data = {
			can_login: '0'
			};
			
			
				
			let userup = this.afs.doc('/users/'+uid).set(upd_data,{merge:true}).then(docRef => {
			});

		}

		if(status === '0'){
			console.log('in2');
			let upd_data = {
			can_login: '1'
			};
		
			let userupd = this.afs.doc('/users/'+uid).set(upd_data,{merge:true}).then(docRef => {
			});
		}
   	}

	open(content,title) {
		this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
	//	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}


   open_group_modal()
   {
   		this.open(this.empModal,'modal-basic-title');
   }

   view_store(uid)
   {
   		this.router.navigate(['/admin/group-owners/'+uid]);
   }

   	search_emp = (value: string) => {
    	this.emp_list.filter = value.trim().toLocaleLowerCase();
  	}
  	search_mgr = (value: string) => {
    	this.mgr_list.filter = value.trim().toLocaleLowerCase();
  	}



   emp_add(value)
  	{
  		console.log(value);
	  	if(this.emp_add_form.valid)
	  	{

	  		console.log(value);
	  		this.afAuth.createUserWithEmailAndPassword(value.emp_email, "123456")
		      .then((result) => {
		        /* Call the SendVerificaitonMail() function when new user sign
		        up and returns promise */
		        console.log('user id',result.user.uid);
		        console.log(result);

		        let userdata = {
			   		email: value.emp_email?value.emp_email:"",
					displayName: value.emp_name?value.emp_name:"",
					uid: result.user.uid,
					store_user_uid: this.user.uid,
					store_uid: this.user.store_uid,
					role: "employee",
					created_at: new Date(),
					can_login:'1',
					phone: value.emp_phone?value.emp_phone:""
				};

			  	let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
			  	});

			  	this._snackBar.open("Employee created successfully!", '', {
			    	duration: 1500,
			    });


		  		

	        this.ForgotPassword(value.emp_email);

	        this.emp_add_form.reset();
	        let element: HTMLElement = document.getElementsByClassName('close_emp_modal')[0] as HTMLElement;
			element.click();

	      }).catch((error) => {
		       	this._snackBar.open("Error: "+error.message, '', {
			      duration: 3000,
			    });
	      });
  		}
  	}

  	mgr_add(value)
  	{
  		console.log(value);
	  	if(this.mgr_add_form.valid)
	  	{

	  		console.log(value);
	  		this.afAuth.createUserWithEmailAndPassword(value.mgr_email, "123456")
		      .then((result) => {
		        /* Call the SendVerificaitonMail() function when new user sign
		        up and returns promise */
		        console.log('user id',result.user.uid);
		        console.log(result);

		        let userdata = {
			   		email: value.mgr_email?value.mgr_email:"",
					displayName: value.mgr_name?value.mgr_name:"",
					uid: result.user.uid,
					store_user_uid: this.user.uid,
					store_uid: this.user.store_uid,
					role: "manager",
					created_at: new Date(),
					can_login:'1',
					phone: value.mgr_phone?value.mgr_phone:""
				};

			  	let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
			  	});

			  	this._snackBar.open("Manager created successfully!", '', {
			    	duration: 1500,
			    });

		  		

	        this.ForgotPassword(value.mgr_email);
	        this.mgr_add_form.reset();
	        let element: HTMLElement = document.getElementsByClassName('close_mgr_modal')[0] as HTMLElement;
			element.click();

	      }).catch((error) => {
		       	this._snackBar.open("Error: "+error.message, '', {
			      duration: 3000,
			    });
	      });
  		}
  	}

  	ForgotPassword(passwordResetEmail) {
		return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
		.then(() => {
		// window.alert('Password reset email sent, check your inbox.');
		}).catch((error) => {
		//window.alert(error)
		})
  	}

  	delete_emp(uid)
  	{
  		this.open(this.empDeleteModal,'modal-basic-title3');
  		this.delete_uid = uid;
  	}

  	delete_mgr(uid)
  	{
  		this.open(this.mgrDeleteModal,'modal-basic-title4');
  		this.delete_uid = uid;
  	}
  	delete_emp_submit()
  	{
  		//this.delete_uid
  		//delete here
  		let userupd = this.afs.doc('/users/'+this.delete_uid).delete();

  		let element: HTMLElement = document.getElementsByClassName('close_emp_delete_modal')[0] as HTMLElement;
		element.click();


  		this._snackBar.open("Employee Deleted successfully", '', {
			duration: 2000,
		});
  	}

  	delete_mgr_submit()
  	{
  		//this.delete_uid
  		//delete here
  		let userupd = this.afs.doc('/users/'+this.delete_uid).delete();
  		let element: HTMLElement = document.getElementsByClassName('close_mgr_delete_modal')[0] as HTMLElement;
			element.click();

  		this._snackBar.open("Manager Deleted successfully", '', {
			duration: 2000,
		});
  	}

  	open_update_emp_modal(data)
 	{
	  	console.log('editing uid --',data);
	  	this.edit_emp = data;
	  	this.emp_update_form.patchValue({
		 	emp_uid: data.uid,
			emp_name: data.displayName,
			emp_email: data.email,
			emp_phone: data.phone,

		});
	  	  this.open(this.empUpdateModal,'modal-basic-title7');
  	}

  	open_update_mgr_modal(data)
 	{
	  	console.log('editing uid --',data);
	  	this.edit_mgr = data;
	  	this.mgr_update_form.patchValue({
		 	mgr_uid: data.uid,
			mgr_name: data.displayName,
			mgr_email: data.email,
			mgr_phone: data.phone,

		});
	  	  this.open(this.mgrUpdateModal,'modal-basic-title8');
  	}


  	emp_update(value)
  	{
  		console.log(value);
  		console.log(value);
	  	if(this.emp_update_form.valid)
	  	{
	  		if(value.emp_email != this.edit_emp.email)
	  		{
	  			
		  		console.log(value);
		  		this.afAuth.createUserWithEmailAndPassword(value.emp_email, "123456")
			      .then((result) => {
			        /* Call the SendVerificaitonMail() function when new user sign
			        up and returns promise */
			        console.log('user id',result.user.uid);
			        console.log(result);

			        let userdata = {
				   		email: value.emp_email?value.emp_email:"",
						displayName: value.emp_name?value.emp_name:"",
						uid: result.user.uid,
						old_uid: this.edit_emp.uid,
						store_user_uid: this.user.uid,
						store_uid: this.user.store_uid,
						role: "employee",
						updated_at: new Date(),
						phone: value.emp_phone?value.emp_phone:""
					};

				  	//let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {});
				  	let userupd = this.afs.doc('/users/'+value.emp_uid).set(userdata,{merge:true}).then(docRef => {
			 		});

				  	this._snackBar.open("Employee details updated successfully!", '', {
				    	duration: 1500,
				    });

			  		

		        this.ForgotPassword(value.emp_email);
		        let element: HTMLElement = document.getElementsByClassName('close_emp_update_modal')[0] as HTMLElement;
				element.click();

		      }).catch((error) => {
			       	this._snackBar.open("Error: "+error.message, '', {
				      duration: 3000,
				    });
		      });


	  		} else {

	  			let userdata = {
						displayName: value.emp_name?value.emp_name:"",
						phone: value.emp_phone?value.emp_phone:""
					};

				  	//let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {});
				  	let userupd = this.afs.doc('/users/'+value.emp_uid).set(userdata,{merge:true}).then(docRef => {
			 		});

			 		this._snackBar.open("Employee details updated successfully!", '', {
				    	duration: 1500,
				    });

			 	let element: HTMLElement = document.getElementsByClassName('close_emp_update_modal')[0] as HTMLElement;
				element.click();

	  		}
	  		
  		}
  		
  	}

  	mgr_update(value)
  	{
  		console.log(value);
  		console.log(value);
	  	if(this.mgr_update_form.valid)
	  	{
	  		if(value.mgr_email != this.edit_mgr.email)
	  		{
	  			
		  		console.log(value);
		  		this.afAuth.createUserWithEmailAndPassword(value.mgr_email, "123456")
			      .then((result) => {
			        /* Call the SendVerificaitonMail() function when new user sign
			        up and returns promise */
			        console.log('user id',result.user.uid);
			        console.log(result);

			        let userdata = {
				   		email: value.mgr_email?value.mgr_email:"",
						displayName: value.mgr_name?value.mgr_name:"",
						uid: result.user.uid,
						old_uid: this.edit_mgr.uid,
						store_user_uid: this.user.uid,
						store_uid: this.user.store_uid,
						role: "manager",
						updated_at: new Date(),
						phone: value.mgr_phone?value.mgr_phone:""
					};

				  	//let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {});
				  	let userupd = this.afs.doc('/users/'+value.mgr_uid).set(userdata,{merge:true}).then(docRef => {
			 		});

				  	this._snackBar.open("Manager details updated successfully!", '', {
				    	duration: 1500,
				    });

			  		

		        this.ForgotPassword(value.mgr_email);
		        let element: HTMLElement = document.getElementsByClassName('close_mgr_update_modal')[0] as HTMLElement;
				element.click();

		      }).catch((error) => {
			       	this._snackBar.open("Error: "+error.message, '', {
				      duration: 3000,
				    });
		      });


	  		} else {

	  			let userdata = {
						displayName: value.mgr_name?value.mgr_name:"",
						phone: value.mgr_phone?value.mgr_phone:""
					};

				  	//let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {});
				  	let userupd = this.afs.doc('/users/'+value.mgr_uid).set(userdata,{merge:true}).then(docRef => {
			 		});

			 		this._snackBar.open("Manager details updated successfully!", '', {
				    	duration: 1500,
				    });

			 	let element: HTMLElement = document.getElementsByClassName('close_mgr_update_modal')[0] as HTMLElement;
				element.click();

	  		}
	  		
  		}
  		
  	}

  	view_logs(uid)
  	{

  	}

}