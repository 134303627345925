import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";

interface PwaManifest {
  fileName: string;
  data: ManifestObj;
}

interface ManifestObj {
  name: string;
  short_name: string;
  theme_color: string;
  background_color: string;
  display: string;
  scope: string;
  start_url: string;
  icons: Icons[];
}

interface Icons {
  src: string;
  sizes: string;
  type: string;
  purpose: string;
}

@Injectable({
  providedIn: "root",
})
export class PwaService {
  deferredPrompt: any;
  isInstallable = new Subject<boolean>()

  constructor(private http: HttpClient) { }

  createManifestFile(fileName: string) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      // Add any other required headers here
    });

    // Create the request options with the headers
    const httpOptions = {
      headers: headers,
    };
    return this.http.get<{ message: string, url: string, fileName: string }>(environment.ServerURL + `/manifest?name=${fileName}`, httpOptions);
  }

  updateManifestFile(data: PwaManifest) {
    const headers = new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
      'Access-Control-Allow-Headers': 'Content-Type, Authorization',
      // Add any other required headers here
    });

    // Create the request options with the headers
    const httpOptions = {
      headers: headers,
    };
    return this.http.post<{ message: string, url: string, fileName: string }>(environment.ServerURL + '/manifest/update', data, httpOptions)
  }

  createLinkTag(url: string) {
    const newLinkTag = document.createElement('link');

    newLinkTag.href = url;
    newLinkTag.rel = 'manifest';
    newLinkTag.crossOrigin = 'anonymous';
    document.getElementsByTagName('head')[0].appendChild(newLinkTag);
  }

  chnageAppleTouchIcon(url: string) {
    let index_dynamic_icon = document.createElement('link');
    index_dynamic_icon.href = url;
    index_dynamic_icon.rel = 'apple-touch-icon';
    document.getElementsByTagName('head')[0].appendChild(index_dynamic_icon);
  }
}
