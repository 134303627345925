import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DynamicLayoutComponent } from './core/layouts/dynamic/dynamic-layout.component';
import { SignInComponent } from './components/sign-in/sign-in.component';


import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { AuthGuard } from "./shared/guard/auth.guard";

//ADMIN ROUTES
import { StoreOwnersComponent as AdminStoreOwnersComponent } from './components/store-owners/store-owners/store-owners.component';
import { HomeComponent } from './components/home/home.component';
import { StoreHomeComponent } from './components/store-home/store-home.component';
import { StoreRecipesComponent  as FrontRecipesComponent} from './components/store-recipes/store-recipes.component';
import { StoreCouponsComponent as FrontCouponsComponent } from './components/store-coupons/store-coupons.component';
import { StoreBundlesComponent } from './components/store-bundles/store-bundles.component';
import { StoreMenuComponent } from './components/store-menu/store-menu.component';
import { StoreCircularComponent } from './components/store-circular/store-circular.component';
import { EditStoreProductComponent } from './components/store-owners/edit-store-product/edit-store-product.component';
import { EditBundleProductComponent } from './components/store-owners/edit-bundle-product/edit-bundle-product.component';
import { EditMenuProductComponent } from './components/store-owners/edit-menu-product/edit-menu-product.component';
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';

const routes: Routes = [
     // Static Routes
  { path: '', component: HomeComponent },
  { path: 'login', component: SignInComponent },
  { path: 'store-owner', component: AdminStoreOwnersComponent, canActivate: [AuthGuard]},
  { path: '404', component: PagenotfoundComponent },
  // 4-segment Routes
  { path: ':store_name/coupons/list/:list_id', component: FrontCouponsComponent },
  { path: ':store_name/recipes/list/:list_id', component: FrontRecipesComponent },

  // 3-segment Routes (most specific first)
  { path: ':store_name/bundles/edit', component: EditBundleProductComponent, canActivate: [AuthGuard] },
  { path: ':store_name/menu/edit', component: EditMenuProductComponent, canActivate: [AuthGuard] },
  { path: ':store_name/bundles/:id', component: StoreBundlesComponent },
  { path: ':store_name/menu/:id', component: StoreMenuComponent },
  { path: ':store_name/recipes/:recipe_categories', component: FrontRecipesComponent },
  { path: ':store_name/list/:list_id', component: StoreHomeComponent, data: { showAppList: true } },
  { path: ':store_name/coupons/:id', component: FrontCouponsComponent },
  { path: ':store_name/recipes/:id', component: FrontRecipesComponent },

  // 2-segment Routes
  { path: ':store_name/bundles', component: StoreBundlesComponent, data: { showHeaderBundle: true } },
  { path: ':store_name/menu', component: StoreMenuComponent, data: { showHeaderBundle: true } },
  { path: ':store_name/edit', component: EditStoreProductComponent, canActivate: [AuthGuard] },
  { path: ':store_name/coupons', component: FrontCouponsComponent, data: { showHeaderCoupon: true } },
  { path: ':store_name/recipes', component: FrontRecipesComponent },
  { path: ':store_name/:id', component: StoreCircularComponent },

  // 1-segment Route (catch-all)
  { path: ':store_name', component: StoreCircularComponent, data: { showHeaderStore: true } },

  { path: 'sign-in', component: SignInComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },


  {
    path: '',
    component: DynamicLayoutComponent,
    children: [
      {
        path: 'tables',
        loadChildren: () => import('./tables/tables.module').then(m => m.TablesModule)
      },
       {
        path: 'charts',
        loadChildren: () => import('./charts/charts.module').then(m => m.ChartsModule)
      },
      
      {
        path: 'utilities',
        loadChildren: () => import('./utilities/utilities.module').then(m => m.UtilitiesModule)
      },
      {
        path: 'components',
        loadChildren: () => import('./components/components.module').then(m => m.ComponentsModule)
      },
      {
        path: 'dashboards',
        loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
      }
    ]
  },
  /*
    Undefined routes (should redirect to a 404 page)
  */
    { path: '**', redirectTo: '404' }
];

@NgModule({
  // Only call RouterModule.forRoot() in the root AppRoutingModule (or the AppModule if that's where you register
  // top level application routes). In any other module, you must call the RouterModule.forChild method to register additional routes.
  imports: [
    RouterModule.forRoot(routes, {
    // If you want to preload all lazy routes when the app loads, uncomment the following line
    // preloadingStrategy: PreloadAllModules,
    onSameUrlNavigation: 'reload',
    // This value is required for server-side rendering to work.
    initialNavigation: 'enabledBlocking'
})
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
