import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {



tableColumns  :  string[] = ['id', 'incident_name', 'reported_by','reported_on','status','actions'];
groups_list:any;
closeResult:any;
view_details:any; 
incident_list:any;
user:any;
get_current_store_id:any;
stores_data:any;
ghost_mode:boolean =false;
user_store_uid:any;

@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('viewIncidentModal') viewIncidentModal;


  	constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
	

   }

  	ngOnInit() {

		this.get_current_store_id = localStorage.getItem('current_store_id');
  		let get_default_store = localStorage.getItem('group_default_store');
  		let chkghost = localStorage.getItem('ghost_mode');
  		if(chkghost)
  		{
  			this.ghost_mode = true;
  		} else {
  			this.ghost_mode = false;
  		}

  		if(this.get_current_store_id)
  		{
  			console.log('get data from localStorage');
  			this.user = JSON.parse(this.get_current_store_id);
  			this.user_store_uid = this.user.store_uid;
  		} else{
  			console.log('get data from login user');
  			this.user = JSON.parse(localStorage.getItem('userrole'));
  			this.user_store_uid = this.user.store_uid;
  		}

  		if(get_default_store)
  		{
  			this.user_store_uid = get_default_store;
  		}


		this.afs.collection('/stores',ref=>ref.where('uid','==',this.user_store_uid)).snapshotChanges().subscribe(data => {
			this.stores_data = data.map(e => {
				return{ 
					uid: e.payload.doc.id,
					store_name: e.payload.doc.data()['store_name'],
					email: e.payload.doc.data()['email'],
					group_id: e.payload.doc.data()['group_id'],
					group_name: e.payload.doc.data()['group_name'],
					phone: e.payload.doc.data()['phone'],
					store_id: e.payload.doc.data()['store_id'],
					store_owner_name: e.payload.doc.data()['store_owner_name'],
					warehouse_id: e.payload.doc.data()['warehouse_id'],
					warehouse_name: e.payload.doc.data()['warehouse_name'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					status:e.payload.doc.data()['status'],
					user_id:e.payload.doc.data()['user_id']
				};
			});	
		});
 
  	}


	open(content,title) {
		this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
	//	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	

  	

} 