import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: 'app-store-branch-view',
  templateUrl: './store-branch-view.component.html',
  styleUrls: ['./store-branch-view.component.scss']
})
export class StoreBranchViewComponent implements OnInit {



validationMessages = {
	add_store_name: [
	  { type: 'required', message: 'Please enter Store Name' }
	],
	add_email: [
	  { type: 'required', message: 'Email is required' },
	  { type: 'email', message: 'Please enter a valid email' },
	],
	add_so_name: [
	  { type: 'required', message: 'Please enter Store Owner Name' },
	],
	update_store_name: [
	  { type: 'required', message: 'Please enter Store Name' }
	],
	update_email: [
	  { type: 'required', message: 'Email is required' },
	  { type: 'email', message: 'Please enter a valid email' },
	],
	update_so_name: [
	  { type: 'required', message: 'Please enter Store Owner Name' },
	], 
	group_name: [
	  { type: 'required', message: 'Please enter Group Name' },
	],
	go_name: [
	  { type: 'required', message: 'Please enter Group Owner Name' },
	],
	go_email: [
	  { type: 'required', message: 'Email is required' },
	  { type: 'email', message: 'Please enter a valid email' },
	],
	update_group_name: [
	  { type: 'required', message: 'Please enter Group Name' },
	],
	update_go_name: [
	  { type: 'required', message: 'Please enter Group Owner Name' },
	],
	update_go_email: [
	  { type: 'required', message: 'Email is required' },
	  { type: 'email', message: 'Please enter a valid email' },
	],
	warehouse_name: [
	  { type: 'required', message: 'Warehouse name is required' }
	],
	store_id: [
	  { type: 'required', message: 'Store Name is required' }
	]
  };


group_add_form:FormGroup;
group_update_form:FormGroup;
assign_store_form:FormGroup;
storeaddForm: FormGroup;
filteruserform: FormGroup;
warehouse_add_form:FormGroup;
filter_store_form:FormGroup;
store_update_form:FormGroup;

tableColumns  :  string[] = ['id', 'store_name', 'store_owner_name','added_date','store_id','warehouse','login','actions'];
groups_list:any;
stores_list:any = [];
closeResult:any;
group_data:any;
assign_storeslist:any;
selected_store:any;
assign_store_error:boolean=false;
id:any;
modalRef:any;
temp_user:any;


statuslist:any= [{name: "Client"},{name:"Trial"}];

completedMarketingTasks: number;
completedSalesTasks: number;
completedCustomerSupportTasks: number;
isBrowser: boolean;
statistics: any;
topSellers: any;
laggingSellers: any;
articles: any;
tasks: any;
users:any;
currentTab:any;
  
datasource = [];
filter_status: any = '';
currentrole:any = 'all';
warehouselist:any;
groupaddlist:any;
add_select_group:any;
add_select_warehouse:any;
temp_users_data:any;
temp_warehouses:any;
temp_check_dup_location:any;
store_get_data:any;
stores_list_data:any = [];
warehouseaddlist:any;



@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('groupModal') groupModal;
@ViewChild('assignModal') assignModal;
@ViewChild('warehouseModal') warehouseModal;
@ViewChild('mymodal') mymodal;
@ViewChild('closebtn') closebtn;
@ViewChild('storeupdateModal') storeupdateModal;
@ViewChild('groupupdateModal') groupupdateModal;
@ViewChild('groupdeleteModal') groupdeleteModal;


	constructor( @Inject(PLATFORM_ID) private platformId: object,
		private route: ActivatedRoute,
		private router:Router,
		private formBuilder: FormBuilder,public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private modalService: NgbModal,
		private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
			//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
			this.assign_store_form = new FormGroup({
	  			store_id: new FormControl(null, Validators.compose([Validators.required])),
	  		});

			this.filter_store_form = new FormGroup({
				warehouse_id: new FormControl(0),
				store_id: new FormControl(),
			});

			this.store_update_form = new FormGroup({
				update_uid: new FormControl(),
				update_store_name: new FormControl(null, Validators.compose([Validators.required])),
				update_so_name: new FormControl(null, Validators.compose([Validators.required])),
				update_group_id: new FormControl(),
				update_warehouse_id: new FormControl(),
				update_store_id: new FormControl(),
				update_email: new FormControl( null,Validators.compose([Validators.required,Validators.email])),
				update_phone: new FormControl(),
				update_status: new FormControl(),
			});

			this.group_add_form = new FormGroup({
				group_name: new FormControl(null, Validators.compose([Validators.required])),
				go_name: new FormControl(null, Validators.compose([Validators.required])),
				go_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
				go_phone: new FormControl(),
			});

			this.group_update_form = new FormGroup({
				update_group_name: new FormControl(null, Validators.compose([Validators.required])),
				update_go_name: new FormControl(null, Validators.compose([Validators.required])),
				update_go_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
				update_go_phone: new FormControl(),
			});

			this.warehouse_add_form = new FormGroup({
				warehouse_name: new FormControl(null, Validators.compose([Validators.required])),
			});
	}

	ngOnInit() {
		this.id = this.route.snapshot.params['id'];

		this.afs.collection('/stores',ref=>ref.where('parent_id','==',this.id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.stores_list = data.map(e => {
				return{
					uid 				: e.payload.doc.id,
					store_name 			: e.payload.doc.data()['store_name'],
					branch_name 		: e.payload.doc.data()['branch_name'],
					email 				: e.payload.doc.data()['email'],
					group_id 			: e.payload.doc.data()['group_id'],
					group_name 			: e.payload.doc.data()['group_name'],
					phone 				: e.payload.doc.data()['phone'],
					store_id 			: e.payload.doc.data()['store_id'],
					store_owner_name 	: e.payload.doc.data()['store_owner_name'],
					warehouse 			: e.payload.doc.data()['warehouse'],
					created_at 			: e.payload.doc.data()['created_at'],
					can_login  			: e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					status  						: e.payload.doc.data()['status'],
					user_id  						: e.payload.doc.data()['user_id'],
					parent_id 					: e.payload.doc.data()['parent_id'],
					branch_name_slug  	: e.payload.doc.data()['branch_name_slug'],
					store_name_slug 		: e.payload.doc.data()['store_name_slug']

				};
	  		}); 
			this.stores_list_data = this.stores_list;
			this.stores_list = new MatTableDataSource(this.stores_list);
			this.stores_list.sort = this.leadsSort;
			this.stores_list.paginator = this.leadsPaginator;
		});


		this.afs.collection('/stores',ref=>ref.where('group_id','==',"").orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
	  		this.assign_storeslist = data.map(e => {
				return{
					uid: e.payload.doc.id,
					store_name: e.payload.doc.data()['store_name'],
					email: e.payload.doc.data()['email'],
					group_id: e.payload.doc.data()['group_id'],
					group_name: e.payload.doc.data()['group_name'],
					phone: e.payload.doc.data()['phone'],
					store_id: e.payload.doc.data()['store_id'],
					store_owner_name: e.payload.doc.data()['store_owner_name'],
					warehouse_id: e.payload.doc.data()['warehouse_id'],
					warehouse_name: e.payload.doc.data()['warehouse_name'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					status:e.payload.doc.data()['status'],
					user_id:e.payload.doc.data()['user_id']
				};
	 		}); 
		});


		this.afs.collection('/stores',ref=>ref.where('uid','==',this.id)).snapshotChanges().subscribe(data => {
  			this.group_data = data.map(e => {
				return{
					uid: e.payload.doc.id,
					company_name: e.payload.doc.data()['company_name'],
					store_owner_name: e.payload.doc.data()['store_owner_name'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					phone:e.payload.doc.data()['phone'],
					user_id:e.payload.doc.data()['user_id']
				};
  			}); 
		});

		this.afs.collection('/warehouses').snapshotChanges().subscribe(data => {
			this.warehouselist = data.map(e => {
				return{
					uid: e.payload.doc.id,
					warehouse_name: e.payload.doc.data()['warehouse_name'],
				};
			}); 
		});

		this.afs.collection('/groups').snapshotChanges().subscribe(data => {
			this.groupaddlist = data.map(e => {
				return{
					uid: e.payload.doc.id,
					group_name: e.payload.doc.data()['group_name'],
				};
			});
		});
 
	}


	change_login_status(uid,user_id,status)
	{

		this._snackBar.open('Status updated successfully', '', {
		  duration: 2000,
		});
		console.log('uid',uid);
		console.log('status',status);
		console.log('store user id ',user_id);

		if(status === '1'){
			console.log('in1');
			let upd_data = {
			can_login: '0'
			};
			let stores = this.afs.doc('/stores/'+uid).set(upd_data,{merge:true}).then(docRef => {
			});


			let userup = this.afs.doc('/users/'+user_id).set(upd_data,{merge:true}).then(docRef => {
			});
		}

		if(status === '0'){
			console.log('in2');
			let upd_data = {
				can_login: '1'
			};
			let aa = this.afs.doc('/stores/'+uid).set(upd_data,{merge:true}).then(docRef => {
			});
			let userupd = this.afs.doc('/users/'+user_id).set(upd_data,{merge:true}).then(docRef => {
			});
		}
	}

	go_back()
	{
	  this.router.navigate(['/admin/store-owners']);
	}

	addNewBranch()
	{
	  this.router.navigate(['/admin/add-new-branch/'+ this.id]);
	}

	warehouse_add(value)
  	{
		console.log(value);
		if(this.warehouse_add_form.valid)
		{
	  		let theware = this.afs.collection('/warehouses/',ref=> ref.where('name','==',value.warehouse_name)).snapshotChanges().subscribe(data => {
				this.temp_warehouses = data.map(e => {
					return{
					  uid: e.payload.doc.id,
					};
	  			}); 

			  	if(this.temp_warehouses.length > 0)
			 	{
					this._snackBar.open("Error: This Warehouse already exists!", '', {
						duration: 2000,
					  });
		  			theware.unsubscribe();
		  			return false;
	  			} else {
					theware.unsubscribe();
					let warehouseData = {
						name:value.warehouse_name,
						created_at: new Date()
		  			};
					this._snackBar.open("Creating Warehouse...Please wait...", '', {
					duration: 2000,
					});

		  			let add_warehouse = this.afs.collection('/warehouses').add(warehouseData).then(docRef => {
						const itemRefx= this.afs.doc('/warehouses/'+docRef.id);
			  			itemRefx.update({ uid: docRef.id});

			  			this.storeaddForm.patchValue({
			  				add_warehouse_id: docRef.id
						});

						this.store_update_form.patchValue({
				  			update_warehouse_id: docRef.id
						});

						this.add_select_warehouse = warehouseData;
		  			});
				}
			});
		}
	}


  open(content,title) {
	this.modalRef = this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
	this.closeResult = `Closed with: ${result}`;
	}, (reason) => {
  //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
	});
  }


   open_group_modal()
   {
	  this.open(this.groupModal,'modal-basic-title2');
   }

   view_store(uid)
   {
	  this.router.navigate(['/admin/group-owners/'+uid]);
   }

	group_add(value)
	{
	  if(this.group_add_form.valid)
	  {

		console.log(value);

		let groupData = {
		  group_name:value.group_name,
		  group_owner_name: value.go_name,
		  email: value.go_email,
		  phone: value.go_phone,
		  created_at: new Date()
		};

		this.afAuth.createUserWithEmailAndPassword(value.go_email, "123456")
		  .then((result) => {
			/* Call the SendVerificaitonMail() function when new user sign
			up and returns promise */
			console.log('user id',result.user.uid);
			console.log(result);

			let userdata = {
				email: value.go_email?value.go_email:"",
				displayName: value.go_name?value.go_name:"",
				uid: result.user.uid,
				role: "group",
				created_at: new Date()
		};

		  let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
		  });

		  this._snackBar.open("Creating Group...Please wait...", '', {
			duration: 2000,
		  });

		  groupData['user_id'] = result.user.uid;
		  let groupadd = this.afs.collection('/groups').add(groupData).then(docRef => {
			const itemRefx= this.afs.doc('/groups/'+docRef.id);
			  itemRefx.update({ uid: docRef.id,user_id: result.user.uid});
			
		  });

		  this.ForgotPassword(value.go_email);

		}).catch((error) => {
			this._snackBar.open("Error: "+error.message, '', {
			duration: 3000,
		  });
		});


	  }
	}
	group_update(value)
	{
	  console.log(value);
	  if(this.group_update_form.valid)
	  {

		console.log(value);

		let groupData = {
		  group_name:value.update_group_name,
		  group_owner_name: value.update_go_name,
		  phone: value.update_go_phone,
		  updated_at: new Date()
		};

		let groupadd = this.afs.doc('/groups/'+this.id).set(groupData,{merge:true}).then(docRef => {
	   });
		console.log("GROUP DATA ---",this.group_data);
		  console.log("VALUE DATA ---",value);
		if(this.group_data[0]?.group_owner_name != value.update_go_name)
		{
		  let userupd = this.afs.doc('/users/'+this.group_data[0]?.user_id).set({group_owner_name: value.update_go_name},{merge:true}).then(docRef => {
		});
		}
		if(this.group_data[0]?.email != value.update_go_email )
		{

		  this.afAuth.createUserWithEmailAndPassword(value.update_go_email, "123456")
			.then((result) => {
			  /* Call the SendVerificaitonMail() function when new user sign
			  up and returns promise */
			  console.log('user id',result.user.uid);
			  console.log(result);

			  let userdata = {
			  email: value.update_go_email?value.update_go_email:"",
			displayName: value.update_go_name?value.update_go_name:"",
			uid: result.user.uid,
			role: "group",
			created_at: new Date(),
			group_uid: this.id
		  };

			let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
			});

			// this._snackBar.open("Updating Group...Please wait...", '', {
		   //   duration: 2000,
		   //  });
		   let groupaddu = this.afs.doc('/groups/'+this.id).set({email: value.update_go_email,user_id: result.user.uid},{merge:true}).then(docRef => {
		  });
		   
			//groupData['user_id'] = result.user.uid;
			
			this.ForgotPassword(value.go_email);


			this._snackBar.open("Group details updated successfully", '', {
			  duration: 2000,
			});
			let element: HTMLElement = document.getElementsByClassName('close_group_update_modal')[0] as HTMLElement;
		  element.click();

			}).catch((error) => {
			  this._snackBar.open("Error: "+error.message, '', {
			  duration: 3000,
			});
			});

		} else {
		  this._snackBar.open("Group details updated successfully", '', {
			  duration: 2000,
			});
			let element: HTMLElement = document.getElementsByClassName('close_group_update_modal')[0] as HTMLElement;
		  element.click();
		}
	  }
	}
	ForgotPassword(passwordResetEmail) {
	return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
	.then(() => {
	// window.alert('Password reset email sent, check your inbox.');
	}).catch((error) => {
	//window.alert(error)
	})
	}

	assign_store_change_select(event)
	{
	  let groupdata = this.assign_storeslist.find(i => i.uid === event);
	  console.log(groupdata);
	  this.selected_store = groupdata;

	}
	open_assign_modal()
	{
	  this.open(this.assignModal,'modal-basic-title');
	}


	add_select_warehouse_change(event)
	{
	   console.log(event);
	  if(event == 0)
	  {
		this.open(this.warehouseModal,'modal-basic-title3');
		this.storeaddForm.patchValue({
		 add_warehouse_id: null
	   });
		this.store_update_form.patchValue({
		 update_warehouse_id: null
	   });
	  } else {
		let warehousedata = this.warehouselist.find(i => i.uid === event);
		console.log(warehousedata);
		this.add_select_warehouse = warehousedata;
	  }
	}

	add_select_group_change(event)
	{
	  //this.modalService.dismissAll("sdsd");
	  if(event == 0)
	  {
		this.open(this.groupModal,'modal-basic-title2');
		this.storeaddForm.patchValue({
		 add_group_id: null
		});
		this.store_update_form.patchValue({
		 update_group_id: null
		});
	  } else {
		let groupdata = this.groupaddlist.find(i => i.uid === event);
		console.log(groupdata);
		this.add_select_group = groupdata;

	  }
	  
	}

	open_group_update_modal()
  {
	  this.group_update_form.patchValue({
		update_uid: this.group_data[0]?.uid,
	  update_group_name: this.group_data[0]?.group_name,
	  update_go_name: this.group_data[0]?.group_owner_name,
	  update_go_email: this.group_data[0]?.email,
	  update_go_phone: this.group_data[0]?.phone,
	});
	  this.open(this.groupupdateModal,'modal-basic-title5');
	}

	open_delete_group_modal()
  {
	  this.open(this.groupdeleteModal,'modal-basic-title6');
	}
	delete_group()
	{
	  //delete the group
	  //remove the group from the stores
	  //delete the group users data from auth
	  let delgrp = this.afs.doc('/groups/'+ this.id).delete();
	  let upd_del = {
		group_id:'',
		group_name:''
	  };
	  this.stores_list_data.forEach( (value, key) => {
	  
		let storeupdate = this.afs.doc('/stores/'+value.uid).set(upd_del,{merge:true}).then(docRef => {
	  });

	  });
	  //delete group user here
	  this.router.navigate(['/admin/group-owners']);
	  this.modalService.dismissAll();
	  this._snackBar.open("Group Deleted successfully", '', {
	  duration: 2000,
	});
	}

	open_update_modal(data)
  {
	  console.log('editing uid --',data);
	  this.store_update_form.patchValue({
	  update_uid: data.uid,
	  update_store_name: data.store_name,
	  update_so_name:data.store_owner_name,
	  update_group_id: data.group_id,
	  update_warehouse_id: data.warehouse_id,
	  update_store_id: data.store_id,
	  update_email: data.email,
	  update_phone: data.phone,
	  update_status: data.status,

	});
		this.open(this.storeupdateModal,'modal-basic-title4');
	}

  store_update(value)
  {
	console.log(value);
  if( this.store_update_form.valid){
	let temp_get_store_data = this.afs.collection('/stores',ref=>ref.where('uid',"==",value.update_uid)).snapshotChanges().subscribe(data => {
	  this.store_get_data = data.map(e => {
		return{
		  uid: e.payload.doc.id,
		store_name: e.payload.doc.data()['store_name'],
		email: e.payload.doc.data()['email'],
		group_id: e.payload.doc.data()['group_id'],
		group_name: e.payload.doc.data()['group_name'],
		phone: e.payload.doc.data()['phone'],
		store_id: e.payload.doc.data()['store_id'],
		store_owner_name: e.payload.doc.data()['store_owner_name'],
		warehouse_id: e.payload.doc.data()['warehouse_id'],
		warehouse_name: e.payload.doc.data()['warehouse_name'],
		created_at:e.payload.doc.data()['created_at'],
		can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
		status:e.payload.doc.data()['status'],
		user_id:e.payload.doc.data()['user_id']
		  
		};
	  }); 

	  let chkloc =this.afs.collection('/stores',ref=>ref.where('warehouse_id','==',value.update_warehouse_id).where('store_id','==',value.update_store_id).where('uid','!=',value.update_uid)).snapshotChanges().subscribe(data => {
		this.temp_check_dup_location = data.map(e => {
		  return{
			uid: e.payload.doc.id,
		  };
		}); 
		if(this.temp_check_dup_location && this.temp_check_dup_location.length > 0)
		{
		  this._snackBar.open("Error: There is already a store at this location.", '', {
			duration: 3000,
		  });
		  chkloc.unsubscribe();
		  return false;
		} 
		else 
		{
		  console.log(value);
		  let groupdata = this.groupaddlist.find(i => i.uid === value.update_group_id);
		  let warehousedata = this.warehouselist.find(i => i.uid === value.update_warehouse_id);

		  let storedata = {
			group_id: value.update_group_id?value.update_group_id:"",
			phone: value.update_phone?value.update_phone:"",
			store_owner_name: value.update_so_name?value.update_so_name:"",
			store_id: value.update_store_id?value.update_store_id:"",
			store_name: value.update_store_name?value.update_store_name:"",
			warehouse_id: value.update_warehouse_id?value.update_warehouse_id:"",
			warehouse_name: warehousedata?.name?warehousedata?.name:"",
			group_name: groupdata?.group_name?groupdata?.group_name:"",
			updated_at: new Date(),
			status: value.update_status?value.update_status:"Client"
		  };

		  let storeupdate = this.afs.doc('/stores/'+value.update_uid).set(storedata,{merge:true}).then(docRef => {
		  });

		  if(this.store_get_data[0]?.store_owner_name != value.update_so_name)
		  {
			let storeupdate = this.afs.doc('/users/'+this.store_get_data[0]?.user_id).set({displayName: value.update_so_name},{merge:true}).then(docRef => {
			});
		  }

		  if(this.store_get_data[0]?.email != value.update_email)
		  {
			console.log('LOOKS LIKE EMAIL VALUE UPDATE IN STORE');
			//looks like email has changed
			this.afAuth.createUserWithEmailAndPassword(value.update_email, "123456")
			.then((result) => {
				/* Call the SendVerificaitonMail() function when new user sign
				up and returns promise */
			  console.log('user id',result.user.uid);
			  console.log(result);

			  let userdata = {
				email: value.update_email?value.update_email:"",
				displayName: value.update_so_name?value.update_so_name:"",
				uid: result.user.uid,
				role: "store",
				created_at: new Date(),
				can_login: '1',
				store_uid:value.update_uid
			  };

			  let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
			  });


			  let storeupdate = this.afs.doc('/stores/'+value.update_uid).set({user_id: result.user.uid,email:value.update_email},{merge:true}).then(docRef => {
			  });

			  //DELETE PREVIOUS STORE EMAIL HERE

			  this.ForgotPassword(value.update_email);
			  temp_get_store_data.unsubscribe();

			  let element: HTMLElement = document.getElementsByClassName('close_store_update_modal')[0] as HTMLElement;
			  element.click();

			  this._snackBar.open("Store details updated successfully", '', {
				duration: 2000,
			  });


			}).catch((error) => {
			  
			  this._snackBar.open("Error: "+error.message, '', {
				duration: 3000,
			  });

			  //this.open(this.storeupdateModal,'modal-basic-title4');

			});
		  } else {
			let element: HTMLElement = document.getElementsByClassName('close_store_update_modal')[0] as HTMLElement;
			  element.click();

			  this._snackBar.open("Store details updated successfully", '', {
				duration: 2000,
			  });
		  }
		  temp_get_store_data.unsubscribe();
		  chkloc.unsubscribe();
		  // this._snackBar.open("Store details updated successfully", '', {
		//        duration: 2000,
		//    });
		}
  
	  });
	  temp_get_store_data.unsubscribe();
	});
	}
  }



	open_store_panel(uid)
  {
  	console.log("OPEN STORE ID ----",uid);
	this.afs.collection('/users', ref => ref.where('store_uid', '==',uid)).snapshotChanges().subscribe(data => {
	  this.temp_user = data.map(e => {
		return{
		  uid: e.payload.doc.id,
		  can_login:  e.payload.doc.data()['can_login'],
		  displayName: e.payload.doc.data()['displayName'],
		  email:  e.payload.doc.data()['email'],
		  emailVerified: true,
		  group_uid:  e.payload.doc.data()['group_uid'],
		  photoURL:  e.payload.doc.data()['photoURL'],
		  role: e.payload.doc.data()['role'],
		  store_uid: e.payload.doc.data()['store_uid']
		};
	  });
	  console.log("GOT THE USER FOR STORE",this.temp_user[0]);
	  localStorage.setItem('current_store_id', JSON.stringify(this.temp_user[0]));
	  localStorage.setItem('ghost_mode', 'admin');
	  this.router.navigate(['store/dashboard'])
		.then(() => {
		  window.location.reload();
		});
		 // this._snackBar.open('Stored changed successfully', '', {
		 //    duration: 3000,
		 //  });
	});
  }


   delete_warehouse(uid)
  {
	let del_warehouse = this.afs.doc('/warehouses/'+uid).delete();
	this._snackBar.open('Warehouse deleted successfully', '', {
	  duration: 2000,
	});
  }


   clear_filter()
  {
	this.filter_store_form.reset();
	this.filter_store_form.patchValue({
	warehouse_id: 0,
  });
	this.ngOnInit();
  }

  filter_store(value)
  {
	console.log(value);
	if(value.store_id == null && value.warehouse_id == null)
	{
	  this._snackBar.open("Error: No parameters to filter", '', {
		duration: 3000,
	  });
	  return false;
	}
	if(value.warehouse_id == 0 && value.store_id != "" && value.store_id != null)
	{
	  //ONLY FILTER BY STORE ID
	  console.log('only filtering by storeid');
	  this.stores_list.filter = value.store_id.trim().toLocaleLowerCase();
	} 
	else if(value.warehouse_id != "" && value.store_id != "" && value.warehouse_id != null && value.store_id != null )
	{
	  //FILTER BY BOTH WAREHOUSE ID AND STORE ID
	  console.log('filter by both');
	  this.afs.collection('/stores',ref=>ref.where('type','==','main_store').where('warehouse','==',value.warehouse_id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
	this.stores_list = data.map(e => {
	  return{
		uid: e.payload.doc.id,
		company_name: e.payload.doc.data()['company_name'],
		email: e.payload.doc.data()['email'],
		group_id: e.payload.doc.data()['group_id'],
		group_name: e.payload.doc.data()['group_name'],
		phone: e.payload.doc.data()['phone'],
		store_id: e.payload.doc.data()['store_id'],
		store_owner_name: e.payload.doc.data()['store_owner_name'],
		warehouse_id: e.payload.doc.data()['warehouse_id'],
		warehouse_name: e.payload.doc.data()['warehouse_name'],
		created_at:e.payload.doc.data()['created_at'],
		can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
		status:e.payload.doc.data()['status'],
		user_id:e.payload.doc.data()['user_id'],
		branch_count: e.payload.doc.data()['branch_count'],
		parent_id: e.payload.doc.data()['parent_id']
	  };
	}); 

	  this.stores_list = new MatTableDataSource(this.stores_list);
	  this.stores_list.sort = this.leadsSort;
	  this.stores_list.paginator = this.leadsPaginator;

	  this.stores_list.filter = value.store_id.trim().toLocaleLowerCase();
	});
	}
	else if(value.warehouse_id != 0 && value.warehouse_id != null)
	{
	  //FILTER BY  WAREHOUSE ID 
	  console.log('only filter by warehouse id');
	  this.afs.collection('/stores',ref=>ref.where('type','==','main_store').where('warehouse','==',value.warehouse_id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
	this.stores_list = data.map(e => {
	  return{
		uid: e.payload.doc.id,
		company_name: e.payload.doc.data()['company_name'],
		email: e.payload.doc.data()['email'],
		group_id: e.payload.doc.data()['group_id'],
		group_name: e.payload.doc.data()['group_name'],
		phone: e.payload.doc.data()['phone'],
		store_id: e.payload.doc.data()['store_id'],
		store_owner_name: e.payload.doc.data()['store_owner_name'],
		warehouse_id: e.payload.doc.data()['warehouse_id'],
		warehouse_name: e.payload.doc.data()['warehouse_name'],
		created_at:e.payload.doc.data()['created_at'],
		can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
		status:e.payload.doc.data()['status'],
		user_id:e.payload.doc.data()['user_id'],
		branch_count: e.payload.doc.data()['branch_count'],
				parent_id: e.payload.doc.data()['parent_id']

	  };
	}); 

	  this.stores_list = new MatTableDataSource(this.stores_list);
	  this.stores_list.sort = this.leadsSort;
	  this.stores_list.paginator = this.leadsPaginator;
	});

	} else{
	  console.log('filter by else');
	  this.ngOnInit();
	}
	this._snackBar.open("Filter applied successfully", '', {
		duration: 1000,
	  });
	
  }

  view_site(branch_uid)
  {
	console.log('open branch panel here');
  }

  edit_branch(uid,parent_id)
	{
		if(uid == parent_id)
		{
			this.router.navigate(['/admin/store-edit/'+ parent_id]);
		} else {
			this.router.navigate(['/admin/branch-edit/'+ uid]);
		}
	  
	}



}