// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase : {
    apiKey: "AIzaSyBQ4KgVt6cjubBhjPJQ0juJdwlwWGCG9sA",
    authDomain: "digital-menus-pro-6b7bd.firebaseapp.com",
    projectId: "digital-menus-pro-6b7bd",
    storageBucket: "digital-menus-pro-6b7bd.appspot.com",
    messagingSenderId: "733773726439",
    appId: "1:733773726439:web:be2413ccfbce745ebfd842",
    measurementId: "G-ZEML6TWGLH"  
  },
  imgix: {
    subdomain: 'sparkleinfotech-738550736.imgix.net',
    secureToken: 'WbBUX2HVncEDKeMr'
  },

  ServerURL:"https://pwa.digitalmenuspro.com"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
