        <!-- Modal -->
        <div class="modal fade" id="orderSuccessModal" tabindex="-1" role="dialog" aria-labelledby="orderSuccessModalLabel"
            aria-hidden="true" style="position: relative; z-index: 10500;">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content" style="position: relative; z-index: 10500;">
                    <!-- Header -->
                    <div class="modal-header" style="
                         background-color: #8BC34A;
                         color: white;
                         text-align: center;
                         justify-content: center;display: flex; flex-direction: column; align-items: center;">
                        <h5 class="modal-title" id="orderSuccessModalLabel"
                            style="font-weight: bold; font-size: 1.5rem;">
                            Order Placed Successfully! 
                        </h5>
                        <button type="button" class="close" style="color: black; margin-top: -45px;" data-dismiss="modal"
                            aria-label="Close">
                            <span aria-hidden="true" id="closeModal">&times;</span>
                        </button>
                    </div>
        
                    <!-- Body -->
                    <div class="modal-body" style="padding: 1rem 2rem;">
                        <!-- Success Message Section -->
                        <div class="text-center">
                            <h4 class="text-success font-weight-bold mb-3" style="color: #8BC34A;">
                                <!-- Your order has been successfully placed! 🎉 -->
                                Your order has been placed successfully 🎉 🎉 
                            </h4>
                            <p>Thank you for shopping with us! 🛍️</p>
                            <p>You will receive an email shortly with your payment receipt.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="paymentFailedModal" tabindex="-1" aria-labelledby="paymentFailedLabel" aria-hidden="true" style="position: relative; z-index: 10500;">
            <div class="modal-dialog modal-dialog-centered">
              <div class="modal-content">
                <div class="modal-header bg-danger text-white"style="display: flex; flex-direction: column; align-items: center;">
                  <h5 class="modal-title" id="paymentFailedLabel">Payment Failed </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"style="margin-top: -45px">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    Your payment was not successful. Please try again later or check your payment details. 💳
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-danger" data-dismiss="modal" id="faildCloseModel">Close</button>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Payment Cancelled Modal -->
          <div class="modal fade" id="paymentCancelledModal" tabindex="-1" aria-labelledby="paymentCancelledLabel" aria-hidden="true" style="position: relative; z-index: 10500;">
            <div class="modal-dialog modal-dialog-centered ">
              <div class="modal-content">
                <div class="modal-header bg-warning text-white"style="display: flex; flex-direction: column; align-items: center;">
                  <h5 class="modal-title" id="paymentCancelledLabel">Payment Cancelled </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"style="margin-top: -45px">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  You have cancelled the payment. If this was a mistake, please try again. 🛑
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-warning" data-dismiss="modal" id="cancelCloseModel">Close</button>
                </div>
              </div>
            </div>
          </div>
<ng-container *ngIf="isDisplay ">
    <!-- Start Header-Top -->
    <app-header [store_data]="store_data" [branch_list]="branch_list" [cartItem]="cartItem" [textColor]="textColor"
        [current_layout_data]="current_layout_data" [serializedIds]="serializedIds" [serializedData]="serializedData"[serializedBundleData]="serializedBundleData" [serializedMenuData]="serializedMenuData"
        [cart_ids]="cart_ids" [selected_cart_items]="selected_cart_items" [all_items]="all_items" [totalAmount]="totalAmount" (checkoutClicked)="onCheckoutClick()"  (closeRecipeboxPanel)="CloseFilePanel()"
        (addToList)="add_to_list($event)"></app-header>
    <!-- End Bottom Header -->

    <div class="slider close">
        <div (click)="bottomSliderDiv()" class="close-arrow text-center cursor-pointer">
            <img src="../../assets/images/black-down.png" alt="" />
        </div>
        <div class="container">
            <div class="header-social">
                <div class="envelope social-icons">
                    <a [href]="sendMail()" target="_blank">
                        <img src="../../assets/images/envelope.png" alt="" />
                        <span>Email</span>
                    </a>
                </div>
                <div class="clip-board social-icons">
                    <div (click)="copy_link()">
                        <img src="../../assets/images/clip.png" alt="" />
                        <span>Copy Link</span>
                    </div>
                </div>
                <div class="share-to-phone social-icons">
                    <div (click)="bottomSliderDiv()" data-toggle="modal" data-target="#QrCodeModel">
                        <img class="ShareWithPhone" src="../../assets/images/shartophone.png" alt="" />
                        <img class="ShareVia d-none" src="../../assets/images/sharevia.png" alt="" />
                        <span class="ShareWithPhone">Send to phone</span>
                        <span class="ShareVia d-none">Share Via</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- QR CODE POPUP -->
    <!-- Button trigger modal -->

    <!-- Modal -->
    <div class="modal fade" id="QrCodeModel" tabindex="-1" role="dialog" aria-labelledby="QrCodeModel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header justify-content-start">
                    <span class="close" data-dismiss="modal" aria-label="Close"><img style="width: 10px"
                            src="../../assets/images/Arrow.png" alt="" /></span>
                    <h5 class="modal-title pl-5" id="QrCodeModel">
                        Send List to your Phone
                    </h5>
                </div>
                <div class="modal-body">
                    <div class="qr-code mt-4 mb-4 text-center">
                        <qrcode [elementType]="qr_elementType" [qrdata]="qr_value" cssClass="aclass"
                            errorCorrectionLevel="L" [allowEmptyString]="true">
                        </qrcode>
                    </div>
                    <div class="qr-msg text-center">
                        <p style="color: #000000">Point Phone Camera Here and Click Link</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="checkoutModal" tabindex="-1" role="dialog" aria-labelledby="checkoutModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <!-- Header -->
                <div class="modal-header" style="
                            background-color: #8BC34A;
                            color: white;
                            text-align: center;
                            justify-content: center;display: flex; flex-direction: column; align-items: center;">
                    <h5 class="modal-title" id="checkoutModalLabel" style="font-weight: bold; font-size: 1.5rem;">
                        Complete Your Payment
                    </h5>
                    <button type="button" class="close" style="color: black;margin-top: -45px;" data-dismiss="modal"
                        aria-label="Close">
                        <span aria-hidden="true" id="closeModal">&times;</span>
                    </button>
                </div>
                <!-- Body -->
                <div class="modal-body" style="padding: 1rem 2rem;">
                    <!-- Success Message Section -->
                    <div *ngIf="isLoading" class="loader-overlay">
                        <div class="loader"></div>
                        <!-- <h4 class="font-weight-bold mb-3" style="text-align: center; margin-top: 1rem; color: #8BC34A;">
                            Processing your payment... Please wait.
                        </h4> -->
                    </div>
                    <form [formGroup]="checkoutForm" (ngSubmit)="onCheckoutClick1()" style="font-size: 1rem;">
                        <!-- Personal Details Section -->
                        <h6 class="mb-3"
                            style="font-weight: bold; border-bottom: 2px solid #ddd; padding-bottom: 0.5rem; font-size: 1.2rem;">
                            Personal Details
                        </h6>
    
                        <div class="form-group">
                            <div class="row">
                                <div class="col-md-6">
                                    <label for="firstName">First Name:</label>
                                    <input type="text" formControlName="firstName" class="form-control" id="firstName"
                                        required style="border-radius: 0.4rem;" />
                                    <small
                                        *ngIf="checkoutForm.controls['firstName'].invalid && checkoutForm.controls['firstName'].touched"
                                        class="text-danger">
                                        First name is required.
                                    </small>
                                </div>
                                <div class="col-md-6">
                                    <label for="lastName">Last Name:</label>
                                    <input type="text" formControlName="lastName" class="form-control" id="lastName"
                                        required style="border-radius: 0.4rem;" />
                                    <small
                                        *ngIf="checkoutForm.controls['lastName'].invalid && checkoutForm.controls['lastName'].touched"
                                        class="text-danger">
                                        Last name is required.
                                    </small>
                                </div>
                            </div>
                        </div>
    
                        <div class="form-group" style="margin-bottom: 0.7rem;">
                            <label for="email">Email:</label>
                            <input type="email" formControlName="email" class="form-control" id="email" required
                                style="border-radius: 0.4rem;" />
                            <small *ngIf="checkoutForm.controls['email'].invalid && checkoutForm.controls['email'].touched"
                                class="text-danger">
                                Enter a valid email.
                            </small>
                        </div>
    
                        <div class="form-group" style="margin-bottom: 0.7rem;">
                            <label for="phone">Phone:</label>
                            <div class="input-group">
                                <ngx-intl-tel-input [cssClass]="'custom'" [preferredCountries]="preferredCountries" [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true" [searchCountryFlag]="true" [searchCountryField]="[SearchCountryField.Iso2,SearchCountryField.Name]" 
                                    [selectFirstCountry]="false" [selectedCountryISO]="CountryISO.Canada"
                                    [maxLength]="15" [phoneValidation]="true" [separateDialCode]="separateDialCode"
                                    [numberFormat]="PhoneNumberFormat.National" name="phone" formControlName="phone" class="form-control">
                                </ngx-intl-tel-input>
                            </div>
                            <small *ngIf="checkoutForm.controls['phone'].invalid && checkoutForm.controls['phone'].touched"
                                class="text-danger">
                                Phone number is not valid.
                            </small>
                        </div>
    
                        <div class="form-group text-center mt-2">
                            <button type="submit" mat-button class="submit-button" [disabled]="!isFormValid()"
                                [ngClass]="{'disabled-btn': !isFormValid()}">
                                Next
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

<div class="modal fade" id="paymentConfigModal" tabindex="-1" role="dialog" aria-labelledby="paymentConfigModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content" style="position: relative; z-index: 10500;">
            <!-- Header -->
            <div class="modal-header"
                style="background-color: #F44336; color: white; text-align: center; justify-content: center; display: flex; flex-direction: column; align-items: center">
                <h5 class="modal-title" id="paymentConfigModalLabel" style="font-weight: bold; font-size: 1.5rem;">
                    Payment Configuration Missing
                </h5>
                <button type="button" class="close" style="color: black; margin-top: -45px;" data-dismiss="modal"
                    aria-label="Close">
                    <span aria-hidden="true" id="closeModal">&times;</span>
                </button>
            </div>

            <!-- Body -->
            <div class="modal-body" style="padding: 1rem 2rem;">
                <!-- Error Message Section -->
                <div class="text-center">
                    <p>
                        Online payment configuration is not set up for this store.
                        Please contact the store owner to resolve this issue.
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

    <!-- Mobile Modal -->
    <div class="modal fade" id="ContactModel" tabindex="-1" role="dialog" aria-labelledby="ContactModel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
                <!-- <div class="modal-header justify-content-start">
        
        <h5 class="modal-title pl-5" id="QrCodeModel">
          Send List to your Phone
        </h5>
      </div> -->

                <div class="modal-body p-0">


                    <div aria-labelledby="LocationDropdown">
                        <div class="zip-code-input-div">
                            <div class="select-store-location-headline">

                                <h5 class="d-flex align-items-center"><span class="close" data-dismiss="modal"
                                        aria-label="Close"><img style="width: 10px" src="../../assets/images/Arrow.png"
                                            alt="" /></span>Selected store name </h5>
                                <div class="contact-popup">
                                    <a href="https://www.google.com/maps"><img
                                            src="../../assets/images/sqaure location.png" alt=""></a>
                                    <a href="tel:+1234567890"><img src="../../assets/images/squarePhoone.png"
                                            alt=""></a>

                                </div>
                                <h5>Change Store Location </h5>
                            </div>

                            <input type="number" id="SearchByZipCode" class="form-control"
                                placeholder="Search by Zip code" />
                        </div>
                        <a class="dropdown-item" href="" *ngFor="let branch of branch_list">Branch 1 Name</a>
                    </div>


                </div>
            </div>
        </div>
    </div>


    <!-- BODY CONTENT HERE -->
<div class="swipe-event-class" style="height: 100vh;"
(touchstart)="onTouchStart($event)" 
(mousedown)="onTouchStart($event)" 
(mouseup)="onTouchEnd($event)" 
(touchend)="onTouchEnd($event)">
    <section class="body_content recipe-choice-section pb-5">
        <div class="container-fluid" >
            <div *ngIf="store_data[0].access_recipe !== 'National Recipes'">
                <div class="recipe-list">
                    <ul>
                        <li>
                            <a (click)="ShowRecipe()" class="recipe-btn national-coupon active">Recipes</a>
                        </li>
                        <li>
                            <a (click)="ShowRecipeBox()" class="recipe-box-btn store-coupon">My Recipe Box</a>
                        </li>
                    </ul>
                </div>

                <div class="recipe-boxes-section Recipe">
                    <div class="row">
                        <div class="col-md-3" *ngFor="let recipe of recipes_list;let i = index">
                            <div class="recipeBox" (click)="ShowFilePanel(i)">
                                <div class="recipe-img">
                                    <img class="img-fluid" src="{{recipe.recipe_cover_image}}" alt=""
                                        *ngIf="recipe.recipe_cover_image !=''" />
                                    <img class="img-fluid" src="https://via.placeholder.com/300x200/2f2f2f/2f2f2f"
                                        alt="" *ngIf="recipe.recipe_cover_image ==''" />
                                </div>
                                <div class="recipe-name">
                                    <h5 class="mb-0">{{recipe.recipe_name}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="recipe-boxes-section RecipeBox d-none">
                    <div class="row">
                        <div class="col-md-3" *ngFor="let recipe of recipes_box_list;let i = index">
                            <div class="recipeBox" (click)="ShowRecipeboxFilePanel(i)">
                                <div class="recipe-img">
                                    <img class="img-fluid" src="{{recipe.recipe_cover_image}}" alt=""
                                        *ngIf="recipe.recipe_cover_image !=''" />
                                    <img class="img-fluid" src="https://via.placeholder.com/300x200/2f2f2f/2f2f2f"
                                        alt="" *ngIf="recipe.recipe_cover_image ==''" />
                                </div>
                                <div class="recipe-name">
                                    <h5 class="mb-0">{{recipe.recipe_name}}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="store_data[0].access_recipe === 'National Recipes'">

                <div class="main_national_recipes">
                    <div class="col-12 col-sm-6" style="margin-bottom:1rem;">
                        <div class="card" style="padding:1rem;">
                            <h5 style="padding:10px;" class="serach-recipe-by-name">Search Recipe by Recipe Name</h5>
                            <hr style="margin:0;">
                            <div class="" style="padding:10px;">
                                <label>Enter Recipe name</label>
                                <input name="recipe_name" class="form-control form-group"
                                    (blur)="recipe_search($event.target.value)" placeholder="Example: Apple Pie">
                                <button class="btn btn-success">Search</button>
                            </div>
                        </div>
                    </div>
                    
                    <div class="col-12 col-sm-6" style="margin-bottom:1rem;">
                        <div class="card" style="padding:1rem;">
                            <h5 style="padding:10px;" class="serach-recipe-by-ingredient-name">Search Recipe by Ingredient Name</h5>
                            <hr style="margin:0;">
                            <div class="" style="padding:10px;">
                                <label>Enter Ingredient name</label>
                                <input name="recipe_name" class="form-control form-group"
                                    (blur)="recipe_search($event.target.value)"
                                    placeholder="Example: apple,chocolate,cream">
                                <button class="btn btn-success">Search</button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="col-4" style="margin-bottom:1rem;">
                        <div class="card" style="padding:1rem;">
                            <h5 style="padding:10px;">Search Recipe by UPC Code</h5>
                            <hr style="margin:0;">
                            <div class="" style="padding:10px;">
                                <label>Enter UPC code</label>
                                <input name="recipe_name" class="form-control form-group"
                                    (blur)="upc_search($event.target.value)" placeholder="Example: 00049000050103">
                                <button class="btn btn-success">Search</button>
        
                                <img src="https://assets.edgenet.com/{{product_image_data['Results'][0]['Components'][0]['Assets']['en-US'][1]['Value']}}"
                                    *ngIf="product_image_data && product_image_data['Results'].length > 0"
                                    style="width: 130px;border:1px solid #000;">
                            </div>
                        </div>
                    </div> -->
                </div>
                <div class="filter_class">
                    <div class="child_div" (click)="filter_recipe(name)" id={{name}}
                        *ngFor="let name of recipe_categories; let index = index" [ngClass]="{ 'active': name === currentCategory }">{{name}}</div>
                </div>
                <div class="api-recipe-list" style="display: flex; flex-wrap: wrap;">
                    <div class="" *ngIf="recipe_list && recipe_list.length == 0 && loading == false"
                        style="margin-left:15px;">
                        <p>No recipes found!</p>
                    </div>

                    <div class="" *ngIf="loading" style="margin-left:15px;">
                        <p>Finding Recipes...</p>
                    </div>


                    <div class="" style="margin-bottom:1rem;padding-bottom:1rem;min-height: 20px;"></div>
                    <div class="col-6 col-md-3" *ngFor="let data of recipe_list">
                        <div class="recipeBox" (click)="gotoRecipeDetail(data)">
                            <div class="recipe-img">
                                <img class="img-fluid" src="{{data.image}}" alt="" />
                            </div>
                            <div class="recipe-name">
                                <h5 class="mb-0 descriptionText">{{data.title}}</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <!-- another-recipe-box-for-show-hide -->

        </div>
    </section>
</div>
    <div class="recipe-ingredience-slide-div notification-container dismiss racipe-bg" #swipeElement (touchstart)="onTouchStart($event)" 
    (mousedown)="onTouchStart($event)" 
    (mouseup)="onTouchEnd($event)" 
    (touchend)="onTouchEnd($event)">
        <div class="recipe-ingredience-headline d-flex align-items-center ">
            <span id="closeFilePanel"  (click)="CloseFilePanel()" class="cursor-pointer"><img src="../../assets/images/white-back.png"
                    alt="" /></span>
            <h5 class="mb-0 pl-4">Recipe & Ingredients</h5>
            <div class="help-icon-btn"id="helpicon" >
                <div class="helpicon" (click)="startRecipeSlideTour()"><svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="15" height="15" viewBox="0 0 256 256" xml:space="preserve">

                    <defs>
                    </defs>
                    <g style="stroke: none; stroke-width: 0; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: none; fill-rule: nonzero; opacity: 1;" transform="translate(1.4065934065934016 1.4065934065934016) scale(2.81 2.81)" >
                        <path d="M 45 0 C 20.147 0 0 20.147 0 45 c 0 24.853 20.147 45 45 45 s 45 -20.147 45 -45 C 90 20.147 69.853 0 45 0 z M 49.899 67.939 c -0.06 0.32 -0.159 0.63 -0.279 0.94 c -0.13 0.3 -0.28 0.59 -0.46 0.86 c -0.19 0.27 -0.391 0.529 -0.63 0.76 c -0.23 0.229 -0.48 0.439 -0.761 0.62 c -0.27 0.18 -0.56 0.34 -0.859 0.46 c -0.301 0.13 -0.61 0.22 -0.93 0.279 c -0.33 0.07 -0.65 0.101 -0.98 0.101 c -1.32 0 -2.61 -0.53 -3.54 -1.46 c -0.23 -0.23 -0.43 -0.49 -0.62 -0.76 c -0.18 -0.271 -0.33 -0.561 -0.46 -0.86 c -0.12 -0.311 -0.22 -0.62 -0.28 -0.94 C 40.03 67.62 40 67.29 40 66.96 c 0 -0.32 0.03 -0.65 0.1 -0.97 c 0.06 -0.32 0.16 -0.641 0.28 -0.94 c 0.13 -0.3 0.28 -0.59 0.46 -0.86 c 0.19 -0.279 0.39 -0.529 0.62 -0.76 c 1.16 -1.16 2.89 -1.7 4.52 -1.37 c 0.32 0.07 0.629 0.16 0.93 0.29 c 0.3 0.12 0.59 0.28 0.859 0.46 c 0.28 0.181 0.53 0.391 0.761 0.62 c 0.239 0.23 0.439 0.48 0.63 0.76 c 0.18 0.271 0.33 0.561 0.46 0.86 c 0.12 0.3 0.22 0.62 0.279 0.94 C 49.97 66.31 50 66.64 50 66.96 C 50 67.29 49.97 67.62 49.899 67.939 z M 60.372 34.71 c 0 4.027 -1.546 7.834 -4.354 10.72 c -0.04 0.041 -0.08 0.081 -0.121 0.12 L 49 52.062 v 2.818 c 0 2.209 -1.791 4 -4 4 s -4 -1.791 -4 -4 v -4.543 c 0 -1.101 0.454 -2.153 1.254 -2.908 l 8.083 -7.631 c 1.313 -1.377 2.035 -3.181 2.035 -5.087 v -0.302 c 0 -2.005 -0.791 -3.881 -2.228 -5.281 c -1.436 -1.399 -3.321 -2.14 -5.342 -2.089 c -3.957 0.102 -7.175 3.523 -7.175 7.626 c 0 2.209 -1.791 4 -4 4 s -4 -1.791 -4 -4 c 0 -8.402 6.715 -15.411 14.969 -15.623 c 4.183 -0.109 8.138 1.439 11.131 4.357 c 2.995 2.918 4.645 6.829 4.645 11.01 V 34.71 z" style="stroke: none; stroke-width: 1; stroke-dasharray: none; stroke-linecap: butt; stroke-linejoin: miter; stroke-miterlimit: 10; fill: rgb(255,255,255); fill-rule: nonzero; opacity: 1;" transform=" matrix(1 0 0 1 0 0) " stroke-linecap="round" />
                    </g>
                    </svg></div>  
            </div>
        </div>
        <aside class="overflow-scroll" *ngIf="store_data[0].access_recipe !== 'National Recipes'">
            <div style="text-align: center; background-color: lightgray;">
                <div class="recipe-slide-img">
                    <div class="recipe-img">
                        <img class="img-fluid" src="{{recipes_list[selected_recipe_id]?.recipe_cover_image}}"
                            style="height:200px;width: 100%;object-fit: cover;object-position: center;" alt="" />
                    </div>
                    <div class="recipe-name">
                        <h5 class="mb-0">{{recipes_list[selected_recipe_id]?.recipe_name}}</h5>
                    </div>
                </div>
            </div>
            <div class="instruction-dropdown cursor-pointer ">
                <span class="d-flex align-items-center justify-content-center" (click)="showInstructions()">Instructions
                    <!-- <img class="ChervonDown" src="../../assets/images/down-arrow-black.png" alt="" /> -->
                    <a>
                        <div class="rotate"><img id="instructions_icon" src="../../assets/images/black-down.png" alt="">
                        </div>
                    </a>
                </span>
            </div>
            <div class="hidden-content" *ngIf="show_instructions">
                <span>Ingredients: </span>
                {{recipes_list[0]?.ingredients}}
                <br />
                <span>Instructions:</span>
                {{recipes_list[0]?.instructions}}

            </div>
            <div class="slide-btns d-flex recipe-slide-btns">
                <div class="slide-btn-one" *ngIf="store_data[0].access_recipe !== 'National Recipes'"
                    (click)="add_recipe_ingredients(recipes_list[selected_recipe_id])">
                    <img src="../../assets/images/recipe-slide-left.png" alt="" />
                    <span>Add Recipe to <br />
                        My Recipe Box</span>
                </div>
                <div class="slide-btn-two" (click)="add_all_ingredients(recipes_list[selected_recipe_id])">
                    <img src="../../assets/images/slide-right.png" alt="" />
                    <span>Add All Ingredients <br />
                        to My Shopping List</span>
                </div>
            </div>
            <div class="recipe-three-column-boxes" *ngIf="selected_recipe_id >= 0">
                <div class="row">
                    <div class="col-6 col-md-4" *ngFor="let data of products_array_data" style="padding:6px;">
                        <div class="card" style="padding: 10px;    height: 200px;margin-bottom:1rem;">
                            <img  class="plusimage" src="{{getImage(data.product_uid ? data.product_uid : data.id)}}"
                              style="position: absolute;width: 24px;height: 24px;right: 5px;top: 0px;cursor:pointer;"
                                (click)="add_recipe(data)">
                            <img src="{{data.product_image}}&size=408&thumbnail=true&palette=RGB"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.product_image!=''">
                            <img src="https://via.placeholder.com/120/2f2f2f/2f2f2f"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.product_image==''">
                            {{data.product_description}}
                        </div>
                    </div>
                </div>
            </div>
        </aside>

        <aside class="overflow-scroll" *ngIf="store_data[0].access_recipe === 'National Recipes'">
            <div style="text-align: center; background-color: lightgray;">
                <div class="recipe-slide-img">
                    <div class="recipe-img">
                        <img class="img-fluid" src="{{selected_national_recipe?.image}}"
                            style="height:200px;width:100%;object-fit:cover;object-position:center;" alt="" />
                    </div>
                    <div class="recipe-name">
                        <h5 class="mb-0">{{selected_national_recipe?.title}}</h5>
                    </div>
                </div>
            </div>
            <div class="instruction-dropdown cursor-pointer ">
                <span class="d-flex align-items-center justify-content-center" (click)="showInstructions()">Instructions
                    <a>
                        <div class="rotate"><img id="instructions_icon" src="../../assets/images/black-down.png" alt="">
                        </div>
                    </a>
                </span>
            </div>
            <div class="hidden-content" *ngIf="show_instructions">
                <span>Ingredients: </span>
                {{selected_national_recipe.ingredients}}
                <br />
                <span>Instructions:</span>
                {{selected_national_recipe.analyzedInstructions}}

            </div>
            <div class="slide-btns d-flex">
                <div class="slide-btn-one" *ngIf="store_data[0].access_recipe !== 'National Recipes'"
                    (click)="add_recipe(selected_national_recipe)">
                    <img src="../../assets/images/recipe-slide-left.png" alt="" />
                    <span>Add Recipe to <br />
                        My Recipe Box</span>
                </div>
                <div class="slide-btn-two" (click)="add_all_ingredients(selected_national_recipe)">
                    <img src="../../assets/images/slide-right.png" alt="" />
                    <span>Add All Ingredients <br />
                        to My Shopping List</span>
                </div>
            </div>
            <div class="recipe-three-column-boxes" *ngIf="selected_national_recipe">
                <div class="row">
                    <div class="col-6 col-md-4" *ngFor="let data of selected_national_recipe?.missedIngredients"
                        style="padding:6px;">
                        <div class="card" style="padding: 10px;    height: 200px;margin-bottom:1rem;">
                            <img  class="plusimage" src="{{getImage(data.id ? data.id : data.uid)}}"
                                style="position: absolute;width: 24px;height: 24px;right: 5px;top: 0px;cursor:pointer;"
                                (click)="add_recipe(data)">
                            <img src="{{data.image}}"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.image!=''">
                            <img src="https://via.placeholder.com/120/2f2f2f/2f2f2f"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.image==''">
                            {{data.name}}
                        </div>
                    </div>
                </div>
            </div>
        </aside>
    </div>

    <div class="recipe-ingredience-slide-div notification-container-recipebox dismiss" (touchstart)="onTouchStart($event)" 
    (mousedown)="onTouchStart($event)" 
    (mouseup)="onTouchEnd($event)" 
    (touchend)="onTouchEnd($event)">
        <div class="recipe-ingredience-headline d-flex align-items-center cursor-pointer"
            (click)="CloseRecipeboxFilePanel()">
            <span id="closeFilePanel" class="cursor-pointer"><img src="../../assets/images/white-back.png"
                    alt="" /></span>
            <h5 class="mb-0 pl-4">Recipe & Ingredients</h5>
        </div>
        <aside class="overflow-scroll" *ngIf="store_data[0].access_recipe !== 'National Recipes'">
            <div style="text-align: center; background-color: lightgray;">
                <div class="recipe-slide-img">
                    <div class="recipe-img">
                        <img class="img-fluid" src="{{recipes_box_list[selected_recipe_id]?.recipe_cover_image}}"
                            style="height:200px;width: 100%;object-fit: cover;object-position: center;" alt="" />
                    </div>
                    <div class="recipe-name">
                        <h5 class="mb-0">{{recipes_box_list[selected_recipe_id]?.recipe_name}}</h5>
                    </div>
                </div>
            </div>
            <div class="instruction-dropdown cursor-pointer ">
                <span class="d-flex align-items-center justify-content-center" (click)="showInstructions()">Instructions
                    <a>
                        <div class="rotate"><img id="instructions_icon" src="../../assets/images/black-down.png" alt="">
                        </div>
                    </a>
                </span>
            </div>
            <div class="hidden-content" *ngIf="show_instructions">
                <span>Ingredients: </span>
                {{recipes_box_list[0]?.ingredients}}
                <br />
                <span>Instructions:</span>
                {{recipes_box_list[0]?.instructions}}

            </div>
            <div class="slide-btns d-flex">
                <div class="slide-btn-one" *ngIf="store_data[0].access_recipe !== 'National Recipes'"
                    (click)="add_recipe_ingredients(recipes_box_list[selected_recipe_id])">
                    <img src="../../assets/images/recipe-slide-left.png" alt="" />
                    <span>Add Recipe to <br />
                        My Recipe Box</span>
                </div>
                <div class="slide-btn-two" (click)="add_all_ingredients(recipes_box_list[selected_recipe_id])">
                    <img src="../../assets/images/slide-right.png" alt="" />
                    <span>Add All Ingredients <br />
                        to My Shopping List</span>
                </div>
            </div>
            <div class="recipe-three-column-boxes" *ngIf="selected_recipe_id >= 0">
                <div class="row">
                    <div class="col-6 col-md-4" *ngFor="let data of products_array_data" style="padding:6px;">
                        <div class="card" style="padding: 10px;    height: 200px;margin-bottom:1rem;">
                            <img  class="plusimage" src="{{getImage(data.product_uid ? data.product_uid : data.id)}}"
                                style="position: absolute;width: 24px;height: 24px;right: 5px;top: 0px;cursor:pointer;"
                                (click)="add_recipe(data)">
                            <img src="{{data.product_image}}&size=408&thumbnail=true&palette=RGB"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.product_image!=''">
                            <img src="https://via.placeholder.com/120/2f2f2f/2f2f2f"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.product_image==''">
                            {{data.product_description}}
                        </div>
                    </div>
                </div>
            </div>
        </aside>

        <aside class="overflow-scroll" *ngIf="store_data[0].access_recipe === 'National Recipes'">
            <div style="text-align: center; background-color: lightgray;">
                <div class="recipe-slide-img">
                    <div class="recipe-img">
                        <img class="img-fluid" src="{{selected_national_recipe?.image}}"
                            style="height:200px;width:100%;object-fit:cover;object-position:center;" alt="" />
                    </div>
                    <div class="recipe-name">
                        <h5 class="mb-0">{{selected_national_recipe?.title}}</h5>
                    </div>
                </div>
            </div>
            <div class="instruction-dropdown cursor-pointer ">
                <span class="d-flex align-items-center justify-content-center" (click)="showInstructions()">Instructions
                    <!-- <img class="ChervonDown" src="../../assets/images/down-arrow-black.png" alt="" /> -->
                    <a>
                        <div class="rotate"><img id="instructions_icon" src="../../assets/images/black-down.png" alt="">
                        </div>
                    </a>
                </span>
            </div>
            <div class="hidden-content" *ngIf="show_instructions">
                <span>Ingredients: </span>
                {{selected_national_recipe.ingredients}}
                <br />
                <span>Instructions:</span>
                {{selected_national_recipe.analyzedInstructions}}

            </div>
            <div class="slide-btns d-flex">
                <div class="slide-btn-one" *ngIf="store_data[0].access_recipe !== 'National Recipes'"
                    (click)="add_recipe(selected_national_recipe)">
                    <img src="../../assets/images/recipe-slide-left.png" alt="" />
                    <span>Add Recipe to <br />
                        My Recipe Box</span>
                </div>
                <div class="slide-btn-two" (click)="add_all_ingredients(selected_national_recipe)">
                    <img src="../../assets/images/slide-right.png" alt="" />
                    <span>Add All Ingredients <br />
                        to My Shopping List</span>
                </div>
            </div>
            <div class="recipe-three-column-boxes" *ngIf="selected_national_recipe">
                <div class="row">
                    <div class="col-6 col-md-4" *ngFor="let data of selected_national_recipe?.missedIngredients"
                        style="padding:6px;">
                        <div class="card" style="padding: 10px;    height: 200px;margin-bottom:1rem;">
                            <img class="plusimage" src="{{getImage(data.id)}}"
                                style="position: absolute;width: 24px;height: 24px;right: 5px;top: 0px;cursor:pointer;"
                                (click)="add_recipe(data)">
                            <img src="{{data.image}}"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.product_image!=''">
                            <img src="https://via.placeholder.com/120/2f2f2f/2f2f2f"
                                style="margin-bottom:5px;height:120px;width:100%;background:#fff;"
                                *ngIf="data.product_image==''">
                            {{data.name}}
                        </div>
                    </div>



                </div>
            </div>
        </aside>
    </div>
    <!-- FOOTER STARTS HERE -->
    <!-- Start Footer -->
    <footer class="footer_style">

        <div class="container-fluid">
            <div class="footer-social">
                <div class="row"
                    style="color:{{textColor}}; background:{{store_data && store_data[0]?.theme_base_color}}">
                    <div
                        class="col-md-6 col-sm-12 px-0 d-flex align-items-center  mod-mid-header-menu d-block d-md-none">
                        <div class="cart-area">
                            <div (click)="ShowShoppingList()" class="shopping-list-icon chervon-up-icon">
                                <!-- <img src="{{counter_img}}"> -->
                                <!-- <svg width="35" height="48" viewBox="0 0 35 48" [attr.fill]="textColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 0V48H26.1818V40.3636C26.1873 39.8321 26.6816 39.2787 27.2727 39.2727H34.9091V0H0ZM4.90909 7.63636H9.27273V12H4.90909V7.63636ZM14.1818 8.72727H28.9091C29.5116 8.72727 30 9.21567 30 9.81818C30 10.4207 29.5116 10.9091 28.9091 10.9091H14.1818C13.5793 10.9091 13.0909 10.4207 13.0909 9.81818C13.0909 9.21567 13.5793 8.72727 14.1818 8.72727ZM4.90909 16.3636H9.27273V20.7273H4.90909V16.3636ZM14.1818 17.4545H28.9091C29.5116 17.4545 30 17.943 30 18.5455C30 19.148 29.5116 19.6364 28.9091 19.6364H14.1818C13.5793 19.6364 13.0909 19.148 13.0909 18.5455C13.0909 17.943 13.5793 17.4545 14.1818 17.4545ZM4.90909 25.0909H9.27273V29.4545H4.90909V25.0909ZM14.1818 26.1818H28.9091C29.5116 26.1818 30 26.6702 30 27.2727C30 27.8752 29.5116 28.3636 28.9091 28.3636H14.1818C13.5793 28.3636 13.0909 27.8752 13.0909 27.2727C13.0909 26.6702 13.5793 26.1818 14.1818 26.1818ZM4.90909 33.8182H9.27273V38.1818H4.90909V33.8182ZM14.1818 34.9091H28.9091C29.5116 34.9091 30 35.3975 30 36C30 36.6025 29.5116 37.0909 28.9091 37.0909H14.1818C13.5793 37.0909 13.0909 36.6025 13.0909 36C13.0909 35.3975 13.5793 34.9091 14.1818 34.9091ZM28.3636 41.4545V46.9091L33.8182 41.4545H28.3636Z" />
                            </svg> -->
                                <div class="main-counter">
                                    <img src="../../../assets/imgs/right-with-border.png">
                                    <div class="counter" style="border: 1px solid {{textColor}};">
                                        <!-- <span class="added-items-total-numbers" style="color:{{textColor}};">{{cartItem?.length}}</span>  -->
                                        <span>{{cartItem?.length}}</span>
                                    </div>
                                </div>
                            </div>
                            <div (click)="CloseShoppingList()" class="shopping-list-icon chervon-down-icon d-none">
                                <!-- <img src="{{counter_img}}"> -->
                                <!-- <svg width="35" height="48" viewBox="0 0 35 48" [attr.fill]="textColor"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M0 0V48H26.1818V40.3636C26.1873 39.8321 26.6816 39.2787 27.2727 39.2727H34.9091V0H0ZM4.90909 7.63636H9.27273V12H4.90909V7.63636ZM14.1818 8.72727H28.9091C29.5116 8.72727 30 9.21567 30 9.81818C30 10.4207 29.5116 10.9091 28.9091 10.9091H14.1818C13.5793 10.9091 13.0909 10.4207 13.0909 9.81818C13.0909 9.21567 13.5793 8.72727 14.1818 8.72727ZM4.90909 16.3636H9.27273V20.7273H4.90909V16.3636ZM14.1818 17.4545H28.9091C29.5116 17.4545 30 17.943 30 18.5455C30 19.148 29.5116 19.6364 28.9091 19.6364H14.1818C13.5793 19.6364 13.0909 19.148 13.0909 18.5455C13.0909 17.943 13.5793 17.4545 14.1818 17.4545ZM4.90909 25.0909H9.27273V29.4545H4.90909V25.0909ZM14.1818 26.1818H28.9091C29.5116 26.1818 30 26.6702 30 27.2727C30 27.8752 29.5116 28.3636 28.9091 28.3636H14.1818C13.5793 28.3636 13.0909 27.8752 13.0909 27.2727C13.0909 26.6702 13.5793 26.1818 14.1818 26.1818ZM4.90909 33.8182H9.27273V38.1818H4.90909V33.8182ZM14.1818 34.9091H28.9091C29.5116 34.9091 30 35.3975 30 36C30 36.6025 29.5116 37.0909 28.9091 37.0909H14.1818C13.5793 37.0909 13.0909 36.6025 13.0909 36C13.0909 35.3975 13.5793 34.9091 14.1818 34.9091ZM28.3636 41.4545V46.9091L33.8182 41.4545H28.3636Z" />
                            </svg> -->
                                <div class="main-counter">
                                    <img src="../../../assets/imgs/right-with-border.png">
                                    <div class="counter" style="border: 1px solid {{textColor}};">
                                        <!-- <span class="added-items-total-numbers" style="color:{{textColor}};">{{cartItem?.length}}</span>  -->
                                        <span>{{cartItem?.length}}</span>
                                    </div>
                                </div>
                            </div>
                            <span class="shopping-list-headline">
                                <h4 (click)="CloseShoppingList()" class="mb-0 chervon-up-icon d-none"
                                    style="color:{{textColor}};">
                                    My Shopping List
                                </h4>
                                <h4 (click)="ShowShoppingList()" class="mb-0 chervon-down-icon"
                                    style="color:{{textColor}};">
                                    My Shopping List
                                </h4>
                            </span>
                            <!-- <span  class="chervon-up-icon"><img src="../../assets/images/chervon-up.png" alt=""></span> -->
                            <span (click)="CloseShoppingList()" class="chervon-up-icon d-none">
                                <svg width="24px" height="24px" fill="#fff" class="footer-arrow-icon" viewBox="0 0 24 24" id="magicoon-Regular"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <title>chevron-circle-right</title>
                                    <path id="chevron-circle-right-Regular-2" data-name="chevron-circle-right-Regular"
                                        class="cls-1"
                                        d="M12,2.25A9.75,9.75,0,1,0,21.75,12,9.761,9.761,0,0,0,12,2.25Zm0,18A8.25,8.25,0,1,1,20.25,12,8.259,8.259,0,0,1,12,20.25Zm2.53-8.78a.749.749,0,0,1,0,1.06l-3,3a.75.75,0,0,1-1.06-1.06L12.939,12,10.47,9.53a.75.75,0,0,1,1.06-1.06Z" />
                                </svg>
                            </span>
                            <span (click)="ShowShoppingList()" class="chervon-down-icon">
                                <svg width="24px" height="24px" fill="#fff" class="footer-arrow-icon" viewBox="0 0 24 24" id="magicoon-Regular"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <title>chevron-circle-left</title>
                                    <path id="chevron-circle-left-Regular-2" data-name="chevron-circle-left-Regular"
                                        class="cls-1"
                                        d="M13.53,9.53,11.061,12l2.469,2.47a.75.75,0,1,1-1.06,1.06l-3-3a.749.749,0,0,1,0-1.06l3-3a.75.75,0,0,1,1.06,1.06ZM21.75,12A9.75,9.75,0,1,1,12,2.25,9.761,9.761,0,0,1,21.75,12Zm-1.5,0A8.25,8.25,0,1,0,12,20.25,8.259,8.259,0,0,0,20.25,12Z" />
                                </svg>
                            </span>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 px-0 sociel-hide">
                        <div class="social-links">
                            <div class="follow-headline"
                                *ngIf="display_social_icons.twitter || display_social_icons.facebook || display_social_icons.instagram || display_social_icons.youtube">
                                <span>Follow us on</span>
                            </div>

                            <div class="social-media" *ngIf="display_social_icons.twitter">
                                <a href="{{store_data && store_data[0]?.social_twitter_url }}" target="_blank">
                                    <svg width="36" height="35" viewBox="0 0 36 35" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M18 0C8.33594 0 0.5 7.83594 0.5 17.5C0.5 27.1641 8.33594 35 18 35C27.6641 35 35.5 27.1641 35.5 17.5C35.5 7.83594 27.6641 0 18 0ZM26.4102 13.1914C26.4219 13.375 26.4219 13.5664 26.4219 13.7539C26.4219 19.4883 22.0547 26.0938 14.0742 26.0938C11.6133 26.0938 9.33203 25.3789 7.41016 24.1484C7.76172 24.1875 8.09766 24.2031 8.45703 24.2031C10.4883 24.2031 12.3555 23.5156 13.8438 22.3516C11.9375 22.3125 10.3359 21.0625 9.78906 19.3438C10.457 19.4414 11.0586 19.4414 11.7461 19.2656C10.7646 19.0662 9.88232 18.5331 9.24928 17.757C8.61624 16.9808 8.27143 16.0094 8.27344 15.0078V14.9531C8.84766 15.2773 9.52344 15.4766 10.2305 15.5039C9.6361 15.1078 9.14866 14.5711 8.81137 13.9415C8.47408 13.3119 8.29736 12.6088 8.29688 11.8945C8.29688 11.0859 8.50781 10.3477 8.88672 9.70703C9.97618 11.0482 11.3357 12.1451 12.8768 12.9264C14.418 13.7078 16.1063 14.1561 17.832 14.2422C17.2188 11.293 19.4219 8.90625 22.0703 8.90625C23.3203 8.90625 24.4453 9.42969 25.2383 10.2734C26.2188 10.0898 27.1562 9.72266 27.9922 9.23047C27.668 10.2344 26.9883 11.082 26.0859 11.6172C26.9609 11.5234 27.8047 11.2812 28.5859 10.9414C27.9961 11.8086 27.2578 12.5781 26.4102 13.1914Z"
                                            [attr.fill]="textColor" />
                                    </svg>
                                </a>
                            </div>

                            <div class="social-media" *ngIf="display_social_icons.facebook">
                                <a href="{{store_data && store_data[0]?.social_facebook_url }}" target="_blank">
                                    <svg width="35" height="35" viewBox="0 0 35 35" fill="none"
                                        xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                            d="M0 17.5977C0 26.2981 6.31896 33.5329 14.5833 35V22.3606H10.2083V17.5H14.5833V13.6106C14.5833 9.23563 17.4023 6.80604 21.3894 6.80604C22.6523 6.80604 24.0144 7 25.2773 7.19396V11.6667H23.0417C20.9023 11.6667 20.4167 12.7356 20.4167 14.0977V17.5H25.0833L24.306 22.3606H20.4167V35C28.681 33.5329 35 26.2996 35 17.5977C35 7.91875 27.125 0 17.5 0C7.875 0 0 7.91875 0 17.5977Z"
                                            [attr.fill]="textColor" />
                                    </svg>
                                </a>
                            </div>

                            <div class="social-media" *ngIf="display_social_icons.instagram">
                                <a href="{{store_data && store_data[0]?.social_instagram_url }}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="white">
                                        <path d="M14.829 6.302c-.738-.034-.96-.04-2.829-.04s-2.09.007-2.828.04c-1.899.087-2.783.986-2.87 2.87-.033.738-.041.959-.041 2.828s.008 2.09.041 2.829c.087 1.879.967 2.783 2.87 2.87.737.033.959.041 2.828.041 1.87 0 2.091-.007 2.829-.041 1.899-.086 2.782-.988 2.87-2.87.033-.738.04-.96.04-2.829s-.007-2.09-.04-2.828c-.088-1.883-.973-2.783-2.87-2.87zm-2.829 9.293c-1.985 0-3.595-1.609-3.595-3.595 0-1.985 1.61-3.594 3.595-3.594s3.595 1.609 3.595 3.594c0 1.985-1.61 3.595-3.595 3.595zm3.737-6.491c-.464 0-.84-.376-.84-.84 0-.464.376-.84.84-.84.464 0 .84.376.84.84 0 .463-.376.84-.84.84zm-1.404 2.896c0 1.289-1.045 2.333-2.333 2.333s-2.333-1.044-2.333-2.333c0-1.289 1.045-2.333 2.333-2.333s2.333 1.044 2.333 2.333zm-2.333-12c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6.958 14.886c-.115 2.545-1.532 3.955-4.071 4.072-.747.034-.986.042-2.887.042s-2.139-.008-2.886-.042c-2.544-.117-3.955-1.529-4.072-4.072-.034-.746-.042-.985-.042-2.886 0-1.901.008-2.139.042-2.886.117-2.544 1.529-3.955 4.072-4.071.747-.035.985-.043 2.886-.043s2.14.008 2.887.043c2.545.117 3.957 1.532 4.071 4.071.034.747.042.985.042 2.886 0 1.901-.008 2.14-.042 2.886z" />
                                    </svg>
                                </a>
                            </div>

                            <div class="social-media" *ngIf="display_social_icons.youtube">
                                <a href="{{store_data && store_data[0]?.social_youtube_url }}" target="_blank">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="38" height="38" viewBox="0 0 24 24" fill="white" >
                                        <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm4.441 16.892c-2.102.144-6.784.144-8.883 0-2.276-.156-2.541-1.27-2.558-4.892.017-3.629.285-4.736 2.558-4.892 2.099-.144 6.782-.144 8.883 0 2.277.156 2.541 1.27 2.559 4.892-.018 3.629-.285 4.736-2.559 4.892zm-6.441-7.234l4.917 2.338-4.917 2.346v-4.684z"/>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 col-sm-12 px-0">
                        <div class="price-goods"
                            *ngIf="store_data[0]?.access_layout != 'Upload Page’s Images/url layout only' && !this.store_data[0]?.override_noad_layout">
                            <span>Prices Good</span>
                            <span>{{(current_layout_data && (current_layout_data[0]?.actual_start_date ? current_layout_data[0]?.actual_start_date : (current_layout_data[0]?.start_date.seconds* 1000)) |
                                date:"MM/dd/yyyy")}}</span>
                            <span>-</span>
                            <span>{{(current_layout_data && (current_layout_data[0]?.actual_end_date ? current_layout_data[0]?.actual_end_date : (current_layout_data[0]?.end_date.seconds* 1000)) |
                                date:"MM/dd/yyyy") }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6 col-sm-12  align-items-center justify-content-end d-none" id="ShowListButton">
            <div class="cart-area footer-cart" style="color:{{textColor}};">
                <div (click)="CloseShoppingList()" class="shopping-list-icon chervon-up-icon ">
                    <img src="{{counter_img}}">
                    <!-- <span class="added-items-total-numbers" style="color:{{textColor}};">{{cartItem?.length}}</span>  -->
                </div>
                <div (click)="ShowShoppingList()" class="shopping-list-icon chervon-down-icon d-none">
                    <img src="{{counter_img}}">
                    <!-- <span class="added-items-total-numbers" style="color:{{textColor}};">{{cartItem?.length}}</span>  -->
                </div>
                <span class="shopping-list-headline">
                    <h4 (click)="ShowShoppingList()" class="mb-0 chervon-up-icon" style="color:{{textColor}};">My
                        Shopping List</h4>
                    <h4 (click)="CloseShoppingList()" class="mb-0 chervon-down-icon d-none"
                        style="color:{{textColor}};">My Shopping List</h4>
                </span>
                <!-- <span  class="chervon-up-icon"><img src="../../assets/images/chervon-up.png" alt=""></span> -->
                <span (click)="CloseShoppingList()" class="chervon-up-icon d-none">
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="white" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M31.5 16.015C31.5 24.5841 24.56 31.53 16 31.53C7.44003 31.53 0.5 24.5841 0.5 16.015C0.5 7.44586 7.44003 0.5 16 0.5C24.56 0.5 31.5 7.44586 31.5 16.015Z"
                            [attr.stroke]="textColor" />
                        <path
                            d="M9.00021 17.8302C8.9997 17.6808 9.03268 17.5332 9.09671 17.3982C9.16073 17.2632 9.25419 17.1443 9.37021 17.0502L15.3702 12.2202C15.5491 12.0731 15.7736 11.9927 16.0052 11.9927C16.2368 11.9927 16.4613 12.0731 16.6402 12.2202L22.6402 17.2202C22.8444 17.3899 22.9729 17.6338 22.9972 17.8982C23.0216 18.1627 22.94 18.4259 22.7702 18.6302C22.6005 18.8344 22.3566 18.9628 22.0921 18.9872C21.8277 19.0116 21.5644 18.9299 21.3602 18.7602L16.0002 14.2902L10.6402 18.6102C10.4934 18.7324 10.3147 18.8101 10.1251 18.834C9.93559 18.8579 9.74316 18.827 9.57064 18.7449C9.39811 18.6629 9.2527 18.5331 9.15161 18.371C9.05052 18.2089 8.99798 18.0212 9.00021 17.8302Z"
                            [attr.fill]="textColor" />
                    </svg>
                </span>
                <span (click)="ShowShoppingList()" class="chervon-down-icon">
                    <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M31.5 16.985C31.5 8.41586 24.56 1.47 16 1.47C7.44003 1.47 0.5 8.41586 0.5 16.985C0.5 25.5541 7.44003 32.5 16 32.5C24.56 32.5 31.5 25.5541 31.5 16.985Z"
                            fill="none" [attr.stroke]="textColor" />
                        <path
                            d="M8.99972 15.17C8.99922 15.3194 9.03219 15.467 9.09622 15.602C9.16025 15.737 9.2537 15.8559 9.36973 15.95L15.3697 20.78C15.5487 20.9271 15.7731 21.0075 16.0047 21.0075C16.2363 21.0075 16.4608 20.9271 16.6397 20.78L22.6397 15.78C22.8439 15.6103 22.9724 15.3663 22.9967 15.1019C23.0211 14.8375 22.9395 14.5742 22.7697 14.37C22.6 14.1658 22.3561 14.0374 22.0916 14.013C21.8272 13.9886 21.5639 14.0703 21.3597 14.24L15.9997 18.71L10.6397 14.39C10.4929 14.2677 10.3142 14.19 10.1246 14.1662C9.9351 14.1423 9.74268 14.1732 9.57015 14.2553C9.39762 14.3373 9.25221 14.4671 9.15112 14.6292C9.05003 14.7913 8.99749 14.979 8.99972 15.17Z"
                            [attr.fill]="textColor" />
                    </svg>
                </span>
            </div>
        </div>

    </footer>
    <!-- End Footer -->
</ng-container>
<!-- <ng-container *ngIf=" !this.displayRecipePage">
    <div style="position: fixed; top: 50%; margin: 0 auto; right: 0; left: 0;">This page is not accessible.you do not have permission to access this page</div>
</ng-container> -->
<div class="loader" *ngIf="show_loader">
    <mat-spinner mode="indeterminate"
        style=" position: fixed; top: 50%; margin: 0 auto; right: 0; left: 0; transform: translate(-50%);">
    </mat-spinner>
</div>