import { Component, OnInit } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router,NavigationEnd  } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Location } from "@angular/common";

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: [
    './styles/main-menu.component.scss'
  ]
})  
export class MainMenuComponent implements OnInit {
user:any;
currentRoute: string;
currenturl:string; 
val:boolean = false; 
ghost_mode:boolean = false;
route:any;
  constructor(location: Location,public authService: AuthService,private router: Router) {
    this.currenturl = location.path();
    console.log('CURRENT URL IS ',this.currenturl);
    this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            console.log(event);
            this.currenturl = event['view']['location']['pathname'];
            console.log('current url is =',this.currenturl);
        }); 

         router.events.subscribe(val => {
      if (location.path() != "") {
        this.route = location.path();
        this.currenturl = this.route;
        console.log('THE ROUTE IS >>> ',this.route);
      } else {
        this.route = "Home";
        console.log('THE ROUTE IS >>>', this.route);
      }
    });
       
  
   // this.currenturl = window.location.href;
    //console.log(this.currenturl);
  }

  ngOnInit() {

   // this.currenturl = location.path();
    let chkghost = (localStorage.getItem('ghost_mode'));
    if(chkghost)
    {
      console.log('main-menu got the user from currentstoreid -ghsot mode');
      this.ghost_mode = true;
      this.user  = JSON.parse(localStorage.getItem('current_store_id'));
    } else{
      this.ghost_mode = false;
      console.log('main-menu got the user from main');
      this.user = JSON.parse(localStorage.getItem('userrole'));
    }
  	


    //this.currenturl = window.location.href;
    //console.log(this.currenturl);      
  }

  ngOnDestroy() {
    // this.currenturl = location.path();
    // this.router.events.pipe(
    //         filter(event => event instanceof NavigationEnd)
    //     ).subscribe(() => {
    //         console.log(event);
    //         this.currenturl = event['view']['location']['pathname'];
    //         console.log('current url is =',this.currenturl);
    //     }); 
  }



}  
