import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import Shepherd from 'shepherd.js';

@Injectable({
  providedIn: 'root'
})
export class AppRecipeSlideoutTourService {

  private tour: Shepherd.Tour;
  private recipeSlideoutTourSeenFlag = 'recipeSlideOutTourSeen';
  currentUrl: string;
  stepsID: any;
  constructor(private router: Router) {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        },
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.tour.isActive) {
        if (event.url !== this.currentUrl) {
          this.tour.cancel();
        }
        this.currentUrl = event.url;
      }


    });
  }

  private setTourSeenFlag() {
    localStorage.setItem(this.recipeSlideoutTourSeenFlag, 'true');
  }

  private hasTourBeenSeen() {
    return localStorage.getItem(this.recipeSlideoutTourSeenFlag) === 'true';
  }


  addTourStep(stepsObject) {
    stepsObject.forEach((step) => {
      this.tour.addStep(
        {
          id: step.id,
          cancelIcon: {
            enabled: true,
          },
          title: '<h3>' + step.title + '</h3>',
          text: '<p>' + step.content + '</p>',
          attachTo: {
            element: '.Coupons',
            on: 'bottom'
          },
          classes: 'shopping-tour',
          buttons: this.getButtons(["Cancel", "Next"]),
        }

      );
    });
    this.tour.start();
    stepsObject.forEach((step) => {
      this.tour.removeStep(step.id);
    })
  }

  startTour() {
    if (!this.hasTourBeenSeen()) {
      this.tour.addSteps([
        {
          id: 'show-tour-action',
          title: '<h3>Recipe Details</h3>',
          text: '<p>This slide gives you the recipes details. Everything you need to make the recipe and add ingredients to your shopping list is here.</p>',
          attachTo: {
            element: '',
            on: 'bottom'
          },
          classes: 'recipe-slideout-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
            },
          },
          buttons: [
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'START TOUR',
              action: this.tour.next
            }

          ]
        },
        {
          id: 'step-1',
          title: '<h3>Recipe Instructions</h3>',
          text: '<p>This is where the cooking instructions for your recipes are given in detail. </p>',
          attachTo: {
            element: '.rotate',
            on: 'bottom'
          },
          classes: 'recipe-slideout-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.rotate');
              if (!element) {
                if (previousId === 'show-tour-action') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-2',
          title: '<h3>Add All Ingredients</h3>',
          text: '<p>Use this button to add ALL the ingredients in the recipe to your shopping list. </p>',
          attachTo: {
            element: '.slide-btn-two',
            on: 'bottom'
          },
          classes: 'recipe-slideout-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.slide-btn-two');
              if (!element) {
                if (previousId === 'step-1') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-3',
          title: '<h3>The Plus Button</h3>',
          text: '<p>It’s all about the Plus Button. Use this button to add one ingredient at a time to your shopping list.</p>',
          attachTo: {
            element: '.plusimage',
            on: 'bottom'
          },
          classes: 'recipe-slideout-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.plusimage');
              if (!element) {
                if (previousId === 'step-2') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },


        {
          id: 'step-4',
          title: '<h3>The Help Icon</h3>',
          text: '<p>Press this icon to open this tutorial anytime.  </p>',
          attachTo: {
            element: '.helpicon',
            on: 'bottom-end'
          },
          classes: 'recipe-slideout-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.helpicon');
              if (!element) {
                if (previousId === 'step-3') {
                  this.tour.complete();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'FINISH',
              action: () => {
                this.tour.complete()
              }
            }
          ]
        },
      ]);


      this.tour.start();

      this.setTourSeenFlag();

    }
  }

  cancelTour() {
    this.tour.cancel();
  }

  private getButtons(labels: string[]): Shepherd.Step.StepOptionsButton[] {
    return labels.map((label) => ({
      action: this.tour[label.toLowerCase()],
      classes: label.toLowerCase() + "-button",
      text: label,
    }));
  }
}

