import { Component, OnInit, Inject, PLATFORM_ID } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";

import { LayoutsService } from "./core/layouts/layouts.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/compat/firestore";
import { MatDialog } from '@angular/material/dialog';
import { HttpClient } from "@angular/common/http";
import { PwaService } from "./shared/services/pwa.service";
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
})
export class AppComponent implements OnInit {
  title = "Digital Circular Pro";
  loadedData: any;
  manifest_temp: any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private breakpointObserver: BreakpointObserver,
    private layoutsService: LayoutsService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    public afs: AngularFirestore,
    public dialog: MatDialog,
    public http: HttpClient,
    private pwaService: PwaService,
    private analytics: AngularFireAnalytics
    ) {

  }

  ngOnInit() {

    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      this.pwaService.isInstallable.next(true)
      this.pwaService.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.analytics.logEvent('@pwa_installed_on_Android&Desktop_count', {
        message: 'PWA install successfully.'
    })
    });
    // Listen to device size changes to dynamically adjust the layout
    if (isPlatformBrowser(this.platformId)) {
      // Use Angular CDK Breakpoint Observer to detect changes on the screen size
      this.breakpointObserver
        .observe([Breakpoints.XSmall])
        .subscribe((result) => {
          if (result.breakpoints[Breakpoints.XSmall]) {
            // Switch to mobile layout
            const mobileLayoutName = "mobile";
            this.layoutsService.switchLayout(mobileLayoutName);
          } else {
            // Switch back to previous layout (or the default one)
            const previousLayoutName = this.layoutsService.previousLayout.name;
            this.layoutsService.switchLayout(previousLayoutName);
          }
        });
    }
  }

}