import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StoreBundlesComponent } from '../../store-bundles.component';
import { main } from '@popperjs/core';

@Component({
  selector: 'app-product-dialog2-component',
  templateUrl: './product-dialog2-component.component.html',
  styleUrls: ['./product-dialog2-component.component.scss']
})
export class ProductDialog2ComponentComponent implements OnInit {

  cartItem = []
  cart_ids = []
  all_product_count = []
  qnt_dropdown: any = false
  product_count: any
  image_path = ''
  current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
  current_list_data: any = {}
  counter_img = ''
  show_loader = false
  view: string;

  @Output() cart_created = new EventEmitter
  past_item: any;
  Amount: any=0;

  constructor(
    public dialogRef: MatDialogRef<StoreBundlesComponent>,
    public afs: AngularFirestore,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  async ngOnInit() {

    if (this.current_list_id !== '') {
      const listData: any = await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).get().toPromise()
        .then(data => {
          return data.docs.map(doc => doc.data());
        });
      this.current_list_data = {
        uid: listData[0].id,
        items: listData[0].items
      }
      this.cartItem = this.current_list_data.items
      this.cartItem.forEach(i => {
        if (!i.static) {
          if (i.product.id) {
            this.cart_ids.push(i.product.id)
          } else {
            this.cart_ids.push(i.product.uid)
          }
        }
      })
      let foundProduct
      if (this.data.product.id) {
        foundProduct = this.cartItem.filter(x => x.product.id == this.data.product.id)
      } else {
        foundProduct = this.cartItem.filter(x => x.product.uid == this.data.product.uid)
      }
      if (foundProduct.length !== 0) {
        this.image_path = '../../../../../assets/imgs/right.png'
        this.product_count = foundProduct[0].count
        if (this.data.product.digital_coupon) {
          this.qnt_dropdown = false;
        } else {
          this.qnt_dropdown = true;

        }
      } else {
        this.image_path = '../../../../../assets/imgs/plus.png'
        this.qnt_dropdown = false
      }
    }
    else {
      this.image_path = '../../../../../assets/imgs/plus.png'
      this.qnt_dropdown = false
    }

    if (!this.data.YoutubeURL) {
      let pl
      const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
      if (this.data.product.isVideo) {
        pl = document.getElementById(this.data.gridView.id).parentElement.parentElement.getElementsByClassName('pricelabel')
      } else {
        pl = document.getElementById(this.data.gridView.id).parentElement.getElementsByClassName('pricelabel')
      }
      if (pl.length > 0) {
        let temp_pl = pl[0].cloneNode(true);
        temp_pl.style.display = 'block';
        temp_pl.style.setProperty('right', ' 65px', 'important'); 
        document.getElementById('price_label').appendChild(temp_pl)
      } else {
        if (view == 'listView') {
          if (!this.data.product.digital_coupon) {
            let temp_pl: any = document.createElement('div');
            const csvPriceLabel = await this.getCSVpricelabel(this.data.product);
            temp_pl.innerHTML = csvPriceLabel;
            temp_pl.classList.add('pricelabel'); // Add class to the first child
            temp_pl.setAttribute('style', 'position: absolute; bottom: 6px; left: 8px;'); // Add style attribute
            document.getElementById('price_label').appendChild(temp_pl);
          }
        }
      }
    } else {
      setTimeout(() => {
        let pl = document.getElementById("youtube_id")
        let url = this.data.product.product_url
        if (this.data.product.autoplay) {
          url = url + "?autoplay=1&mute=1"
        }
        pl?.setAttribute('src', url)
      }, 0);
    }
    if (this.data.product.digital_coupon) {
      //Digital_Main_price Block
      let digitalPriceMainDiv = document.createElement('div');
      digitalPriceMainDiv.className = 'Digital_price_main';

      digitalPriceMainDiv.innerHTML = this.data.product.sale_content_price_label_svg;

      let digitalCouponDiv = document.createElement('div');
      digitalCouponDiv.className = 'digital_coupon';
      digitalCouponDiv.innerHTML = this.data.product.digital_content_coupon_price_label_svg;
      digitalPriceMainDiv.appendChild(digitalCouponDiv);

      let finalPriceDiv = document.createElement('div');
      finalPriceDiv.className = 'final_price';
      finalPriceDiv.innerHTML = this.data.product.final_content_price_label_svg;

      let digitalNoteDiv = document.createElement('div');
      digitalNoteDiv.className = 'digital-note';
      digitalNoteDiv.textContent = this.data.product.note;
      finalPriceDiv.appendChild(digitalNoteDiv);

      digitalPriceMainDiv.appendChild(finalPriceDiv);

      document.body.appendChild(digitalPriceMainDiv);
      document.getElementById('digital_preview').appendChild(digitalPriceMainDiv);

      //transaction_limit_block
      let useUpToWrapper = document.createElement('div');
      useUpToWrapper.className = 'use_up_to_wrapper';
      let h2Element = document.createElement('h2');
      h2Element.textContent = 'USE UP TO';
      useUpToWrapper.appendChild(h2Element);
      let priceMainWrapperCut = document.createElement('div');
      priceMainWrapperCut.className = 'price_main_wrapper_cut';
      let h3Element = document.createElement('h3');
      h3Element.textContent = this.data.product.usage_limit;
      priceMainWrapperCut.appendChild(h3Element);
      let priceCutoutDiv = document.createElement('div');
      priceCutoutDiv.className = 'price_cutout';
      let svgElement = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      svgElement.setAttribute("viewBox", "0 0 512 512");
      svgElement.innerHTML = `
        <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
        <path d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6L396.8 460.8c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 240a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
      `;
      priceCutoutDiv.appendChild(svgElement);
      let spanElement = document.createElement('span');
      spanElement.textContent = 'TIMES';
      priceCutoutDiv.appendChild(spanElement);
      priceMainWrapperCut.appendChild(priceCutoutDiv);
      useUpToWrapper.appendChild(priceMainWrapperCut);
      let h6Element = document.createElement('h6');
      h6Element.textContent = 'IN ONE TRANSACTION';
      useUpToWrapper.appendChild(h6Element);
      document.body.appendChild(useUpToWrapper);
      document.getElementById('transaction_limit_preview').appendChild(useUpToWrapper);

      //Digital Coupon Link Block
      if (this.data.product.link) {
        let anchorElement = document.createElement('a');
        anchorElement.className = 'price_link_data';
        anchorElement.href = this.data.product.link;
        anchorElement.setAttribute('style', 'top:60px')
        anchorElement.target = '_blank';
        let svgElementanchor = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        svgElementanchor.setAttribute("viewBox", "0 0 512 512");
        svgElementanchor.innerHTML = `
        <!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.-->
        <path d="M256 192l-39.5-39.5c4.9-12.6 7.5-26.2 7.5-40.5C224 50.1 173.9 0 112 0S0 50.1 0 112s50.1 112 112 112c14.3 0 27.9-2.7 40.5-7.5L192 256l-39.5 39.5c-12.6-4.9-26.2-7.5-40.5-7.5C50.1 288 0 338.1 0 400s50.1 112 112 112s112-50.1 112-112c0-14.3-2.7-27.9-7.5-40.5L499.2 76.8c7.1-7.1 7.1-18.5 0-25.6c-28.3-28.3-74.1-28.3-102.4 0L256 192zm22.6 150.6L396.8 460.8c28.3 28.3 74.1 28.3 102.4 0c7.1-7.1 7.1-18.5 0-25.6L342.6 278.6l-64 64zM64 112a48 48 0 1 1 96 0 48 48 0 1 1 -96 0zm48 240a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
      `;
        anchorElement.appendChild(svgElementanchor);
        document.body.appendChild(anchorElement);
        document.getElementById('price_link_preview').appendChild(anchorElement);
      }
    }
  }

  closeModal() {
    this.dialogRef.close()
  }
  async getCSVpricelabel(data) {
    // circular price label
    if (data.price_label_format != '') {
      switch (data.price_label_format) {
        case "format1":
          return '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
        case "format2":
          if (data.price_label_value1) {
            return '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format2" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></span></div></div></div></div></div></div></div></div>';
          }
        case "format3":
          if (data.price_label_value1) {
            return '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span class=><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format3" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/LB</span></span></div></div></div></div></div></div></div></div>'
          }
        case "format4":
          if (data.price_label_value1) {
            return '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format4" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span><span class="price-unit">/EA</span></span></div></div></div></div></div></div></div></div>'
          }
        case "format5":
          if (data.price_label_value2) {
            return '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></span></div></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format5" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span><span class="pricing-for">for</span></div><div class="circular-card-price"><div class="top-line"><span id="third" class="cents-amount">' + ' ' + data.price_label_value3 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div></div></div></div></div></div></div></div>'
          }
        case "format6":
          if (data.price_label_value3 == '') {
            return '<div id="format6" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="third-amount"> Free</span></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format6" class="circular-card-deal-wrapper extraSmall withNumber"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Buy</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">Get</span><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></div><div class="num-to-cart pricing-num-for"><span class="pricing-buy-get">For</span><span id="third" class="third-amount">' + ' ' + data.price_label_value3 + '</span></div></div></div></div></div></div></div>';
          }
        case "format7":
          if (data.price_label_value1) {
            return '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + data.price_label_value1 + '</span><span class="price-unit-wrapper"><span id="cent" class="cents-amount">' + ' ' + data.price_label_value2 + '</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>';
          } else {
            return '<div id="format7" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height: 100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span id="cent" class="dollar-amount">' + data.price_label_value2 + '</span><span class="price-unit-wrapper"><span class="dollar-sign">¢</span></span></div><span class="bottom-line">OFF</span></div></div></div></div></div></div></div>'
          }
        default:
          return '<div id="format1" class="circular-card-deal-wrapper extraSmall"><div class="app-circular-card-pricing-tag" style="height:100%"><div class="circular-card-deal"><div class="price-container"><div><div class="circular-card-deal-content"><div class="circular-card-price"><div class="top-line"><span class="dollar-sign">$</span><span id="dollar" class="dollar-amount">' + ' ' + data.price_label_value1 + '</span></div></div></div></div></div></div></div></div>';
      }
    } else {
      return ''
    }
  }
  make_price_message(formate, value1, value2, value3) {
    if ((value1 !== null || value1 !== '') && (value2 !== null || value2 !== '')) {
      if (formate === 'format6') {
        return `Buy ${value1} Get ${value2} ${value3}`
      }
      if (formate === 'format2' || formate === 'Final Price Format1' || formate === 'Sale Price format1' || formate === 'format3' || formate === 'format4' || formate === 'format7') {
        if (value1 == '') {
          return `$0.${value2}`
        } else if (value1 && value2) {
          return `$${value1}.${value2}`
        } else if (value1 && value2 == '') {
          return `$${value1}`
        }
      }
      if (formate === 'format5' || formate === 'Sale Price format2' || formate === 'Final Price Format2') {
        if (value2 == '') {
          return `${value1} for $0.${value3}`
        } else if (value2 && value3) {
          return `${value1} for $${value2}.${value3}`
        } else if (value2 && value3 == '') {
          return `${value1} for $${value2}`
        }
      }
    }
    if (formate === 'format1' && (value1 !== null || value1 !== '')) {
      return `$${value1}`
    }
    return ''
  }
  async addItem() {
    // this.show_loader = true
    let main_item
    if (this.data?.product?.id) {
      if (this.data?.product?.isVideo) {
        main_item = document.getElementById(this.data.product.id)?.parentElement
      } else {
        main_item = document.getElementById(this.data.product.id)?.parentElement
      }
    } else {
      if (this.data?.product?.isVideo) {
        main_item = document.getElementById(this.data.product.uid).parentElement
      } else {
        main_item = document.getElementById(this.data.product.uid)?.parentElement
      }
    }
    if (this.image_path.split('/').includes('plus.png')) {
      let msg;
      if (this.data.product.digital_coupon) {
        msg = this.make_price_message(this.data.product?.sale_price_label_format, this.data.product?.sale_price_label_value1, this.data.product?.sale_price_label_value2, this.data.product?.sale_price_label_value3)
      } else {
        msg = this.make_price_message(this.data.product?.price_label_format, this.data.product?.price_label_value1, this.data.product?.price_label_value2, this.data.product?.price_label_value3)
      };
      if (this.data.product.price_label_format === 'format1' && (this.data.product.price_label_value1 !== null || this.data.product.price_label_value1 !== '')) {
        this.Amount = Number(this.data.product.price_label_value1); // Ensures it's a number
      } else if (this.data.product.price_label_format == "format2") {
        if (this.data.product.price_label_value1 == '') {
          this.Amount = Number(`0.${this.data.product.price_label_value2}`); // Ensures it's a number
        } else if (this.data.product.price_label_value1 && this.data.product.price_label_value2) {
          this.Amount = Number(`${this.data.product.price_label_value1}.${this.data.product.price_label_value2}`); // Ensures it's a number
        } else if (this.data.product.price_label_value1 && this.data.product.price_label_value2 == '') {
          this.Amount = Number(this.data.product.price_label_value1); // Ensures it's a number
        }
      } else if (this.data.product.price_label_format == "format3") {
        if (this.data.product.price_label_value1 == '') {
          this.Amount = Number(`0.${this.data.product.price_label_value2}`); // Ensures it's a number
        } else if (this.data.product.price_label_value1 && this.data.product.price_label_value2) {
          this.Amount = Number(`${this.data.product.price_label_value1}.${this.data.product.price_label_value2}`); // Ensures it's a number
        } else if (this.data.product.price_label_value1 && this.data.product.price_label_value2 == '') {
          this.Amount = Number(this.data.product.price_label_value1); // Ensures it's a number
        }
      } else if (this.data.product.price_label_format == "format4") {
        if (this.data.product.price_label_value1 == '') {
          this.Amount = Number(`0.${this.data.product.price_label_value2}`); // Ensures it's a number
        } else if (this.data.product.price_label_value1 && this.data.product.price_label_value2) {
          this.Amount = Number(`${this.data.product.price_label_value1}.${this.data.product.price_label_value2}`); // Ensures it's a number
        } else if (this.data.product.price_label_value1 && this.data.product.price_label_value2 == '') {
          this.Amount = Number(this.data.product.price_label_value1); // Ensures it's a number
        }
      } else if (this.data.product.price_label_format == "format5") {
        const quantity = parseInt(this.data.product?.price_label_value1, 10) || 1; // Ensure value1 is a valid number
        const dollars = parseFloat(this.data.product?.price_label_value2) || 0;   // Ensure value2 is a valid float
        const cents = parseFloat(this.data.product?.price_label_value3) || 0;     // Ensure value3 is a valid float
        const totalPrice = (dollars + cents / 100) / quantity; // Calculate totalPrice as a number
        this.Amount = totalPrice; // Directly assigning the numeric value
      }
      this.cartItem.push({ product: this.data.product,Amount:this.Amount, count: 1, static: false, priceMessage: msg, digital_coupon: this.data.product.digital_coupon ? this.data.product.digital_coupon : false ,isBundleProduct: this.data.product.isBundleProduct ? this.data.product.isBundleProduct : false,displayValidDate:false });
      if (this.data.product.id) {
        this.cart_ids.push(this.data.product.id)
      } else {
        this.cart_ids.push(this.data.product.uid)
      }
      this.toggleProduct('add');
      if (this.current_list_id !== '') {
        await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
        });
      } else {
        this.show_loader = true;
        const listRef = (await this.afs.collection('/list').add({ items: this.cartItem }))
        const listId = (await listRef.get()).id
        const listData: any = (await listRef.get()).data()
        const updatedList = await listRef.update({ uid: listId })
        this.current_list_id = listId
        this.current_list_data = { uid: listId, items: listData.items }
        this.asyncLocalStorage.setItem('list_id', listId).then(() => {
          return this.asyncLocalStorage.getItem('list_id');
        }).then(async data => {
          if (data) {
            await this.cart_created.emit(true);
          }
        })
      }
      this.image_path = '../../../../../assets/imgs/right.png'
      if (this.data.product.digital_coupon) {
        this.qnt_dropdown = false;
      } else {
        this.qnt_dropdown = true;

      }
      this.product_count = 1
      setTimeout(() => {
        this.show_loader = false
      }, 2000);
    } else {
      if (this.data.product.id) {
        this.cartItem = this.cartItem.filter(x =>
          x?.product?.id !== this.data.product.id)
        this.cart_ids = this.cart_ids.filter(x => x !== this.data.product.id)
      } else {
        this.cartItem = this.cartItem.filter(x =>
          x?.product?.uid !== this.data.product.uid)
        this.cart_ids = this.cart_ids.filter(x => x !== this.data.product.uid)
      }
      this.image_path = '../../../../../assets/imgs/plus.png'
      this.qnt_dropdown = false
      this.toggleProduct('delete')
      await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
      });
      this.show_loader = false
    }
  }

  asyncLocalStorage = {
    setItem: function (key, value) {
      return Promise.resolve().then(function () {
        localStorage.setItem(key, value);
      });
    },
    getItem: function (key) {
      return Promise.resolve().then(function () {
        return localStorage.getItem(key);
      });
    }
  }

  async increaseQNT() {
    let index
    if (this.data.product.id) {
      index = this.cartItem.findIndex(c => c.product.id == this.data.product.id)
    } else {
      index = this.cartItem.findIndex(c => c.product.uid == this.data.product.uid)
    }
    let temp_itm = this.cartItem[index]
    temp_itm.count += 1
    this.product_count = temp_itm.count
    this.productCount(temp_itm.count)
    this.cartItem[index] = temp_itm
    await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
    });
  }

  async decreaseQNT() {
    let index
    if (this.data.product.id) {
      index = this.cartItem.findIndex(c => c.product.id == this.data.product.id)
    } else {
      index = this.cartItem.findIndex(c => c.product.uid == this.data.product.uid)
    }
    let temp_itm = this.cartItem[index]
    if (temp_itm.count > 1) {
      temp_itm.count -= 1
      this.cartItem[index] = temp_itm
      this.product_count = temp_itm.count
      this.productCount(temp_itm.count)
    } else if (temp_itm.count === 1) {
      this.cartItem.splice(index, 1)
      if (!temp_itm.static) {
        this.product_count = ''
        this.qnt_dropdown = false;
        if (this.data.product.id) {
          this.cart_ids.splice(this.cart_ids.indexOf(this.data.product.id), 1)
        } else {
          this.cart_ids.splice(this.cart_ids.indexOf(this.data.product.uid), 1)
        }
        this.toggleProduct('delete')
        this.image_path = '../../../../../assets/imgs/plus.png';
      }
    }

    await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => { });
    this.show_loader = false;
  }

  toggleProduct(val) {
    let main_item
    let title_bar
    let delete_icon
    let layout_product_qnt
    let layout_count_product: HTMLElement
    this.view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
    if (this.view == "listView" && window.innerWidth < 768) {
      if (this.data?.product?.id) {
        main_item = document.getElementById(this.data.product.id)?.parentElement?.parentElement
      } else {
        main_item = document.getElementById(this.data.product.uid)?.parentElement?.parentElement
      }
      delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
      layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
      layout_count_product = main_item?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
    } else {
      if (this.data?.product?.id) {
        if (this.data?.product?.isVideo) {
          main_item = document.getElementById(this.data.product.id).parentElement
        } else {
          main_item = document.getElementById(this.data.product.id)?.parentElement
        }
      } else {
        if (this.data?.product?.isVideo) {
          main_item = document.getElementById(this.data.product.uid).parentElement
        } else {
          main_item = document.getElementById(this.data.product.uid)?.parentElement
        }
      }
      delete_icon = main_item?.parentElement.getElementsByClassName('deleteicon')[0]
      title_bar = main_item?.parentElement?.childNodes[1]
      layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
      layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
    }

    if (val == 'delete') {
      title_bar?.classList?.remove("test")
      this.changeVisibility(layout_count_product)
      delete_icon.src = "../../../assets/imgs/plus.png"
    } else {
      this.changeVisibility(layout_count_product)
      layout_product_qnt.innerHTML = "1"
      title_bar?.classList?.add("test")
      delete_icon.src = '../../../assets/imgs/right.png'
    }
  }

  getHostname(url) {
    const hostname = new URL(url)
    return hostname.hostname;
  }

  productCount(val) {
    let main_item
    let layout_product_qnt;
    this.view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
    if (this.view == "listView" && window.innerWidth < 768) {
      if (this.data?.product?.id) {
        main_item = document.getElementById(this.data.product.id)?.parentElement?.parentElement
      } else {
        main_item = document.getElementById(this.data.product.uid)?.parentElement?.parentElement
      }
      layout_product_qnt = main_item?.getElementsByClassName("layout_product_qnt")[0]
    } else {
      if (this.data?.product?.id) {
        if (this.data?.product?.isVideo) {
          main_item = document.getElementById(this.data.product.id).parentElement
        } else {
          main_item = document.getElementById(this.data.product.id)?.parentElement
        }
      } else {
        if (this.data?.product?.isVideo) {
          main_item = document.getElementById(this.data.product.uid).parentElement
        } else {
          main_item = document.getElementById(this.data.product.uid)?.parentElement
        }
      }
      layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
    }
    layout_product_qnt.innerHTML = val
  }

  changeVisibility(val) {
    const visibility = val?.style?.visibility
    if (this.data.product.digital_coupon) {
      val.style.visibility = 'hidden'
    } else {
      if (visibility == "hidden" || visibility == "") {
        val.style.visibility = 'visible'
      } else {
        val.style.visibility = 'hidden'
      }
    }
  }
}
