<ng-template #deleteStoreModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title3" style="font-size: 18px;"><i class="fas fa-chevron-left" style="    margin-right: 1rem;font-size: 17px;" (click)="modal.dismiss('Cross click')"></i>Delete Store</h4>
    <button type="button" class="close close_store_delete_modal" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">×</span>
    </button>
  </div>


  <div class="modal-body">

      <p>Are you sure you want to delete this Store permanently?  </p>
      <p style="font-size: 14px;color:#858585;">Note - All data related to this store namely staff,incident reports, logs,etc will be deleted permanently!</p>
        <button class="btn btn-danger float-right" (click)="delete_store_submit()">Yes, Delete Store</button>
        <button class="btn btn-light float-right" (click)="modal.dismiss('Cross click')" style="margin-right: 1rem;">No</button>

  </div>
  
</ng-template>



<ng-container *ngTemplateOutlet="(_mode === 'condensed') ? condensedToolbar : expandedToolbar;">
</ng-container>

<ng-template #condensedToolbar>
  <mat-toolbar class="top-navbar-toolbar">
    <mat-toolbar-row>
      <div class="navbar-branding">
        <button class="btn toggle-sidenav-btn" type="button" (click)="toggleMainMenu()">
          <i class="fa fa-bars"></i>
        </button>
      </div>
      <span class="navbar-title">Title here</span>
      <div class="navbar-user">
        <div class="user-image-wrapper">
  
        </div>
        <div ngbDropdown class="user-actions-dropdown" placement="bottom-right" display="static">
          <button class="user-action-toggle-btn btn btn-link" id="accountMenu" ngbDropdownToggle></button>
          <div ngbDropdownMenu aria-labelledby="accountMenu" class="dropdown-menu-right">
           <!--  <a class="btn btn-link" ngbDropdownItem [routerLink]="['/forms/form-layouts']">Account</a> -->
            <button class="btn btn-link" ngbDropdownItem (click)="authService.SignOut()">Log out</button>
          </div>
        </div>
      </div>
    </mat-toolbar-row>
    <mat-toolbar-row>
      <div class="navbar-controls">
        <div class="navbar-search">
          <input class="search-input form-control form-control-lg" type="text" placeholder="Search">
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</ng-template>

<ng-template #expandedToolbar>
  <mat-toolbar class="top-navbar-toolbar" style="padding-right: 10px !important;">
    <mat-toolbar-row>
      <div class="navbar-branding">
        <mat-icon class="brand-logo" svgIcon="angular-logo-2"></mat-icon>
        <button class="btn toggle-sidenav-btn" type="button" (click)="toggleMainMenu()">
          <i class="fa fa-bars"></i>
        </button>
      </div>
      <div class="navbar-controls">
       <!--  <div class="navbar-search d-none d-md-block">
          <input class="search-input form-control form-control-lg" type="text" placeholder="Search">
        </div> -->
        <div class=" d-none d-md-block">
            <p style="margin-bottom: 0;font-weight: 400;color: #9d9d9d;" *ngIf="users && users[0]?.role == 'store' && !ghost_mode">Store Owner Panel</p>
            <p style="margin-bottom: 0;font-weight: 400;color: #9d9d9d;" *ngIf="users && users[0]?.role == 'manager'">Manager Panel</p>
            <p style="margin-bottom: 0;font-weight: 400;color: #000;" *ngIf="users && users[0]?.role == 'group'">{{group_data && group_data[0]?.company_name}}</p>
             <p style="margin-bottom: 0;font-weight: 400;color: #9d9d9d;" *ngIf="users && users[0]?.role == 'admin'">Admin Panel</p>
        </div>
        
          <div *ngIf="users && users[0]?.role =='store' || users && users[0]?.role =='manager' " style="margin-left: 3rem;">
          <p style="margin-bottom: 0;font-size: 18px;font-weight: 500;border: 1px solid #76b100;
    padding: 3px 20px;
    border-radius: 30px;" *ngIf="store_data"><img src="assets/imgs/icon-store.svg" style="margin-right: 9px;margin-top: -8px;">{{store_data[0]?.company_name}}, {{store_data[0]?.branch_name}}</p>
          </div>

          <div *ngIf="ghost_mode && store_data">
            
            <button class="btn btn-outline-danger" style="margin-left: 3rem;border-radius: 32px;" (click)="open_delete_store()"><img src="assets/imgs/icon-trash.svg" style="    margin-right: 5px;margin-top: -3px;">Delete Store</button>
          </div>


          <div *ngIf="users && users[0]?.role =='group' " style="margin-left: 3rem;">
			<div *ngIf="stores_list_data " style="display: flex;">
				<img src="assets/imgs/icon-store.svg" style="    margin-right: 9px;margin-top: -8px;">
				<ng-select  bindLabel="Select Store"  class="storeselecter groupstoreselector" style="    width: 250px;font-weight: 400;font-size: 15px;" [(ngModel)]="current_store_id" [clearable]="false" (change)="store_change($event)" placeholder="Select Store" notFoundText="No Store Found"   #addwarehouseid >
					<ng-option *ngFor="let type of stores_list_data;let j = index" [value]="type.uid">{{type.branch_name}}</ng-option>
				</ng-select>
			</div>
          </div>
         <!--  <button type="button" class="action-button btn btn-link" (click)="toggleNotificationsMenu()">
            <img src="./assets/imgs/icons/notification.svg"  style="width: 25px;height:25px;">
          </button> -->
          <!-- <button type="button" class="action-button btn btn-link" (click)="toggleSettingsMenu()">
            <mat-icon class="navbar-icons" svgIcon="settings"></mat-icon>
          </button> --> 
        </div>
        <div class="navbar-user">
          <div class="user-image-wrapper">
            <cc-aspect-ratio [ratio]="{w: 1, h: 1}">
              <cc-image-shell class="user-image" animation="spinner" [src]="users && users[0]?.photoURL" [alt]="'user image'" style="border:1px solid #71b01d;"></cc-image-shell>
            </cc-aspect-ratio>
          </div>
          <div  class="user-actions-dropdown" placement="bottom-right" display="static">
            <button class="user-action-toggle-btn btn btn-link" id="accountMenu">
              <span class="user-name">
                <cc-text-shell [data]="users && users[0]?.displayName" class="text-primary" style="font-weight:800;font-family: 'Roboto';    color: #464646 !important;text-decoration: underline !important;"></cc-text-shell> 
              </span>
              <img src="assets/imgs/icon-logout-green.svg" style="float:right;text-align: right;margin-left: 1rem;cursor: pointer;" (click)="authService.SignOut()" *ngIf="!ghost_mode">
            </button>

            <!-- <div ngbDropdownMenu aria-labelledby="accountMenu" class="dropdown-menu-right">
              <button class="btn btn-link" ngbDropdownItem (click)="authService.SignOut()">Log out</button>
            </div> -->
          </div>
        </div>
      
    </mat-toolbar-row>
  </mat-toolbar>
</ng-template>
