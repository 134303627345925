import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";



@Component({
  selector: 'app-store-owners',
  templateUrl: './store-owners.component.html',
  styleUrls: ['./store-owners.component.scss']
})
export class StoreOwnersComponent implements OnInit {
 
modalRef:NgbModalRef;
storeaddForm: FormGroup;
group_add_form:FormGroup;
filteruserform: FormGroup;
warehouse_add_form:FormGroup;
filter_store_form:FormGroup;
store_update_form:FormGroup;

completedMarketingTasks: number;
completedSalesTasks: number;
completedCustomerSupportTasks: number;
isBrowser: boolean;
statistics: any;
topSellers: any;
laggingSellers: any;
articles: any;
tasks: any;
users:any;
currentTab:any;
  
datasource = [];
filter_status: any = '';
currentrole:any = 'all';
warehouselist:any;
groupaddlist:any;
add_select_group:any;
add_select_warehouse:any;
stores_list:any;
temp_users_data:any;
temp_warehouses:any;
temp_check_dup_location:any;
store_get_data:any;
temp_user:any

warehouseaddlist:any;
leadsTableDataSource: any;
statuslist:any= [{name: "Client"},{name:"Trial"}];
// dataSource = new UserDataSource(this.userService);
tableColumns  :  string[] = ['id', 'company_name', 'so_name','added_date','branch_count','login','actions'];


 title = 'appBootstrap';

 validationMessages = {
    add_store_name: [
      { type: 'required', message: 'Please enter Store Name' }
    ],
    add_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    add_so_name: [
      { type: 'required', message: 'Please enter Store Owner Name' },
    ],
    update_store_name: [
      { type: 'required', message: 'Please enter Store Name' }
    ],
    update_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    update_so_name: [
      { type: 'required', message: 'Please enter Store Owner Name' },
    ],
    group_name: [
      { type: 'required', message: 'Please enter Group Name' },
    ],
    go_name: [
      { type: 'required', message: 'Please enter Group Owner Name' },
    ],
    go_email: [
      { type: 'required', message: 'Email is required' },
      { type: 'email', message: 'Please enter a valid email' },
    ],
    warehouse_name: [
      { type: 'required', message: 'Warehouse name is required' }
    ]
  };
  closeResult: string;
  @ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
  @ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
  @ViewChild('groupModal') groupModal;
  @ViewChild('warehouseModal') warehouseModal;
  @ViewChild('mymodal') mymodal;
  @ViewChild('closebtn', {static: true}) closebtn:ElementRef;
    @ViewChild('storeupdateModal') storeupdateModal;




  constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	 public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);

		this.filter_store_form = new FormGroup({
			warehouse_id: new FormControl(0),
			store_id: new FormControl(),
    	});

		this.storeaddForm = new FormGroup({
			add_store_name: new FormControl(null, Validators.compose([Validators.required])),
			add_so_name: new FormControl(null, Validators.compose([Validators.required])),
			add_group_id: new FormControl(),
			add_warehouse_id: new FormControl(),
			add_store_id: new FormControl(),
			add_email: new FormControl( null,Validators.compose([Validators.required,Validators.email])),
			add_phone: new FormControl(),
			add_status: new FormControl(),
    	});

    	this.store_update_form = new FormGroup({
    		update_uid: new FormControl(),
			update_store_name: new FormControl(null, Validators.compose([Validators.required])),
			update_so_name: new FormControl(null, Validators.compose([Validators.required])),
			update_group_id: new FormControl(),
			update_warehouse_id: new FormControl(),
			update_store_id: new FormControl(),
			update_email: new FormControl( null,Validators.compose([Validators.required,Validators.email])),
			update_phone: new FormControl(),
			update_status: new FormControl(),
    	});

    	this.group_add_form = new FormGroup({
			group_name: new FormControl(null, Validators.compose([Validators.required])),
			go_name: new FormControl(null, Validators.compose([Validators.required])),
			go_email: new FormControl(null, Validators.compose([Validators.required,Validators.email])),
			go_phone: new FormControl(),
    	});

    	this.warehouse_add_form = new FormGroup({
			warehouse_name: new FormControl(null, Validators.compose([Validators.required])),
    	});

   }

  ngOnInit() {

	this.afs.collection('/stores',ref=>ref.where('type','==', 'main_store').orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.stores_list = data.map(e => {
			return{
				uid: e.payload.doc.id,
				company_name: e.payload.doc.data()['company_name'],
				email: e.payload.doc.data()['email'],
				group_id: e.payload.doc.data()['group_id'],
				group_name: e.payload.doc.data()['group_name'],
				phone: e.payload.doc.data()['phone'],
				store_id: e.payload.doc.data()['store_id'],
				store_owner_name: e.payload.doc.data()['store_owner_name'],
				warehouse_id: e.payload.doc.data()['warehouse_id'],
				warehouse_name: e.payload.doc.data()['warehouse_name'],
				created_at:e.payload.doc.data()['created_at'],
				can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				status:e.payload.doc.data()['status'],
				user_id:e.payload.doc.data()['user_id'],
				branch_count: e.payload.doc.data()['branch_count']
			};
		});	



		this.stores_list = new MatTableDataSource(this.stores_list);
		this.stores_list.sort = this.leadsSort;
		this.stores_list.paginator = this.leadsPaginator;
	});
 

	this.afs.collection('/warehouses').snapshotChanges().subscribe(data => {
		this.warehouselist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				warehouse_name: e.payload.doc.data()['warehouse_name'],
			};
		});	
	});

	this.afs.collection('/groups').snapshotChanges().subscribe(data => {
		this.groupaddlist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				group_name: e.payload.doc.data()['group_name'],
			};
		});
	});
	

   
	// define a custom sort for the last_contact field
	// this.leadsTableDataSource.sortingDataAccessor = (item, property) => {
	//   switch (property) {
	//     case 'last_contact': return new Date(item.last_contact);
	//     default: return item[property];
	//   }
	// };
	// this.leadsTableDataSource.sort = this.leadsSort;
	// this.leadsTableDataSource.paginator = this.leadsPaginator;
  }

  clear_filter()
  {
  	this.filter_store_form.reset();
  	this.filter_store_form.patchValue({
	 	warehouse_id: 0,
	});
  	this.ngOnInit();
  }

  filter_store(value)
  {
  	console.log(value);
  	if(value.store_id == null && value.warehouse_id == null)
  	{
  		this._snackBar.open("Error: No parameters to filter", '', {
	      duration: 3000,
	    });
	    return false;
  	}
  	if(value.warehouse_id == 0 && value.store_id != "" && value.store_id != null)
  	{
  		//ONLY FILTER BY STORE ID
  		console.log('only filtering by storeid');
  		this.stores_list.filter = value.store_id.trim().toLocaleLowerCase();
  	} 
  	else if(value.warehouse_id != "" && value.store_id != "" && value.warehouse_id != null && value.store_id != null )
  	{
  		//FILTER BY BOTH WAREHOUSE ID AND STORE ID
  		console.log('filter by both');
  		this.afs.collection('/stores',ref=>ref.where('type','==','main_store').where('warehouse','==',value.warehouse_id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.stores_list = data.map(e => {
			return{
				uid: e.payload.doc.id,
				company_name: e.payload.doc.data()['company_name'],
				email: e.payload.doc.data()['email'],
				group_id: e.payload.doc.data()['group_id'],
				group_name: e.payload.doc.data()['group_name'],
				phone: e.payload.doc.data()['phone'],
				store_id: e.payload.doc.data()['store_id'],
				store_owner_name: e.payload.doc.data()['store_owner_name'],
				warehouse_id: e.payload.doc.data()['warehouse_id'],
				warehouse_name: e.payload.doc.data()['warehouse_name'],
				created_at:e.payload.doc.data()['created_at'],
				can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				status:e.payload.doc.data()['status'],
				user_id:e.payload.doc.data()['user_id'],
				branch_count: e.payload.doc.data()['branch_count']
			};
		});	

			this.stores_list = new MatTableDataSource(this.stores_list);
			this.stores_list.sort = this.leadsSort;
			this.stores_list.paginator = this.leadsPaginator;

			this.stores_list.filter = value.store_id.trim().toLocaleLowerCase();
		});
  	}
  	else if(value.warehouse_id != 0 && value.warehouse_id != null)
  	{
  		//FILTER BY  WAREHOUSE ID 
  		console.log('only filter by warehouse id');
  		this.afs.collection('/stores',ref=>ref.where('type','==','main_store').where('warehouse','==',value.warehouse_id).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.stores_list = data.map(e => {
			return{
				uid: e.payload.doc.id,
				company_name: e.payload.doc.data()['company_name'],
				email: e.payload.doc.data()['email'],
				group_id: e.payload.doc.data()['group_id'],
				group_name: e.payload.doc.data()['group_name'],
				phone: e.payload.doc.data()['phone'],
				store_id: e.payload.doc.data()['store_id'],
				store_owner_name: e.payload.doc.data()['store_owner_name'],
				warehouse_id: e.payload.doc.data()['warehouse_id'],
				warehouse_name: e.payload.doc.data()['warehouse_name'],
				created_at:e.payload.doc.data()['created_at'],
				can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				status:e.payload.doc.data()['status'],
				user_id:e.payload.doc.data()['user_id'],
				branch_count: e.payload.doc.data()['branch_count']
			};
		});	

			this.stores_list = new MatTableDataSource(this.stores_list);
			this.stores_list.sort = this.leadsSort;
			this.stores_list.paginator = this.leadsPaginator;
		});

  	} else{
  		console.log('filter by else');
  		this.ngOnInit();
  	}
  	this._snackBar.open("Filter applied successfully", '', {
	      duration: 1000,
	    });
  	
  }


	store_add(value) {
  		if( this.storeaddForm.valid){

	  		let chkloc =this.afs.collection('/stores',ref=>ref.where('warehouse_id','==',value.add_warehouse_id).where('store_id','==',value.add_store_id)).snapshotChanges().subscribe(data => {
				this.temp_check_dup_location = data.map(e => {
					return{
						uid: e.payload.doc.id,
					};
				});	
				
				if(this.temp_check_dup_location && this.temp_check_dup_location.length > 0)
				{
					this._snackBar.open("Error: There is already a store at this location.", '', {
					duration: 3000,
					});
					chkloc.unsubscribe();
					return false;
				} 
				else
				{
					console.log(value);
					let storedata = {
						email: value.add_email?value.add_email:"",
						group_id: value.add_group_id?value.add_group_id:"",
						group_name: this.add_select_group?.group_name?this.add_select_group?.group_name:"",
						phone: value.add_phone?value.add_phone:"",
						store_owner_name: value.add_so_name?value.add_so_name:"",
						store_id: value.add_store_id?value.add_store_id:"",
						store_name: value.add_store_name?value.add_store_name:"",
						warehouse_id: value.add_warehouse_id?value.add_warehouse_id:"",
						warehouse_name: this.add_select_warehouse?.name?this.add_select_warehouse?.name:"",
						created_at: new Date(),
						status: value.add_status?value.add_status:"Client"
					};

					this.afAuth.createUserWithEmailAndPassword(value.add_email, "123456")
					.then((result) => {
						/* Call the SendVerificaitonMail() function when new user sign
						up and returns promise */
						console.log('user id',result.user.uid);
						console.log(result);
						let userdata = {
							email: value.add_email?value.add_email:"",
							displayName: value.add_so_name?value.add_so_name:"",
							uid: result.user.uid,
							role: "store",
							created_at: new Date(),
							can_login: '1',
						};

						let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
						});

						storedata['user_id'] = result.user.uid;
						let storeadd = this.afs.collection('/stores').add(storedata).then(docRef => {
							const itemRefx= this.afs.doc('/stores/'+docRef.id);
							itemRefx.update({ uid: docRef.id}); 
							const itemRefx2= this.afs.doc('/users/'+result.user.uid);
							itemRefx2.update({ store_uid: docRef.id}); 
						});

						this.ForgotPassword(value.add_email);

						let element: HTMLElement = document.getElementsByClassName('close_add_store_modal')[0] as HTMLElement;
							element.click();
						this._snackBar.open("Store added successfully", '', {
							duration: 3000,
						});

						this.storeaddForm.reset();

					}).catch((error) => {
						this._snackBar.open("Error: "+error.message, '', {
							duration: 3000,
						});
					});

				}
				
				// this._snackBar.open("Store details updated successfully", '', {
				// duration: 2000,
				// });
				chkloc.unsubscribe();
			});
		}
  	}

  store_update(value)
  {
  	console.log(value);
 	if( this.store_update_form.valid){
 	 	let temp_get_store_data = this.afs.collection('/stores',ref=>ref.where('uid',"==",value.update_uid)).snapshotChanges().subscribe(data => {
			this.store_get_data = data.map(e => {
				return{
					uid: e.payload.doc.id,
				store_name: e.payload.doc.data()['store_name'],
				email: e.payload.doc.data()['email'],
				group_id: e.payload.doc.data()['group_id'],
				group_name: e.payload.doc.data()['group_name'],
				phone: e.payload.doc.data()['phone'],
				store_id: e.payload.doc.data()['store_id'],
				store_owner_name: e.payload.doc.data()['store_owner_name'],
				warehouse_id: e.payload.doc.data()['warehouse_id'],
				warehouse_name: e.payload.doc.data()['warehouse_name'],
				created_at:e.payload.doc.data()['created_at'],
				can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
				status:e.payload.doc.data()['status'],
				user_id:e.payload.doc.data()['user_id']
					
				};
			});	

			let chkloc =this.afs.collection('/stores',ref=>ref.where('warehouse_id','==',value.update_warehouse_id).where('store_id','==',value.update_store_id).where('uid','!=',value.update_uid)).snapshotChanges().subscribe(data => {
				this.temp_check_dup_location = data.map(e => {
					return{
						uid: e.payload.doc.id,
					};
				});	
				if(this.temp_check_dup_location && this.temp_check_dup_location.length > 0)
				{
					this._snackBar.open("Error: There is already a store at this location.", '', {
						duration: 3000,
					});
					chkloc.unsubscribe();
					return false;
				} 
				else 
				{
					console.log(value);
					let groupdata = this.groupaddlist.find(i => i.uid === value.update_group_id);
					let warehousedata = this.warehouselist.find(i => i.uid === value.update_warehouse_id);

					let storedata = {
						group_id: value.update_group_id?value.update_group_id:"",
						phone: value.update_phone?value.update_phone:"",
						store_owner_name: value.update_so_name?value.update_so_name:"",
						store_id: value.update_store_id?value.update_store_id:"",
						store_name: value.update_store_name?value.update_store_name:"",
						warehouse_id: value.update_warehouse_id?value.update_warehouse_id:"",
						warehouse_name: warehousedata?.name?warehousedata?.name:"",
						group_name: groupdata?.group_name?groupdata?.group_name:"",
						updated_at: new Date(),
						status: value.update_status?value.update_status:"Client"
					};

					let storeupdate = this.afs.doc('/stores/'+value.update_uid).set(storedata,{merge:true}).then(docRef => {
					});

					if(this.store_get_data[0]?.store_owner_name != value.update_so_name)
					{
						let storeupdate = this.afs.doc('/users/'+this.store_get_data[0]?.user_id).set({displayName: value.update_so_name},{merge:true}).then(docRef => {
						});
					}

					if(this.store_get_data[0]?.email != value.update_email)
					{
						console.log('LOOKS LIKE EMAIL VALUE UPDATE IN STORE');
						//looks like email has changed
						this.afAuth.createUserWithEmailAndPassword(value.update_email, "123456")
						.then((result) => {
								/* Call the SendVerificaitonMail() function when new user sign
								up and returns promise */
							console.log('user id',result.user.uid);
							console.log(result);

							let userdata = {
								email: value.update_email?value.update_email:"",
								displayName: value.update_so_name?value.update_so_name:"",
								uid: result.user.uid,
								role: "store",
								created_at: new Date(),
								can_login: '1',
								store_uid:value.update_uid
							};

							let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
							});


							let storeupdate = this.afs.doc('/stores/'+value.update_uid).set({user_id: result.user.uid,email:value.update_email},{merge:true}).then(docRef => {
							});

							//DELETE PREVIOUS STORE EMAIL HERE

							this.ForgotPassword(value.update_email);
							temp_get_store_data.unsubscribe();
							let element: HTMLElement = document.getElementsByClassName('close_store_update_modal')[0] as HTMLElement;
							element.click();

							this._snackBar.open("Store details updated successfully", '', {
								duration: 2000,
							});

							this.store_update_form.reset();


						}).catch((error) => {
							
							this._snackBar.open("Error: "+error.message, '', {
								duration: 3000,
							});

							//this.open(this.storeupdateModal,'modal-basic-title4');

						});
					} else{
						let element: HTMLElement = document.getElementsByClassName('close_store_update_modal')[0] as HTMLElement;
							element.click();
						this._snackBar.open("Store details updated successfully", '', {
								duration: 2000,
							});
						this.store_update_form.reset();
					}
					temp_get_store_data.unsubscribe();
					chkloc.unsubscribe();
					// this._snackBar.open("Store details updated successfully", '', {
			  //     		duration: 2000,
			  //   	});
				}
	
			});
			temp_get_store_data.unsubscribe();
		});
  	}
  }

  open_update_modal(data)
  {
  	console.log('editing uid --',data);
  	this.store_update_form.patchValue({
	 	update_uid: data.uid,
	 	update_store_name: data.store_name,
		update_so_name:data.store_owner_name,
		update_group_id: data.group_id,
		update_warehouse_id: data.warehouse_id,
		update_store_id: data.store_id,
		update_email: data.email,
		update_phone: data.phone,
		update_status: data.status,

	});
  	  this.open(this.storeupdateModal,'modal-basic-title4');
  }


  change_login_status(uid,user_id,status)
  {
	this._snackBar.open('Status updated successfully', '', {
		duration: 2000,
	});
	console.log('uid',uid);
	console.log('status',status);
	console.log('store user id ',user_id);

	if(status === '1'){
		console.log('in1');
		let upd_data = {
			can_login: '0'
		};
		let stores = this.afs.doc('/stores/'+uid).set(upd_data,{merge:true}).then(docRef => {
		});
		
			
		let userup = this.afs.doc('/users/'+user_id).set(upd_data,{merge:true}).then(docRef => {
		});

	}

	if(status === '0'){
		console.log('in2');
		let upd_data = {
		can_login: '1'
		};
		let aa = this.afs.doc('/stores/'+uid).set(upd_data,{merge:true}).then(docRef => {
		});
			
		let userupd = this.afs.doc('/users/'+user_id).set(upd_data,{merge:true}).then(docRef => {
		});
	}

   }

   view_branches(store_uid)
   {
   			this.router.navigate(['/admin/store-branch-view/'+ store_uid ]);
   }

	ForgotPassword(passwordResetEmail) {
    return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
    .then(() => {
     // window.alert('Password reset email sent, check your inbox.');
    }).catch((error) => {
      //window.alert(error)
    })
  }
  group_add(value)
  {
  	if(this.group_add_form.valid)
  	{

  		console.log(value);

	  	let groupData = {
	  		group_name:value.group_name,
	  		group_owner_name: value.go_name,
	  		email: value.go_email,
	  		phone: value.go_phone,
	  		created_at: new Date()
	  	};

	  	


  		this.afAuth.createUserWithEmailAndPassword(value.go_email, "123456")
	      .then((result) => {
	        /* Call the SendVerificaitonMail() function when new user sign
	        up and returns promise */
	        console.log('user id',result.user.uid);
	        console.log(result);

	        let userdata = {
		   		email: value.go_email?value.go_email:"",
				displayName: value.go_name?value.go_name:"",
				uid: result.user.uid,
				role: "group",
				created_at: new Date()
			};

		  	let useradd = this.afs.doc('/users/'+result.user.uid).set(userdata,{merge:true}).then(docRef => {
		  	});

		  	this._snackBar.open("Creating Group...Please wait...", '', {
		    	duration: 2000,
		    });

		  	groupData['user_id'] = result.user.uid;
		  	let groupadd = this.afs.collection('/groups').add(groupData).then(docRef => {
		  		const itemRefx= this.afs.doc('/groups/'+docRef.id);
		        itemRefx.update({ uid: docRef.id,user_id: result.user.uid});
		        this.storeaddForm.patchValue({
		   	 		add_group_id: docRef.id
		  		});
		  		this.store_update_form.patchValue({
		   	 		update_group_id: docRef.id
		  		});
		  		this.add_select_group = groupData;
		  	});

	        this.ForgotPassword(value.go_email);

	      }).catch((error) => {
		       	this._snackBar.open("Error: "+error.message, '', {
			      duration: 3000,
			    });
	      });


  	}
  	


  }
  warehouse_add(value)
  {
  	console.log(value);
  	if(this.warehouse_add_form.valid)
  	{
  		let theware = this.afs.collection('/warehouses/',ref=> ref.where('name','==',value.warehouse_name)).snapshotChanges().subscribe(data => {
		this.temp_warehouses = data.map(e => {
				return{
					uid: e.payload.doc.id,
				};
			});	
			if(this.temp_warehouses.length > 0)
			{
				this._snackBar.open("Error: This Warehouse already exists!", '', {
			      duration: 2000,
			    });
			    theware.unsubscribe();
			    return false;
			} else {
				theware.unsubscribe();
				let warehouseData = {
			  		name:value.warehouse_name,
			  		created_at: new Date()
			  	};
			  	this._snackBar.open("Creating Warehouse...Please wait...", '', {
			      duration: 2000,
			    });
			  	let add_warehouse = this.afs.collection('/warehouses').add(warehouseData).then(docRef => {
			  		const itemRefx= this.afs.doc('/warehouses/'+docRef.id);
			        itemRefx.update({ uid: docRef.id});
			        this.storeaddForm.patchValue({
			   	 		add_warehouse_id: docRef.id
			  		});
			  		this.store_update_form.patchValue({
			   	 		update_warehouse_id: docRef.id
			  		});
			  		this.add_select_warehouse = warehouseData;
			  	});

			}
		});
  	}
  	
  }

  addStoreOwner()
  {

  }

  delete_warehouse(uid)
  {
  	let del_warehouse = this.afs.doc('/warehouses/'+uid).delete();
  	this._snackBar.open('Warehouse deleted successfully', '', {
      duration: 2000,
    });
  }


  open(content,title) {
   	this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  showtab(tabname){
	
	this.authService.getUsersByRole(tabname).subscribe(data => {
	  this.users = data.map(e => {
		return {
		  uid: e.payload.doc.id,
		  email:e.payload.doc.data()['email'],
		  name:e.payload.doc.data()['displayName'],
		  role:e.payload.doc.data()['role'],
		  emailVerified:e.payload.doc.data()['emailVerified'],
		 	photo:e.payload.doc.data()['photoURL'],
		  joineddate:e.payload.doc.data()['joineddate'],
		  lastlogindate:e.payload.doc.data()['lastlogindate'],
		  status:e.payload.doc.data()['status']
		};
	  })
	  this.users = new MatTableDataSource(this.users);
		this.users.sort = this.leadsSort;
		   this.users.paginator = this.leadsPaginator;
	});
	this.currentTab = tabname;
	
  }

  	changestatus(uid,status)
  	{
	  	this._snackBar.open('Status updated successfully', '', {
	      duration: 2000,
	    });
	    console.log('uid',uid);
	    console.log('status',status);
	    if(status == '1'){
	      	console.log('in1');
	       	let productdata = {
	        	status: '0'
	      	};

	      	let aa = this.afs.doc('/users/'+uid).set(productdata,{merge:true}).then(docRef => {
	  		});
	    }
	    if(status == '0'){
	       	let productdata = {
	        	status: '1'
	      	};
	      	let aa = this.afs.doc('/users/'+uid).set(productdata,{merge:true}).then(docRef => {
	  		});
	    }
	    if(status == ''){
	    	console.log('in blank');
	       	let productdata = {
	        	status: '0'
	      	};
	      	let aa = this.afs.doc('/users/'+uid).set(productdata,{merge:true}).then(docRef => {
	  		});
	    }
	    if(status == undefined){
	    	console.log('inundef');
	       	let productdata = {
	        	status: '1'
	      	};
	      	let aa = this.afs.doc('/users/'+uid).set(productdata,{merge:true}).then(docRef => {
	  		});
		}
  }

  blockUser(id)
  {
  	if(confirm('Are you sure you want to block the user?')){
  		let currentTimestamp = new Date().valueOf();
		const userData = {
			status: '0',
			updated_timestamp: currentTimestamp,
			user_deactivated_at: currentTimestamp,
			updated_at: new Date(),
		}

		let aa = this.afs.doc('/users/'+id).set(userData,{merge:true}).then(docRef => {
			//console.log(docRef);
			alert('user deactivated successfully');
		});
  	}
	
  }

  addUser(){
	console.log('user-add');
	this.router.navigate(['/user-add']);

  }

  addNewStore()
  {
  	this.router.navigate(['/admin/add-new-store']);
  }

  filterUser(data){

    //console.log(data);
    console.log('filter status',data.filter_status);
    if(data.filter_status !=""){
    	console.log(this.currentTab);
    	if(this.currentTab != "all"){
    		console.log('tab all - status',data.filter_status);
		      	this.afs.collection('/users', ref => ref.where("status","==",data.filter_status).where("role","==",this.currentTab).orderBy('created_timestamp','desc')).snapshotChanges().subscribe(data => {
			        this.users = data.map(e => {
						return {
								uid: e.payload.doc.id,
								email:e.payload.doc.data()['email'],
								name:e.payload.doc.data()['displayName'],
								role:e.payload.doc.data()['role'],
								emailVerified:e.payload.doc.data()['emailVerified'],
								photo:e.payload.doc.data()['photoURL'],
								joineddate:e.payload.doc.data()['joineddate'],
								lastlogindate:e.payload.doc.data()['lastlogindate'],
								status: e.payload.doc.data()['status']
						  	};
						});
			        	//console.log(this.plans);
				        this.users = new MatTableDataSource(this.users);
				        this.users.sort = this.leadsSort;
				        this.users.paginator = this.leadsPaginator;
			        });
		} else {
			console.log('tab',this.currentTab);
			this.afs.collection('/users', ref => ref.where("status","==",data.filter_status).orderBy('created_timestamp','desc')).snapshotChanges().subscribe(data => {
		        this.users = data.map(e => {
					return {
						  uid: e.payload.doc.id,
						  email:e.payload.doc.data()['email'],
						  name:e.payload.doc.data()['displayName'],
						  role:e.payload.doc.data()['role'],
						  emailVerified:e.payload.doc.data()['emailVerified'],
						  photo:e.payload.doc.data()['photoURL'],
						  joineddate:e.payload.doc.data()['joineddate'],
						  lastlogindate:e.payload.doc.data()['lastlogindate'],
						  status: e.payload.doc.data()['status']
					  };
					});
		        	//console.log(this.plans);
			        this.users = new MatTableDataSource(this.users);
			        this.users.sort = this.leadsSort;
			        this.users.paginator = this.leadsPaginator;
		    });

		}
    	} else {
    		this.ngOnInit();
    	}
	}

  	clearFilter(){
    	console.log('in clear filter');
		this.filter_status = "";
		this.ngOnInit();
  	}

  	add_select_warehouse_change(event)
  	{

  		console.log(event);
  		if(event == 0)
  		{
  			this.open(this.warehouseModal,'modal-basic-title3');
  			this.storeaddForm.patchValue({
		   	 add_warehouse_id: null
		 	 });
  			this.store_update_form.patchValue({
		   	 update_warehouse_id: null
		 	 });
  		} else {
  			let warehousedata = this.warehouselist.find(i => i.uid === event);
  			console.log(warehousedata);
  			this.add_select_warehouse = warehousedata;
  		}
  	}

  	add_select_group_change(event)
  	{
  // 		let element: HTMLElement = document.getElementsByClassName('close_add_store_modal')[0] as HTMLElement;
		// element.click();
  		//this.modalService.dismissAll("sdsd");
  		if(event == 0)
  		{
  			this.open(this.groupModal,'modal-basic-title2');
  			this.storeaddForm.patchValue({
		   	 add_group_id: null
		  	});
		  	this.store_update_form.patchValue({
		   	 update_group_id: null
		  	});
  		} else {
  			let groupdata = this.groupaddlist.find(i => i.uid === event);
  			console.log(groupdata);
  			this.add_select_group = groupdata;

  		}
  		
  	}

  	filter_warehouse_change(event)
	{

	}

	open_store_panel(uid)
	{
		this.afs.collection('/users', ref => ref.where('store_uid', '==',uid).where('role','==','store')).snapshotChanges().subscribe(data => {
			this.temp_user = data.map(e => {
				return{
					uid: e.payload.doc.id,
					can_login:  e.payload.doc.data()['can_login'],
					displayName: e.payload.doc.data()['displayName'],
					email:  e.payload.doc.data()['email'],
					emailVerified: true,
					group_uid:  e.payload.doc.data()['group_uid'],
					photoURL:  e.payload.doc.data()['photoURL'],
					role: e.payload.doc.data()['role'],
					store_uid: e.payload.doc.data()['store_uid']
				};
			});
			console.log("GOT THE USER FOR STORE",this.temp_user[0]);
			localStorage.setItem('current_store_id', JSON.stringify(this.temp_user[0]));
			localStorage.setItem('ghost_mode', 'admin');
			this.router.navigate(['store/dashboard'])
			  .then(() => {
			    window.location.reload();
			  });
				 // this._snackBar.open('Stored changed successfully', '', {
			   //    duration: 3000,
			   //  });
		});
	}

  	
	doFilter = (value: string) => {
	    	this.stores_list.filter = value.trim().toLocaleLowerCase();
	}
  	edit_store(uid)
  	{
  		this.router.navigate(['/admin/store-edit/'+ uid]);
  	}
 
}  
