import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import Shepherd from 'shepherd.js';

@Injectable({
  providedIn: 'root'
})
export class AppRecipeTourService {

  private tour: Shepherd.Tour;
  private recipeTourSeenFlag = 'RecipeTourSeen';
  currentUrl: string;
  stepsID: any;
  constructor(private router: Router) {

    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        },
      },
    });
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.tour.isActive) {
        if (event.url !== this.currentUrl) {
          this.tour.cancel();
        }
        this.currentUrl = event.url;
      }


    });


  }




  private setTourSeenFlag() {
    localStorage.setItem(this.recipeTourSeenFlag, 'true');
  }

  private hasTourBeenSeen() {
    return localStorage.getItem(this.recipeTourSeenFlag) === 'true';
  }


  addTourStep(stepsObject) {
    stepsObject.forEach((step) => {
      this.tour.addStep(
        {
          id: step.id,
          cancelIcon: {
            enabled: true,
          },
          title: '<h3>' + step.title + '</h3>',
          text: '<p>' + step.content + '</p>',
          attachTo: {
            element: '.Coupons',
            on: 'bottom',
          },
          classes: 'example-step-extra-class',
          buttons: this.getButtons(["Cancel", "Next"]),
        }

      );
    });
    this.tour.start();
    stepsObject.forEach((step) => {
      this.tour.removeStep(step.id);
    })
  }

  startTour() {
    if (!this.hasTourBeenSeen()) {
      this.tour.addSteps([
        {
          id: 'show-tour-action',
          title: '<h3>Welcome to the Recipes Page</h3>',
          text: '<p>This page is loaded with recipes for Breakfast, Lunch, and Dinner. You can click on recipes and use the Plus Button to add the ingredients to your shopping list.</p>',
          attachTo: {
            element: '',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
            },
          },
          buttons: [
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'START TOUR',
              action: this.tour.next
            }

          ]
        },
        {
          id: 'step-1',
          title: '<h3>Search Recipes by Name:</h3>',
          text: '<p>Find recipes by searching the recipe name here.</p>',
          attachTo: {
            element: '.serach-recipe-by-name',
            on: 'bottom'
          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.serach-recipe-by-name');
              if (!element) {
                if (previousId === 'show-tour-action') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },


        {
          id: 'step-2',
          title: '<h3>Search Recipes by Ingredients</h3>',
          text: '<p>Find recipes by searching for ingredients here. </p>',
          attachTo: {
            element: '.serach-recipe-by-ingredient-name',
            on: 'bottom'

          },
          classes: 'example-step-extra-class',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.serach-recipe-by-ingredient-name');
              if (!element) {
                if (previousId === 'step-1') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },

        {
          id: 'step-3',
          title: '<h3>Recipe Categories</h3>',
          text: '<p>Click on a category to find recipes that match your chosen category.</p>',
          attachTo: {
            element: '.filter_class',
            on: 'bottom'

          },
          classes: 'recipe-categories',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.filter_class');
              if (!element) {
                if (previousId === 'step-2') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }

          ]
        },
        {
          id: 'step-4',
          title: '<h3>Recipes</h3>',
          text: '<p>Click on any recipe photo to get recipe details and ingredients you can add to your shopping list.</p>',
          attachTo: {
            element: '.recipe-img',
            on: 'bottom'

          },
          classes: 'recipe-categories',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.recipe-img');
              console.log(element);
              if (!element) {
                if (previousId === 'step-3') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },

        
        {
          id: 'step-5',
          title: '<h3>The Help Icon</h3>',
          text: '<p>Press this icon to open this tutorial anytime.  </p>',
          attachTo: {
            element: '.Help',
            on: 'bottom'
          },
          classes: 'recipe-categories',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.Help');
              if (!element) {
                if (previousId === 'step-4') {
                  this.tour.complete();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'FINISH',
              action: () => {
                this.tour.complete()
              }
            }
          ]
        },
      ]);



      this.tour.start();

      this.setTourSeenFlag();

    }
  }

  cancelTour() {
    this.tour.cancel();
  }

  private getButtons(labels: string[]): Shepherd.Step.StepOptionsButton[] {
    return labels.map((label) => ({
      action: this.tour[label.toLowerCase()],
      classes: label.toLowerCase() + "-button",
      text: label,
    }));
  }
}
