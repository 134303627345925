import { Component, PLATFORM_ID, Inject, ViewChild, OnInit, HostListener, ChangeDetectorRef } from '@angular/core';
import { AuthService } from 'src/app/shared/services/auth.service';
import { Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { FormBuilder } from '@angular/forms';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { HttpClient } from '@angular/common/http';
declare var $: any;
declare var FB: any;
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { GridStack } from 'gridstack';
import e from 'express';
import { ProductDialog5ComponentComponent } from './product-dialog-component/product-dialog5-component.component';
import { ProductDialog6ComponentComponent } from './modal/product-dialog-component/product-dialog6-component.component';
import html2canvas from 'html2canvas';
import { HeaderComponent } from 'src/app/shared/header/header.component';
import { PwaService } from 'src/app/shared/services/pwa.service';
import { AppTourService } from 'src/app/shared/services/app-tour.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
// import '@tensorflow/tfjs-backend-wasm'; // Register WASM backend for broader compatibility
import { Subscription } from 'rxjs';
import { getMessaging } from "firebase/messaging";
import { NotificationPermissionService } from 'src/app/shared/services/notification-permission.service';
@Component({
  selector: 'app-edit-store-product',
  templateUrl: './edit-store-product.component.html',
  styleUrl: './edit-store-product.component.scss'
})
export class EditStoreProductComponent implements OnInit {
	@ViewChild(HeaderComponent, { static: false }) headerComponent: HeaderComponent;
	coupons_list: any = []
	name = 'Jquery Integration With Angular!';
	layout_cat: any = [];
	isJqueryWorking: any;
	store_name_slug: any;
	categories_list: any = [];
	branch_list: any = [];
	store_data: any;
	current_store_uid: any;
	current_layout_data: any;
	iframe_src: any;
	isDisplay = false;
	textColor = 'white';
	isPreview = ''
	display_social_icons = { "facebook": false, "instagram": false, "twitter": false, "youtube": false }
	serializedData = []
	serializedIds = []
	grid: any
	cartItem = []
	cart_ids = []
	selectedProdct = []
	selected_cart_items = []
	isAllSelected = false
	show_add_item = false
	all_items = []
	filtered_items = []
	no_content_display = false
	current_list_id = ''
	current_list_data: any = {}
	counter_img = ''
	show_loader = false
	is_list_view = false
	display_print_content = false
	qr_elementType: 'url' | 'canvas' | 'img' = 'url';
	qr_value = ''
	store_id: any;
	store_category: any;
	printCart = false;
	store_logo: any;
	old_localstorage_last_publish_date: any;
	old_localstorage_uid: any;
	layout_change: any;
	circular_update_flag: any = false;
	circular_update_flag_inside: any = false;
	store_item: any;
	past_item: any;

	emailForm = this.formBuilder.group({
		email: ''
	});
	layout_data: any;
	show_dynamic_header: any = '';
	show_loader1: boolean;
	pro_dailog: boolean;
	list: any;
	packName: any;
	categoryLoad: boolean;
	packDetails: any[];
	matching_items: any;
	currentListData: any;
	createdCart: boolean;
	private myVideoPlayer: any;
	cateGoryLoad: boolean;
	displayCircularPage: boolean;
	current_bundle_data: any;
	serializedBundleData: any =[];
	serializedMenuData: any =[];
	combinedData: any=[];
	current_menu_data: any;
	loadPage: string;
	image1: string ='';
	image: string ='';
	tabLoader: boolean;
	isVideo: boolean;
	showImageCapture: boolean;
	upload_image: boolean;
	visualizationImage: any ='';
	video: string ='';
	ref: import("@angular/fire/compat/storage").AngularFireStorageReference;
	task: import("@angular/fire/compat/storage").AngularFireUploadTask;
	targetFile: Blob | File;
	finalImage: string;
	bounding_box_image: any;
	message: any;
	LoggedIn: string ='no';
	private authSubscription: Subscription;
	isSubscribed: boolean =true;
	constructor(@Inject(PLATFORM_ID)
	private platformId: object,
		public sanitizer: DomSanitizer,
		private route: ActivatedRoute,
		private router: Router,
		private formBuilder: FormBuilder,
		public authService: AuthService,
		public afs: AngularFirestore,
		public afAuth: AngularFireAuth,
		private afStorage: AngularFireStorage,
		private modalService: NgbModal,
		private storage: AngularFireStorage,
		private _snackBar: MatSnackBar,
		private http: HttpClient,
		public dialog: MatDialog,
		private cdr: ChangeDetectorRef,
		private fns: AngularFireFunctions,
		private pwaService: PwaService,
		private apptourService: AppTourService,
		private notificationPermission: NotificationPermissionService,
		private analytics : AngularFireAnalytics,
		@Inject(DOCUMENT) private document,
	) {

	}

	// code for swipe left event (open shopping list)
	private startX: number;
	private startY: number;
	countLeft: number = 0;
	private subscriptions: Subscription[] = [];

	@HostListener('document:mousemove', ['$event'])
	@HostListener('document:touchmove', ['$event'])
	onTouchMove(event: MouseEvent | TouchEvent) {
		if (document.documentElement.clientWidth <= 767) {
			if (this.startX === null || this.startY === null) {
				return;
			}
			const currentX = this.getCurrentX(event);
			const currentY = this.getCurrentY(event);
			const deltaX = currentX - this.startX;
			const deltaY = currentY - this.startY;
			if (Math.abs(deltaX) > 40) {
				const directionX = deltaX > 0 ? 'right' : 'left';
				if (directionX == 'left') {
					this.countLeft = this.countLeft + 1;
				}
				if (this.countLeft > 20) {
					// this.ShowShoppingList()
					this.countLeft = 0;
				}
			}
		}
	}

	zoomOutMobile() {
		var viewport = document.querySelector('meta[name="viewport"]');

		if (viewport) {
			viewport.setAttribute('content', 'initial-scale=0.7, width=280,user-scalable=no,minimum-scale=-9, maximum-scale=1.0');

		}
	}
	zoomOutMobile1() {
		var viewport = document.querySelector('meta[name="viewport"]');
		if (viewport) {
			viewport.setAttribute('content', 'initial-scale=0.5, width=280,user-scalable=no,minimum-scale=-9, maximum-scale=1.0');

		}
	}

	onTouchStart(event: MouseEvent | TouchEvent) {
		this.startX = this.getCurrentX(event);
		this.startY = this.getCurrentY(event);
		this.countLeft = 0;
	}

	onTouchEnd(event: MouseEvent | TouchEvent) {
		this.startX = null;
		this.startY = null;
	}

	private getCurrentX(event: MouseEvent | TouchEvent): number {
		if (event instanceof MouseEvent) {
			return event.clientX;
		} else if (event instanceof TouchEvent) {
			return event.touches[0].clientX;
		}

		return 0;
	}

	private getCurrentY(event: MouseEvent | TouchEvent): number {
		if (event instanceof MouseEvent) {
			return event.clientY;
		} else if (event instanceof TouchEvent) {
			return event.touches[0].clientY;
		}

		return 0;
	}
	async ngOnDestroy() {
		this.isSubscribed = false; // Mark as unsubscribed
		if (this.authSubscription) {
		  await this.authSubscription.unsubscribe();
		}
		this.subscriptions.forEach(subscription => {
			if (subscription) {
				subscription.unsubscribe();
			}
		});
	  }
	
		
	isPWAInstalled() {
		return (window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone) || document.referrer.includes('android-app://');
	}
	async openNotification() {
		if ("Notification" in window) {
			if (Notification.permission === "granted") {
				alert("Notifications are already enabled!");
			} else if (Notification.permission === "denied") {
				if (this.isPWAInstalled()) {
					// If PWA is installed, guide the user to enable notifications via system settings
					alert("Press and hold the app icon and select settings. Then allow notifications. ");
				} else {
					// If PWA is not installed, guide the user to enable via browser settings
					alert("Notifications are blocked. To enable them, click the icon next to the URL in the address bar and allow notifications.");
				}
			} else {
				Notification.requestPermission().then(async (permission) => {
					if (permission === "granted") {
						alert("Notifications enabled!");
						const registration = await navigator.serviceWorker.ready;
						console.log('Service Worker ready');

						const messaging = getMessaging();
						this.notificationPermission.listenForForegroundMessages(messaging);

						const currentToken = await this.notificationPermission.generateToken(messaging, registration);
						if (!currentToken) return;
						this.authSubscription = await this.afAuth.authState.subscribe(async user => {
							if (!this.isSubscribed) return; // Prevent execution if unsubscribed
							if (user) {
								this.LoggedIn = 'yes'
							} else {
								this  .LoggedIn = 'no'
							}
							this.subscriptions[this.subscriptions.length] = await this.afs.collection('/stores', ref => ref.where('uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
								const storeData = data.map(e => {
									return {
										uid: e.payload.doc.id,
										access_coupon: e.payload.doc.data().access_coupon,
										access_layout: e.payload.doc.data().access_layout,
										access_recipe: e.payload.doc.data().access_recipe,
										company_name_slug: e.payload.doc.data().company_name_slug,
										company_name: e.payload.doc.data().company_name,
										branch_name_slug: e.payload.doc.data().branch_name_slug,
									};
								});
								this.notificationPermission.handleTokenStorage(this.store_data[0].company_name, storeData[0].uid, this.LoggedIn, currentToken);
							});
						});
					} else {
						alert("Notifications denied.");
					}
				});
			}
		} else {
			alert("Your browser does not support notifications.");
		}
	}
	  
	// Call the function
	async ngOnInit() {
		(window as any).openNotification = this.openNotification.bind(this);
		if ('serviceWorker' in navigator) {
			navigator.serviceWorker.register('/firebase-messaging-sw.js')
				.then(registration => {
					console.log('Service Worker registered with scope');
				})
				.catch(err => {
					console.error('Service Worker registration failed:', err);
				});
		}
		localStorage.setItem('loadPage', 'circular');
		const storedImage = localStorage.getItem('uploadedImage');
		const storedProcessImage = localStorage.getItem('uploadedProcessImage');
		const storedProcessVideo = localStorage.getItem('uploadedProcessVideo');
         if (storedImage) {
           this.image = storedImage;
         }else{
			this.image = '';
		 }
		 if (storedProcessImage) {
			this.visualizationImage = storedProcessImage;
		  }else{
			 this.visualizationImage = '';
		  }
		  if (storedProcessVideo) {
			this.video = storedProcessVideo;
			this.image = '';
			// this.image1 = '';
		  }else{
			 this.video = '';
		  }
		if (window.innerWidth >= 768) {
			localStorage.setItem("view", "normalView")
		}
		if (window.innerWidth <= 280 && window.innerWidth >= 200) {
			this.zoomOutMobile()
		}
		if (window.innerWidth <= 199) {
			this.zoomOutMobile1()
		}
		if (localStorage.getItem('showAppList') == 'true') {
			if (window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://')) {
				window.location.href = localStorage.getItem('appRedirectUrl');
				localStorage.setItem('showAppList', 'false');
				localStorage.removeItem('appRedirectUrl');
			} else {
			}
		} else {
			if (!(window.matchMedia('(display-mode: standalone)').matches || window.navigator['standalone'] || document.referrer.includes('android-app://'))) {
				this.route.data.subscribe(data => {
					if (data.showAppList === true) {
						localStorage.setItem('showAppList', 'true');
						localStorage.setItem('appRedirectUrl', window.location.href);
					} else {
						localStorage.setItem('showAppList', 'false');
						localStorage.removeItem('appRedirectUrl');
					}
				})
			}
		}

		if (this.route.snapshot.params?.list_id) {
			this.current_list_id = this.route.snapshot.params?.list_id
			this.is_list_view = true
		} else {
			if (localStorage.getItem('list_ids')) {
				const list_ids = JSON.parse(localStorage.getItem('list_ids'))
				if (list_ids.hasOwnProperty(this.route.snapshot.params.store_name)) {
					const value = list_ids[this.route.snapshot.params.store_name];
					localStorage.setItem('list_id', value)
					this.current_list_id = value
				} else {
					localStorage.removeItem('list_id')
					this.current_list_id = ''
				}
			} else {
				this.current_list_id = localStorage.getItem('list_id') ? localStorage.getItem('list_id') : ''
			}
		}

		const params = new URLSearchParams(window.location.search)
		if (params.has('preview') && params.get('preview') === 'true') {
			this.isPreview = '&preview=true'
		}
		localStorage.setItem('viewMode', this.route.snapshot.params.id);
		this.store_name_slug = this.route.snapshot.params.store_name;
		

		$(document).ready(() => {
			const body = document.body;
			const scrollUp = "scroll-up";
			const scrollDown = "scroll-down";
			let lastScroll = 0;

			window.addEventListener("scroll", () => {
				const currentScroll = window.pageYOffset;
				if (currentScroll <= 200) {
					body.classList.remove(scrollUp);
					body.classList.remove(scrollDown);
					return;
				}

				if (currentScroll > lastScroll && !body.classList.contains(scrollDown)) {
					// down
					body.classList.remove(scrollUp);
					body.classList.add(scrollDown);

				} else if (
					currentScroll < lastScroll &&
					body.classList.contains(scrollDown)
				) {
					// up
					body.classList.remove(scrollDown);
					body.classList.add(scrollUp);

				}
				lastScroll = currentScroll;
			});

			const LocalURL = 'http://localhost:4200';
			const FireBaseURL = 'https://digital-circular-pro-admin.web.app';
			if (document.location.href == FireBaseURL + '/home') {
				$('.circular-menu').addClass('menu-active');
			}
			if (document.location.href == FireBaseURL + '/coupon-list') {
				$('.Coupons').addClass('menu-active');
				$('.bottom-header-section').addClass('d-none');
			}
			if (document.location.href == FireBaseURL + '/recipe-list') {
				$('.Recipes').addClass('menu-active');
				$('.bottom-header-section').addClass('d-none');
			}
			if (this.router.url == '/' + this.store_name_slug) {
				$('.circular-menu').addClass('tab-high');
			}
		});	
		// this.store_data = await this.afs.collection('/stores', ref => ref.where('company_name_slug', '==', this.store_name_slug)).get().toPromise()
		// .then(storeData => {
		// 	return storeData.docs.map(doc => doc.data());
		// });
		await this.afs.collection('stores', ref => ref.where('company_name_slug', '==', this.store_name_slug)).snapshotChanges().subscribe(async (data: any) => {
			this.store_data = await data.map(e => {
			  return {
				uid: e.payload.doc.id,
				access_coupon: e.payload.doc.data()['access_coupon'],
				access_layout: e.payload.doc.data()['access_layout'],
				access_recipe: e.payload.doc.data()['access_recipe'],
				address: e.payload.doc.data()['address'],
				zip_code: e.payload.doc.data()['zip_code'],
				company_name_slug: e.payload.doc.data()['company_name_slug'],
				company_name: e.payload.doc.data()['company_name'],
				email: e.payload.doc.data()['email'],
				menu1_link: e.payload.doc.data()['menu1_link'] ? e.payload.doc.data()['menu1_link'] : "",
				menu1_name: e.payload.doc.data()['menu1_name'],
				menu2_name: e.payload.doc.data()['menu2_name'],
				menu2_link: e.payload.doc.data()['menu2_link'],
				menu3_name: e.payload.doc.data()['menu3_name'],
				menu3_link: e.payload.doc.data()['menu3_link'],
				menu4_name: e.payload.doc.data()['menu4_name'],
				menu4_link: e.payload.doc.data()['menu4_link'],
				menu5_name: e.payload.doc.data()['menu5_name'],
				menu5_link: e.payload.doc.data()['menu5_link'],
				menu6_name: e.payload.doc.data()['menu6_name'],
				menu6_link: e.payload.doc.data()['menu6_link'],
				menu7_name: e.payload.doc.data()['menu7_name'],
				menu7_link: e.payload.doc.data()['menu7_link'],
				menu8_name: e.payload.doc.data()['menu8_name'],
				menu8_link: e.payload.doc.data()['menu8_link'],
				menu9_name: e.payload.doc.data()['menu9_name'],
				menu9_link: e.payload.doc.data()['menu9_link'],
				parent_id: e.payload.doc.data()['parent_id'],
				type: e.payload.doc.data()['type'],
				store_logo: e.payload.doc.data()['store_logo'],
				phone: e.payload.doc.data()['phone'],
				social_facebook_url: e.payload.doc.data()['social_facebook_url'],
				social_twitter_url: e.payload.doc.data()['social_twitter_url'],
				social_instagram_url: e.payload.doc.data()['social_instagram_url'],
				social_youtube_url: e.payload.doc.data()['social_youtube_url'],
				theme_base_color: e.payload.doc.data()['theme_base_color'],
				branch_name_color: e.payload.doc.data().branch_name_color || "#666666",
				theme_text_color: e.payload.doc.data()['theme_text_color'],
				override_noad_layout: e.payload.doc.data().override_noad_layout ? e.payload.doc.data().override_noad_layout : false,
				displayCircularPage: e.payload.doc.data().displayCircularPage ? e.payload.doc.data().displayCircularPage : false,
				displayBundlePage: e.payload.doc.data().displayBundlePage ? e.payload.doc.data().displayBundlePage : false,
				displayMenuPage: e.payload.doc.data().displayMenuPage ? e.payload.doc.data().displayMenuPage : false,
				displayCouponPage: e.payload.doc.data().displayCouponPage ? e.payload.doc.data().displayCouponPage : false,
				displayRecipePage: e.payload.doc.data().displayRecipePage ? e.payload.doc.data().displayRecipePage : false,
			  };
			});
			this.authSubscription=await this.afAuth.authState.subscribe(async user => {
				if (!this.isSubscribed) return; // Prevent execution if unsubscribed
				if (user) {
				  this.LoggedIn ='yes'
				} else {
					this.LoggedIn ='no'
				}
				await this.notificationPermission.requestPermission(this.store_data[0].company_name, this.store_data[0].uid,this.LoggedIn);
			  });

		if(this.store_data[0]?.displayCircularPage){
			this.displayCircularPage = true
			if(this.store_data[0]?.company_name){
				if(this.route.snapshot.params?.list_id){
					document.title = `ShoppingList - ${this.store_data[0]?.company_name}`;
					this.analytics.logEvent('page_view', { page_title:`ShoppingList - ${this.store_data[0]?.company_name}` });	
				}else{					
					document.title = `Circular - ${this.store_data[0]?.company_name}`;
					this.analytics.logEvent('page_view', { page_title:`Circular - ${this.store_data[0]?.company_name}` });
				}
			}	  
		}else{
			this.displayCircularPage = false
			if(this.store_data[0]?.displayBundlePage){
				this.router.navigate(['/' + this.store_name_slug + '/bundles/edit']);
			}else if (this.store_data[0]?.displayMenuPage){
				this.router.navigate(['/' + this.store_name_slug + '/menu/edit']);
			}else if (this.store_data[0]?.displayCouponPage){
				this.router.navigate(['/' + this.store_name_slug + '/coupon']);
			}else if (this.store_data[0]?.displayRecipePage){
				this.router.navigate(['/' + this.store_name_slug + '/recipes']);
			}
		}
		this.textColor = this.store_data[0]?.theme_text_color ? this.store_data && this.store_data[0]?.theme_text_color : 'white';
		if (this.textColor === 'white' || this.textColor === '#ffffff') {
			this.counter_img = "../../../assets//imgs/white-counter.svg"
		} else {
			this.counter_img = '../../../assets//imgs/black-counter.svg'
		}
		this.isDisplay = true;
		if (this.store_data[0].social_facebook_url !== "" && this.store_data[0].social_facebook_url !== undefined) { this.display_social_icons.facebook = true }
		if (this.store_data[0].social_twitter_url !== "" && this.store_data[0].social_twitter_url !== undefined) { this.display_social_icons.twitter = true }
		if (this.store_data[0].social_instagram_url !== "" && this.store_data[0].social_instagram_url !== undefined) { this.display_social_icons.instagram = true }
		if (this.store_data[0].social_youtube_url !== "" && this.store_data[0].social_youtube_url !== undefined) { this.display_social_icons.youtube = true }

		if (this.store_data && this.store_data.length > 0) {
			this.current_store_uid = this.store_data[0]?.uid;

			const start_date = new Date();
			const params = new URLSearchParams(window.location.search)

			const store_itemData = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.store_data[0].uid).orderBy('created_at', 'desc')).get().toPromise();
			const convertArrayToString = (value) => {
				return Array.isArray(value) ? value.join('') : value;
			};
			this.store_item = store_itemData.docs.map((e: any) => {
				return {
					upc_code: e.data()['upc_code'],
					product_description: e.data()['product_description'],
					product_name_slug: e.data().product_name_slug,
					pack: e.data()['pack'],
					price_label_format: e.data()['price_label_format'],
					price_label_value1: convertArrayToString(e.data()['price_label_value1'] ? e.data()['price_label_value1'] : ""),
					price_label_value2: convertArrayToString(e.data()['price_label_value2'] ? e.data()['price_label_value2'] : ""),
					price_label_value3: convertArrayToString(e.data()['price_label_value3'] ? e.data()['price_label_value3'] : ""),
					price_label_highlight: e.data()['price_label_highlight'] ? e.data()['price_label_highlight'] : "",
					product_url: e.data()['product_url'] ? e.data()['product_url'] : "",
					hide_price_label: e.data()['hide_price_label'] ? e.data()['hide_price_label'] : false,
					hide_addtolist_label: e.data()['hide_addtolist_label'] ? e.data()['hide_addtolist_label'] : false,
					hide_productname_label: e.data()['hide_productname_label'] ? e.data()['hide_productname_label'] : false,
					cost: e.data()['cost'] ? e.data()['cost'] : "",
					srp: e.data()['srp'] ? e.data()['srp'] : "",
					storeInMaster: e.data().storeInMaster ? e.data().storeInMaster : '',
					category: e.data()['category'] ? e.data()['category'] : "",
					offerLabel: e.data().offerLabel ? e.data().offerLabel : '',
					labelSide: e.data().labelSide ? e.data().labelSide : '',
					uid: e.id,
					product_image: e.data().product_image ? e.data().product_image : 'https://via.placeholder.com/30/cecece/cecece',
					price_label_svg: e.data().price_label_svg ? e.data().price_label_svg : '',
					sale_price_label_format: e.data()['sale_price_label_format'] ? e.data()['sale_price_label_format'] : "",
					sale_price_label_value1: e.data()['sale_price_label_value1'] ? e.data()['sale_price_label_value1'] : "",
					sale_price_label_value2: e.data()['sale_price_label_value2'] ? e.data()['sale_price_label_value2'] : "",
					sale_price_label_value3: e.data()['sale_price_label_value3'] ? e.data()['sale_price_label_value3'] : "",
					final_price_label_format: e.data()['final_price_label_format'] ? e.data()['final_price_label_format'] : "",
					final_price_label_value1: e.data()['final_price_label_value1'] ? e.data()['final_price_label_value1'] : "",
					final_price_label_value2: e.data()['final_price_label_value2'] ? e.data()['final_price_label_value2'] : "",
					final_price_label_value3: e.data()['final_price_label_value3'] ? e.data()['final_price_label_value3'] : "",
					digital_coupon_price_label_format: e.data()['digital_coupon_price_label_format'] ? e.data()['digital_coupon_price_label_format'] : "",
					digital_coupon_price_label_value1: e.data()['digital_coupon_price_label_value1'] ? e.data()['digital_coupon_price_label_value1'] : "",
					digital_coupon_price_label_value2: e.data()['digital_coupon_price_label_value2'] ? e.data()['digital_coupon_price_label_value2'] : "",
					digital_coupon_price_label_value3: e.data()['digital_coupon_price_label_value3'] ? e.data()['digital_coupon_price_label_value3'] : "",
					note: e.data()['note'] ? e.data()['note'] : "",
					link: e.data()['link'] ? e.data()['link'] : "",
					usage_limit: e.data()['usage_limit'] ? e.data()['usage_limit'] : "",
					digital_coupon: e.data()['digital_coupon'] ? e.data()['digital_coupon'] : false,
					sale_price_label_svg: e.data().sale_price_label_svg ? e.data().sale_price_label_svg : '',
					sale_content_price_label_svg: e.data().sale_content_price_label_svg ? e.data().sale_content_price_label_svg : '',
					final_price_label_svg: e.data().final_price_label_svg ? e.data().final_price_label_svg : '',
					final_content_price_label_svg: e.data().final_content_price_label_svg ? e.data().final_content_price_label_svg : '',
					digital_coupon_price_label_svg: e.data().digital_coupon_price_label_svg ? e.data().digital_coupon_price_label_svg : '',
					digital_content_coupon_price_label_svg: e.data().digital_content_coupon_price_label_svg ? e.data().digital_content_coupon_price_label_svg : '',
					is_saved: e.data().is_saved ? e.data().is_saved : '',
					store_uid: e.data().store_uid ? e.data().store_uid : '',
					isVideo: e.data().isVideo ? e.data().isVideo : false,
					master_uid: e.data().master_uid ? e.data().master_uid : '',
				};
			});
			this.packDetails = store_itemData.docs.map(doc => ({
				uid: doc.id,
				pack: (doc.data() as { pack: any }).pack
			}));

			this.show_loader1 = true
			if (this.route.snapshot.params.id && params.has('preview') && params.get('preview') === 'true') {
				if (params.has('type') && params.get('type') === 'noad') {
					this.current_layout_data = await this.getLayoutPreview("/layouts-noad")
					this.current_bundle_data = await this.getBundlePreview("/bundles");
					this.current_menu_data = await this.getMenuPreview("/menus");
					this.layoutData();
					this.afs.collection('/layouts-noad', ref => ref.where('uid', '==', this.route.snapshot.params.id)).snapshotChanges().subscribe(async (data: any) => {
						const current_layout: any = await this.getLayoutPreview("/layouts-noad")
						this.old_localstorage_last_publish_date = localStorage.getItem('layout_last_publish_date') ? localStorage.getItem('layout_last_publish_date') : localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
						this.old_localstorage_uid = localStorage.getItem('layout_uid') ? localStorage.getItem('layout_uid') : localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid);
						if (this.old_localstorage_last_publish_date != current_layout[0]?.last_publish_timestamp && this.old_localstorage_uid == current_layout[0]?.uid && !params.has('preview')) {
							const notificationClicked = localStorage.getItem('notificationClicked')
							if(notificationClicked == 'yes'){
								this.layout_change = false
							}else{
								this.layout_change = true
							}							
							localStorage.setItem("layout_last_publish_date", current_layout[0]?.last_publish_timestamp);
							localStorage.setItem("layout_uid", current_layout[0]?.uid)
						}
						localStorage.setItem('notificationClicked',"no");													 
					})
				} else {
					this.current_layout_data = await this.getLayoutPreview("/layouts")
					this.current_bundle_data = await this.getBundlePreview("/bundles");
					this.current_menu_data = await this.getMenuPreview("/menus");
					this.layoutData();
					if (this.route.snapshot.params.id == 'upcoming') {
						this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).snapshotChanges().subscribe(async (data) => {
							const current_layout: any = await this.getLayoutPreview("/layouts")
							this.old_localstorage_last_publish_date = localStorage.getItem('layout_last_publish_date') ? localStorage.getItem('layout_last_publish_date') : localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
							this.old_localstorage_uid = localStorage.getItem('layout_uid') ? localStorage.getItem('layout_uid') : localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid);
							if (this.old_localstorage_last_publish_date != current_layout[0]?.last_publish_timestamp && this.old_localstorage_uid == current_layout[0]?.uid && !params.has('preview')) {
								const notificationClicked = localStorage.getItem('notificationClicked')
								if(notificationClicked == 'yes'){
									this.layout_change = false
								}else{
									this.layout_change = true
								}								
								localStorage.setItem("layout_last_publish_date", current_layout[0]?.last_publish_timestamp);
								localStorage.setItem("layout_uid", current_layout[0]?.uid)
							}
							localStorage.setItem('notificationClicked',"no");													 
						})
					} else {
						this.afs.collection('/layouts', ref => ref.where('uid', '==', this.route.snapshot.params.id)).snapshotChanges().subscribe(async (data: any) => {
							const current_layout: any = await this.getLayoutPreview("/layouts")
							this.old_localstorage_last_publish_date = localStorage.getItem('layout_last_publish_date') ? localStorage.getItem('layout_last_publish_date') : localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
							this.old_localstorage_uid = localStorage.getItem('layout_uid') ? localStorage.getItem('layout_uid') : localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid);
							if (this.old_localstorage_last_publish_date != current_layout[0]?.last_publish_timestamp && this.old_localstorage_uid == current_layout[0]?.uid && !params.has('preview')) {
								const notificationClicked = localStorage.getItem('notificationClicked')
								if(notificationClicked == 'yes'){
									this.layout_change = false
								}else{
									this.layout_change = true
								}								
								localStorage.setItem("layout_last_publish_date", current_layout[0]?.last_publish_timestamp);
								localStorage.setItem("layout_uid", current_layout[0]?.uid)
							}
							localStorage.setItem('notificationClicked',"no");													 
						})
					}
				}
			} else {
				if (!this.store_data[0].override_noad_layout) {
					this.current_layout_data = await this.getLayoutData();
					this.current_bundle_data = await this.getBundleData();
					this.current_menu_data = await this.getMenuData();
					this.layoutData();
					this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).snapshotChanges().subscribe(async (data) => {
						const current_layout: any = await this.getLayoutData()
						this.old_localstorage_last_publish_date = localStorage.getItem('layout_last_publish_date') ? localStorage.getItem('layout_last_publish_date') : localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
						this.old_localstorage_uid = localStorage.getItem('layout_uid') ? localStorage.getItem('layout_uid') : localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid);
						if (this.old_localstorage_last_publish_date != current_layout[0]?.last_publish_timestamp && this.old_localstorage_uid == current_layout[0]?.uid && !params.has('preview')) {
							const notificationClicked = localStorage.getItem('notificationClicked')
							if(notificationClicked == 'yes'){
								this.layout_change = false
							}else{
								this.layout_change = true
							}							
							localStorage.setItem("layout_last_publish_date", current_layout[0]?.last_publish_timestamp);
							localStorage.setItem("layout_uid", current_layout[0]?.uid)
						}
						localStorage.setItem('notificationClicked',"no");													 
					})
				} else {
					const overrideLayout = await this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise()
					this.current_layout_data = overrideLayout.docs.map(doc => doc.data());
					this.current_bundle_data = await this.getBundleData();
					this.current_menu_data = await this.getMenuData();
					if (this.current_layout_data.include_header_checkbox) {
						this.show_dynamic_header = 'show_dynamic_header';
					} else {
						this.show_dynamic_header = '';
					}

					const params = new URLSearchParams(window.location.search)
					this.serializedData = this.current_layout_data[0]?.layout_json;
					this.serializedMenuData = this.current_menu_data[0]?.menu_json;
					this.serializedBundleData = this.current_bundle_data[0]?.bundle_json;
					this.combinedData = [];
					if(this.serializedData?.length>0){
						this.combinedData.push(...this.serializedData);
					   }
				    if(this.serializedBundleData?.length>0){
				        this.combinedData.push(...this.serializedBundleData);
				       }
				    if(this.serializedMenuData?.length>0){
				        this.combinedData.push(...this.serializedMenuData);
				      }
					this.serializedIds = this.combinedData?.map(s => s.id)
					this.store_id = this.current_layout_data[0]?.store_uid;

					if (this.current_layout_data[0]?.layout_type == 'grid') {
						this.iframe_src = this.sanitizer.bypassSecurityTrustResourceUrl('assets/dclive.html?store_uid=' + this.current_store_uid + '&template_id=' + this.current_layout_data[0]?.uid + '&r=11' + this.isPreview);
					} else {
						this.iframe_src = '';
					}

					this.layoutData()

					this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).snapshotChanges().subscribe(async (data: any) => {
						const current_layout: any = await this.getLayoutData()
						this.old_localstorage_last_publish_date = localStorage.getItem('layout_last_publish_date') ? localStorage.getItem('layout_last_publish_date') : localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
						this.old_localstorage_uid = localStorage.getItem('layout_uid') ? localStorage.getItem('layout_uid') : localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid);
						if (this.old_localstorage_last_publish_date != current_layout[0]?.last_publish_timestamp && this.old_localstorage_uid == current_layout[0]?.uid && !params.has('preview')) {
							const notificationClicked = localStorage.getItem('notificationClicked')
							if(notificationClicked == 'yes'){
								this.layout_change = false
							}else{
								this.layout_change = true
							}							
							localStorage.setItem("layout_last_publish_date", current_layout[0]?.last_publish_timestamp);
							localStorage.setItem("layout_uid", current_layout[0]?.uid)
						}
						localStorage.setItem('notificationClicked',"no");													 
					});
				}
			}
		}

		await this.afs.collection('/lookup-categories').snapshotChanges().subscribe((data: any) => {
			this.categories_list = data.map(e => {
				return {
					uid: e.payload.doc.id,
					value: e.payload.doc.data().value,
				};
			});
		});

		if (this.current_list_id !== '') {
			this.qr_value = window.location.href + '/list/' + this.current_list_id;
			await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
				this.current_list_data = data.map(e => {
					return {
						uid: e.payload.doc.id,
						items: e.payload.doc.data().items,
						past_items: e.payload.doc.data().past_items,
					};
				});
				this.current_list_data = this.current_list_data[0]
				this.cartItem = this.current_list_data?.items;
				this.past_item = this.current_list_data?.past_items
				this.cart_ids = []
				this.current_list_data?.items.forEach(i => {
					if (i?.product?.id) {
						this.cart_ids.push(i.product.id)
					} else {
						this.cart_ids.push(i.product.uid)
					}
				})
			});
		}

		this.afs.collection('/coupons', ref => ref.where('store_uid', '==', this.current_store_uid).where('is_publish', '==', true).orderBy('created_at', 'desc')).snapshotChanges().subscribe(data => {
			this.coupons_list = data.map(e => {
				const validTillTimestamp = e.payload.doc.data()['valid_till'].seconds * 1000;
				const validTillDate = new Date(validTillTimestamp);
				validTillDate.setHours(0, 0, 0, 0);
				const currentDate = new Date();
				currentDate.setHours(0, 0, 0, 0);
				if (validTillDate >= currentDate) {
					return {
						uid: e.payload.doc.id,
						coupon_image: e.payload.doc.data()['coupon_image'] ? e.payload.doc.data()['coupon_image'] : "https://via.placeholder.com/300x200/2e2e2e/2e2e2e",
						upc: e.payload.doc.data()['upc'],
						coupon_description: e.payload.doc.data()['coupon_description'],
						label: e.payload.doc.data()['label'],
						coupon_code: e.payload.doc.data()['coupon_code'],
						valid_from: e.payload.doc.data()['valid_from'],
						valid_till: e.payload.doc.data()['valid_till'],
					};
				} else {
					return {
						uid: e.payload.doc.id,
						valid_till: 'expired'
					}
				}
			});
		});
	});
	}
	resizeGrid(i) {
		this.grid.column(i);
		this.grid.compact();
	}

	async getLayoutData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			const layout_start_date = docs.start_date.toDate();
			const endDate = docs.end_date.toDate();

			return layout_start_date <= start_date && endDate > start_date;
		});
		return current_layout;
	}
	async getBundleData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/bundles', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			// const layout_start_date = docs.start_date.toDate();
			// const endDate = docs.end_date.toDate();

			// return layout_start_date <= start_date && endDate > start_date;
		});
		return layouts;
	}
	async getMenuData() {
		const start_date = new Date();
		const layout_data = await this.afs.collection('/menus', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

		const layouts = layout_data.docs.map(doc => doc.data());

		const current_layout = layouts.filter((docs: any) => {
			if (docs.include_header_checkbox) {
				this.show_dynamic_header = 'show_dynamic_header';
			} else {
				this.show_dynamic_header = '';
			}

			// const layout_start_date = docs.start_date.toDate();
			// const endDate = docs.end_date.toDate();

			// return layout_start_date <= start_date && endDate > start_date;
		});
		return layouts;
	}
	async getLayoutPreview(data) {
		if (data == '/layouts' && this.route.snapshot.params.id == 'upcoming') {
			const current_date = new Date();
			const noadLayout = await this.afs.collection('/layouts', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

			const current_layout_data = noadLayout.docs.map((doc: any) => {
				const startDate = doc.data().start_date.toDate();
				if (startDate > current_date) {
					return doc.data();
				}
				return null; // or any appropriate placeholder for excluded data
			}).filter(data => data !== null);
			return current_layout_data
		} else {
			const noadLayout = await this.afs.collection(`${data}`, ref => ref.where('uid', '==', this.route.snapshot.params.id)).get().toPromise()
			const current_layout_data = noadLayout.docs.map(doc => doc.data());
			return current_layout_data
		}
	}
	async getBundlePreview(data) {
		if (data == '/bundles' && this.route.snapshot.params.id == 'upcoming') {
			const current_date = new Date();
			const noadLayout = await this.afs.collection('/bundles', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

			const current_layout_data = noadLayout.docs.map((doc: any) => {
				const startDate = doc.data().start_date.toDate();
				// if (startDate > current_date) {
					return doc.data();
				// }
				// return null; // or any appropriate placeholder for excluded data
			}).filter(data => data !== null);
			return current_layout_data
		} else {
			const noadLayout = await this.afs.collection(`${data}`, ref => ref.where('uid', '==', this.route.snapshot.params.id)).get().toPromise()
			const current_layout_data = noadLayout.docs.map(doc => doc.data());
			return current_layout_data
		}
	}
	async getMenuPreview(data) {
		if (data == '/menus' && this.route.snapshot.params.id == 'upcoming') {
			const current_date = new Date();
			const noadLayout = await this.afs.collection('/menus', ref => ref.where('store_uid', '==', this.current_store_uid).orderBy('start_date', 'desc')).get().toPromise();

			const current_layout_data = noadLayout.docs.map((doc: any) => {
				const startDate = doc.data().start_date.toDate();
				// if (startDate > current_date) {
					return doc.data();
				// }
				// return null; // or any appropriate placeholder for excluded data
			}).filter(data => data !== null);
			return current_layout_data
		} else {
			const noadLayout = await this.afs.collection(`${data}`, ref => ref.where('uid', '==', this.route.snapshot.params.id)).get().toPromise()
			const current_layout_data = noadLayout.docs.map(doc => doc.data());
			return current_layout_data
		}
	}
	async layoutData() {
		if (this.current_layout_data) {
			const params = new URLSearchParams(window.location.search)
			//preview tab
			if (this.route.snapshot.params.id && params.has('preview') && params.get('preview') === 'true') {
				if (typeof (this.current_layout_data[0]?.layout_json_temp) == "string") {
					const layoutjsontemp = await this.afs.collection('layout-json-temp').doc(this.current_layout_data[0]?.layout_json_temp).get().toPromise()
					const current_layout_data: any = layoutjsontemp.data();
					this.current_layout_data[0].layout_json_temp = current_layout_data?.layout_json_temp
				}
				this.serializedData = this.current_layout_data[0]?.layout_json_temp;
				this.serializedMenuData = this.current_menu_data[0]?.menu_json_temp;
				this.serializedBundleData = this.current_bundle_data[0]?.bundle_json_temp;
				this.combinedData=[]
				if(this.serializedData?.length>0){
					this.combinedData.push(...this.serializedData);
				   }
				if(this.serializedBundleData?.length>0){
					this.combinedData.push(...this.serializedBundleData);
				   }
				if(this.serializedMenuData?.length>0){
					this.combinedData.push(...this.serializedMenuData);
				  }
				
				if (Array.isArray(this.current_layout_data[0]?.layout_json_temp)) {
					this.current_layout_data[0]?.layout_json_temp.forEach(x => {
						if (x.category && !this.layout_cat.includes(x.category.trim())) {
							this.layout_cat.push(x.category.trim());
						}
					})
				}
				if (this.current_list_id !== '') {
					try {
						const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
						const currentListData: any = currentListSnapshot.data();

						let finalCurrentListData;
						if (currentListData?.past_items) {
							this.past_item = currentListData?.past_items;
							finalCurrentListData = [...currentListData?.items, ...currentListData?.past_items];
						} else {
							this.past_item = [];
							finalCurrentListData = currentListData?.items;
						};

						const commonItems = [];
						for (const storeItem of this.combinedData) {
							const storeItemUid = storeItem.id;
							if (storeItem?.id) {
								const isCommon = finalCurrentListData.some(cartItem => {
									return cartItem.product.id == storeItem.id || cartItem.product.uid == storeItem.id;
								});

								if (isCommon) {
									commonItems.push(storeItem);
								}
							}
						}
						for (const storeItem of this.store_item) {
							const storeItemUid = storeItem.id;
							if (storeItem?.uid) {
								const isCommon = finalCurrentListData.some(cartItem => {
									return cartItem.product.uid == storeItem.uid;
								});
								if (isCommon) {
									commonItems.push(storeItem);
								}
							}
						}
						this.cartItem = [];
						this.past_item = [];

						for (const currentCartItem of finalCurrentListData) {

							let storeItem
							if (currentCartItem.product.id) {
								storeItem = commonItems.find(item =>
									item.id == currentCartItem.product.id);
							} else {
								storeItem = commonItems.find(item => {
									return item.uid == currentCartItem.product.uid || item.uid == currentCartItem.product.uid
								})
							}
							if (storeItem && (
								currentCartItem?.product?.price_label_value1 !== storeItem?.price_label_value1 ||
								currentCartItem?.product?.price_label_value2 !== storeItem?.price_label_value2 ||
								currentCartItem?.product?.price_label_value3 !== storeItem?.price_label_value3 ||
								currentCartItem?.product?.sale_price_label_value1 !== storeItem?.sale_price_label_value1 ||
								currentCartItem?.product?.sale_price_label_value2 !== storeItem?.sale_price_label_value2 ||
								currentCartItem?.product?.sale_price_label_value3 !== storeItem?.sale_price_label_value3)

							) {
								this.past_item.push(currentCartItem);
							} else {
								this.cartItem.push(currentCartItem);
							}
						}
						await this.afs.collection('/list').doc(this.current_list_id).update({
							items: this.cartItem,
							past_items: this.past_item
						});
					} catch (error) {
						console.error("Error:", error);
					}
				}
				//store 
			} else {
				this.serializedData = this.current_layout_data[0]?.layout_json
				this.serializedBundleData = this.current_bundle_data[0]?.bundle_json
				this.serializedMenuData = this.current_menu_data[0]?.menu_json
				this.combinedData=[]
				if(this.serializedData?.length>0){
					this.combinedData.push(...this.serializedData);
				   }
				if(this.serializedBundleData?.length>0){
					this.combinedData.push(...this.serializedBundleData);
				   }
				if(this.serializedMenuData?.length>0){
					this.combinedData.push(...this.serializedMenuData);
				  }
				this.current_layout_data[0]?.layout_json.forEach(x => {
					if (x.category && !this.layout_cat.includes(x.category.trim())) {
						this.layout_cat.push(x.category.trim());
					}
				});
				if (this.current_list_id !== '') {
					try {
						const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
						const currentListData: any = currentListSnapshot.data();
						let finalCurrentListData;
						if (currentListData?.past_items) {
							this.past_item = currentListData?.past_items;
							finalCurrentListData = [...currentListData?.items, ...currentListData?.past_items];
						} else {
							this.past_item = [];
							finalCurrentListData = currentListData?.items;
						};

						const commonItems = [];
						for (const storeItem of this.combinedData) {
							const storeItemUid = storeItem.id;
							if (storeItem?.id) {
								const isCommon = finalCurrentListData?.some(cartItem => {
									return cartItem.product.id == storeItem.id || cartItem.product.uid == storeItem.id;
								});

								if (isCommon) {
									commonItems.push(storeItem);
								}
							}
						}
						for (const storeItem of this.store_item) {
							const storeItemUid = storeItem.id;
							if (storeItem?.uid) {
								const isCommon = finalCurrentListData?.some(cartItem => {
									return cartItem.product.uid == storeItem.uid;
								});
								if (isCommon) {
									commonItems.push(storeItem);
								}
							}
						}
						this.cartItem = [];
						this.past_item = [];

						for (const currentCartItem of finalCurrentListData) {

							let storeItem
							if (currentCartItem.product.id) {
								storeItem = commonItems.find(item =>
									item.id == currentCartItem.product.id);
							} else {
								storeItem = commonItems.find(item => {
									return item.uid == currentCartItem.product.uid || item.uid == currentCartItem.product.uid
								})
							}
							if (storeItem && (
								currentCartItem?.product?.price_label_value1 !== storeItem?.price_label_value1 ||
								currentCartItem?.product?.price_label_value2 !== storeItem?.price_label_value2 ||
								currentCartItem?.product?.price_label_value3 !== storeItem?.price_label_value3 ||
								currentCartItem?.product?.sale_price_label_value1 !== storeItem?.sale_price_label_value1 ||
								currentCartItem?.product?.sale_price_label_value2 !== storeItem?.sale_price_label_value2 ||
								currentCartItem?.product?.sale_price_label_value3 !== storeItem?.sale_price_label_value3)

							) {
								this.past_item.push(currentCartItem);
							} else {
								this.cartItem.push(currentCartItem);
							}
						}
						await this.afs.collection('/list').doc(this.current_list_id).update({
							items: this.cartItem,
							past_items: this.past_item
						});
					} catch (error) {
						console.error("Error:", error);
					}
				}
				if (this.current_list_id !== '') {
					this.serializedIds = this.combinedData?.map(s => s.id)
					this.store_id = this.current_layout_data[0]?.store_uid;

					const currentListSnapshot = await this.afs.collection('/list').doc(this.current_list_id).get().toPromise();
					const currentListData: any = currentListSnapshot.data();

					if (currentListData?.items) {
						const updatedCartItems = [];
						const updatedPastItems = [];
						const CouponItems = currentListData.items.filter((a) => a.isCoupon == true)
						const RecipeItems = currentListData.items.filter((a) => a.isRecipe == true)
						const filterCartItems = currentListData.items.filter((a) => a.isRecipe != true && a.isCoupon != true);
						const filterPastItems = currentListData.past_items.filter((a) => a.isRecipe != true && a.isCoupon != true);
						updatedCartItems.push(...RecipeItems)
						CouponItems.forEach(currentCartItem => {
							if (currentCartItem.isCoupon) {
								this.coupons_list?.forEach(item => {
									if (item?.uid == currentCartItem?.product?.uid) {
										const validTillDate = new Date(currentCartItem.product.valid_till);
										validTillDate.setHours(0, 0, 0, 0);
										const currentDate = new Date();
										currentDate.setHours(0, 0, 0, 0);
										 if (item.valid_till == 'expired' || validTillDate < currentDate) {
											currentCartItem.product = {
												...currentCartItem.product,
												isExpired: 'Yes'
											};
											updatedCartItems.push(currentCartItem)
										} else {
											currentCartItem.product = {
												...currentCartItem.product,
												isExpired: 'No'
											};
											updatedCartItems.push(currentCartItem)
										}
									}
								})

							}
						})

						for (const currentCartItem of filterCartItems) {
							if (currentCartItem.product.id) {
								if (!this.serializedIds.includes(currentCartItem.product.id) && !currentCartItem.static) {
									updatedPastItems.push(currentCartItem);
								} else {
									updatedCartItems.push(currentCartItem);
								}
							} else {
								if (!this.serializedIds.includes(currentCartItem.product.uid) && !currentCartItem.static) {
									updatedPastItems.push(currentCartItem);
								} else {
									updatedCartItems.push(currentCartItem);
								}
							}
						}
						for (const currentPastItem of filterPastItems) {
							updatedPastItems.push(currentPastItem);
						}

						await this.afs.collection('/list').doc(this.current_list_id).update({
							items: updatedCartItems,
							past_items: updatedPastItems
						});
					}
				}
			}

			if (this.current_layout_data[0]?.layout_type == 'grid') {
				this.iframe_src = this.sanitizer.bypassSecurityTrustResourceUrl('assets/dclive.html?store_uid=' + this.current_store_uid + '&template_id=' + this.current_layout_data[0]?.uid + '&r=11' + this.isPreview);
			} else {
				this.iframe_src = '';
			}
			setTimeout(async () => {
				this.grid = await GridStack.init({ column: window.innerWidth < 768 ? 3 : 8, disableResize: true, disableDrag: true, removable: '#trash' });
				window.addEventListener("resize", () => {
					if (this.grid && window.innerWidth < 768) {
						this.resizeGrid(3);
					} else {
						this.resizeGrid(8);
					}
				})
				this.show_loader = false
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view != "listView") {
					this.show_loader1 = false;
				}
				this.loadCircularGrid(this.cart_ids) // finally size to actual window
				if (this.is_list_view) {
					this.ShowShoppingList()
				}
				this.display_print_content = true
			}, 200)
			const temp = setInterval(() => {
				if (this.store_id) {
					clearInterval(temp)
				}
			}, 200);
			sessionStorage.setItem('is_all_clicked', 'false');
		} else {
			this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).snapshotChanges().subscribe((data: any) => {
				this.current_layout_data = data.map(e => {
					return {
						uid: e.payload.doc.id,
						selected_product_id: e.payload.doc.data().selected_product_id ? e.payload.doc.data().selected_product_id : '',
						layout_json: e.payload.doc.data().layout_json ? e.payload.doc.data().layout_json : [],
						layout_type: e.payload.doc.data().layout_type ? e.payload.doc.data().layout_type : '',
						include_header_checkbox: e.payload.doc.data().include_header_checkbox,
						start_date: e.payload.doc.data().start_date,
						end_date: e.payload.doc.data().end_date,
						actual_start_date: e.payload.doc.data().actual_start_date ? e.payload.doc.data().actual_start_date : e.payload.doc.data().start_date,
						actual_end_date: e.payload.doc.data().actual_end_date ? e.payload.doc.data().actual_end_date : e.payload.doc.data().end_date,
						header_text: e.payload.doc.data().header_text,
						terms: e.payload.doc.data().terms ? e.payload.doc.data().terms : '',
						layout_json_temp: e.payload.doc.data().layout_json_temp ? e.payload.doc.data().layout_json_temp : '',
						is_publish: e.payload.doc.data().is_publish ? e.payload.doc.data().is_publish : '',
						page1_type: e.payload.doc.data().page1_type ? e.payload.doc.data().page1_type : '',
						page2_type: e.payload.doc.data().page2_type ? e.payload.doc.data().page2_type : '',
						page3_type: e.payload.doc.data().page3_type ? e.payload.doc.data().page3_type : '',
						page4_type: e.payload.doc.data().page4_type ? e.payload.doc.data().page4_type : '',
						page5_type: e.payload.doc.data().page5_type ? e.payload.doc.data().page5_type : '',
						page1_url: e.payload.doc.data().page1_url ? e.payload.doc.data().page1_url : '',
						page2_url: e.payload.doc.data().page2_url ? e.payload.doc.data().page2_url : '',
						page3_url: e.payload.doc.data().page3_url ? e.payload.doc.data().page3_url : '',
						page4_url: e.payload.doc.data().page4_url ? e.payload.doc.data().page4_url : '',
						page5_url: e.payload.doc.data().page5_url ? e.payload.doc.data().page5_url : '',
						last_save_timestamp: e.payload.doc.data().last_save_timestamp,
						last_publish_timestamp: e.payload.doc.data().last_publish_timestamp,
						store_uid: e.payload.doc.data().store_uid
					};
				});
				const params = new URLSearchParams(window.location.search);
				this.old_localstorage_last_publish_date = localStorage.getItem('layout_last_publish_date') ? localStorage.getItem('layout_last_publish_date') : localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
				this.old_localstorage_uid = localStorage.getItem('layout_uid') ? localStorage.getItem('layout_uid') : localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid);
				if (this.old_localstorage_last_publish_date != this.current_layout_data[0]?.last_publish_timestamp && this.old_localstorage_uid == this.current_layout_data[0]?.uid && !params.has('preview')) {
					const notificationClicked = localStorage.getItem('notificationClicked')
					if(notificationClicked == 'yes'){
						this.layout_change = false
					}else{
						this.layout_change = true
					}					
					localStorage.setItem("layout_last_publish_date", this.current_layout_data[0]?.last_publish_timestamp);
					localStorage.setItem("layout_uid", this.current_layout_data[0]?.uid)
				}
				localStorage.setItem('notificationClicked',"no");													 
				this.serializedData = this.current_layout_data[0]?.layout_json
				this.serializedBundleData = this.current_bundle_data[0]?.bundle_json
				this.serializedMenuData = this.current_menu_data[0]?.menu_json
				this.combinedData=[]
				if(this.serializedData?.length>0){
					this.combinedData.push(...this.serializedData);
				   }
				if(this.serializedBundleData?.length>0){
					this.combinedData.push(...this.serializedBundleData);
				   }
				if(this.serializedMenuData?.length>0){
					this.combinedData.push(...this.serializedMenuData);
				  }
				this.serializedIds = this.combinedData?.map(s => s.id)
				this.store_id = this.current_layout_data[0]?.store_uid;
				if (this.current_layout_data[0]?.layout_type == 'grid') {
					this.iframe_src = this.sanitizer.bypassSecurityTrustResourceUrl('assets/dclive.html?store_uid=' + this.current_store_uid + '&template_id=' + this.current_layout_data[0]?.uid + '&r=11' + this.isPreview);
				} else {
					this.iframe_src = '';
				}
			})
			setTimeout(async () => {
				this.grid = await GridStack.init({ column: window.innerWidth < 768 ? 3 : 8, disableResize: true, disableDrag: true, removable: '#trash' });
				window.addEventListener("resize", () => {
					if (this.grid && window.innerWidth < 768) {
						this.resizeGrid(3);
					} else {
						this.resizeGrid(8);
					}
				})
				this.show_loader = false
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view != "listView") {
					this.show_loader1 = false;
				}
				this.loadCircularGrid(this.cart_ids) // finally size to actual window
				if (this.is_list_view) {
					this.ShowShoppingList()
				}
				this.display_print_content = true
			}, 3500)
			const temp = setInterval(() => {
				if (this.store_id) {
					clearInterval(temp)
				}
			}, 1000);
		}
	}
	close_notification() {
		this.layout_change = false
	}
	showLoader(val) {
		this.show_loader = val
	}
	async categoryProd(val) {
		const params = new URLSearchParams(window.location.search)
		if (val == 'All') {
			if (this.route.snapshot.params.id && params.has('preview') && params.get('preview') === 'true') {
				this.serializedData = this.current_layout_data[0]?.layout_json_temp;
			} else {
				this.serializedData = this.current_layout_data[0]?.layout_json;
			}
			this.cateGoryLoad = true
			this.loadCircularGrid(this.cart_ids)

		}else {
			var newItem
			if (this.route.snapshot.params.id && params.has('preview') && params.get('preview') === 'true') {
				newItem = this.current_layout_data[0]?.layout_json_temp.filter(x => x?.category?.trim() === val);
			} else {
				newItem = this.current_layout_data[0]?.layout_json.filter(x => x?.category?.trim() === val);
				this.categoryLoad = true;
			}

			this.serializedData = newItem;
			this.cateGoryLoad = true
			this.loadCircularGrid(this.cart_ids)
			const grid = GridStack.init({ column: window.innerWidth < 768 ? 3 : 8, disableResize: true, disableDrag: true, removable: '#trash' });
			grid.compact();
		}
	}

	toDataURL(url, callback) {
		var xhr = new XMLHttpRequest();
		xhr.onload = function () {
			var reader = new FileReader();
			reader.onloadend = function () {
				callback(reader.result);
			}
			reader.readAsDataURL(xhr.response);
		};
		xhr.open('GET', url);
		xhr.responseType = 'blob';
		xhr.send();
	}
	initializeFB(item) {
		const initParams = {
			appId: '317808808064170',
			xfbml: true,
			version: 'v20.0'
		};
		FB.init(initParams);

		let myVideoPlayers = {};

		FB.Event.subscribe('xfbml.ready', (msg) => {
			if (msg.type === 'video' && msg.id == item.id) {
				const videoPlayer = msg.instance;
				const videoId = msg.id;

				myVideoPlayers[videoId] = videoPlayer;

				if (videoId === item.id) {
					if (item.autoplay) {
						videoPlayer.play();
					}
					videoPlayer.subscribe('finishedPlaying', () => {
						this.restartVideo(videoPlayer);
					});
				}
			}
		});

		FB.XFBML.parse();
	}

	restartVideo(videoPlayer) {
		if (videoPlayer) {
			videoPlayer.seek(0); // Seek to the beginning of the video
			videoPlayer.play(); // Play the video
		}
	}

	 public async loadCircularGrid(id) {
		localStorage.setItem('isFreshLoad','no');
		let dataForLoad = []
		let cat = [];
		localStorage.setItem("printButton",'NotDisplay')

		if (Array.isArray(this.serializedData)) {
			await Promise.all(this.serializedData?.map(async (item, index) => {
				let iterationDone = true;
				let temp
				let ti = item

				if (item.bannerText != '' && item.bannerText != null) {
					const div = document.createElement('div')
					div.innerHTML = `<div id="${item.id}" upc="${item.upc_code}" class="hide_bar custom-text-banner" style="height: 100%;width: auto;margin: 0px !important;"><div class="banner-text">${item.bannerText}</div></div>`;
					item.content = div.innerHTML
				}
				// add new class for banner images
				if (item.isBanner == true) {
					item.content = item.content.replace('hide_bar', 'hide_bar banner-img-width');
				}
				if (item.domain_name == 'facebook') {
					if (item.w == undefined) {
						item.w = item.w ? item.w : 1
					}
					if (item.h == undefined) {
						item.w = item.h ? item.h : 1
					}
				}
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (window.innerWidth < 768) {
					if (item.digital_coupon) {
						if (item.isVideo) {
							item.w = 3
							item.h = 3
						} else {
							item.w = 3
							item.h = 3
						}
					}
					if (item.w > 3 && item.isBanner == true) {
						// get image url from html
						var htmlString = item.content;
						var fragment: any = document.createRange().createContextualFragment(htmlString);
						var imgURL = fragment.firstChild.src

						// get box height
						var boxHeight = (255 * document.documentElement.offsetWidth) / 767;

						// get image height and width and define boxsize
						if (!item.bannerText) {
							var img = new Image();
							img.src = imgURL;
							var imgHeight = img.height;
							var imgWidth = img.width;
							var boxSize = Math.round((imgHeight * 3) / imgWidth);
							item.h = boxSize;
						}
					} else {
						// In normalView(largeView)  all items apperas in 3*3
						if (view == "normalView") {
							if (item.digital_coupon) {
								if (item.isVideo) {
									item.w = 3
									item.h = 3
									$(".grid-stack-item").addClass("large-view");
								} else {
									item.w = 3
									item.h = 3
									$(".grid-stack-item").addClass("large-view");
								}
							} else {
								if (item.isVideo) {
									item.w = 3
									item.h = 2
									$(".grid-stack-item").addClass("large-view");
								} else {
									item.w = 3
									item.h = 3
									$(".grid-stack-item").addClass("large-view");
								}
							}
						} else if (view == "listView") {
							item.w = 3
							item.h = 1
						} else {
							if (item.digital_coupon) {
								if (item.isVideo) {
									item.w = 3
									item.h = 3
								}
							} else {
								if (item.isVideo) {
									item.w = 3
									item.h = 2
								}
							}
						}
					}
				}
				if (view == "listView" && window.innerWidth < 768) {
					var packDetailsDiv = document.createElement('div');
					packDetailsDiv.innerHTML = item.content;
					const existingItemPack = packDetailsDiv.getElementsByClassName("packDetailsName");
					if (existingItemPack.length == 0) {
						const packNameDiv1 = document.createElement('div');
						packNameDiv1.className = "packDetailsName";
						const matchingItem = this.packDetails.find((packItem: { uid: any; pack?: any }) => packItem.uid === item.id);
						if (matchingItem?.pack) {
							const packNameTextNode = document.createTextNode(`${matchingItem?.pack}`);
							packNameDiv1.appendChild(packNameTextNode);
							packDetailsDiv.querySelector('.product-title')?.appendChild(packNameDiv1);
							item.content = packDetailsDiv.innerHTML
						}
					}
				}else{
					var packDetailsDiv = document.createElement('div');
					packDetailsDiv.innerHTML = item.content;
					const packTitleProName = packDetailsDiv.getElementsByClassName("packTitleProName");
					if (packTitleProName.length == 0) {
						const matchingItem = this.packDetails.find((packItem: { uid: any; pack?: any }) => packItem.uid === item.id);
						if (matchingItem?.pack) {
							const packNameTextNode = document.createTextNode(` (${matchingItem?.pack})`);
							const productTitleDiv = packDetailsDiv.querySelector('.product-title');
							const pTag = productTitleDiv?.querySelector('p');  // Find the <p> tag inside .product-title
							if (pTag) {
							  pTag.classList.add("packTitleProName")
							  pTag.appendChild(packNameTextNode);  // Append the text node to the <p> tag
						}				
							item.content = packDetailsDiv.innerHTML
						}
					}
				}
						
				var tempYou = document.createElement('div');
				tempYou.innerHTML = item.content;
				const iframe = tempYou.getElementsByClassName("iframe");
				if (iframe.length == 0 && view != 'listView') {
					if (item.youtubeURL && !item.openNewTab) {
						var temp3 = document.createElement('div');
						temp3.innerHTML = item.content;
						const img_ele: any = temp3.querySelector('img,video');
						if (img_ele) {
							const src = img_ele.src;
							if (item.domain_name == '') {
								if (src.includes("firebasestorage.googleapis.com") || src.includes("assets.edgenet.com") || src.includes("images.unsplash.com")) {
									const websiteURL = item.youtubeURL;
									img_ele.addEventListener('click', function () {
										window.open(websiteURL);
									});
								}
							} else {
								const div = document.createElement('div')
								div.innerHTML = item.content
								item.offerLabel
								// Check if the first div has the class name "OfferLabel"
								const firstDiv = div.getElementsByTagName('div')[0];
								const useSecondImg = firstDiv && firstDiv.classList.contains('offerLabel');

								// Use the appropriate img tag based on the condition
								let imgTag
								if (this.cateGoryLoad == true && item.domain_name == 'facebook') {
									imgTag = div.getElementsByClassName('fb-video')[0];
								} else {
									imgTag = useSecondImg ? div.getElementsByTagName('img')[1] : div.getElementsByTagName('img')[0]
								}
								if (imgTag) {
									let baseSize = 8
									if (window.innerWidth < 768) {
										baseSize = 3
									}
									if (item.domain_name == 'youtube') {
										const iframeTag = `<div class="iframe" > <iframe class="hide_bar" width="${(item.w / baseSize) * window.innerWidth}" height="${(item.h / baseSize) * window.innerWidth - 15}" src="${item.youtubeURL}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>`
										imgTag.outerHTML = iframeTag
									} else if (item.domain_name == 'facebook') {
										if (item.autoplay) {
											const iframeTag = `<div  id="${item.id}" class="fb-video" data-href="${item.youtubeURL}"  data-show-text="false"  data-width="${((item.w / baseSize) * window.innerWidth).toFixed(0)}"
								data-show-text="false"
								data-autoplay="true"
								data-allowfullscreen="false"
								data-controls="true"
								data-show-captions="false"
								data-show-post="false"
								data-show-social-context="false"
								data-show-metadata="false"
								data-show-adaptive-playback="false"
								data-autoplay-metadata="false"></div>`
											imgTag.outerHTML = iframeTag
											await this.initializeFB(item);
										} else {
											const iframeTag = `<div  id="${item.id}" class="fb-video" data-href="${item.youtubeURL}"  data-show-text="false"    data-width="${((item.w / baseSize) * window.innerWidth).toFixed(0)}"
								data-show-text="false"
								data-autoplay="false"
								data-allowfullscreen="false"
								data-controls="true"
								data-show-captions="false"
								data-show-post="false"
								data-show-social-context="false"
								data-show-metadata="false"
								data-show-adaptive-playback="false"
								data-autoplay-metadata="false"></div>`
											imgTag.outerHTML = iframeTag
											await this.initializeFB(item);
										}
									}
									item.content = div.innerHTML
								}
							}
						}
					}
				}
				if (!cat.includes(item.category) && item.category != '' && item.category != undefined) {
					cat.push(item.category)
				}
				const sr = item.content.split(`<img src="imgs/minus.png" id="${item.id}"`)
				if (sr.length === 2) {
					const z = item.content.split(`<img src="imgs/minus.png" id="${item.id}" class="deleteicon_click" style="position: absolute;width: 24px;height: 24px;right: 5px;top: 4px;">`)
					if (z.length === 2) {
						temp = z[0] + z[1]
					} else {
						temp = item.content
					}
				} else if (sr.length === 3) {
					temp = `${sr[0]}<img src="imgs/minus.png" id="${item.id}"${sr[2]}`
				} else {
					temp = item.content
				}
				if (id.some(x => x == item.id)) {
					if (temp.includes('plus.png')) {
						ti.content = temp?.replace("plus.png", "right.png")
					} else {
						ti.content = temp?.replace("minus.png", "../../../assets/imgs/right.png")
					}
				} else {
					ti.content = temp?.replace("minus.png", "../../../assets/imgs/plus.png")
				}
				// ti.content = temp.replace('src="imgs/minus.png"', 'src="imgs/minus.png" onclick=remove_from_cart(' + '"' +item.id+ '"' + ') ')
				ti.content = ti?.content?.replace('class="deleteicon_click deleteicon hide_bar_add"', 'class="deleteicon_click deleteicon add_cart_icon hide_bar_add"')
				ti.content = ti?.content?.replace('class="deleteicon_click deleteicon"', 'class="deleteicon_click deleteicon add_cart_icon"')
				var temp2 = document.createElement('div');
				temp2.innerHTML = ti.content;
				if(item.digital_coupon==false && item.isBanner != true){
				var newImg = document.createElement('img') ;
				newImg.src = "assets/images/edit.png"; // Replace with the desired image path
				newImg.id = item.id; // Set a unique ID
				newImg.className = "EditPro"; // Add your desired class
				newImg.style.position = "absolute";
				newImg.style.width = "30px";
				newImg.style.height = "30px";
				newImg.style.left = "5px";
				newImg.style.top = "2px";				
				// Append or insert the new image tag
				// Example: Append to the first child element of temp2
					temp2.appendChild(newImg);
				}
				const productTitleDiv = temp2.querySelector('.product-title') as HTMLElement;
				if(productTitleDiv){
					productTitleDiv.style.padding = '0px 40px 0px 40px'; // top, right, bottom, le
					}
				ti.content = temp2.innerHTML;
			
				const img_dialog = temp2.getElementsByClassName("img_dialog")
				if (img_dialog.length != 0) {
					img_dialog[0].remove()
				}
				const img_dialog1 = temp2.getElementsByClassName("EditPro")
				if (img_dialog1.length >1) {
					img_dialog1[0].remove()
				}
				if (view == "listView" && window.innerWidth < 768) {
					const iframe = temp2.getElementsByClassName("iframe");
					const actualDatePrice = temp2.getElementsByClassName("actualDate_price")
					const priceLabel1 = temp2.querySelector('.pricelabel') as HTMLElement;
					const linkSymbol1 = temp2.getElementsByClassName("linkSymbol")
					const packItem1 = temp2.getElementsByClassName("packItem")
					const fill_video = temp2.getElementsByClassName("fill_video")
					const pricelabel = temp2.getElementsByClassName("pricelabel")
					const deleteIcon = temp2.getElementsByClassName("deleteicon")
					const offerLabel = temp2.getElementsByClassName("offerLabel")
					const countproduct = temp2.getElementsByClassName("count-products")
					const productTitle = temp2.getElementsByClassName("product-title")
					const digitalCoupon = temp2.querySelector('.Digital_price_main') as HTMLElement;
					const packDetailsDivF = temp2.querySelector('.packDetailsName') as HTMLElement;
					const productTitleDiv = temp2.querySelector('.product-title') as HTMLElement;
					if(productTitleDiv){
						productTitleDiv.style.padding = '0px 40px 0px 40px'; // top, right, bottom, le
						productTitleDiv.style.margin = '-5px 0px 0px 0px'; // top, right, bottom, le
					}
					if (digitalCoupon) {
						digitalCoupon.style.display = 'none';
					}
					if (priceLabel1) {
						priceLabel1.style.display = 'none';
					}
					if (packDetailsDivF) {
						packDetailsDivF.style.display = 'none';
					}

					const transactionLimit = temp2.querySelector('.use_up_to_wrapper') as HTMLElement;
					if (transactionLimit) {
						transactionLimit.style.display = 'none';
					}

					const link = temp2.querySelector('.price_link_data') as HTMLElement;
					if (link) {
						link.style.display = 'none';
					}
					if (pricelabel.length == 0 && productTitle.length == 0 && deleteIcon.length == 0) {
						iterationDone = false;
					}
					if (iframe.length != 0) {
						iframe[0].remove()
					}
					if (linkSymbol1.length != 0) {
						linkSymbol1[0].remove()
					}
					if (packItem1.length != 0) {
						packItem1[0].remove()
					}
					if (actualDatePrice.length != 0) {
						actualDatePrice[0].remove()
					}
					if (fill_video.length != 0) {
						fill_video[0].remove()
					}
					if (offerLabel.length != 0) {
						offerLabel[0].remove()
					}
					if (deleteIcon.length > 0) {
						deleteIcon[0].classList.add("deleteIconlistView");
					}
					if (countproduct.length > 0) {
						countproduct[0].classList.add("countProductlistView");
					}
					if (productTitle.length > 0) {
						productTitle[0].classList.add("productTitlelistView");
					}

					let img_elements: NodeListOf<HTMLImageElement> = temp2.querySelectorAll('img');
					let video_elements: NodeListOf<HTMLVideoElement> = temp2.querySelectorAll('video');
					if (item.isVideo == true) {
						if (video_elements.length > 0) {
							video_elements[0].parentElement.remove();
						}
					} else if (item.isVideo != 'youtube' || item.isVideo != 'facebook') {
						if (img_elements.length > 2) {
							img_elements[0].remove();
						}
					} else if (item.isVideo == 'youtube' || item.isVideo == 'facebook') {
						if (img_elements.length > 2) {
							img_elements[0].remove();
						}
					}
					// Create a new div element
					const actualDate_price = document.createElement('div');
					actualDate_price.className = 'actualDate_price'
					const newDiv = document.createElement('div');
					newDiv.className = "actualDate"; // Set the class name

					// Access the actual_start_date from this.current_layout_data[0]
					const actualStartDate = this.current_layout_data[0]?.actual_start_date;
					const actual_end_date = this.current_layout_data[0]?.actual_end_date;
					if (item.updated) {
						const updatedTime = item.updatedAt || 0; // Default to 0 if not available
						const currentTime = Date.now();
						const timeDifference = currentTime - updatedTime;
					
						// Check if 24 hours have passed (24 * 60 * 60 * 1000 ms)
						if (timeDifference >= 24 * 60 * 60 * 1000) {
							console.log("More than 24 hours passed, removing green dot");
							
							const existingDot = temp2.querySelector('.product-title')?.querySelector('.greenDot');
							if (existingDot) {
								temp2.querySelector('.product-title')?.removeChild(existingDot);
							}
						} else {
							// If within 24 hours, show the green dot
							const existingDot = temp2.querySelector('.product-title')?.querySelector('.greenDot');
							if (existingDot) {
								temp2.querySelector('.product-title')?.removeChild(existingDot);
							}
					
							const greenDot = document.createElement('div');
							greenDot.className = "greenDot"; // Assign a class for styling
							temp2.querySelector('.product-title')?.prepend(greenDot);
							
							const proGreenDot = temp2.querySelector('.product-title') as HTMLElement;
							proGreenDot?.style.setProperty('display', 'flex', 'important');
							proGreenDot?.style.setProperty('flex-direction', 'row', 'important');
							proGreenDot?.style.setProperty('justify-content', 'flex-start', 'important');
							proGreenDot?.style.setProperty('padding-left', '0px', 'important');
						}
					}
					// Check if actual_start_date exists before appending it to the new div
					if (actualStartDate && actual_end_date) {
						// Create a text node with the actual_start_date value
						const textNode = document.createTextNode(actualStartDate + ' To ' + actual_end_date);

						// Append the text node to the new div
						newDiv.appendChild(textNode);
						actualDate_price.appendChild(newDiv)
						// Append the new div to temp2
						temp2.appendChild(actualDate_price);
					}
					const price = this.getPrice(item.content, item.digital_coupon)
					const newDivPrice = document.createElement('div');
					newDivPrice.className = "priceLabel"; // Set the class name
					if (price) {
						// Create a text node with the actual_start_date value
						const textNode = document.createTextNode(price);

						// Append the text node to the new div
						newDivPrice.appendChild(textNode);
						actualDate_price.appendChild(newDivPrice)

						// Append the new div to temp2
						temp2.appendChild(actualDate_price);
					}
					if (item.digital_coupon) {
						const link_icon = document.createElement('div');
						link_icon.className = "linkSymbol"
						const link_svg = document.createElement('img')
						link_svg.setAttribute('src', 'assets/imgs/coupon-svgrepo-com (4).svg')
						link_icon.appendChild(link_svg);
						temp2.appendChild(link_icon);
					}


					const newDivPack = document.createElement('div');
					newDivPack.className = "packItem"; // Set the class name

					const matchingItem = this.packDetails.find((packItem: { uid: any; pack?: any }) => packItem.uid === item.id);
					if (matchingItem?.pack) {
						// Create a text node with the actual_start_date value
						const textNode = document.createTextNode(`Pack:  ${matchingItem?.pack}`);

						// Append the text node to the new div
						newDivPack.appendChild(textNode);

						// Append the new div to temp2
						temp2.appendChild(newDivPack);
					}
				}
				this.show_loader1 = false

				if (iterationDone) {
					ti.content = temp2.innerHTML
					// ti.content = temp.replace("minus.png", '../../../assets/imgs/plus.png')
					dataForLoad?.push(ti)
					this.loadPage = localStorage.getItem('loadPage');
					if(this.loadPage=='circular'){
					this.grid?.load(dataForLoad, true);
					this.grid.compact()
					}
					this.cdr?.detectChanges()
			}
			})
			)
			
		}
		this.store_category = this.layout_cat
		if (dataForLoad.length > 0) {
			this.add_to_cart()
		}
		var elements = document.getElementsByClassName("grid-stack-item-content");
		for (var i = 0; i < elements.length; i++) {
			(elements[i] as HTMLElement).style.overflowY = "hidden";
		}
	}

	add_to_cart() {
		this.serializedData.forEach((item, index) => {
			if (document.getElementById(item.id)) {
				let main_item
				let title_bar
				let count_icon_plus: HTMLElement
				let count_icon_minus: HTMLElement
				let delete_icon
				let edit_pro
				let priceLabelClick
				let layout_product_qnt
				let layout_count_product: HTMLElement
				const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
				if (view == "listView" && window.innerWidth < 768) {
					main_item = document.getElementById(item.id)?.parentElement;
					delete_icon = main_item?.getElementsByClassName('deleteIconlistView')[0]
					edit_pro = main_item?.parentElement.getElementsByClassName('EditPro')[0]
					layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
					count_icon_plus = main_item?.parentElement?.getElementsByClassName("count-products")[0]?.firstChild as HTMLElement;
					count_icon_minus = main_item?.parentElement?.getElementsByClassName("count-products")[0]?.lastChild as HTMLElement;
				} else {
					if (document.getElementById(item.id).tagName != 'VIDEO') {
						if (item.isVideo == 'youtube') {
							main_item = document.getElementById(item.id)?.parentElement
						} else {
							main_item = document.getElementById(item.id)
						}
					} else {
						main_item = document.getElementById(item.id)?.parentElement
					}
					if (main_item?.parentElement?.childNodes[0]?.classList.contains("offerLabel")) {
						title_bar = main_item?.parentElement?.childNodes[2];
						count_icon_plus = main_item?.parentElement?.childNodes[3]?.firstChild as HTMLElement;
						count_icon_minus = main_item?.parentElement?.childNodes[3]?.lastChild as HTMLElement;
					} else {
						title_bar = main_item?.parentElement?.childNodes[1];
						count_icon_plus = main_item?.parentElement?.childNodes[2]?.firstChild as HTMLElement;
						count_icon_minus = main_item?.parentElement?.childNodes[2]?.lastChild as HTMLElement;
					}
					priceLabelClick = main_item?.parentElement?.getElementsByClassName('pricelabel')[0];
					if (priceLabelClick) {
						priceLabelClick.style.cursor = 'pointer';
					}

					layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
					delete_icon = main_item?.parentElement.getElementsByClassName('deleteicon')[0]
					edit_pro = main_item?.parentElement.getElementsByClassName('EditPro')[0]
					layout_count_product = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;
					if (this.cart_ids.some(x => x == item.id)) {
						title_bar?.classList?.add("test")
					}
				}
				if (item.digital_coupon) {
					if (layout_count_product) {
						layout_count_product.style.visibility = "hidden"
					}
				} else {
					if (this.cart_ids.some(x => x == item.id)) {
						layout_product_qnt.innerHTML = this.cartItem.find(x => {
							if (x.product.id) {
								return x.product.id == item.id;
							} else {
								return x.product.uid == item.id;
							}
						})?.count || 0;
						layout_count_product.style.visibility = "visible"
					}
				}
				main_item.onclick = async () => {
					let check = 0;
					const storeItems = await this.afs.collection('/store-items', ref => ref.where('uid', '==', item.id)).get().toPromise()
					const product: any = storeItems.docs[0].data();
					const convertBlankArrayToString = (value) => {
						return Array.isArray(value) && value.length === 0 ? '' : value;
					};

					// Applying the helper function to the relevant fields
					product.price_label_value1 = convertBlankArrayToString(product.price_label_value1);
					product.price_label_value2 = convertBlankArrayToString(product.price_label_value2);
					product.price_label_value3 = convertBlankArrayToString(product.price_label_value3);

					let youtubeCon = ''
					let hideLabelCon = ''

					const hide_addtolist = product.hide_addtolist_label
					const hide_price = product.hide_price_label
					const hide_productname = product.hide_productname_label
					const new_window = product.new_window
					const autoplay = product.autoplay

					// youtube autoplay and new tab conditions
					youtubeCon =
						(autoplay && new_window) ? 'both'
							:
							(autoplay && !new_window) ? 'autoplay'
								:
								(new_window && !autoplay) ? 'newtab'
									:
									'none'

					// hide price, add to list and product name label conditions
					hideLabelCon =
						(hide_price && !hide_addtolist && !hide_productname) ? 'price'
							:
							(hide_price && hide_addtolist && !hide_productname) ? 'price_addlist'
								:
								(hide_price && !hide_addtolist && hide_productname) ? 'price_name'
									:
									(!hide_price && hide_addtolist && !hide_productname) ? 'addlist'
										:
										(!hide_price && hide_addtolist && hide_productname) ? 'addlist_name'
											:
											(!hide_price && !hide_addtolist && hide_productname) ? 'name'
												:
												(hide_price && hide_addtolist && hide_productname) ? 'trio'
													:
													'none'

					if (product.product_url && check <= 0) {
						var domain = new URL(product.product_url)
						// Youtube Part
						if (domain.hostname == "www.youtube.com" && check <= 0) {
							check++
							this.handleYoutubeURL(product, item, youtubeCon, item.domain_name)
						} else if (domain.hostname == "www.facebook.com" && check <= 0) {
							check++
							this.handleYoutubeURL(product, item, youtubeCon, item.domain_name)
						}
						//Other URL
						else {
							check++
							this.handleYoutubeURL(product, item, youtubeCon, item.domain_name)
						}
					} else {
						check++
						this.hideLabelConditions(hideLabelCon, product, item)
					}
				};
				if(edit_pro){
					edit_pro.onclick =async () =>{
						const storeItems = await this.afs.collection('/store-items', ref => ref.where('uid', '==', item.id)).get().toPromise()
						const product: any = storeItems.docs[0].data();
					    this.openDialog(product, item,this.current_layout_data[0].uid,this.current_store_uid,this.cart_ids)
					}
				}
				if (priceLabelClick) {
					priceLabelClick.onclick = async () => {
						let check = 0;
						const storeItems = await this.afs.collection('/store-items', ref => ref.where('uid', '==', item.id)).get().toPromise()
						const product: any = storeItems.docs[0].data();
						const convertBlankArrayToString = (value) => {
							return Array.isArray(value) && value.length === 0 ? '' : value;
						};

						// Applying the helper function to the relevant fields
						product.price_label_value1 = convertBlankArrayToString(product.price_label_value1);
						product.price_label_value2 = convertBlankArrayToString(product.price_label_value2);
						product.price_label_value3 = convertBlankArrayToString(product.price_label_value3);

						let youtubeCon = ''
						let hideLabelCon = ''

						const hide_addtolist = product.hide_addtolist_label
						const hide_price = product.hide_price_label
						const hide_productname = product.hide_productname_label
						const new_window = product.new_window
						const autoplay = product.autoplay

						// youtube autoplay and new tab conditions
						youtubeCon =
							(autoplay && new_window) ? 'both'
								:
								(autoplay && !new_window) ? 'autoplay'
									:
									(new_window && !autoplay) ? 'newtab'
										:
										'none'

						// hide price, add to list and product name label conditions
						hideLabelCon =
							(hide_price && !hide_addtolist && !hide_productname) ? 'price'
								:
								(hide_price && hide_addtolist && !hide_productname) ? 'price_addlist'
									:
									(hide_price && !hide_addtolist && hide_productname) ? 'price_name'
										:
										(!hide_price && hide_addtolist && !hide_productname) ? 'addlist'
											:
											(!hide_price && hide_addtolist && hide_productname) ? 'addlist_name'
												:
												(!hide_price && !hide_addtolist && hide_productname) ? 'name'
													:
													(hide_price && hide_addtolist && hide_productname) ? 'trio'
														:
														'none'

						if (product.product_url && check <= 0) {
							var domain = new URL(product.product_url)
							// Youtube Part
							if (domain.hostname == "www.youtube.com" && check <= 0) {
								check++
								this.handleYoutubeURL(product, item, youtubeCon, item.domain_name)
							} else if (domain.hostname == "www.facebook.com" && check <= 0) {
								check++
								this.handleYoutubeURL(product, item, youtubeCon, item.domain_name)
							}

							//Other URL
							else {
								check++
								this.handleYoutubeURL(product, item, youtubeCon, item.domain_name)
							}
						} else {
							check++
							this.hideLabelConditions(hideLabelCon, product, item)
						}
					};
				}
				
				if (delete_icon) {
					delete_icon.onclick = async () => {
						event.stopPropagation();
						const visibility = layout_count_product?.style?.visibility
						if (item.digital_coupon) {
							layout_count_product.style.visibility = 'hidden'

						} else {
							if (visibility == "hidden" || visibility == "") {
								layout_count_product.style.visibility = 'visible'
							} else {
								layout_count_product.style.visibility = 'hidden'
							}
						};
						if (!this.cart_ids.includes(item.id)) {
							let data_content = main_item?.parentElement?.lastElementChild?.getElementsByClassName('pricelabel')[0]?.innerHTML
							await this.afs.collection('/store-items', ref => ref.where('uid', '==', item.id)).snapshotChanges().subscribe(async (data: any) => {
								let product = await data.map(e => {
									const convertArrayToString = (value) => {
										return Array.isArray(value) ? value.join('') : value;
									};
									return {
										uid: e.payload.doc.id,
										fromCSV: e.payload.doc.data().fromCSV ? e.payload.doc.data().fromCSV : '',
										upc_code: e.payload.doc.data().upc_code,
										product_image: e.payload.doc.data().product_image,
										product_description: e.payload.doc.data().product_description,
										category: e.payload.doc.data().category,
										pack: e.payload.doc.data().pack,
										price_label_format: e.payload.doc.data().price_label_format ? e.payload.doc.data().price_label_format : '',
										price_label_value1: convertArrayToString(e.payload.doc.data().price_label_value1 ? e.payload.doc.data().price_label_value1 : ''),
										price_label_value2: convertArrayToString(e.payload.doc.data().price_label_value2 ? e.payload.doc.data().price_label_value2 : ''),
										price_label_value3: convertArrayToString(e.payload.doc.data().price_label_value3 ? e.payload.doc.data().price_label_value3 : ''),
										isVideo: e.payload.doc.data().isVideo ? e.payload.doc.data().isVideo : false,
										product_url: e.payload.doc.data().product_url,
										new_window: e.payload.doc.data().new_window ? e.payload.doc.data().new_window : '',
										digital_coupon: e.payload.doc.data().digital_coupon ? e.payload.doc.data().digital_coupon : false,
										sale_price_label_format: e.payload.doc.data().sale_price_label_format ? e.payload.doc.data().sale_price_label_format : '',
										sale_price_label_value1: e.payload.doc.data().sale_price_label_value1 ? e.payload.doc.data().sale_price_label_value1 : '',
										sale_price_label_value2: e.payload.doc.data().sale_price_label_value2 ? e.payload.doc.data().sale_price_label_value2 : '',
										sale_price_label_value3: e.payload.doc.data().sale_price_label_value3 ? e.payload.doc.data().sale_price_label_value3 : '',
									};
								});
								let msg = '';
								let test
								product[0].fromCSV = product[0]?.fromCSV ? product[0]?.fromCSV : '';
								if (item.product_image) {
									if (product[0].digital_coupon) {
										msg = this.make_price_message(item?.sale_price_label_format, item?.sale_price_label_value1, item?.sale_price_label_value2, item?.sale_price_label_value3)
										test = this.cartItem.some(x =>
											x?.product?.id == item?.id
										)
									} else {
										msg = this.make_price_message(item?.price_label_format, item?.price_label_value1, item?.price_label_value2, item?.price_label_value3)
										test = this.cartItem.some(x =>
											x?.product?.id == item?.id
										)
									}
								} else {
									if (product[0].digital_coupon) {
										msg = this.make_price_message(product[0]?.sale_price_label_format, product[0]?.sale_price_label_value1, product[0]?.sale_price_label_value2, product[0]?.sale_price_label_value3)
										test = this.cartItem.some(x =>
											x?.product?.uid == product[0]?.uid
										)
									} else {
										msg = this.make_price_message(product[0]?.price_label_format, product[0]?.price_label_value1, product[0]?.price_label_value2, product[0]?.price_label_value3)
										test = this.cartItem.some(x =>
											x?.product?.uid == product[0]?.uid
										)
									}
								}
								if (!test) {
									if (this.past_item && this.past_item.length > 0) {
										if (item.product_image) {
											this.past_item = this.past_item.filter(pastItem =>
												pastItem?.product?.id !== item.id && pastItem?.product?.uid !== item.id
											);
										} else {
											this.past_item = this.past_item.filter(pastItem =>
												pastItem?.product?.uid !== product[0]?.uid
											)
										}

									}
									if (item.product_image) {
										this.cartItem.push({ product: item, count: 1, static: false, priceMessage: msg, digital_coupon: item.digital_coupon ? item.digital_coupon : false ,isBundleProduct : item.isBundleProduct ? item.isBundleProduct : false,displayValidDate:true})
										layout_product_qnt.innerHTML = "1"

									} else {
										this.cartItem.push({ product: product[0], count: 1, static: false, priceMessage: msg, digital_coupon: product[0].digital_coupon ? product[0].digital_coupon : false ,isBundleProduct : product[0].isBundleProduct ? product[0].isBundleProduct : false,displayValidDate:true})
										layout_product_qnt.innerHTML = "1"
									}
								}
								if (this.current_list_id !== '') {
									await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem, past_items: this.past_item ? this.past_item : [] }, { merge: true }).then(docRef => {
									});
									delete_icon.src = '../../../assets/imgs/right.png'
									title_bar?.classList?.add("test");
									await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
										this.current_list_data = data.map(e => {
											return {
												uid: e.payload.doc.id,
												items: e.payload.doc.data().items ? e.payload.doc.data().items : [],
												past_items: e.payload.doc.data().past_items ? e.payload.doc.data().past_items : []
											};
										});
										this.current_list_data = this.current_list_data[0]
										this.cartItem = this.current_list_data.items
										this.past_item = this.current_list_data.past_items;
										this.cart_ids = []
										this.current_list_data.items.forEach(i => {
											if (i.product.id) {
												this.cart_ids.push(i.product.id)
											} else {
												this.cart_ids.push(i.product.uid)
											}
										});
									});
								} else {
									if (!this.createdCart) {
										this.createdCart = true;
										await this.createCart();
									}
									delete_icon.src = '../../../assets/imgs/right.png'
									title_bar?.classList?.add("test")
								}
							})
							this.cart_ids.push(item.id);
						} else {
							if (this.current_list_id !== '') {
								await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem, past_items: this.past_item ? this.past_item : [] }, { merge: true }).then(docRef => {
								});
								await this.afs.collection('/list', ref => ref.where('uid', '==', this.current_list_id)).snapshotChanges().subscribe((data: any) => {
									this.current_list_data = data.map(e => {
										return {
											uid: e.payload.doc.id,
											items: e.payload.doc.data().items ? e.payload.doc.data().items : [],
											past_items: e.payload.doc.data().past_items ? e.payload.doc.data().past_items : []
										};
									});
									this.current_list_data = this.current_list_data[0]
									this.cartItem = this.current_list_data.items
									this.past_item = this.current_list_data.past_items;
									this.cart_ids = []
									this.current_list_data.items.forEach(i => {
										if (i.product.id) {
											this.cart_ids.push(i.product.id)
										} else {
											this.cart_ids.push(i.product.uid)
										}
									});
								});
							} else {
								if (!this.createdCart) {
									this.createdCart = true;
									await this.createCart();
								}
							}
							delete_icon.src = '../../../assets/imgs/plus.png'
							title_bar?.classList.remove("test")
							layout_product_qnt.innerHTML = ''
							if (item.product_image) {
								this.cartItem = this.cartItem.filter(x =>
									x?.product?.id !== item.id
								)
							} else {
								this.cartItem = this.cartItem.filter(x =>
									x?.product?.uid !== item.id
								)
							}
							this.cart_ids = this.cart_ids.filter(x => x !== item.id)
							await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem, past_items: this.past_item ? this.past_item : [] }, { merge: true }).then(docRef => {
							});
						}
					}
				}
				if (count_icon_plus) {
					$(count_icon_plus).on("click", async () => {
						event.stopPropagation();
						let index
						if (item.product_image) {
							index = this.cartItem.findIndex(c => c.product.id == item.id)
						} else {
							index = this.cartItem.findIndex(c => c.product.uid == item.id)
						}
						let temp_itm = this.cartItem[index]
						temp_itm.count += 1
						this.cartItem[index] = temp_itm
						await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
						});
						layout_product_qnt.innerHTML = temp_itm.count
					})
				}
				if (count_icon_minus) {
					$(count_icon_minus).on("click", async () => {
						event.stopPropagation();
						let index
						if (item.product_image) {
							index = this.cartItem.findIndex(c => c.product.id == item.id)
						} else {
							index = this.cartItem.findIndex(c => c.product.uid == item.id)
						}
						let temp_itm = this.cartItem[index]
						if (temp_itm.count > 1) {
							temp_itm.count -= 1
							this.cartItem[index] = temp_itm
							layout_product_qnt.innerHTML = temp_itm.count
						} else if (temp_itm.count === 1) {
							if (!this.serializedIds && this.serializedIds == undefined) {
								this.serializedIds = this.combinedData.map(s => s.id)
							}
							this.cartItem.splice(index, 1)
							if (!temp_itm.static && this.serializedIds.includes(item.id)) {
								title_bar?.classList?.remove("test")
								layout_product_qnt.innerHTML = ''
								layout_count_product.style.visibility = 'hidden'
								this.cart_ids.splice(this.cart_ids.indexOf(item.id), 1)
								delete_icon.src = "../../../assets/imgs/plus.png"
							}
						}
						await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
						});
					})
				}
			}
		})
	}

	handleYoutubeURL(product, item, youtubeCon, domain_name) {
		const view = localStorage.getItem("view") ? localStorage.getItem("view") : "normalView"
		if (view == "listView" && window.innerWidth < 768) {
			if (domain_name == '') {
				location.href = product.product_url
			} else {
				window.open(product.product_url)
			}
		} else {
			switch (youtubeCon) {
				case 'both':
					// open in new tab with autoplay
					if (domain_name === 'youtube' || domain_name === 'facebook') {
						window.open(`${product.product_url}?autoplay=1&mute=1`);
					} else {
						window.open(product.product_url);
					}
					break;

				case 'autoplay':
					// place iframe with autoplay
					if (domain_name == 'youtube') {
						this.loadIframe(product, item)
					}
					break;

				case 'newtab':
					// open in new tab
					window.open(product.product_url)
					break;

				default:
					// none part
					if (domain_name == '') {
						location.href = product.product_url
					} else if (domain_name == 'youtube') {
						this.loadIframe(product, item)
					}
					break;
			}
		}
	}


	hideLabelConditions(hideLabelCon, product, item) {
		switch (hideLabelCon) {
			case 'price':
				// only price hide
				this.openDialogImage(product, item)
				break;
			case 'trio':
				break;
			default:
				// none part (show all)
				this.openDialogImage(product, item)
				break;
		}
	}

	openDialog(product, item,uid,store_uid,cart_id) {
		if (!this.pro_dailog) {
			this.pro_dailog = true;
			let dialogRef
			if (item.product_image) {
				dialogRef = this.dialog.open(ProductDialog5ComponentComponent,{
					data: {
						product: item,
						YoutubeURL: false,
						gridView: item,
						startDate: this.current_layout_data[0]?.start_date.seconds * 1000,
						endDate: this.current_layout_data[0]?.end_date.seconds * 1000,
						actualStartDate: this.current_layout_data[0]?.actual_start_date ? this.current_layout_data[0]?.actual_start_date : this.current_layout_data[0]?.start_date.seconds * 1000,
						actualEndDate: this.current_layout_data[0]?.actual_end_date ? this.current_layout_data[0]?.actual_end_date : this.current_layout_data[0]?.end_date.seconds * 1000,
						override_noad_layout: this.store_data[0]?.override_noad_layout,
						increaseFun: this.increase_product,
						decreaseFun: this.decrease_product,
						id: product.uid,
						layout_id:uid,
						store_uid:store_uid,
						cart_id:cart_id,
						storeName:this.store_data[0].company_name,
						price_label:this.current_layout_data[0].price_label_type,
						companyNameSlug:this.store_data[0].company_name_slug
					},
				});
			} else {
				dialogRef = this.dialog.open(ProductDialog5ComponentComponent,{
					data: {
						product: product,
						YoutubeURL: false,
						gridView: item,
						startDate: this.current_layout_data[0]?.start_date.seconds * 1000,
						endDate: this.current_layout_data[0]?.end_date.seconds * 1000,
						actualStartDate: this.current_layout_data[0]?.actual_start_date ? this.current_layout_data[0]?.actual_start_date : this.current_layout_data[0]?.start_date.seconds * 1000,
						actualEndDate: this.current_layout_data[0]?.actual_end_date ? this.current_layout_data[0]?.actual_end_date : this.current_layout_data[0]?.end_date.seconds * 1000,
						override_noad_layout: this.store_data[0]?.override_noad_layout,
						increaseFun: this.increase_product,
						decreaseFun: this.decrease_product,
						id: product.uid,
						layout_id:uid,
						store_uid:store_uid,
						cart_id:cart_id,
						storeName:this.store_data[0].company_name,
						price_label:this.current_layout_data[0].price_label_type,
						companyNameSlug:this.store_data[0].company_name_slug
					},
				});
			}
			dialogRef.componentInstance.cart_created.subscribe((res) => {
				if (res) {
					this.headerComponent.ngOnInit()
				}
			})
			dialogRef.afterClosed().subscribe(async result => {
				this.pro_dailog = false;
				this.current_list_data = dialogRef.componentInstance.current_list_data
				this.cartItem = dialogRef.componentInstance.cartItem
				this.cart_ids = dialogRef.componentInstance.cart_ids
				const params = new URLSearchParams(window.location.search)
				const store_itemData = await this.afs.collection('/store-items', ref => ref.where('store_uid', '==', this.store_data[0].uid).orderBy('created_at', 'desc')).get().toPromise();
			    this.packDetails = store_itemData.docs.map(doc => ({
			    	uid: doc.id,
			    	pack: (doc.data() as { pack: any }).pack
			    }));
				if (this.route.snapshot.params.id && params.has('preview') && params.get('preview') === 'true') {
					if (params.has('type') && params.get('type') === 'noad') {
						this.current_layout_data = await this.getLayoutPreview("/layouts-noad")
					} else {
						this.current_layout_data = await this.getLayoutPreview("/layouts")
					}
				} else {
					if (!this.store_data[0].override_noad_layout) {
						this.current_layout_data = await this.getLayoutData();
					} else {
						const overrideLayout = await this.afs.collection('/layouts-noad', ref => ref.where('store_uid', '==', this.current_store_uid)).get().toPromise()
						this.current_layout_data = overrideLayout.docs.map(doc => doc.data());
					}
				}
				this.layoutData()
			});
		}
	}
	openDialogImage(product, item) {
		if (!this.pro_dailog) {
			this.pro_dailog = true;
			let dialogRef
			if (item.product_image) {
				dialogRef = this.dialog.open(ProductDialog6ComponentComponent,{
					data: {
						product: item,
						YoutubeURL: false,
						gridView: item,
						startDate: this.current_layout_data[0]?.start_date.seconds * 1000,
						endDate: this.current_layout_data[0]?.end_date.seconds * 1000,
						actualStartDate: this.current_layout_data[0]?.actual_start_date ? this.current_layout_data[0]?.actual_start_date : this.current_layout_data[0]?.start_date.seconds * 1000,
						actualEndDate: this.current_layout_data[0]?.actual_end_date ? this.current_layout_data[0]?.actual_end_date : this.current_layout_data[0]?.end_date.seconds * 1000,
						override_noad_layout: this.store_data[0]?.override_noad_layout,
						increaseFun: this.increase_product,
						decreaseFun: this.decrease_product,
						id: product.uid
					},
				});
			} else {
				dialogRef = this.dialog.open(ProductDialog6ComponentComponent,{
					data: {
						product: product,
						YoutubeURL: false,
						gridView: item,
						startDate: this.current_layout_data[0]?.start_date.seconds * 1000,
						endDate: this.current_layout_data[0]?.end_date.seconds * 1000,
						actualStartDate: this.current_layout_data[0]?.actual_start_date ? this.current_layout_data[0]?.actual_start_date : this.current_layout_data[0]?.start_date.seconds * 1000,
						actualEndDate: this.current_layout_data[0]?.actual_end_date ? this.current_layout_data[0]?.actual_end_date : this.current_layout_data[0]?.end_date.seconds * 1000,
						override_noad_layout: this.store_data[0]?.override_noad_layout,
						increaseFun: this.increase_product,
						decreaseFun: this.decrease_product,
						id: product.uid
					},
				});
			}
			dialogRef.componentInstance.cart_created.subscribe((res) => {
				if (res) {
					this.headerComponent.ngOnInit()
				}
			})
			dialogRef.afterClosed().subscribe(result => {
				this.pro_dailog = false;
				this.current_list_data = dialogRef.componentInstance.current_list_data
				this.cartItem = dialogRef.componentInstance.cartItem
				this.cart_ids = dialogRef.componentInstance.cart_ids
			});
		}
	}
	loadIframe(product, item) {
		let baseSize = 8
		if (window.innerWidth < 768) {
			baseSize = 3
		}
		const div = document.createElement('div')
		div.innerHTML = item.content
		const imgTag = document.getElementById(item.id)
		if (imgTag) {
			const iframeTag = `<div class="iframe" > <iframe class="hide_bar" width="${(item.w / baseSize) * window.innerWidth}" height="${(item.h / baseSize) * window.innerWidth - 15}" src="${product.product_url}?autoplay=1&muted=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> </div>`
			imgTag.outerHTML = iframeTag
		}
	}
	getCartList() {
		this.cart_ids = []
		this.afs.collection('/list', ref => ref.where('uid', '==', this.getListID())).snapshotChanges().subscribe((data: any) => {
			this.current_list_data = data.map(e => {
				return {
					uid: e.payload.doc.id,
					items: e.payload.doc.data().items,
				};
			});
			this.current_list_data = this.current_list_data[0]
			this.cartItem = this.current_list_data.items
			this.cart_ids = this.current_list_data.items.map((item) => {
				return item.product.uid
			})
			setTimeout(() => {
				this.loadCircularGrid(this.cart_ids);
			}, 200)
		});
	}

	getListID() {
		return localStorage.getItem('list_id')
	}

	getFormate(data, digital_coupon) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		if (digital_coupon) {
			return temp?.getElementsByClassName("price_format_wrapper")[0]?.getAttribute("id") || ''
		} else {
			return temp?.getElementsByClassName("circular-card-deal-wrapper")[0]?.getAttribute("id") || ''
		}
	}

	getPrice(data, digital_coupon) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		let label1;
		let label2;
		let label3;
		var formate = this.getFormate(data, digital_coupon)
		if (digital_coupon) {
			label1 = $(temp).find('#sale_price_doller').text().trim()
			label2 = $(temp).find('#sale_price_cent').text().trim()
			label3 = $(temp).find('#sale_price_for_value').text().trim()
		} else {
			label1 = $(temp).find('#dollar').text().trim()
			label2 = $(temp).find('#cent').text().trim()
			label3 = $(temp).find('#third').text().trim()
		}
		switch (formate) {
			case 'format1':
				return '$' + label1;
			case 'format2':
			case 'final_price_format1':
			case 'sale_price_format1':
				if (label1 && label2) {
					return '$' + label1 + '.' + label2;
				} else if (label1 && label2 == '') {
					return '$' + label1;
				} else {
					return '$' + '0' + '.' + label2;
				}
			case 'format3':
				return '$' + label1 + '.' + label2 + '/LB';
			case 'format4':
				return '$' + label1 + '.' + label2 + '/EA';
			case 'format5':
				if (label2 && label3) {
					return label1 + ' For $' + label2 + '.' + label3;
				} else if (label2 && label3 == '') {
					return label1 + ' For $' + label2;
				} else {
					return label1 + ' For $' + '0' + '.' + label3;
				}
			case 'format6':
				return 'Buy ' + label1 + ' Get ' + label2 + ' For ' + (label3 ? label3 : 'Free');
			case 'format7':
				return '$' + label1 + '.' + label2 + ' Off';
			case 'final_price_format2':
			case 'sale_price_format2':
				if (label1 && label2) {
					return label3 + ' For $' + label1 + '.' + label2;
				} else if (label1 && label2 == '') {
					return label3 + ' For $' + label1;
				} else {
					return label3 + ' For $' + '0' + '.' + label2;
				}
			default:
				return '';
		}
	}

	async getPack(id) {
		const store_item = await this.afs.collection('/store-items', ref => ref.where('uid', '==', id)).get().toPromise()
			.then(storeItem => {
				return storeItem.docs.map(doc => doc.data());
			});
		return store_item[0]
	}

	getImage(data) {
		var temp = document.createElement('div');
		temp.innerHTML = data;
		var doc = new DOMParser().parseFromString(temp?.innerHTML, "text/html")
		return doc.querySelector('img')?.getAttribute('src')
	}

	async createCart() {
		await this.afs.collection('/list').add({ items: this.cartItem }).then(docRef => {
			const itemRefx2 = this.afs.doc('/list/' + docRef.id);
			itemRefx2.update({ uid: docRef.id });
			localStorage.setItem('list_id', docRef.id)
			let list_ids;
			if (localStorage.getItem('list_ids')) {
				list_ids = JSON.parse(localStorage.getItem('list_ids'))
				list_ids[this.store_name_slug] = docRef.id;
				localStorage.setItem('list_ids', JSON.stringify(list_ids))
			} else {
				list_ids = {
					[this.store_name_slug]: docRef.id
				}
				localStorage.setItem('list_ids', JSON.stringify(list_ids))
			}
			this.current_list_id = docRef.id
			this.qr_value = window.location.href + '/list/' + this.current_list_id;
			this.show_loader = false
			this.show_loader1 = false
		});
	}
	make_price_message(formate, value1, value2, value3) {
		if ((value1 !== null || value1 !== '') && (value2 !== null || value2 !== '')) {
			if (formate === 'format6') {
				return `Buy ${value1} Get ${value2} ${value3}`
			}
			if (formate === 'format2' ||formate === 'Final Price Format1'|| formate === 'Sale Price format1'|| formate === 'format3' || formate === 'format4' || formate === 'format7') {
				if(value1==''){
					return `$0.${value2}`
				}else if(value1 && value2){
				return `$${value1}.${value2}`
				}else if(value1 && value2==''){
				return `$${value1}`
				}
			}
			if (formate === 'format5'||formate === 'Sale Price format2' ||formate === 'Final Price Format2') {
				if(value2==''){
					return `${value1} for $0.${value3}`
				}else if(value2 && value3){
					return `${value1} for $${value2}.${value3}`
				}else if(value2 && value3==''){
					return `${value1} for $${value2}`
			    }
			}
		}
		if (formate === 'format1' && (value1 !== null || value1 !== '')) {
			return `$${value1}`
		}
		return ''
	}

	async increase_product(i) {
		this.show_loader = true
		let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
		temp_itm.count += 1
		this.cartItem[this.cartItem.indexOf(i)] = temp_itm
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		this.show_loader = false
	}

	async decrease_product(i) {
		this.show_loader = true
		let temp_itm = this.cartItem[this.cartItem.indexOf(i)]
		if (temp_itm.count > 1) {
			temp_itm.count -= 1
			this.cartItem[this.cartItem.indexOf(i)] = temp_itm
		} else if (temp_itm.count === 1) {
			this.cartItem.splice(this.cartItem.indexOf(i), 1)
			if (!temp_itm.static && this.serializedIds.includes(i.product.uid)) {
				let title_bar: any = document.getElementById(i.product.uid).parentElement.childNodes[1]
				title_bar.classList.remove("test")
				this.cart_ids.splice(this.cart_ids.indexOf(i.product.uid), 1)
				document.getElementById(i.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
			}
		}
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		this.show_loader = false
	}

	async remove_one_from_cart(i, type) {
		this.show_loader = true
		if (type === 'one') {
			this.cartItem.splice(this.cartItem.indexOf(i), 1)
			if (!i.static && this.serializedIds.includes(i.product.uid)) {
				let title_bar: any = document.getElementById(i.product.uid).parentElement.childNodes[1]
				title_bar.classList.remove("test")
				this.cart_ids.splice(this.cart_ids.indexOf(i.product.uid), 1)
				document.getElementById(i.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
			}
		}
		if (type == 'selected') {
			this.cartItem = this.cartItem.filter(itm => {
				if (!this.selected_cart_items.includes(itm)) {
					return true
				} else {
					this.cart_ids.splice(this.cart_ids.indexOf(i.product.uid), 1)
					if (this.serializedIds.includes(itm.product.uid)) {
						let title_bar: any = document.getElementById(itm.product.uid).parentElement.childNodes[1]
						title_bar.classList.remove("test")
						document.getElementById(itm.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
						return false
					}
				}
			})
		}
		if (type === 'all') {
			this.cartItem.forEach(itm => {
				if (!itm.static && this.serializedIds.includes(itm.product.uid)) {
					let title_bar: any = document.getElementById(itm.product.uid).parentElement.childNodes[1]
					title_bar.classList.remove("test")
					this.cart_ids.splice(this.cart_ids.indexOf(itm.product.uid), 1)
					document.getElementById(itm.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
				}
			})
			this.cartItem = []
		}
		await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
		});
		this.show_loader = false
	}

	product_select_change(event) {
		if (event.event.target.checked && (event.event.type === 'all')) {
			this.selected_cart_items = this.cartItem
			this.isAllSelected = true
		} else if (!event.event.target.checked && (event.event.type === 'all')) {
			this.selected_cart_items = []
			this.isAllSelected = false
		} else if (event.event.target.checked && (event.event.type === 'single') && this.serializedIds.includes(event.event.product.product.uid)) {
			this.selected_cart_items.push(event.event.product)
			let title_bar: any = document.getElementById(event.event.product.product.uid).parentElement.childNodes[1]
			title_bar.classList.add("test")
			document.getElementById(event.event.product.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/right.png"
		} else if (!event.event.target.checked && (event.event.type === 'single') && this.serializedIds.includes(event.event.product.product.uid)) {
			this.selected_cart_items.splice(this.selected_cart_items.indexOf(event.event.product), 1)
			let title_bar: any = document.getElementById(event.event.product.product.uid).parentElement.childNodes[1]
			title_bar.classList.remove("test")
			this.cart_ids.splice(this.cart_ids.indexOf(event.event.product.product.uid), 1)
			document.getElementById(event.event.product.product.uid).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
		} else if (event.event.target.checked && (event.event.type === 'single') && this.serializedIds.includes(event.event.product.product.id)) {
			this.selected_cart_items.push(event.event.product)
			let title_bar: any = document.getElementById(event.event.product.product.id).parentElement.childNodes[1]
			title_bar.classList.add("test")
			document.getElementById(event.event.product.product.id).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/right.png"
		} else if (!event.event.target.checked && (event.event.type === 'single') && this.serializedIds.includes(event.event.product.product.id)) {
			this.selected_cart_items.splice(this.selected_cart_items.indexOf(event.event.product), 1)
			let title_bar: any = document.getElementById(event.event.product.product.id).parentElement.childNodes[1]
			title_bar.classList.remove("test")
			this.cart_ids.splice(this.cart_ids.indexOf(event.event.product.product.id), 1)
			document.getElementById(event.event.product.product.id).parentElement.lastElementChild.getElementsByTagName('img')[0].src = "../../../assets/imgs/plus.png"
		}
	}

	async add_to_list(item) {
		if (this.cart_ids.includes(item.uid)) {
			this._snackBar.open('Selected item is already present in your list', '', {
				duration: 2000,
			});
		} else {
			item.hide_addtolist_label = item.hide_addtolist_label ? item.hide_addtolist_label : ''
			item.price_label_value1 = item.price_label_value1 ? item.price_label_value1 : ''
			item.price_label_value2 = item.price_label_value2 ? item.price_label_value2 : ''
			item.price_label_value3 = item.price_label_value3 ? item.price_label_value3 : ''
			item.price_label_format = item.price_label_format ? item.price_label_format : ''
			item.sale_price_label_value1 = item.sale_price_label_value1 ? item.sale_price_label_value1 : ''
			item.sale_price_label_value2 = item.sale_price_label_value2 ? item.sale_price_label_value2 : ''
			item.sale_price_label_value3 = item.sale_price_label_value3 ? item.sale_price_label_value3 : ''
			item.sale_price_label_format = item.sale_price_label_format ? item.sale_price_label_format : ''

			item.product_image = item.product_image ? item.product_image : ''
			item.isVideo = item.isVideo ? item.isVideo : '',
				item.digital_coupon = item.digital_coupon ? item.digital_coupon : false;
			this.show_loader = true
			let msg
			if (item.digital_coupon) {
				msg = this.make_price_message(item?.sale_price_label_format, item?.sale_price_label_value1, item?.sale_price_label_value2, item?.sale_price_label_value3)

			} else {
				msg = this.make_price_message(item?.price_label_format, item?.price_label_value1, item?.price_label_value2, item?.price_label_value3)
			} if (document.getElementById(item.uid)) {
				let main_item = document.getElementById(item.uid)?.parentElement
				let layout_product_qnt = main_item?.parentElement?.getElementsByClassName("layout_product_qnt")[0]
				let layout_count_product: HTMLElement = main_item?.parentElement?.getElementsByClassName("layout_count_product")[0] as HTMLElement;

				let test = this.cartItem.some(x =>
					x?.product?.uid == item?.uid
				)
				if (!test) {
					this.cartItem.push({ product: item, count: 1, static: false, priceMessage: msg, digital_coupon: item.digital_coupon,isBundleProduct: item.isBundleProduct ? item.isBundleProduct : false ,displayValidDate:true });
					layout_product_qnt.innerHTML = "1"
				}

				const visibility = layout_count_product?.style?.visibility
				if (item.digital_coupon) {
					layout_count_product.style.visibility = 'hidden'
				} else {
					if (visibility == "hidden" || visibility == "") {
						layout_count_product.style.visibility = 'visible'
					} else {
						layout_count_product.style.visibility = 'hidden'
					}
				}
			} else {
				this.cartItem.push({ product: item, count: 1, static: false, priceMessage: msg, digital_coupon: item.digital_coupon,isBundleProduct: item.isBundleProduct ? item.isBundleProduct : false ,displayValidDate:false});
				this.cart_ids.push(item?.uid)
			}
			if (this.current_list_id !== '') {
				await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
				});
			}
			else {
				if (!this.createdCart) {
					this.createdCart = true;
					await this.createCart();
				}
			}
			this.show_loader = false
			if (this.serializedIds.includes(item.uid)) {
				let main_item
				if (document.getElementById(item.uid)) {
					if (document.getElementById(item.uid).tagName != 'VIDEO') {
						main_item = document.getElementById(item.uid)
					} else {
						main_item = document.getElementById(item.uid)?.parentElement
					}
				}
				let title_bar: any = main_item?.parentElement?.childNodes[1]
				let delete_icon = main_item?.parentElement.getElementsByClassName('deleteicon')[0]
				delete_icon.src = '../../../assets/imgs/right.png'
				title_bar.classList.add("test")
			}
			this.filtered_items = []
			this._snackBar.open('Item added in your list', '', {
				duration: 2000,
			});
		}
	}

	sendMail() {
		if (this.current_list_id) {
			const test = this.fns.httpsCallable('httpEmail')({
				to: this.emailForm.value.email,
				link: window.location.href + '/list/' + this.current_list_id
			}).toPromise()
			if (this.emailForm.value.email) {
				$("#EmailModel").modal("hide");
				this._snackBar.open('Email send successfull', '', {
					duration: 2000,
				});
				this.emailForm.reset()
			} else {
				this._snackBar.open('Please Enter Email..', '', {
					duration: 2000,
				});
			}
		};

	}

	async add_static() {
		this.show_loader = true
		let inpt: any = document.getElementById('addItem')
		this.cartItem.push({ productName: inpt.value, static: true, count: 1 })
		if (this.current_list_id !== '') {
			await this.afs.doc('/list/' + this.current_list_id).set({ items: this.cartItem }, { merge: true }).then(docRef => {
			});
		}
		else {
			if (!this.createdCart) {
				this.createdCart = true;
				await this.createCart();
			}
		}
		this.show_loader = false
		this.filtered_items = []
		inpt.value = ''
		this.no_content_display = false
		this._snackBar.open('Item added in your list', '', {
			duration: 2000,
		});
	}

	hendle_Add_item(type) {
		if (type === 'add') {
			this.show_add_item = true
		}
		if (type === 'close') {
			this.show_add_item = false
			this.filtered_items = []
		}
	}


	async ShowShoppingList() {
		$("#ShopDropdown").animate({
			right: "0"
		});
		$('.chervon-down-icon').addClass('d-none');
		$('.chervon-up-icon').removeClass('d-none');
	}
	removeFilter() {
		this.serializedData = this.current_layout_data[0]?.layout_json;
		this.loadCircularGrid(this.cart_ids)
	}
	CloseShoppingList() {
		$("#ShopDropdown").animate({
			right: "-100%"
		});
		$('.chervon-up-icon').addClass('d-none');
		$('.chervon-down-icon').removeClass('d-none');
		this.filtered_items = []
	}
	goToHome() {
		this.router.navigate(['./home']);
	}
	goToCouponPage() {
		this.router.navigate(['./coupon-list']);
	}
	goToRecipePage() {
		this.router.navigate(['./recipe-list']);
	}
	allSelection() {
		$('.all-selection-btn a').addClass('current');
		$('.coupons-only-selection-btn a').removeClass('current');
		$('.items-list').removeClass('d-none');
	}
	couponSelection() {
		$('.all-selection-btn a').removeClass('current');
		$('.coupons-only-selection-btn a').addClass('current');
		$('.items-list').addClass('d-none');
		$('.with-coupon').removeClass('d-none');
		$('.with-coupon').addClass('d-block');
	}
	bottomSliderDiv() {
		$('.slider').toggleClass('close');
	}

	copy_link() {
		var textarea = document.createElement("textarea");
		textarea.textContent = window.location.href + '/list/' + this.current_list_id;
		textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
		document.body.appendChild(textarea);
		textarea.select();
		try {
			document.execCommand("copy");  // Security exception may be thrown by some browsers.
			this._snackBar.open('Link is copied to your clipboard', '', {
				duration: 2000,
			});
		}
		catch (ex) {
			console.warn("Copy to clipboard failed.", ex);
		}
		finally {
			document.body.removeChild(textarea);
		}
	}

	async open_qr_code() {
	}
	async open_print() {
		this.printCart = true
		this.show_loader = true
		$('.print-head').removeClass('show-logo');
		$('body').addClass('scroll-disable');
		setTimeout(async () => {
			let element: any = document.getElementById("print-div");
			const canvas = await html2canvas(element, { useCORS: true, })
			const temp = new Promise(resolve => {
				canvas.toBlob(resolve)
			})
			await temp.then((res: any) => {
				var win = window.open(URL.createObjectURL(res));
				win.print()
				$('.print-head').addClass('show-logo');
				$('body').removeClass('scroll-disable');
				this.printCart = false
				this.show_loader = false
			})
		}, 500);
	}

	openHomePage() {
		if (this.is_list_view) {
			this.router.navigate(['/' + this.store_name_slug + '/' + this.store_name_slug + '/list/' + this.current_list_id]);
		} else {
			this.router.navigate(['/' + this.store_name_slug + '/' + this.store_name_slug]);
		}
	}

	openCouponsPage() {
		if (this.is_list_view) {
			this.router.navigate(['/' + this.store_name_slug + '/' + this.store_name_slug + '/coupons' + '/list/' + this.current_list_id]);
		} else {
			this.router.navigate(['/' + this.store_name_slug + '/' + this.store_name_slug + '/coupons']);
		}
	}

	openRecipesPage() {
		if (this.is_list_view) {
			this.router.navigate(['/' + this.store_name_slug + '/' + this.store_name_slug + '/recipes' + '/list/' + this.current_list_id]);
		} else {
			this.router.navigate(['/' + this.store_name_slug + '/' + this.store_name_slug + '/recipes']);
		}
	}

	normalView() {
		localStorage.setItem("view", "CircularView")
		window.location.reload()
	}

	largeView() {
		localStorage.setItem("view", "normalView")
		window.location.reload()
	}

	listView() {
		localStorage.setItem("view", "listView")
		window.location.reload()
	}

}
