import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone,ElementRef } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import { NgbModal, ModalDismissReasons,NgbModalRef,NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";
import * as $ from 'jquery';

@Component({
  selector: 'app-store-edit',
  templateUrl: './store-edit.component.html',
  styleUrls: ['./store-edit.component.scss']
})
export class StoreEditComponent implements OnInit {


storeaddForm: FormGroup;
closeResult:any;
modalRef:any;
create_warehouse_form:FormGroup;
warehouselist:any = [];
required_label_warehouse:boolean = false;
selected_warehouse:any;
id:any;
main_store_data:any;
user:any;
users:any;

@ViewChild('warehouseAddModal') warehouseAddModal;

  constructor( @Inject(PLATFORM_ID) private platformId: object,
  private route: ActivatedRoute,
  private router:Router,
  private formBuilder: FormBuilder,public authService: AuthService,
  public afs: AngularFirestore,
  public afAuth: AngularFireAuth,
  private modalService: NgbModal,
  private storage: AngularFireStorage,private _snackBar: MatSnackBar) {

  this.storeaddForm = new FormGroup({
	  company_name: new FormControl(null, Validators.compose([Validators.required])),
	  store_owner_name: new FormControl(null, Validators.compose([Validators.required])),
	  email       : new FormControl(null),
	  phone       : new FormControl(null),
	  warehouse   : new FormControl(0),
	  store_id    : new FormControl( null),
	  branch_name : new FormControl(null),
	  zip_code    : new FormControl(null),
	  address     : new FormControl(null),
	  menu1_name  : new FormControl(null),
	  menu1_link  : new FormControl(null),
	  menu2_name  : new FormControl(null),
	  menu2_link  : new FormControl(null),
	  menu3_name  : new FormControl(null),
	  menu3_link  : new FormControl(null),
	  menu4_name  : new FormControl(null),
	  menu4_link  : new FormControl(null),
	  menu5_name  : new FormControl(null),
	  menu5_link  : new FormControl(null),
	  menu6_name  : new FormControl(null),
	  menu6_link  : new FormControl(null),
	  menu7_name  : new FormControl(null),
	  menu7_link  : new FormControl(null),
	  menu8_name  : new FormControl(null),
	  menu8_link  : new FormControl(null),
	  menu9_name  : new FormControl(null),
	  menu9_link  : new FormControl(null),
	  access_layout: new FormControl(""),
	  access_recipe: new FormControl(""),
	  access_coupon: new FormControl(""),
	});

	this.create_warehouse_form = new FormGroup({
	  warehouse_name: new FormControl(null, Validators.compose([Validators.required])),
	  });


  }

  ngOnInit(): void {
	this.id = this.route.snapshot.params['id'];


	console.log('get data from login user');
	this.user = JSON.parse(localStorage.getItem('userrole'));
	console.log("USER GOT IISS---",this.user);

	//this.user = JSON.parse(localStorage.getItem('userrole'));
	this.afs.collection('/users', ref => ref.where('uid', '==', this.user.uid)).snapshotChanges().subscribe(data => {
	this.users = data.map(e => {
	return{
	uid: e.payload.doc.id,
	photoURL: e.payload.doc.data()['photoURL']?e.payload.doc.data()['photoURL']:'./assets/imgs/user-placeholder.png',
	displayName: e.payload.doc.data()['displayName'],
	role: e.payload.doc.data()['role']
	};
	});
	console.log(this.users);
	});



	this.afs.collection('/warehouses',ref=>ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
		this.warehouselist = data.map(e => {
			return{
				uid: e.payload.doc.id,
				warehouse_name: e.payload.doc.data()['warehouse_name']?e.payload.doc.data()['warehouse_name']:"",
			};
		}); 
	});

	this.afs.collection('/stores',ref=>ref.where('uid','==',this.id)).snapshotChanges().subscribe(data => {
	  this.main_store_data = data.map(e => {
		return{
		  	uid               	: e.payload.doc.id,
			email 				: e.payload.doc.data()['email'],
			company_name 		: e.payload.doc.data()['company_name'],
			store_owner_name 	: e.payload.doc.data()['store_owner_name'],
			phone 				: e.payload.doc.data()['phone'],
			warehouse 			: e.payload.doc.data()['warehouse'] ,
			store_id 			: e.payload.doc.data()['store_id'],
			branch_name 		: e.payload.doc.data()['branch_name'],
			zip_code 			: e.payload.doc.data()['zip_code'],
			address 			: e.payload.doc.data()['address'],
			menu1_name 			: e.payload.doc.data()['menu1_name'],
			menu1_link 			: e.payload.doc.data()['menu1_link'],
			menu2_name 			: e.payload.doc.data()['menu2_name'],
			menu2_link 			: e.payload.doc.data()['menu2_link'],
			menu3_name 			: e.payload.doc.data()['menu3_name'],
			menu3_link 			: e.payload.doc.data()['menu3_link'],
			menu4_name 			: e.payload.doc.data()['menu4_name'],
			menu4_link 			: e.payload.doc.data()['menu4_link'],
			menu5_name 			: e.payload.doc.data()['menu5_name'],
			menu5_link 			: e.payload.doc.data()['menu5_link'],
			menu6_name 			: e.payload.doc.data()['menu6_name'],
			menu6_link 			: e.payload.doc.data()['menu6_link'],
			menu7_name 			: e.payload.doc.data()['menu7_name'],
			menu7_link 			: e.payload.doc.data()['menu7_link'],
			menu8_name 			: e.payload.doc.data()['menu8_name'],
			menu8_link 			: e.payload.doc.data()['menu8_link'],
			menu9_name 			: e.payload.doc.data()['menu9_name'],
			menu9_link  		: e.payload.doc.data()['menu9_link'],
			type        		: e.payload.doc.data()['type'],
			branch_count 		: e.payload.doc.data()['branch_count'],
			can_login 			: e.payload.doc.data()['can_login'],
			access_layout		: e.payload.doc.data()['access_layout'],
			access_recipe 		: e.payload.doc.data()['access_recipe'],
			access_coupon 		: e.payload.doc.data()['access_coupon'],
			user_id   			: e.payload.doc.data()['user_id'],
		};
	  }); 

		this.storeaddForm.patchValue({
			company_name 		: this.main_store_data[0]?.company_name,
			store_owner_name 	: this.main_store_data[0]?.store_owner_name,
			email 				: this.main_store_data[0]?.email,
			phone 				: this.main_store_data[0]?.phone,
			warehouse 			: this.main_store_data[0]?.warehouse,
			store_id 			: this.main_store_data[0]?.store_id,
			branch_name 		: this.main_store_data[0]?.branch_name,
			zip_code 			: this.main_store_data[0]?.zip_code,
			address 			: this.main_store_data[0]?.address,
			menu1_name 			: this.main_store_data[0]?.menu1_name,
			menu1_link 			: this.main_store_data[0]?.menu1_link,
			menu2_name 			: this.main_store_data[0]?.menu2_name,
			menu2_link 			: this.main_store_data[0]?.menu2_link,
			menu3_name			: this.main_store_data[0]?.menu3_name,
			menu3_link 			: this.main_store_data[0]?.menu3_link,
			menu4_name 			: this.main_store_data[0]?.menu4_name,
			menu4_link 			: this.main_store_data[0]?.menu4_link,
			menu5_name 			: this.main_store_data[0]?.menu5_name,
			menu5_link 			: this.main_store_data[0]?.menu5_link,
			menu6_name 			: this.main_store_data[0]?.menu6_name,
			menu6_link 			: this.main_store_data[0]?.menu6_link,
			menu7_name 			: this.main_store_data[0]?.menu7_name,
			menu7_link 			: this.main_store_data[0]?.menu7_link,
			menu8_name 			: this.main_store_data[0]?.menu8_name,
			menu8_link			: this.main_store_data[0]?.menu8_link,
			menu9_name 			: this.main_store_data[0]?.menu9_name,
			menu9_link 			: this.main_store_data[0]?.menu9_link,
			access_layout 		: this.main_store_data[0]?.access_layout,
			access_recipe 		: this.main_store_data[0]?.access_recipe,
			access_coupon 		: this.main_store_data[0]?.access_coupon,
		});
	});

  }

  goToLogin() {
  window.location.href = '/login';
  }
  ChangePassword() {
  $('#exampleModal').css('opacity', '0.4');
  }
  closePopup() {
  $('#exampleModal').css('opacity', '1');
  }
  backToStore(){
  this.router.navigate(['/admin/store-owners']);
  }

  store_add(data)
  {
	console.log('store data got is ');
  console.log(data);
  if(data.email  == "")
  {
  	return false;
  }

  let storedata = {
	email: data.email?data.email:"",
	company_name:data.company_name?data.company_name:"",
	store_owner_name: data.store_owner_name?data.store_owner_name:"",
	phone: data.phone? data.phone:"",
	warehouse: data.warehouse?data.warehouse:"",
	store_id: data.store_id?data.store_id:"",
	branch_name: data.branch_name?data.branch_name:"",
	zip_code: data.zip_code?data.zip_code:"",
	address: data.address?data.address:"",
	menu1_name: data.menu1_name?data.menu1_name:"",
	menu1_link: data.menu1_link?data.menu1_link:"",
	menu2_name: data.menu2_name?data.menu2_name:"",
	menu2_link: data.menu2_link?data.menu2_link:"",
	menu3_name: data.menu3_name?data.menu3_name:"",
	menu3_link: data.menu3_link?data.menu3_link:"",
	menu4_name: data.menu4_name?data.menu4_name:"",
	menu4_link: data.menu4_link?data.menu4_link:"",
	menu5_name: data.menu5_name?data.menu5_name:"",
	menu5_link: data.menu5_link?data.menu5_link:"",
	menu6_name: data.menu6_name?data.menu6_name:"",
	menu6_link: data.menu6_link?data.menu6_link:"",
	menu7_name: data.menu7_name?data.menu7_name:"",
	menu7_link: data.menu7_link?data.menu7_link:"",
	menu8_name: data.menu8_name?data.menu8_name:"",
	menu8_link: data.menu8_link?data.menu8_link:"",
	menu9_name: data.menu9_name?data.menu9_name:"",
	menu9_link: data.menu9_link?data.menu9_link:"",
  };

  	if(data.email != this.main_store_data[0]?.email)
  	{

		  this.afAuth.createUserWithEmailAndPassword(data.email, "123456")
			.then((result) => {
			  /* Call the SendVerificaitonMail() function when new user sign
			  up and returns promise */
			  console.log('user id',result.user.uid);

			  console.log(result);
			  //user data 
			  let userdata = {
				email: data.email?data.email:"",
				displayName: data.store_owner_name?data.store_owner_name:"",
				uid: result.user.uid,
				role: "group",
				created_at: new Date(),
				can_login: '1',
				store_uid: this.id,
			  };

			  //add the new user data in to users table 
			  let useradd = this.afs.doc('/users/'+ result.user.uid).set(userdata,{merge:true}).then(docRef => {
			  });

			  storedata['user_id'] = result.user.uid;
			  //store data
			  let storeadd = this.afs.doc('/stores/'+ this.id).set(storedata,{merge:true});
				// const itemRefx= this.afs.doc('/stores/'+ this.id);
				// itemRefx.update({ user_id: result.user.uid });



				//remove the store id from the old user and delete the old user
				const itemRefx3 = this.afs.doc('/users/'+ this.main_store_data[0]?.user_id).set({store_uid: "user-deleted-"+this.id + "--"+ new Date().getTime() },{merge:true});

			  //sending password reset mail to store owner
			  this.ForgotPassword(data.email);

			  //navigate back to stores list page
			  this.router.navigate(['/admin/store-owners']);

			  this._snackBar.open("Store updated successfully", '', {
					duration: 1000,
				});

		  }, (err) => {
			console.log(err,"Error");
			this._snackBar.open(err.message, '', {
			  duration: 2000,
			});
		  });
	} else {

		let store_update = this.afs.doc('/stores/'+ this.id).set(storedata,{merge:true});
		 this.router.navigate(['/admin/store-owners']);
		 this._snackBar.open("Store updated successfully", '', {
					duration: 1000,
				});
	}

  }

  addWarehouse()
  {
	console.log('open warehouse modal here');
	this.open(this.warehouseAddModal,'modal-basic-title2');

  }
  warehouseChange()
  {
	console.log("SELECTED WAREHOUSE ",this.selected_warehouse);
	let selected_value = this.selected_warehouse;
	//console.log('warehouse value changedd',this.storeaddForm.value.warehouse.value);
	//console.log(value);
	if(selected_value == "add")
	{
	  //open the warehouse add modal
	  this.open(this.warehouseAddModal,'modal-basic-title2');
	}
  }

  submit_warehouse(data)
  {
	console.log(data);
  let thedata={
	warehouse_name: data.warehouse_name,
	created_at: new Date(),
  };

	let radio_add = this.afs.collection('/warehouses').add(thedata).then(docRef => {
	const itemRefx= this.afs.doc('/warehouses/'+docRef.id);
	itemRefx.update({ uid: docRef.id}); 
  });

  this.create_warehouse_form.reset();
  let element: HTMLElement = document.getElementsByClassName('close_add_warehouse_modal')[0] as HTMLElement;
  element.click();

  this._snackBar.open("Warehouse created successfully", '', {
	duration: 1000,
  });

  }

	open(content,title) {
	this.modalRef = this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
	this.closeResult = `Closed with: ${result}`;
	}, (reason) => {
	//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
	});
  }

  ForgotPassword(passwordResetEmail) {
	return this.afAuth.sendPasswordResetEmail(passwordResetEmail)
	.then(() => {
	console.log('password reset email sent to '+ passwordResetEmail);
	// window.alert('Password reset email sent, check your inbox.');
	}).catch((error) => {
	//window.alert(error)
	})
  }
  delete_warehouse(uid)
  {
	console.log(uid);
	  let del_ware = this.afs.doc('/warehouses/'+uid).delete();
	  this._snackBar.open('Warehouse deleted successfully', '', {
		duration: 1000,
	  });
 
  }


}
