import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import Shepherd from 'shepherd.js';

@Injectable({
  providedIn: 'root'
})
export class AppShoppinglistTourService {

  private tour: Shepherd.Tour;
  private shoppingTourSeenFlag = 'shoppingTourSeen';
  previousStepID: string;
  currentUrl: string;
  CurrentStepID: any;
  stepsID: string;
  constructor(private router: Router) {
    this.tour = new Shepherd.Tour({
      useModalOverlay: true,
      defaultStepOptions: {
        classes: "shadow-md bg-purple-dark",
        scrollTo: {
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        },

      },
    });

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart && this.tour.isActive) {
        if (event.url !== this.currentUrl) {
          this.tour.cancel();
        }
        this.currentUrl = event.url;
      }


    });
  }

  private setTourSeenFlag() {
    localStorage.setItem(this.shoppingTourSeenFlag, 'true');
  }

  private hasTourBeenSeen() {
    return localStorage.getItem(this.shoppingTourSeenFlag) === 'true';
  }




  startTour() {

    if (!this.hasTourBeenSeen()) {
      this.tour.addSteps([
        {
          id: 'The Shopping List',
          title: '<h3>Shopping List Section</h3>',
          text: '<p>This is your shopping list! It is the list view of everything you added to your shopping list. On mobile, you can swipe left and right to open and close. There are many things you can do with this list. Let’s learn about them. </p>',
          attachTo: {
            element: '',
            on: 'bottom'
          },
          classes: 'shopping-tour',
          buttons: [
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'START TOUR',
              action: this.tour.next
            }

          ]
        },
        {
          id: 'step-1',
          title: '<h3>Send List To Phone</h3>',
          text: '<p>This opens a QR Code. If you are using a PC you can scan the QR Code with your phone camera to get the list. If you are on Mobile, you can send your list to another phone.</p>',
          attachTo: {
            element: '#mobile',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-2',
          title: '<h3>Share Shopping List</h3>',
          text: '<p>You can Email, Copy Link, and Send to Phone using this button.</p>',
          attachTo: {
            element: '#print',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#print');
              if (!element) {
                if (previousId === 'step-1') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-3',
          title: '<h3>Print Shopping List</h3>',
          text: '<p>If you are connected to a printer, you can print your shopping list. </p>',
          attachTo: {
            element: '#share',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#share');
              if (!element) {
                if (previousId === 'step-2') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: this.tour.next
            }
          ]
        },
        {
          id: 'step-4',
          title: '<h3>Clear List</h3>',
          text: '<p>Use this to clear your shopping list.</p>',
          attachTo: {
            element: '#clear-list',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#clear-list');
              if (!element) {
                if (previousId === 'step-3') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },
        {
          id: 'step-5',
          title: '<h3>Add Item Manually</h3>',
          text: '<p>You can manually add any item you need to your shopping list using this button.</p>',
          attachTo: {
            element: '#add-item',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#add-item');
              if (!element) {
                if (previousId === 'step-4') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },
        {
          id: 'step-6',
          title: '<h3>Current Items</h3>',
          text: '<p>Your items from the current circular will display below this line. If you have items from a previous circular, they will show up under a Past Items list below the current items, so you never get confused.</p>',
          attachTo: {
            element: '#currentItems',
            on: 'bottom',
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#currentItems');
              if (!element) {
                if (previousId === 'step-5') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }

            }
          ]
        },
        {
          id: 'step-7',
          title: '<h3>Past Items</h3>',
          text: '<p>The idata-popper-placement="bottom"tems from past circulars will show up under this list.</p>',
          attachTo: {
            element: '#pastItems',
            on: 'bottom'
          },
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#pastItems');
              if (!element) {
                if (previousId === 'step-6') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },

          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },
        {
          id: 'step-8',
          title: '<h3>Remove Selected Items</h3>',
          text: '<p>This button will remove any items you have selected by clicking the box to the left of each product in your shopping list.</p>',
          attachTo: {
            element: '.remove-btn',
            on: 'bottom'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.remove-btn');
              if (!element) {
                if (previousId === 'step-7') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },
        {
          id: 'step-9',
          title: '<h3>Add Quantity Button</h3>',
          text: '<p>Need several of the same item? Click the Plus button to add quantity.</p>',
          attachTo: {
            element: '#plus',
            on: 'bottom'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#plus');
              if (!element) {
                if (previousId === 'step-8') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },
        {
          id: 'step-10',
          title: '<h3>Remove Quantity Button</h3>',
          text: '<p>Use this button to lower the quantity.</p>',
          attachTo: {
            element: '#remove',
            on: 'bottom'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#remove');
              if (!element) {
                if (previousId === 'step-9') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },

        {
          id: 'step-11',
          title: '<h3>Product Check Box</h3>',
          text: '<p>Use this check box two ways.</p><p>1. Use this check box to select and remove items from your shopping list.</p> <p>     2. While in the store, use this check box to mark off items you have in your shopping cart.</p>',
          attachTo: {
            element: '.select-all-with-checkbox',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('.select-all-with-checkbox');
              if (!element) {
                if (previousId === 'step-10') {
                  this.tour.next();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'CANCEL',
              classes: 'shepherd-button-secondary',
              action: this.tour.cancel
            },
            {
              text: 'NEXT',
              action: () => {
                this.tour.next();
              }
            }
          ]
        },


        {
          id: 'step-12',
          title: '<h3>The Help Icon</h3>',
          text: '<p>Press this icon to open this tutorial anytime.  </p>',
          attachTo: {
            element: '#helpicon',
            on: 'bottom-start'
          },
          classes: 'shopping-tour',
          when: {
            show: () => {
              const previousId = this.stepsID;
              const stepID = this.tour.getCurrentStep().id;
              this.stepsID = stepID;
              const element = document.querySelector('#helpicon');
              if (!element) {
                if (previousId === 'step-11') {
                  this.tour.complete();
                }
                else {
                  this.tour.back();
                }
              }
            },
          },
          buttons: [
            {
              text: 'BACK',
              classes: 'shepherd-button-secondary',
              action: this.tour.back
            },
            {
              text: 'FINISH',
              action: () => {
                this.tour.complete()
              }
            }
          ]
        },
      ]);

      this.tour.start();

      this.setTourSeenFlag();

    }
  }

  cancelTour() {
    this.tour.cancel();
  }

  private getButtons(labels: string[]): Shepherd.Step.StepOptionsButton[] {
    return labels.map((label) => ({
      action: this.tour[label.toLowerCase()],
      classes: label.toLowerCase() + "-button",
      text: label,
    }));
  }
}
