import { Component, PLATFORM_ID, Inject, ViewChild, OnInit,NgZone } from '@angular/core';
import { AuthService } from "../../../shared/services/auth.service";
import { Router } from "@angular/router";
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormGroup, FormBuilder, FormControl,Validators } from '@angular/forms';
import { User } from "../../../shared/services/user";
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from "@angular/fire/compat/storage";
import { MatSnackBar} from '@angular/material/snack-bar';
import {NgbModal, ModalDismissReasons,NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { AngularFireAuth } from "@angular/fire/compat/auth";

@Component({
  selector: 'app-saved-templates',
  templateUrl: './saved-templates.component.html',
  styleUrls: ['./saved-templates.component.scss']
})
export class SavedTemplatesComponent implements OnInit {



tableColumns  :  string[] = ['id', 'template_name', 'created_by','assigned_to'];
groups_list:any;
closeResult:any;
view_details:any; 
incident_list:any;
user:any;
get_current_store_id:any;
ghost_mode:boolean =false;
user_store_uid:any;
templates:any;
stores_list:any;
@ViewChild('leadsSort', {static: true}) leadsSort: MatSort;
@ViewChild('leadsPaginator', {static: true}) leadsPaginator: MatPaginator;
@ViewChild('viewIncidentModal') viewIncidentModal;


  	constructor( @Inject(PLATFORM_ID) private platformId: object,
	private route: ActivatedRoute,
	private router:Router,
	private formBuilder: FormBuilder,public authService: AuthService,
	public afs: AngularFirestore,
	public afAuth: AngularFireAuth,
	private modalService: NgbModal,
	private storage: AngularFireStorage,private _snackBar: MatSnackBar) {
		//this.leadsTableDataSource = new MatTableDataSource(route.snapshot.data['data'].leadsData);
	

   }

  	ngOnInit() {
  		this.user =  JSON.parse(localStorage.getItem('userrole'));
  		console.log("DATA FROM SAVED TEMP USER---",this.user);
  		if(this.user)
  		{
  			this.afs.collection('/templates',ref=>ref.where('assigned_to','array-contains',this.user.uid).orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.templates = data.map(e => {
				return{
					uid: e.payload.doc.id,
					template_name: e.payload.doc.data()['template_name']?e.payload.doc.data()['template_name']:"",
					created_at: e.payload.doc.data()['created_at']?e.payload.doc.data()['created_at']:"",
					created_by: e.payload.doc.data()['created_by']?e.payload.doc.data()['created_by']:"",
					created_by_uid: e.payload.doc.data()['created_by_uid']?e.payload.doc.data()['created_by_uid']:"",
					assigned_to: e.payload.doc.data()['assigned_to']?e.payload.doc.data()['assigned_to']:[],
				}; 
			});	

			this.templates = new MatTableDataSource(this.templates);
			this.templates.sort = this.leadsSort;
			this.templates.paginator = this.leadsPaginator;
			});
  		}

  		this.afs.collection('/stores',ref=>ref.orderBy('created_at','desc')).snapshotChanges().subscribe(data => {
			this.stores_list = data.map(e => {
				return{
					uid: e.payload.doc.id,
					store_name: e.payload.doc.data()['store_name'],
					email: e.payload.doc.data()['email'],
					group_id: e.payload.doc.data()['group_id'],
					group_name: e.payload.doc.data()['group_name'],
					phone: e.payload.doc.data()['phone'],
					store_id: e.payload.doc.data()['store_id'],
					store_owner_name: e.payload.doc.data()['store_owner_name'],
					warehouse_id: e.payload.doc.data()['warehouse_id'],
					warehouse_name: e.payload.doc.data()['warehouse_name'],
					created_at:e.payload.doc.data()['created_at'],
					can_login:e.payload.doc.data()['can_login']?e.payload.doc.data()['can_login']:'1',
					status:e.payload.doc.data()['status'],
					user_id:e.payload.doc.data()['user_id']
				};
			});	
		});
		
 
  	}

  	openCreateLog()
  	{
  		this.router.navigate(['/admin/create-log-template']);
  	}

  	view_template(uid)
  	{
  		this.router.navigate(['/admin/log-template-view/'+uid]);
  	}


	open(content,title) {
		this.modalService.open(content, {ariaLabelledBy: title}).result.then((result) => {
		this.closeResult = `Closed with: ${result}`;
		}, (reason) => {
	//	this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
		});
	}

	view_incident_details(data)
	{
		this.view_details = data;
		this.open(this.viewIncidentModal,'modal-basic-title');
	}

	search_temp = (value: string) => {
		this.templates.filter = value.trim().toLocaleLowerCase();
	}

  	mark_as_complete(uid)
  	{
  		let incident_update = this.afs.doc('/incident-reports/'+uid).set({status:'completed'},{merge:true}).then(docRef => {
		});

		let element: HTMLElement = document.getElementsByClassName('close_incident_detail_modal')[0] as HTMLElement;
		element.click();

		this._snackBar.open("Reported Marked as complete successfully!", '', {
	    	duration: 1500,
	    });
  	}

} 